import { BillingProfileModel } from './billingProfileModel';
import { BrandModel } from './brandModel';
import { CategoryModel } from './categoryModel';
import { ErrorModel } from './errorModel';
import { OrderModel } from './orderModel';
import { PriceModel } from './priceModel';
import { PromotionModel } from './promotionModel';
import { QueryModel } from './queryModel';
import { RewardModel } from './rewardModel';
import { ShippingAddressModel } from './shippingAddressModel';
import { SortModel } from './sortModel';
import { StrategyInfoModel } from './strategyInfoModel';

export * from './initialStores';

export const models = {
    ErrorModel,
    PromotionModel,
    BrandModel,
    PriceModel,
    CategoryModel,
    SortModel,
    QueryModel,
    OrderModel,
    ShippingAddressModel,
    StrategyInfoModel,
    BillingProfileModel,
    RewardModel
};
