import { css } from 'styled-components';
import styled from 'styled-components/macro';
import Typography from '../../../../../../../../../../../../../../UiLibrary/Typography';

export default styled(Typography.Text)`
    width: 100%;
    padding-bottom: 20px;

    ${({ simple }) =>
        simple
            ? css`
                  padding: 0 24px 20px;
              `
            : ''}
`;
