import { flow, getParent, types } from 'mobx-state-tree';
import { getMetaByName } from '../utils';
import { BagStore } from './BagStore';

export const CartStore = types
    .model('CartStore', {
        isLoading: false,
        _id: types.maybeNull(types.string),
        bags: types.array(BagStore),
        priceDetails: types.maybeNull(
            types.model({
                qty: types.number,
                originalSubTotal: types.number,
                subTotal: types.number,
                discounts: types.number,
                total: types.number,
                fullTotal: types.number
            })
        ),
        discounts: types.maybeNull(types.array(types.frozen())),
        errorMessage: types.maybeNull(types.string),
        failedProductsIds: types.maybeNull(types.array(types.string))
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get isEmpty() {
            return !(self.bags && self.bags.length);
        },
        get failedProductsData() {
            return self.bags.reduce((accumulator, bag) => {
                bag.items.map((item) => (self.failedProductsIds.includes(item._id) ? accumulator.push(item) : null));
                return accumulator;
            }, []);
        },
        get hasGsProductInCart() {
            return self.bags.some((bag) => {
                return bag.items.reduce(function (accumulator, currentValue) {
                    const expirationMetadata = getMetaByName(currentValue.metadata, 'expireAt');
                    const couponExpireAt = expirationMetadata?.value;
                    const suiteCouponTimerEnd = couponExpireAt ? new Date(couponExpireAt) - Date.now() : 0;
                    if (suiteCouponTimerEnd > 0) return suiteCouponTimerEnd;
                    return accumulator;
                }, 0);
            });
        },
        get pointsEarned() {
            return self.bags.reduce((sum, bag) => {
                const { pointsDetails = {} } = bag;
                return sum + (pointsDetails.points || 0);
            }, 0);
        }
    }))
    .actions((self) => ({
        markLoading(loading) {
            self.isLoading = loading;
        },
        setErrorMessage(err) {
            if (err && err.response && err.response.data) {
                self.errorMessage = err.response.data.message;
                return true;
            }

            if (err && err.message) {
                self.errorMessage = err.message;
            }
        },
        loadCart: flow(function* loadCart() {
            self.root.markLoading(true);
            try {
                const data = yield self.root.callbacks.onCartFetch();
                self._id = data._id;
                self.bags = data.bags;
                self.priceDetails = data.priceDetails;
                self.discounts = data.discounts;

                self.root.markLoading(false);
            } catch (err) {
                console.error("Failed to load ", err); // eslint-disable-line
            }
        }),
        submitCart: flow(function* loadCart() {
            self.failedProductsIds = null;
            self.errorMessage = null;
            self.markLoading(true);
            try {
                const data = yield self.root.callbacks.onGetCheckoutKey(self._id, {
                    priceDetails: self.priceDetails,
                    metadata: self.root.metadata,
                    redirects: self.root.redirects
                });

                if (data.error && data.type === 'shipping') {
                    self.failedProductsIds = data.data;
                    self.markLoading(false);
                    return;
                }

                if (data.key) {
                    self.root.callbacks.onCartSubmit(self._id, data.key);
                } else if (data.updatedCart) {
                    self.bags = data.updatedCart.bags;
                    self.priceDetails = data.updatedCart.priceDetails;

                    self.setErrorMessage({
                        message: `Oops, prices have changed. Your cart total has changed since you added product(s) to cart (now $${self.priceDetails.fullTotal}).`
                    });
                    self.markLoading(false);
                }
            } catch (err) {
                self.setErrorMessage(err);
                self.markLoading(false);
            }
        }),
        changeItemQty: flow(function* changeItemQty(itemId, qty) {
            self.markLoading(true);
            try {
                const data = yield self.root.callbacks.onCartItemQtyChange({
                    itemId,
                    qty
                });
                // TODO: update only affected bag, not all bags
                self.bags = data.bags;
                self.priceDetails = data.priceDetails;

                self.markLoading(false);
            } catch (err) {
                console.error("Failed to load ", err); // eslint-disable-line
            }
        }),
        updateBagPriceAndPoints(bagId, data) {
            const selfBagIndex = self.bags.findIndex((bag) => bag._id === bagId);

            const nonRemovedItems = self.bags[selfBagIndex].items.filter((item) => !item.removed);

            if (selfBagIndex !== -1 && data.bags[selfBagIndex] && nonRemovedItems.length) {
                self.bags[selfBagIndex].priceDetails = data.bags[selfBagIndex].priceDetails;
                self.bags[selfBagIndex].pointsDetails = data.bags[selfBagIndex].pointsDetails;
            }
            self.priceDetails = data.priceDetails;
        },
        removeItem: flow(function* removeItem(itemId, bagId) {
            self.markLoading(true);
            try {
                const data = yield self.root.callbacks.onCartItemRemove({
                    itemId
                });
                self.updateBagPriceAndPoints(bagId, data);
                self.markLoading(false);
            } catch (err) {
                console.error("Failed to load ", err); // eslint-disable-line
            }
        }),
        undoRemoveItem: flow(function* undoRemoveItem(itemId, bagId) {
            self.markLoading(true);
            try {
                const data = yield self.root.callbacks.onCartItemUndoRemove({
                    itemId
                });
                self.updateBagPriceAndPoints(bagId, data);
                self.markLoading(false);
            } catch (err) {
                console.error("Failed to load ", err); // eslint-disable-line
            }
        }),
        buyNow: flow(function* buyNow(item) {
            self.markLoading(true);
            try {
                const metadata = self.root.metadata;
                if (item.metadata && item.metadata.length) {
                    item.metadata.forEach((data) => {
                        if (!metadata.some((rootData) => rootData.name === data.name)) {
                            metadata.push(data);
                        }
                    });
                }

                const payload = {
                    productId: item.productId,
                    variantId: item.variantId,
                    qty: item.qty,
                    prices: item.priceDetails,
                    metadata,
                    redirects: self.root.redirects
                };

                yield self.root.callbacks.onBuyNow(payload);
            } catch (err) {
                console.error("Failed to load ", err); // eslint-disable-line
                self.markLoading(false);
            }
        })
    }));
