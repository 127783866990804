import React from 'react';
import { CloseXIcon } from '../../../../icons';
import { Icon, Typography } from '../../../UiLibrary';
import { StyledButton, StyledButtonsWrapper, StyledClearButton } from './styled';

const SearchButtons = ({ inputValue, value, inputRef, onClear, onSubmit }) => {
    return (
        <StyledButtonsWrapper>
            {(inputValue || value) && (
                <StyledClearButton
                    data-e2e='search-clear-button'
                    type='unstyled'
                    onClick={() => {
                        inputRef?.current.focus();
                        inputRef.current.value = '';
                        return onClear();
                    }}
                >
                    <Icon stylePriority size='24px' component={() => <CloseXIcon />} />
                </StyledClearButton>
            )}
            <StyledButton
                size='small'
                type='primary'
                onClick={() => onSubmit({ search: inputValue })}
                data-e2e='search-apply-button'
            >
                <Typography.Text color='canvas' type='badgeMedium'>
                    Search
                </Typography.Text>
            </StyledButton>
        </StyledButtonsWrapper>
    );
};

export default SearchButtons;
