import React, { useState } from 'react';
import { StyledLabel } from './styled';

const Label = ({ children, label, value, theme, size, prefix, status }) => {
    const [focus, setFocus] = useState(false);
    const labelClass = focus || (value && value.length !== 0) ? 'label label-float' : 'label';

    return (
        <StyledLabel
            $status={status}
            $prefix={prefix}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
            $theme={theme}
            $size={size}
        >
            {children}
            <label className={labelClass}>{label}</label>
        </StyledLabel>
    );
};

export default Label;
