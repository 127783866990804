import React, { useContext } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS, RIGHT_INDENTS } from '../../../../../../../../../../constants';
import { Comment1Icon } from '../../../../../../../../../../icons';
import { Button, Icon, KliThemeContext, Typography } from '../../../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../../../constants';
import { useWriteReviewModal } from '../../../../hooks';
import { StyledNoReviewWrapper } from './styled';

const NoReview = inject('store')(
    observer(({ store }) => {
        const theme = useContext(KliThemeContext);
        const simple = store.view.isSimple(GRID_POINTS.sm);

        const openWriteReviewModal = useWriteReviewModal(simple, store);
        return (
            <StyledNoReviewWrapper>
                <Typography.Text
                    align='center'
                    type='body2'
                    color={theme.colors.secondaryText}
                    style={BOTTOM_INDENTS.M}
                >
                    There are no reviews for this product <br />
                    {store.view.isLoggedIn ? 'Your review may be the first.' : null}
                </Typography.Text>
                {store.view.isLoggedIn ? (
                    <Button type='secondary' onClick={openWriteReviewModal}>
                        <Icon component={() => <Comment1Icon />} style={RIGHT_INDENTS.XS} />
                        <Typography.Text type='badgeSmall'>Write a review</Typography.Text>
                    </Button>
                ) : null}
            </StyledNoReviewWrapper>
        );
    })
);

export default NoReview;
