import React, { useContext } from 'react';
import { ArrowChevronBackIcon, ArrowChevronForwardIcon } from '../../../../../icons';
import { KliThemeContext } from '../../../KliThemeProvider';
import { StyledButton } from './styled';

const ControlButton = ({ carouselRef, nativeControls, type = 'prev', ghost, ...props }) => {
    const theme = useContext(KliThemeContext);
    return (
        <StyledButton
            $theme={theme}
            $withoutMargin={nativeControls}
            type={ghost ? null : 'primary'}
            ghost={ghost}
            shape='circle'
            onClick={() =>
                !nativeControls &&
                carouselRef.current &&
                (type === 'prev' ? carouselRef.current.prev() : carouselRef.current.next())
            }
            {...props}
        >
            {type === 'prev' ? <ArrowChevronBackIcon /> : <ArrowChevronForwardIcon />}
        </StyledButton>
    );
};

export default ControlButton;
