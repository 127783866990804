import React, { Fragment, useEffect, useRef, useState } from 'react';
import nextId from 'react-id-generator';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../constants';
import { useSimpleInterface } from '../../../../utils';
import Placeholder from './components/Placeholder';
import SuggestionContent from './components/SuggestionContent';
import { useHandleClickOutside } from './hooks';
import { StyledContent, StyledSuggestion, StyledWrapper } from './styled';

const AutoSuggestions = inject('store')(
    observer(({ store, searchValue, onSetSearchValue, onSubmitSearch }) => {
        const ref = useRef(null);
        const { autoSuggestions, setAutoSuggestions } = store;
        const [highlightedSuggestion, setHighlightedSuggestion] = useState(null);
        const simple = useSimpleInterface(GRID_POINTS.sm);

        useHandleClickOutside(ref, setAutoSuggestions);

        useEffect(() => {
            if (!autoSuggestions?.length || !searchValue) setHighlightedSuggestion(null);
        }, [autoSuggestions]);

        if (!autoSuggestions?.length) return null;

        return (
            <Fragment>
                {!simple && <Placeholder suggestion={highlightedSuggestion} searchValue={searchValue} />}
                <StyledWrapper ref={ref}>
                    <StyledContent>
                        {autoSuggestions.map((suggestion) => (
                            <StyledSuggestion
                                onClick={() => {
                                    onSetSearchValue(suggestion);
                                    setHighlightedSuggestion(null);
                                    return onSubmitSearch({
                                        search: suggestion
                                    });
                                }}
                                onMouseEnter={() => setHighlightedSuggestion(suggestion)}
                                onMouseLeave={() => setHighlightedSuggestion(null)}
                                key={nextId()}
                                type='unstyled'
                            >
                                <SuggestionContent suggestion={suggestion} searchValue={searchValue} />
                            </StyledSuggestion>
                        ))}
                    </StyledContent>
                </StyledWrapper>
            </Fragment>
        );
    })
);

export default AutoSuggestions;
