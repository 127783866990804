import React, { useContext } from 'react';
import { Space } from 'antd';
import { KliThemeContext } from '../KliThemeProvider';
import { StyledAlert } from './styled';

const Alert = ({ isFixedOnMobile, afterIcon, marginBottom, ...props }) => {
    const theme = useContext(KliThemeContext);
    return (
        <StyledAlert
            $isFixedOnMobile={isFixedOnMobile}
            $theme={theme}
            action={
                afterIcon && (
                    <Space direction='vertical' align='center'>
                        {afterIcon}
                    </Space>
                )
            }
            $marginBottom={marginBottom}
            {...props}
        />
    );
};

export default Alert;
