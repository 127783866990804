import styled from 'styled-components';
import { CloseXIcon } from '../../../../../icons';
import { SlideFromRight } from '../../../KliAnimations';

export const StyledClearIcon = styled(CloseXIcon)`
    position: absolute;
    font-size: 24px;
    right: 0;
    top: ${({ $size }) => ($size === 'large' ? '20px' : '15px')};
    z-index: 4;
    color: ${({ $theme }) => $theme.colors.secondaryText};
    animation: ${SlideFromRight} 0.2s ease-in-out;
    cursor: pointer;
`;
