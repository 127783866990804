import { flow, getParent, types } from 'mobx-state-tree';
import { debounce } from '../utils';

const UPDATE_QTY_DEBOUNCE_TIME = 1000;

export const ItemStore = types
    .model('ItemStore', {
        _id: types.identifier,
        productId: types.string,
        productTitle: types.string,
        variantId: types.number,
        variantTitle: types.string,
        sku: types.optional(types.string, ''),
        weight: types.number,
        qty: types.number,
        maxQty: types.number,
        image: types.string,
        taxable: types.boolean,
        requiredShipping: types.boolean,
        priceDetails: types.model({
            qty: types.number,
            originalSubTotal: types.number,
            subTotal: types.number,
            discounts: types.number,
            total: types.number,
            fullTotal: types.number
        }),
        removed: false,
        metadata: types.array(types.frozen())
    })
    .views((self) => ({
        get bag() {
            return getParent(self, 2);
        },
        get pointsEarned() {
            // in case if bag has welcome points, then we don't need to show points on item level
            if (!self.bag.pointsDetails.isWelcome && self.metadata && self.metadata.length) {
                const { details } = self.metadata.find((el) => el && el.name === 'pointsEarned') || {};
                if (details && details.list) {
                    const percent = details.list.find((el) => el && el.type === 'percent');
                    return percent && percent.value ? Math.round(percent.value * self.qty) : 0;
                }
            }
            return 0;
        }
    }))
    .actions((self) => ({
        incrementQty() {
            self.qty += 1;
        },
        decrementQty() {
            self.qty -= 1;
        },
        updateQty: debounce(() => {
            self.bag.cart.changeItemQty(self._id, self.qty);
        }, UPDATE_QTY_DEBOUNCE_TIME),
        softRemove() {
            self.removed = true;
            self.bag.cart.removeItem(self._id, self.bag._id);
        },
        undoRemove() {
            self.removed = false;
            self.bag.cart.undoRemoveItem(self._id, self.bag._id);
        },
        hardRemove: flow(function* hardRemove(callback = () => null) {
            yield self.bag.cart.removeItem(self._id, self.bag._id);
            // eslint-disable-next-line promise/prefer-await-to-callbacks
            callback();
        })
    }));
