import React from 'react';
import { Icon } from '@klickly/front-packages';
import { StyledLoader } from './styled';

function CashbackLoader() {
    return (
        <StyledLoader>
            <Icon.Loader />
            <span>Confirming your reward ...</span>
        </StyledLoader>
    );
}

export default CashbackLoader;
