import React, { Fragment } from 'react';
import { Typography, useSimpleInterface } from '@klickly/front-packages';
import { GRID_DEVICES_MAX } from '../../../../../../constants';
import ArrowButton from '../ArrowButton';
import { StyledControls, StyledHeader, StyledSlideCounter } from './styled';

function SliderHeader({ title, sliderRef, currentSlide, slidesCount }) {
    const simple = useSimpleInterface(GRID_DEVICES_MAX.sm);
    const isLessThan1200px = useSimpleInterface(GRID_DEVICES_MAX.xl);
    const prevDisabled = currentSlide === 0;
    const nextDisabled = currentSlide + (isLessThan1200px ? 2 : 3) >= slidesCount;

    return (
        <StyledHeader $simple={simple}>
            <Typography.Title level={2}>{title}</Typography.Title>
            <StyledControls>
                {!simple && (
                    <Fragment>
                        <ArrowButton
                            disabled={prevDisabled}
                            onClick={() => sliderRef.current && sliderRef.current.slickPrev()}
                        />
                        <StyledSlideCounter type='descriptor1'>
                            {nextDisabled ? slidesCount : currentSlide + 1}/{slidesCount}
                        </StyledSlideCounter>
                        <ArrowButton
                            type='next'
                            disabled={nextDisabled}
                            onClick={() => sliderRef.current && sliderRef.current.slickNext()}
                        />
                    </Fragment>
                )}
            </StyledControls>
        </StyledHeader>
    );
}

export default SliderHeader;
