import { formatCashBack } from '../utils';

export const useCashBack = (store, location) => {
    const cashBack = store.marketplaceStore.cashBack || location.state?.cashBack;
    const previousCashBack = store.marketplaceStore.previousCashBack || location.state?.previousCashBack;

    return {
        previousCashBack: {
            value: previousCashBack ? Number(previousCashBack) : 0,
            viewValue: formatCashBack(previousCashBack)
        },
        cashBack: {
            value: cashBack ? Number(cashBack) : 0,
            viewValue: formatCashBack(cashBack)
        }
    };
};
