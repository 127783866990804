import { roundToLocaleString } from '../../../../../../../../../../../../../shared';

const MIN_DISCOUNT_PERCENTAGE_TO_SHOW = 3;

export const getPrices = (prices) => {
    if (!prices) {
        return {};
    }
    const textPrices = {
        showOriginalPrice: true
    };

    let discount = 0;

    if (prices.originalPrice) {
        textPrices.originalPrice = `$${roundToLocaleString(prices.originalPrice)}`;
        const priceDiff = prices.originalPrice - prices.price;
        if (priceDiff > 0) {
            discount = priceDiff;
        }
    } else {
        prices.originalPrice = prices.price;
    }

    if (discount) {
        const percentage = (Number(discount) / Number(prices.originalPrice)) * 100;
        textPrices.discount = `-$${roundToLocaleString(discount)}`;
        textPrices.showDiscountPercentage = percentage > MIN_DISCOUNT_PERCENTAGE_TO_SHOW;
        textPrices.discountPercentage = textPrices.showDiscountPercentage
            ? `(${roundToLocaleString(percentage, 0)}% off)`
            : '';
    }

    const priceText = textPrices.discountPercentage
        ? `$${roundToLocaleString(prices.price)} ${textPrices.discountPercentage}`
        : `$${roundToLocaleString(prices.price)}`;

    textPrices.price = prices.price === 0 ? 'Free' : priceText;

    if (!prices.originalPrice || prices.originalPrice === prices.price) {
        textPrices.showOriginalPrice = false;
    }

    return textPrices;
};
