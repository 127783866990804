import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Button, Typography } from '../../../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../../../constants';

export const StyledProductInfoWrapper = styled.div`
    max-width: calc(100% - 433px);
    width: 100%;
    position: relative;

    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.md
            ? css`
                  max-width: calc(100% - 320px);
              `
            : ''};

    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.sm
            ? css`
                  max-width: 100%;
              `
            : ''};
`;

export const StyledInfo = styled.div`
    ${({ simple }) =>
        simple
            ? css`
                  padding: 0 24px;
              `
            : ''};
`;

export const StyledBrandNameButton = styled(Button)`
    && {
        padding: 0 !important;
        cursor: ${({ $linkToBrand }) => ($linkToBrand ? 'pointer' : 'auto')};

        && {
            &:focus {
                outline: none;
            }
        }
    }
`;

export const StyledBrandName = styled(Typography.Text)`
    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.xxs
            ? css`
                  font-size: 14px;
              `
            : ''};
`;

export const StyledTitle = styled(Typography.Title)`
    margin-bottom: 6px;

    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.xxs
            ? css`
                  font-size: 18px;
              `
            : ''};
`;
