import { PROMOTIONS_PRICE_STATE } from '../../shared/constants';

export const INITIAL_STORES = {
    PDP: {
        showPDP: false,
        customizationsKey: 'suite'
    },
    MARKETPLACE: {
        promotions: [],
        pending: false,
        brandsPending: false,
        error: null,
        brandsError: null,
        page: 1,
        nextPage: 1,
        price: { ...PROMOTIONS_PRICE_STATE },
        isOpenOnMobile: false,
        strategyInfo: null,
        strategyPending: false
    },
    REWARDS: {
        pending: false,
        error: null,
        rewards: null,
        hasNextPage: false,
        updateRewardPending: false,
        updateRewardError: null
    },
    RECOMMENDED_PROMOTIONS: {
        pending: false,
        error: null,
        recommendedPromotions: []
    },
    CART: {
        pending: false,
        error: null
    }
};
