import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { $background, $border, $secondaryText, $white } from '../../../../../../styles';

export const Styles = styled.div`
    min-width: 385px;
    width: 385px;
    background-color: ${$background};
    padding: 24px;
    display: flex;
    flex-direction: column;

    ${({ $simple, $withExtraBottomContent }) =>
        $simple
            ? css`
                  min-width: unset;
                  max-width: 100%;
                  width: 100%;
                  padding-bottom: ${$withExtraBottomContent ? '24px' : '130px'};
              `
            : ''};
`;

export const DetailsBody = styled.div`
    color: ${$secondaryText};
    padding-bottom: 10px;
    border-bottom: 1px solid ${$border};
`;

export const DetailsFoot = styled.div`
    padding-top: 10px;
`;

export const PriceRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    white-space: nowrap;
`;

export const ButtonWrap = styled.div`
    width: 100%;
    margin-top: auto;
    background-color: inherit;

    ${({ simple }) =>
        simple
            ? css`
                  width: 100%;
                  margin: 0;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  z-index: 5;

                  background-color: ${$white};
                  padding: 24px;
                  border-top: 1px solid ${$border};
              `
            : ''};

    .anticon {
        margin-right: 8px;
    }
`;
