import React, { Fragment, useContext } from 'react';
import { ArrowChevronBackIcon } from '../../../../../../icons';
import { Col, Divider, Icon, KliThemeContext, Row, Typography } from '../../../../../UiLibrary';
import { StyledButton } from './styled';

const Title = ({ title = 'Categories', isRoot = false, onBackButtonClick }) => {
    const theme = useContext(KliThemeContext);
    return (
        <Fragment>
            <Row align='middle'>
                {!isRoot ? (
                    <Col>
                        <StyledButton
                            $theme={theme}
                            onClick={onBackButtonClick}
                            icon={<Icon component={() => <ArrowChevronBackIcon />} />}
                            type='unstyled'
                        />
                    </Col>
                ) : null}
                <Col>
                    <Typography.Text type='badgeMedium'>{title}</Typography.Text>
                </Col>
            </Row>
            <Divider height='1px' color={theme.colors.lines} />
        </Fragment>
    );
};

export default Title;
