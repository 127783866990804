import styled, { createGlobalStyle } from 'styled-components';
import { Icon, Typography } from '../../../../../../../../../../../../../../../UiLibrary';

export const StyledPopover = createGlobalStyle`
    .popover-points {
        .ant-popover-inner-content {
            padding: 8px 16px;
        }
    }
`;

export const StyledContent = styled(Typography.Text)`
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
        font-weight: 500;
    }
`;

export const StyledKliIcon = styled(Icon)`
    margin-left: 12px;
`;
export const StyledValue = styled.span`
    margin-left: 4px;
`;
