import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

function ErrorBoundary({ children }) {
    if (process.env.REACT_APP_BS_DP_CONSUMER_FRONT) {
        Bugsnag.start({
            apiKey: process.env.REACT_APP_BS_DP_CONSUMER_FRONT,
            autoTrackSessions: false,
            releaseStage: process.env.REACT_APP_FRONT_BUILD_ENV,
            plugins: [new BugsnagPluginReact()]
        });

        const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

        return <BugsnagErrorBoundary>{children}</BugsnagErrorBoundary>;
    }

    return children;
}

export { ErrorBoundary };
