export const processOriginalPrice = (originalPrice, price, customPrice) => {
    let isDisplayOriginalPrice = originalPrice && Number(originalPrice) > 0 && Number(originalPrice) > Number(price);

    if (customPrice && Number(customPrice) > 0 && Number(customPrice) > Number(price)) {
        originalPrice = customPrice;
        isDisplayOriginalPrice = true;
    }

    return [originalPrice, isDisplayOriginalPrice];
};
