import React, { useContext } from 'react';
import { KliThemeContext } from '../../../KliThemeProvider';
import { StyledMenu } from './styled';

const BaseMenu = ({ ...props }) => {
    const theme = useContext(KliThemeContext);
    return <StyledMenu $theme={theme} {...props} />;
};

export default BaseMenu;
