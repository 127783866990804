import styled, { css } from 'styled-components';
import { moveSlideshow } from '../KliAnimations';

export const InfinityScrollGalleryWrap = styled.div`
    height: ${({ height }) => height};
    width: 100%;
    margin: 0 auto;
    position: relative;
    transform: translate3d(0, 0, 0);
    display: flex;

    overflow: hidden;

    ${({ stopOnHover }) =>
        stopOnHover
            ? css`
                  &:hover > div {
                      animation-play-state: paused;
                  }
              `
            : ''}
`;

export const InfinityScrollGalleryMove = styled.div`
    height: 100%;
    transform: translate3d(0, 0, 0);
    display: flex;
    will-change: transform;
    animation: ${moveSlideshow} ${({ speed }) => speed}s linear infinite;
`;

export const InfinityScrollGalleryMoveItem = styled.div`
    height: 100%;
    width: auto;
`;
