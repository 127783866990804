import { Input, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { ReactComponent as CardIcon } from '../../../../../../assets/img/CreditCard.svg';
import { $background, $border, $secondaryText, $white } from '../../../../../../shared/constants';

export const StyledWrap = styled.div`
    width: 100%;

    // hide validation error
    & .ant-form-item-control-input + div {
        display: none !important;
    }

    & :where(.css-dev-only-do-not-override-14wwjjs).ant-form-item {
        margin-bottom: 0 !important;
    }
`;

export const StyledIconWrap = styled.div`
    width: 100%;
    max-width: 278px;
    margin-bottom: 20px;
`;

export const StyledCard = styled(CardIcon)`
    width: 50px;
    height: 40px;
`;

export const StyledCardWrap = styled.div`
    max-width: 420px;
    width: 100%;
    height: 221px;
    background-color: ${$background};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto 40px;
    border: 1px solid ${$border};
    border-radius: 10px;
`;

export const StyledNumberWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledCartNumber = styled(Typography.Title)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px 0 0 !important;
        padding-top: 3px;
    }
`;

export const StyledLast4Wrap = styled.div`
    position: relative;
    width: 72px;
    height: 36px;
    margin-bottom: 5px;

    &:after {
        content: '____';
        position: absolute;
        bottom: -1px;
        letter-spacing: 5px;
        padding-left: 9px;
        touch-action: none;
        pointer-events: none;
        font-size: 18px;
        color: ${$secondaryText};
    }
`;

export const StyledLast4 = styled(Input)`
    &&& {
        position: relative;
        width: 72px;
        height: 36px;
        padding: 0 0 0 8px;
        border: 1px solid ${$border};
        background-color: ${$white};
        margin-bottom: 5px;
        letter-spacing: 5px;
    }
`;

export const StyledError = styled(Typography.Text)`
    margin-bottom: 20px;
`;
