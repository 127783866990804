import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { LayoutContentListItem } from '../../../../../../shared/components/LayoutContentListItem';
import { Promotion } from '../../../../../../shared/components/Promotion';
import { getVariantByPrice } from '../../utils/price';
import { LayoutContentList } from './components/LayoutContentList';

export const Promotions = inject('store')(
    observer(({ store }) => {
        const { openPDP } = store.pdpStore;
        const { getPromotions, promotions, pending } = store.marketplaceStore;
        const { pending: publisherPending } = store.publisherStore;

        useEffect(() => {
            if (!pending && !promotions.length && !publisherPending) {
                getPromotions();
            }
        }, [publisherPending]);

        return (
            <LayoutContentList>
                {promotions &&
                    promotions.map((promotion, index) => {
                        const variant = getVariantByPrice(promotion.variants);

                        return (
                            <LayoutContentListItem key={promotion._id} data-e2e={`promotion-${index}`}>
                                <Promotion
                                    variant={variant}
                                    onBuy={() => {
                                        store.marketplaceStore.setCashBackValue(variant?.cashbackPrice);

                                        openPDP({
                                            promotionId: promotion._id,
                                            addToHistory: true,
                                            promotion
                                        });
                                    }}
                                    promotion={promotion}
                                />
                            </LayoutContentListItem>
                        );
                    })}
            </LayoutContentList>
        );
    })
);
