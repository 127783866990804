import styled from 'styled-components';

export const StyledLabel = styled.div`
    position: relative;
    margin-bottom: 12px;

    .label {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 3;
        border: 2px solid transparent;
        padding: ${({ $size }) => ($size === 'large' ? '18px 24px' : '14px 24px')};
        font: ${({ $theme }) => $theme.fonts.descriptor1};
        color: ${({ $theme }) => $theme.colors.secondaryText};
        transition: ${({ $theme }) => $theme.transition.default};
        pointer-events: none;
        touch-action: none;
    }

    .label-float {
        top: -8px;
        font: ${({ $theme }) => $theme.fonts.descriptor2};
        color: ${({ $theme }) => $theme.colors.secondaryText};
    }
`;
