import React from 'react';
import { CircleActionsCloseIcon } from '../../../../../../../../icons';
import Typography from '../../../../../../../UiLibrary/Typography';
import { StyledIcon, Styles } from './styles';

export const Error = ({ message }) => {
    return (
        <Styles>
            <StyledIcon size='22px' color='error' component={() => <CircleActionsCloseIcon />} />
            <Typography.Text type='descriptor1'>{message}</Typography.Text>
        </Styles>
    );
};
