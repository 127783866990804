import { createIframe } from '../elements';

export const loadTW = ({ src }, options = {}) => {
    const scriptUrl = new URL(src);
    const trackViewOn = scriptUrl.searchParams.get('track_view_event_on');
    const { id, loadOn, queryParams = {} } = options;

    for (const [key, value] of Object.entries(queryParams)) {
        scriptUrl.searchParams.set(key, value);
    }

    if (!loadOn || trackViewOn === loadOn) {
        createIframe({
            id: id || 'kl_tw_iframe',
            src: scriptUrl.toString()
        });
    }
};
