import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Button, Typography } from '../../../../../../../UiLibrary';

export const WrapStyles = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;

    ${({ $simple }) =>
        $simple
            ? css`
                  height: auto;
                  overflow-y: visible;
              `
            : ''};
`;

export const Styles = styled.div`
    max-width: 738px;
    width: 100%;
    margin: 0 auto;
    padding: 20px 24px 40px;

    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: 100%;
                  padding: 0;
              `
            : ''};
`;

export const StyledHeadLine2 = styled(Typography.Title)`
    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 0 24px;
              `
            : ''};
`;

export const BackButton = styled(Button)`
    && {
        padding: 0;

        ${({ $simple }) =>
            $simple
                ? css`
                      padding: 24px 0 0 24px;
                  `
                : ''};
    }
`;
