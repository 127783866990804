import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { inject, observer } from 'mobx-react';
import { paramsStorage } from '../../shared/utils';
import { InitWrapperStyles } from './styled';
import { loadGTM } from './utils';

export const InitWrapper = inject('store')(
    observer(({ store, children }) => {
        const location = useLocation();
        const { addEventListener, removeEventListener, onEventMessage } = store.eventListenerStore;
        const { getPublisherInfoByDomain, setCreditCardsLast4, setCustomerId, setIrclickid } = store.publisherStore;
        const urlSearchParams = new URLSearchParams(location.search);
        const id = urlSearchParams.get('id') || paramsStorage.get('id');
        const irclickid = urlSearchParams.get('irclickid');

        const limit = urlSearchParams.get('limit') || '';
        urlSearchParams.set('id', id);

        useEffect(() => {
            setCustomerId(id);
            setIrclickid(irclickid);
            getPublisherInfoByDomain({ getBrands: true });
            setCreditCardsLast4(limit);

            if (irclickid) {
                sessionStorage.setItem('irclickid', irclickid);
            }

            if (!window.___GTM) {
                (async () => {
                    try {
                        await loadGTM();
                        window.___GTM = true;
                    } catch (e) {
                        window.___GTM = false;
                        Bugsnag.notify(new Error('GTM load failed'));
                    }
                })();
            }
        }, []);

        useEffect(() => {
            function eventHandler(event) {
                // check only for klickly events
                const { origin = '', data = {} } = event || {};

                const isMsgFromKlickly = origin.includes('klickly');
                const isMsgFromDevTools = data.source === '@devtools-page';
                const isMsgFromIframeOrExtension = data.action || (data.payload && data.payload.action);

                if (isMsgFromKlickly && !isMsgFromDevTools && isMsgFromIframeOrExtension) {
                    onEventMessage(data);
                }
            }

            addEventListener({ eventName: 'message', eventHandler });
            return () => removeEventListener({ eventName: 'message', eventHandler });
        }, [addEventListener, removeEventListener, onEventMessage]);

        return <InitWrapperStyles>{children}</InitWrapperStyles>;
    })
);
