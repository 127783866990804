import styled from 'styled-components';
import { Button, Typography } from '../../../../UiLibrary';
import { $primary } from '../../../styles/colors';

export const StyledWrapper = styled.div`
    max-width: 100%;
    padding: 12px 0 32px 0;
`;

export const StyledSuggestionButton = styled(Button)`
    && {
        display: inline-flex;
        padding: 0 4px;
        font: inherit;
        color: ${$primary};
    }
`;

export const StyledText = styled(Typography.Text)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StyledTypoButton = styled(Button)`
    && {
        display: inline-flex;
        padding: 0 4px;
        font: inherit;
        font-weight: 500;
        color: ${$primary};

        &:hover:not(:disabled) {
            color: inherit;
        }
    }
`;
