import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

export const useFitContent = ({ wrapperRef, contentRef }) => {
    const [simple, setSimple] = useState(false);
    const [simpleBreakpoint, setSimpleBreakpoint] = useState(0);

    const onResize = useCallback(() => {
        if (wrapperRef?.current && contentRef?.current) {
            const shouldSimple = wrapperRef.current.offsetWidth < contentRef.current.offsetWidth;
            const moreSpaceThanBreakpoint = wrapperRef.current.offsetWidth > simpleBreakpoint;

            if (shouldSimple) {
                setSimpleBreakpoint(wrapperRef.current.offsetWidth + 20);
                setSimple(true);
            }

            if (!shouldSimple && moreSpaceThanBreakpoint && simpleBreakpoint) {
                setSimple(false);
                setSimpleBreakpoint(0);
            }
        }
    }, [contentRef, wrapperRef, simpleBreakpoint]);

    useEffect(() => {
        onResize();
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [onResize]);

    return simple;
};
