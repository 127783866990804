import styled, { css } from 'styled-components';

export const WrapStyles = styled.div`
    width: 100%;
    height: 100%;
`;

export const Styles = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;

    ${({ $env }) =>
        $env === 'kcp' &&
        css`
            height: auto;
            padding-top: 56px;
        `};

    ${({ simple }) =>
        simple
            ? css`
                  max-width: 100%;
                  padding: 24px 0;
              `
            : ''};
`;
