import React, { Fragment, useCallback, useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { EmojiSadFrownIcon, Icon, Search, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import RewardInfo from '../../../../shared/components/RewardInfo';
import { GRID_POINTS } from '../../../../shared/constants';
import { useLayoutOnBottomHandler } from '../../../../shared/hooks/useLayout';
import { CheckedFilters } from './components/CheckedFilters';
import { useEmailSubscriptionModal } from './components/EmailSubscriptionModal/hooks';
import { Promotions } from './components/Promotions';
import { StrategyBlock } from './components/StrategyBlock';
import { Message, Styles } from './styles';

const { Typos } = Search;

const PromotionsPage = inject('store')(
    observer(({ store }) => {
        const location = useLocation();
        const navigationType = useNavigationType();
        const { totalHits, page, pending, getPromotions } = store.marketplaceStore;
        const { pending: publisherInfoPending, customerEmailCaptureEnabled } = store.publisherStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { openEmailSubscriptionModal } = useEmailSubscriptionModal();

        const nextPage = useCallback(() => {
            if (page) getPromotions(true);
        }, [getPromotions, page]);

        useEffect(() => {
            const emailSubscriptionModalFlag = window.sessionStorage.getItem('email-subscription-modal');

            if (customerEmailCaptureEnabled && !emailSubscriptionModalFlag) {
                openEmailSubscriptionModal();
            }
        }, [customerEmailCaptureEnabled]);

        useLayoutOnBottomHandler(nextPage);

        return (
            <Fragment>
                {(pending || publisherInfoPending) && <Icon.Loader style={{ zIndex: 999 }} />}
                <Styles id='shop-promotions-container'>
                    {simple && (
                        <Fragment>
                            <RewardInfo closable isFixedOnMobile bottomIndent='24px' />
                            <Search type='dp' location={location} navigationType={navigationType} />
                        </Fragment>
                    )}
                    <StrategyBlock />

                    <Typos />
                    <CheckedFilters />
                    <Promotions />
                    {!pending && totalHits === 0 && (
                        <Message>
                            <Icon size='24px' component={() => <EmojiSadFrownIcon />} />
                            <Typography.Text>Sorry, nothing found</Typography.Text>
                        </Message>
                    )}
                </Styles>
            </Fragment>
        );
    })
);

export default PromotionsPage;
