import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../constants';
import { Icon, KliField } from '../../../UiLibrary';
import { $primaryText, $white } from '../../styles/colors';

export const Styles = styled.div`
    position: relative;
    display: flex;
    margin-left: auto;
    width: 100%;
    max-width: 440px;

    ${(prop) =>
        prop.$type === 'dp'
            ? css`
                  margin-right: auto;
                  margin-left: initial;
                  max-width: 691px;
                  @media (${GRID_DEVICES_MAX.md}) {
                      max-width: 100%;
                      margin-bottom: 24px;
                  }
              `
            : ''};

    input {
        padding-right: 56px;
    }

    div {
        &:first-child {
            flex-grow: 1;
        }

        label {
            span {
                &:nth-child(3) {
                    z-index: 3;
                }

                &:nth-child(4) {
                    svg {
                        top: 16px;
                        right: 15px;
                    }
                }
            }
        }
    }
`;

export const StyledInput = styled(KliField)`
    && {
        border: 2px solid ${$primaryText};
        border-right: none;
        background-color: ${$white};
        border-radius: 0;
        color: inherit;

        &:focus:not(:active) {
            outline: none;
        }
    }
`;

export const StyledIcon = styled(Icon)`
    &.anticon {
        height: 61px;
        line-height: 61px;
    }
`;
