import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Typography } from '../../../../../../../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../../../../../../../constants';

export const Styles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const Title = styled(Typography.Text)`
    padding-right: 20px;

    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.xs
            ? css`
                  max-width: 40%;
              `
            : ''};
`;
