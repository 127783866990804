import React from 'react';
import { inject, observer } from 'mobx-react';
import { TOP_INDENTS } from '../../../../../../constants';
import { roundToLocaleString } from '../../../../../../shared';
import Typography from '../../../../../UiLibrary/Typography';
import { Styles } from './styles';

export const OrderSummary = inject('store')(
    observer(({ store }) => {
        const price = `$${roundToLocaleString(store.totalPrice)}`;
        return (
            <Styles style={TOP_INDENTS.XL} data-e2e='orderSum-block'>
                <Typography.Title level={2}>Order summary</Typography.Title>
                <Typography.Title data-e2e='orderSum-val' level={2}>
                    {price}
                </Typography.Title>
            </Styles>
        );
    })
);
