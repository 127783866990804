import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Select } from '../../../../../../../../../../../../../UiLibrary';
import { COLORS } from '../../../../../../../../../../../../constants';

export const ItemsWrap = styled.div`
    width: fit-content;
    margin-left: auto;
    display: flex;
    flex-wrap: nowrap;
`;

export const Label = styled.label`
    color: ${({ active }) => (active ? COLORS.$white : COLORS.$secondaryText)};
    background-color: ${({ active }) => (active ? COLORS.$primaryText : COLORS.$background)};
    transition: all 0.2s linear;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    white-space: nowrap;
    margin-left: 16px;

    cursor: pointer;

    &:hover {
        ${({ disabled }) =>
            !disabled
                ? css`
                      color: ${COLORS.$white};
                      background-color: ${COLORS.$primaryText};
                  `
                : ''}
    }

    ${({ disabled }) =>
        disabled
            ? css`
                  pointer-events: none;
                  opacity: 0.5;
              `
            : ''}
`;

export const Input = styled.input`
    width: 0 !important;
    height: 0 !important;
    visibility: hidden;
`;

export const WrapStyles = styled.div`
    overflow: ${({ simple }) => (simple ? 'visible' : 'hidden')};
    width: 100%;
`;

export const ContentStyles = styled.div`
    width: fit-content;
    margin-left: auto;

    & select {
        color: ${COLORS.$primaryText};
    }
`;

export const StyledSelect = styled(Select)`
    && {
        min-width: 200px;

        ${({ $appWidth }) =>
            $appWidth <= 330
                ? css`
                      max-width: 200px;
                  `
                : ''};
    }
`;

export const OptionsBlockWrap = styled.div`
    width: 100%;
`;
