import React, { useMemo } from 'react';
import { TOP_INDENTS } from '../../../../../../../../../../../../../../constants';
import { ArrowGoingUpIcon } from '../../../../../../../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../../../../../../../UiLibrary';
import HtmlViewer from './components/HtmlViewer';
import StyledLink from './styled/StyledLink';
import StyledText from './styled/StyledText';
import { getProductUrl } from './utils';

export const Details = ({ data, simple = false, brandLink, handleParameter }) => {
    const productUrl = useMemo(() => getProductUrl(brandLink, handleParameter), [brandLink, handleParameter]);
    return (
        <StyledText data-e2e='product-description' simple={simple} type='descriptor1' color='primaryText' as='div'>
            <HtmlViewer html={data} />
            <StyledLink style={TOP_INDENTS.XL} data-e2e='view-in-store-button' href={productUrl} target='_blank'>
                <Typography.Text as='div' type='badgeSmall'>
                    View in brand store
                </Typography.Text>
                <Icon size='16px' component={() => <ArrowGoingUpIcon />} />
            </StyledLink>
        </StyledText>
    );
};
