import { PROMOTIONS_PRICE_STATE } from '../../../../../shared/constants';

export const convertPriceFilter = ({ gte, lte }) => {
    if (lte !== PROMOTIONS_PRICE_STATE.lte && gte === PROMOTIONS_PRICE_STATE.gte) {
        return `under $${lte}`;
    }

    if (lte === PROMOTIONS_PRICE_STATE.lte && gte !== PROMOTIONS_PRICE_STATE.gte) {
        return `over $${gte}`;
    }

    return `$${gte} to $${lte}`;
};

export const getVariantByPrice = (variants) => {
    let variant = variants[0];

    variants.forEach((_variant) => {
        variant = variant.price < _variant.price ? variant : _variant;
    });

    return variant;
};

export const processOriginalPrice = (originalPrice, price, customPrice) => {
    let isDisplayOriginalPrice = originalPrice && Number(originalPrice) > 0 && Number(originalPrice) > Number(price);

    if (customPrice && Number(customPrice) > 0 && Number(customPrice) > Number(price)) {
        originalPrice = customPrice;
        isDisplayOriginalPrice = true;
    }

    return [originalPrice, isDisplayOriginalPrice];
};
