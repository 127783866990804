import React from 'react';
import { inject, observer } from 'mobx-react';
import { BagIcon } from '../../../../../../../../icons';
import { Button, Icon, Typography } from '../../../../../../../UiLibrary';
import { getPrices, GRID_POINTS } from '../../../../../../utils';
import EarnedPoints from './components/EarnedPoints';
import PayWithPointsForm from './components/PayWithPointsForm';
import RewardsApplied from './components/RewardsApplied';
import { ButtonWrap, DetailsBody, DetailsFoot, PriceRow, Styles } from './styles';

export const PricesDetails = inject('store')(
    observer(({ store, withExtraBottomContent, buttonBadge }) => {
        const simple = store.view.isSimple(GRID_POINTS.md);
        const priceDetails = store.cart.priceDetails;
        const pointsEarned = store.cart.pointsEarned;
        const hasGsProductInCart = store.cart.hasGsProductInCart;
        const customizations = store.view.customizations;
        const { showApplyPointsBlock, showRewards } = customizations;
        const { originalSubTotal, discounts, fullTotal, discountPercentage } = getPrices({ ...priceDetails });

        return (
            <Styles $simple={simple} $withExtraBottomContent={withExtraBottomContent}>
                <DetailsBody>
                    <PriceRow>
                        <Typography.Text data-e2e='items-number-title' type='body2'>
                            Number of items
                        </Typography.Text>
                        <Typography.Text data-e2e='items-number-value' type='body2'>
                            {priceDetails.qty}
                        </Typography.Text>
                    </PriceRow>
                    <PriceRow>
                        <Typography.Text data-e2e='original-price-title' type='body2'>
                            Original price
                        </Typography.Text>
                        <Typography.Text data-e2e='original-price-value' type='body2'>
                            {originalSubTotal}
                        </Typography.Text>
                    </PriceRow>
                    {discounts && !showRewards ? (
                        <PriceRow data-e2e='price-row-discounts'>
                            <Typography.Text type='body2'>You save</Typography.Text>
                            <Typography.Text data-e2e='discounts-value' type='body2'>
                                {discounts}
                            </Typography.Text>
                        </PriceRow>
                    ) : null}
                    {showRewards && <RewardsApplied discountInPercents={75} discountInUsd={12} rewardsCount={3} />}
                </DetailsBody>
                <DetailsFoot>
                    {showApplyPointsBlock ? <PayWithPointsForm priceDetails={priceDetails} /> : null}
                    <PriceRow data-e2e='price-row-estimated'>
                        <Typography.Text type='badgeLarge'>Estimated total</Typography.Text>
                        <Typography.Text data-e2e='estimated-total-value' type='badgeLarge'>
                            {fullTotal} {discountPercentage}
                        </Typography.Text>
                    </PriceRow>
                    {pointsEarned ? <EarnedPoints /> : null}
                    <Typography.Text type='descriptor2' color='secondaryText'>
                        Shipping and taxes are calculated at checkout
                    </Typography.Text>
                </DetailsFoot>
                <ButtonWrap simple={simple}>
                    <Button
                        data-e2e='check-out-button'
                        type='primary'
                        ghost
                        block
                        size='large'
                        onClick={() => store.cart.submitCart()}
                    >
                        <Icon stylePriority size='24px' component={() => <BagIcon />} />
                        <Typography.Text type='badgeLarge'>
                            {hasGsProductInCart ? 'Claim Gift' : 'Check out'}
                        </Typography.Text>
                    </Button>
                    {simple && buttonBadge ? buttonBadge : null}
                </ButtonWrap>
            </Styles>
        );
    })
);
