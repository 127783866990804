import { axiosInstance } from './axiosInstance';

/* eslint-disable no-useless-catch */
export const INITIAL_STORE = {
    get: async (url, params) => {
        try {
            const { data } = await axiosInstance.get(url, params && { params });
            return data;
        } catch (error) {
            throw error;
        }
    },
    post: async (url, body = {}) => {
        try {
            const { data } = await axiosInstance.post(url, body);
            return data;
        } catch (error) {
            throw error;
        }
    },
    patch: async (url, body = {}) => {
        try {
            const { data } = await axiosInstance.patch(url, body);
            return data;
        } catch (error) {
            throw error;
        }
    },
    put: async (url, body = {}) => {
        try {
            const { data } = await axiosInstance.put(url, body);
            return data;
        } catch (error) {
            throw error;
        }
    },
    delete: async (url, params) => {
        try {
            const { data } = await axiosInstance.delete(url, params && { params });
            return data;
        } catch (error) {
            throw error;
        }
    }
};
