import { roundToLocaleString } from '../shared';

export const cashbackCalculation = (store) => {
    const data = store.product?.data;

    if (!data.accountCommissions) {
        return false;
    }

    const cashback = {};
    const price = store.product?.variants?.youPayPrice;
    const youPay = price * store.product?.variants?.qty;
    const isAdjusted = data.accountCommissions.limitNewColdCustomerCommission;

    const klicklyCommissionRatio = isAdjusted ? 0.75 : 1;
    const customerCommission = data.publisherCommission?.customer / 100;
    const campaignCommission = data.campaignCommission / 100;
    const coldCustomerCommission = data.accountCommissions.coldCustomerCommission;
    const newCustomerCommission = data.accountCommissions.newCustomerCommission;
    const klicklyAndCustomerCommissionRatio = klicklyCommissionRatio * customerCommission;

    cashback.ccCashback = youPay * campaignCommission * klicklyAndCustomerCommissionRatio;
    cashback.cccCashback = youPay * klicklyAndCustomerCommissionRatio;
    cashback.nccCashback = youPay * klicklyAndCustomerCommissionRatio;

    if (isAdjusted && youPay > coldCustomerCommission) {
        cashback.cccCashback = coldCustomerCommission * klicklyAndCustomerCommissionRatio;
    }

    if (isAdjusted && youPay > newCustomerCommission) {
        cashback.nccCashback = newCustomerCommission * klicklyAndCustomerCommissionRatio;
    }

    if (!isAdjusted) {
        cashback.nccCashback = newCustomerCommission * klicklyAndCustomerCommissionRatio;
        cashback.cccCashback = coldCustomerCommission * klicklyAndCustomerCommissionRatio;
    }

    return `${roundToLocaleString(Math.max(...Object.values(cashback)))}`;
};
