import styled from 'styled-components';
import { KliHintersType } from './types';

export const KliNotificationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    margin: 0 0 10px;
    color: ${({ theme }) => theme.colors.primaryText};
    background: ${({ theme, type }) => `rgba(${theme.RGB[type]}, 0.1)`};
`;

export const IconWrap = styled.span`
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
    height: 26px;

    margin-left: ${(props) => (props.right ? '15px' : 0)};
    margin-right: ${(props) => (props.left ? '15px' : 0)};

    color: ${(props) => {
        if (props.type === KliHintersType.general) {
            return props.theme.colors.primaryText;
        }

        if (props.type === KliHintersType.general) {
            return props.theme.colors.primaryText;
        }

        if (props.type === KliHintersType.error) {
            return props.theme.colors.error;
        }

        if (props.type === KliHintersType.success) {
            return props.theme.colors.success;
        }

        if (props.type === KliHintersType.alert) {
            return props.theme.colors.alert;
        }
    }};
`;

export const NotificationContent = styled.div`
    display: flex;
    flex-grow: 2;
    justify-content: ${({ justify }) => justify};
    align-items: ${({ align }) => align};
`;
