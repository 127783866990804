import { keyframes } from 'styled-components';

export const SlideFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const SlideFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const PortalTopAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-50%, -110%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(-50%, -100%, 0);
  }
`;

export const PortalRightAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(10%, -50%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }
`;

export const PortalBottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-50%, 10%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
`;

export const PortalLeftAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-110%, -50%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(-100%, -50%, 0);
  }
`;
