import React from 'react';
import { observer } from 'mobx-react';
import { BOTTOM_INDENTS, TOP_INDENTS } from '../../../../../../../../constants';
import { PointsIcon } from '../../../../../../../../icons';
import { roundToLocaleString } from '../../../../../../../../shared';
import Typography from '../../../../../../../UiLibrary/Typography';
import { formatPoints } from '../../../../../../utils';
import { ProductCard } from '../ProductCard';
import {
    CardBody,
    CardHead,
    StyledBadge,
    StyledIcon,
    StyledPointsCount,
    StyledText,
    Styles,
    WelcomeGiftPoints,
    WelcomeGiftText
} from './styles';

export const BrandCard = observer(({ bag, orderNumber, simple, showProductPoints, onBuyNow }) => {
    const items = bag.items || [];
    const pointsDetails = bag.pointsDetails;

    return (
        <Styles $simple={simple}>
            <CardHead style={BOTTOM_INDENTS.SM} data-e2e='card-head' $simple={simple}>
                <StyledText data-e2e='order-number' type='badgeLarge'>
                    Order №{orderNumber}
                    {`${bag.merchantName ? ` - ${bag.merchantName}` : ''}`}
                </StyledText>
                <Typography.Text data-e2e='order-total-price' type='body1'>{`$${roundToLocaleString(
                    bag.priceDetails.fullTotal
                )}`}</Typography.Text>
            </CardHead>
            {showProductPoints && pointsDetails.isWelcome ? (
                <WelcomeGiftText>
                    <div>{bag.merchantName} Welcome Gift for your first order</div>
                    <WelcomeGiftPoints>
                        <StyledIcon size='14px' component={() => <PointsIcon />} /> +
                        {roundToLocaleString(pointsDetails.points, 0)}
                    </WelcomeGiftPoints>
                </WelcomeGiftText>
            ) : null}
            {showProductPoints && pointsDetails.points && !pointsDetails.isWelcome ? (
                <StyledBadge $simple={simple} data-e2e='points-count' color='primary'>
                    <StyledIcon size='14px' component={() => <PointsIcon />} />
                    <StyledPointsCount type='description1' as='span'>
                        +{formatPoints(pointsDetails.points, { million: true, billion: true })}
                    </StyledPointsCount>
                </StyledBadge>
            ) : null}
            <CardBody style={TOP_INDENTS.SM} data-e2e='card-body' $simple={simple}>
                {items.length &&
                    items.map((item, key) => {
                        return (
                            <div key={key} style={BOTTOM_INDENTS.XL}>
                                <ProductCard points={pointsDetails.points} key={key} item={item} onBuyNow={onBuyNow} />
                            </div>
                        );
                    })}
            </CardBody>
        </Styles>
    );
});
