import styled from 'styled-components';

export const StyledContent = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
`;

export const StyledImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 273px;
    margin-bottom: 16px;
`;

export const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const StyledBestSellersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > * {
        &:first-child {
            width: 315px;
        }
        &:last-child {
            flex-grow: 1;
            padding-left: 20px;
        }
    }
`;

export const StyledBestSellerTitleWrapper = styled.div`
    height: 100%;
`;
