import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared/constants';
import { get, goToGiftly } from '../shared/utils';

export const ThankYouStore = types
    .model('ThankYouStore', {
        pending: true,
        error: types.maybeNull(
            types.model({
                message: types.maybeNull(types.string),
                status: types.maybeNull(types.number)
            })
        ),
        isCustomerExists: false,
        customerInfoPending: false
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };
        const setError = (error) => {
            self.error = error;
            self.pending = false;
        };
        const getOrdersByCode = flow(function* getOrdersByCode(code) {
            return yield self.root.get(`${kcpAPI.customerData.orders.getByCode}?code=${code}`);
        });

        const processCheckoutOrder = flow(function* processCheckoutOrder(payload) {
            self.pending = true;
            try {
                const response = yield self.root.post(kcpAPI.orders.createCheckoutOrder, payload);

                const order = yield getOrdersByCode(payload.code);
                const cartOrders = get(order, 'cart.bags', []);

                if (response && response.ok) {
                    const data = (cartOrders || []).map((bag) => ({
                        klicklyOrderId: get(bag, 'klicklyOrderId', ''),
                        email: get(order, 'cart.customerInfo.email', ''),
                        cartId: get(order, 'cart._id', ''),
                        shopDomain: get(bag, 'shopDomain', ''),
                        accountId: get(bag, 'merchantId', ''),
                        brandName: get(bag, 'merchantName', ''),
                        orderId: get(bag, 'klicklyOrderId', ''),
                        orderValue: get(bag, 'priceDetails.subTotal'),
                        orderTax: get(bag, 'priceDetails.taxes'),
                        orderShipping: get(bag, 'priceDetails.shipping'),
                        campaignName: get(bag, 'campaigns[0]'),
                        items: bag.items.map((item) => ({
                            productName: item.productTitle,
                            productId: item.productId,
                            qty: item.qty,
                            price: item.price
                        }))
                    }));

                    self.root.trackingStore.sendEvent({
                        type: 'event',
                        name: 'purchase',
                        data
                    });
                }

                setPending(false);
            } catch (e) {
                setError(e);
            } finally {
                self.pending = false;
            }
        });

        const checkIfCustomerExists = flow(function* checkIfCustomerExists(email) {
            self.customerInfoPending = true;
            try {
                const data = yield self.root.get(kcpAPI.customer.exists, {
                    email
                });
                self.isCustomerExists = data.exists;
                self.customerInfoPending = false;
            } catch (error) {
                if (error.response) {
                    self.error = error.response.data.message;
                }
                self.customerInfoPending = false;
            }
        });

        const applyFit = flow(function* applyFit(orderId) {
            try {
                yield self.root.post(kcpAPI.customer.applyFit, { orderId });
                goToGiftly('onboarding/personal-info');
            } catch (error) {
                if (error.response) self.error = error.response.data.message;
            }
        });

        return {
            processCheckoutOrder,
            setPending,
            checkIfCustomerExists,
            applyFit
        };
    });
