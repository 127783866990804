import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Button, Image, Typography } from '../../../../../../../UiLibrary';

export const ReviewStyles = styled.div`
    width: 100%;
    padding: ${({ withPadding }) => (withPadding ? '0 24px' : '0')};
`;

export const ReviewHead = styled.div`
    width: 100%;
`;

export const ReviewHeadLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: ${({ justify }) => justify || 'space-between'};
    align-items: ${({ align }) => align || 'start'};
`;

export const StyledButton = styled(Button)`
    && {
        margin-left: 10px;
        padding-right: 0;

        .anticon {
            display: flex;
            align-items: center;
            margin-left: 12px;
        }
    }
`;

export const ReviewBody = styled.div``;

export const ReviewText = styled(Typography.Text)`
    ${({ $fullReview }) =>
        !$fullReview
            ? css`
                  display: -webkit-box;
                  -webkit-line-clamp: 4;
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                  overflow: hidden;
              `
            : ''}
`;

export const ReviewImages = styled.div`
    max-width: 100%;

    ${({ scrollImages }) =>
        scrollImages
            ? css`
                  display: flex;
                  overflow-x: auto;
              `
            : ''}

    ${({ $fullReview }) =>
        $fullReview
            ? css`
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
              `
            : ''}
`;

export const StyledImage = styled(Image)`
    max-height: ${({ $fullReview }) => ($fullReview ? '200px' : '80px')};
    width: auto;
    object-fit: contain;
    margin-right: 16px;
    margin-bottom: 16px;
    display: inline-block;
`;
