import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Button, Image, Typography } from '../../../../../../../UiLibrary';
import { $background, $secondaryText } from '../../../../../../styles';

export const Styles = styled.div`
    width: 100%;

    ${({ removed }) =>
        removed
            ? css`
                  background-color: ${$background};
                  padding: 20px 24px;
              `
            : ''};
`;

export const CardBody = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

export const CardBodyLeft = styled.div`
    display: flex;
    align-items: flex-start;
    padding-right: 50px;
`;

export const CardFoot = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ removed }) =>
        removed
            ? css`
                  align-items: center;
              `
            : ''};
`;

export const StyledImage = styled(Image)`
    && {
        width: 76px;
        height: 76px;
        object-fit: contain;
        margin-right: 10px;
    }
`;

export const ProductTitle = styled(Typography.Title)`
    margin-bottom: 5px;
`;

export const Info = styled.div``;

export const Prices = styled.div`
    white-space: nowrap;
    text-align: right;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
`;

export const RemoveButton = styled(Button)`
    && {
        padding: 0;
        color: ${$secondaryText};
    }
`;

export const BuyNowButton = styled(Button)`
    && {
        padding: 0;
        margin-left: 16px;

        .anticon {
            margin-right: 8px;
        }
    }
`;

export const StyledText = styled(Typography.Text)`
    padding-right: 16px;
`;

export const StyledPriceLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;

    & > *:not(:last-child) {
        margin-right: 8px;
    }
`;
