import styled, { css } from 'styled-components';
import { Button } from '../../../../../../../../../../../../UiLibrary';

export default styled(Button)`
    && {
        width: 100%;

        ${({ $singleButton }) =>
            $singleButton
                ? css`
                      width: 100%;
                      max-width: ${({ simple }) => (simple ? 'unset' : '415px')};
                  `
                : ''}

        ${({ $simple }) =>
            $simple
                ? css`
                      padding-left: 10px;
                      padding-right: 10px;
                  `
                : ''}

        .anticon {
            margin-right: 8px;
        }
    }
`;
