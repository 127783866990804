import React from 'react';
import { inject, observer } from 'mobx-react';
import { BrandCard } from '../brandCard';

export const OrdersData = inject('store')(
    observer(({ store }) => {
        const orders = store.orders;
        return orders && orders.length
            ? orders.map((bag, key) => {
                  return <BrandCard key={key} orderNumber={key + 1} orderLength={orders.length} bag={bag} />;
              })
            : null;
    })
);
