import React, { useContext } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import { StyledAntdCollapse, StyledAntdPanel } from './styles';

export const Collapse = ({ children, iconSize = '14px', withoutPadding = false, ...props }) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <StyledAntdCollapse $theme={themeContext} $iconSize={iconSize} $withoutPadding={withoutPadding} {...props}>
            {children}
        </StyledAntdCollapse>
    );
};

export const Panel = ({ children, ...props }) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <StyledAntdPanel $theme={themeContext} {...props}>
            {children}
        </StyledAntdPanel>
    );
};
