import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Icon, Typography } from '../../../../../../../UiLibrary';
import { COLORS } from '../../../../../../constants';
import { $border } from '../../../../../../styles';

export const Styles = styled.div`
    width: 100%;
    padding: 40px 0 15px;

    &:not(:last-child) {
        border-bottom: 1px solid ${$border};
    }

    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 24px 0 15px;
              `
            : ''};
`;

export const CardHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 0 24px;
              `
            : ''};
`;

export const CardBody = styled.div`
    width: 100%;

    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 0 24px;
              `
            : ''};
`;

export const StyledText = styled.div`
    padding-right: 16px;
`;

export const StyledIcon = styled(Icon)`
    margin-right: 4px;
`;

export const WelcomeGiftText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background: ${COLORS.$tartRedBackground};
    color: ${COLORS.$tartRed};
`;

export const WelcomeGiftPoints = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledBadge = styled(Typography.Text)`
    && {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: -5px 0 0 auto;
        white-space: nowrap;
        overflow: hidden;

        ${({ $simple }) =>
            $simple
                ? css`
                      padding: 0 24px;
                  `
                : ''};
    }
`;

export const StyledPointsCount = styled(Typography.Text)`
    font-weight: 500;
`;
