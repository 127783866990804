export const ROUTE_PATHS = {
    root: () => '/',
    marketplace: () => '/marketplace',
    marketplacePDP: () => '/marketplace/:promotionId?',
    betaTerms: () => '/betaterms',
    terms: () => '/terms',
    privacy: () => '/privacy',
    privacyCalifornia: () => '/privacy-california',
    privacyOptOut: () => '/privacy-opt-out',
    thankYouPage: () => '/thank-you',
    checkout: () => '/secure/checkout',
    preCheckout: () => '/checkout',
    notFound: () => '*'
};

export const ROUTE_TITLES = {
    root: () => 'Klickly | Shop',
    marketplace: () => 'Klickly | Shop',
    pdp: () => 'Klickly | Product',
    betaTerms: () => 'Klickly | Beta Terms',
    terms: () => 'Klickly | Terms',
    privacy: () => 'Klickly | Privacy Policy',
    privacyCalifornia: () => 'Klickly | Privacy Notice for California Residents',
    privacyOptOut: () => 'Klickly | Right to Opt Out of Sale of Information',
    thankYouPage: () => 'Klickly Shop | Thank you',
    checkout: () => 'Klickly | Checkout',
    notFound: () => '404'
};
