import React, { useRef, useState } from 'react';
import BottomControls from '../BottomControls';
import ControlButton from '../ControlButton';
import { StyledCarousel, StyledWrapper } from './styled';

const BaseCarousel = ({
    children,
    bottomControls,
    sideControls,
    infinite,
    afterChange = () => null,
    initialSlide,
    carouselRef: outerRef = null,
    ...props
}) => {
    const [prevDisabled, setPrevDisabled] = useState(!infinite && !initialSlide);
    const [nextDisabled, setNextDisabled] = useState(!infinite && initialSlide === children?.length - 1);
    const ref = useRef(null);
    const carouselRef = outerRef || ref;
    return (
        <StyledWrapper>
            <StyledCarousel
                ref={carouselRef}
                infinite={infinite}
                afterChange={(index) => {
                    if (!infinite) {
                        setPrevDisabled(index === 0);
                        setNextDisabled(index + 1 === children?.length);
                    }
                    return afterChange(index);
                }}
                $bottomControls={bottomControls}
                prevArrow={<ControlButton ghost nativeControls />}
                nextArrow={<ControlButton ghost nativeControls type='next' />}
                initialSlide={initialSlide}
                {...props}
            >
                {children}
            </StyledCarousel>
            <BottomControls
                bottomControls={bottomControls}
                carouselRef={carouselRef}
                prevDisabled={prevDisabled}
                nextDisabled={nextDisabled}
            />
        </StyledWrapper>
    );
};

export default BaseCarousel;
