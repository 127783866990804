import { formatTime } from './formatTime';

export const msToDisplayFormat = (timeLeftInMilliseconds) => {
    if (!timeLeftInMilliseconds || typeof timeLeftInMilliseconds !== 'number') {
        return null;
    }

    const timeLeftInSeconds = timeLeftInMilliseconds / 1000;

    if (timeLeftInSeconds <= 0) {
        return {
            days: formatTime(0),
            hours: formatTime(0),
            minutes: formatTime(0),
            seconds: formatTime(0)
        };
    }

    const d = Math.floor(timeLeftInSeconds / (3600 * 24));
    const h = Math.floor((timeLeftInSeconds % (3600 * 24)) / 3600);
    const m = Math.floor((timeLeftInSeconds % 3600) / 60);
    const s = Math.floor((timeLeftInSeconds % 3600) % 60);

    return {
        days: formatTime(d),
        hours: formatTime(h),
        minutes: formatTime(m),
        seconds: formatTime(s)
    };
};
