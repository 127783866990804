import React from 'react';
import { inject } from 'mobx-react';
import { BagIcon } from '../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../UiLibrary';
import { ContentStyles, StyledButton, StyledTitle, StyledTypography, WrapStyles } from './styles';

export const UnavailablePage = inject('store')(({ store }) => {
    return (
        <WrapStyles>
            <ContentStyles>
                <Icon
                    size='40px'
                    color='primary'
                    roundedColor='primaryBackground'
                    roundedSize='280%'
                    component={() => <BagIcon />}
                />
                <StyledTitle level={2} align='center'>
                    This item is not available
                </StyledTitle>
                <StyledTypography align='center' type='body1'>
                    We’re sorry for the inconvenience. Explore the Giftly Marketplace for other products and brands.
                </StyledTypography>
                {store.callbacks.onGoToMarketplaceClick ? (
                    <StyledButton type='primary' size='large' onClick={store.callbacks.onGoToMarketplaceClick}>
                        <Typography.Text type='badgeLarge'>Go to Marketplace</Typography.Text>
                    </StyledButton>
                ) : null}
            </ContentStyles>
        </WrapStyles>
    );
});
