import React from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../constants';
import { BackArrowIcon, CloseXIcon } from '../../../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../../../UiLibrary';
import { WriteReviewForm } from '../WriteReviewForm';
import { CloseModalIcon, FormWrapper, StyledHeader, Styles } from './styles';

export const WriteReview = inject('store')(
    observer(({ store, onModalClose, simple }) => {
        const { writing } = store.reviews;

        return (
            <Styles>
                {writing ? <Icon.Loader /> : null}
                <CloseModalIcon color='canvas' onClick={onModalClose} component={() => <CloseXIcon />} />
                <StyledHeader>
                    {simple ? (
                        <Icon
                            size='24px'
                            color='primary'
                            component={() => <BackArrowIcon />}
                            onClick={onModalClose}
                            style={BOTTOM_INDENTS.S}
                        />
                    ) : null}
                    <Typography.Title color='primary' align={simple ? 'left' : 'center'} style={BOTTOM_INDENTS.S}>
                        Write a review
                    </Typography.Title>
                    <Typography.Text type='body1' color='primary' align={simple ? 'left' : 'center'}>
                        Tell us what you think of your products
                    </Typography.Text>
                </StyledHeader>
                <FormWrapper $simple={simple}>
                    <WriteReviewForm onModalClose={onModalClose} />
                </FormWrapper>
            </Styles>
        );
    })
);
