import { Button, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $background } from '../../../../constants/styles';

export const StyledButton = styled(Button)`
    && {
        padding-left: 0;
        padding-right: 40px;
    }
`;

export const StyledText = styled(Typography.Text)`
    && {
        margin-bottom: 4px;
    }
`;

export const StyledHeadLine2 = styled(Typography.Title)`
    && {
        margin-bottom: ${({ $marginBottom }) => $marginBottom || 0};
    }
`;

export const StyledTextWrapper = styled.div`
    max-width: 850px;
    padding: 24px 24px 40px;
    background: ${$background};

    > svg {
        margin-bottom: 20px;
        font-size: 24px;
    }
`;
