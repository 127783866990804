import React, { useContext } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import { ButtonWrap, CardWrap, ContentWrap, ImageWrap, KliCardContentDirections } from './KliCard.styles';

export const KliCard = ({ children, img, button, direction = KliCardContentDirections.column, ...rest }) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <CardWrap theme={themeContext} direction={direction} {...rest}>
            {!!img && <ImageWrap>{img}</ImageWrap>}
            <ContentWrap direction={direction} withButton={!!button}>
                {children}

                {!!button && <ButtonWrap direction={direction}>{button}</ButtonWrap>}
            </ContentWrap>
        </CardWrap>
    );
};
