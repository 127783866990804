import React from 'react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../constants';
import { ArrowReplyIcon } from '../../../../../../../../../../icons';
import { Button, Icon, Typography } from '../../../../../../../../../UiLibrary';
import { $secondaryText } from '../../../../../../../../styles';
import { CardBody, CardFoot, Info, ProductTitle, StyledText, Styles } from '../../styles';

const REMOVED_LABEL = 'Item removed from shopping cart';

export const ProductCardRemoved = ({ item }) => {
    return (
        <Styles removed>
            <CardBody style={BOTTOM_INDENTS.XL}>
                <Info>
                    <ProductTitle level={3}>{item.productTitle}</ProductTitle>
                    <Typography.Text type='descriptor2' color={$secondaryText}>
                        {item.variantTitle.replace('Default Title', '')}
                    </Typography.Text>
                </Info>
            </CardBody>
            <CardFoot removed>
                <StyledText type='descriptor2' color={$secondaryText}>
                    {REMOVED_LABEL}
                </StyledText>
                <Button type='unstyled' style={{ padding: 0 }} onClick={() => item.undoRemove()}>
                    <Icon marginRight='6px' size='16px' stylePriority component={() => <ArrowReplyIcon />} />
                    <Typography.Text type='badgeSmall'>Undo</Typography.Text>
                </Button>
            </CardFoot>
        </Styles>
    );
};
