import qs from 'qs';
import { MARKETPLACE } from '../constants';
import { getPriceRange } from './getPriceRange';

export const getFilterParamsFromUrl = (params = {}) => {
    const searchParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
    });
    const optionalParams = {};
    if (searchParams.suggest) optionalParams.suggest = searchParams.suggest;

    return {
        search: searchParams.q || '',
        price: getPriceRange(searchParams.price),
        selectedBrands: searchParams.brands || [],
        strategy: searchParams.strategy || '',
        strategyId: searchParams.strategyId || '',
        gt: searchParams.gt || '',
        sort: searchParams.sort
            ? MARKETPLACE.PROMOTIONS_SORTS_STATE.map((sortItem) => ({
                  ...sortItem,
                  checked: sortItem.key === searchParams.sort
              }))
            : [...MARKETPLACE.PROMOTIONS_SORTS_STATE],
        ...optionalParams
    };
};
