import React from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import { Provider } from 'mobx-react';
import { device } from '../../utils';
import store from './_store/RootStore';
import PageHeader from './components/PageHeader';
import TaxonomyTree from './components/TaxonomyTree';
import { PSEUDO_ROOT_CATEGORIES_KEYS, TAXONOMY_ROOT_CATEGORIES } from './constants';
import { getCategoriesArrayFromString } from './utils';

const Taxonomy = ({
    taxonomyType = '',
    brandId = '',
    brandData = null,
    selectedSubCategory,
    setSelectedSubCategory,
    onAfterCategorySelect,
    inMobileMenu = false,
    selectCategoryOnArrowClick = false
}) => {
    const isIos = device.iOS();
    const isSafari = device.isSafari();

    return (
        <StyleProvider hashPriority={isSafari && !isIos ? 'high' : 'low'}>
            <Provider store={store}>
                <TaxonomyTree
                    brandId={brandId}
                    taxonomyType={taxonomyType}
                    brandData={brandData}
                    onAfterCategorySelect={onAfterCategorySelect}
                    setSelectedSubCategory={setSelectedSubCategory}
                    selectedSubCategory={selectedSubCategory}
                    inMobileMenu={inMobileMenu}
                    selectCategoryOnArrowClick={selectCategoryOnArrowClick}
                />
            </Provider>
        </StyleProvider>
    );
};

Taxonomy.TAXONOMY_ROOT_CATEGORIES = TAXONOMY_ROOT_CATEGORIES;
Taxonomy.PSEUDO_ROOT_CATEGORIES_KEYS = PSEUDO_ROOT_CATEGORIES_KEYS;
Taxonomy.getCategoriesArrayFromString = getCategoriesArrayFromString;
Taxonomy.store = store;
Taxonomy.CategoryPageHeader = PageHeader;

export default Taxonomy;
