import React, { Fragment } from 'react';
import { Dots, Value } from './styles';

export const AnimatedTime = ({ time }) =>
    time && time.days > 0 ? (
        <Fragment>
            <Value>{time?.days}D</Value>
            <Dots />
            <Value>{time?.hours}H</Value>
            <Dots />
            <Value>{time?.minutes}M</Value>
        </Fragment>
    ) : (
        <Fragment>
            <Value>{time?.hours}H</Value>
            <Dots />
            <Value>{time?.minutes}M</Value>
            <Dots />
            <Value>{time?.seconds}S</Value>
        </Fragment>
    );
