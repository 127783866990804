import React, { useState } from 'react';
import { FilledStarIcon } from '../../../../../../../../../../icons';
import { Icon } from '../../../../../../../../../UiLibrary';
import { StarRatingItemWrap, Styles } from './styles';

const StarRatingItems = ({ rate, maxRate = 5, icon = <FilledStarIcon />, onItemClick }) =>
    Array.from(new Array(maxRate).keys()).map((index) => (
        <StarRatingItemWrap key={index}>
            <Icon
                size='44px'
                roundedSize='110%'
                color={rate >= index + 1 ? 'primary' : 'secondaryText'}
                onClick={() => onItemClick(index + 1)}
                component={() => icon}
            />
            &nbsp; &nbsp;
        </StarRatingItemWrap>
    ));

export const StarRating = ({ value = 0, maxRate = 5, onChange, ...rest }) => {
    const [inputValue, setInputValue] = useState(value);

    const onRateSelect = (_value) => {
        setInputValue(_value);

        if (typeof onChange !== 'undefined') {
            onChange(_value);
        }
    };

    return (
        <Styles {...rest}>
            <StarRatingItems maxRate={maxRate} rate={inputValue} onItemClick={onRateSelect} />
        </Styles>
    );
};
