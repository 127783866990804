import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared/constants';

export const PreCheckoutStore = types
    .model('PreCheckoutStore', {
        pending: true,
        cartData: types.optional(
            types.model({
                _id: types.maybeNull(types.string),
                bags: types.array(
                    types.model({
                        _id: types.identifier,
                        merchantId: types.string,
                        merchantName: types.maybeNull(types.string),
                        items: types.array(
                            types.model({
                                _id: types.identifier,
                                productId: types.string,
                                productTitle: types.string,
                                variantTitle: types.string,
                                sku: types.optional(types.string, ''),
                                qty: types.number,
                                image: types.string,
                                taxable: types.boolean,
                                requiredShipping: types.boolean,
                                priceDetails: types.model({
                                    qty: types.number,
                                    subTotal: types.number,
                                    originalSubTotal: types.number,
                                    discounts: types.number,
                                    shipping: types.maybe(types.number),
                                    taxes: types.maybe(types.number),
                                    total: types.number,
                                    fullTotal: types.number
                                }),
                                metadata: types.array(types.frozen())
                            })
                        ),
                        priceDetails: types.model({
                            qty: types.number,
                            subTotal: types.number,
                            originalSubTotal: types.number,
                            discounts: types.number,
                            shipping: types.maybe(types.number),
                            taxes: types.maybe(types.number),
                            total: types.number,
                            fullTotal: types.number
                        })
                    })
                ),
                priceDetails: types.maybeNull(
                    types.model({
                        qty: types.number,
                        originalSubTotal: types.number,
                        subTotal: types.number,
                        discounts: types.number,
                        total: types.number,
                        fullTotal: types.number
                    })
                )
            }),
            {}
        ),
        email: types.maybe(types.string),
        error: types.maybe(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const getCartData = flow(function* getCartData(cartId) {
            try {
                const data = yield self.root.get(kcpAPI.cart.getById(cartId));
                self.cartData = data;
                self.pending = false;
                return data._id;
            } catch (error) {
                if (error.response) {
                    self.error = error.response.data.message;
                }
                self.pending = false;
            }
            return self.cartData;
        });

        return { getCartData };
    });
