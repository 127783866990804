import styled, { css } from 'styled-components';
import { $border, $primaryText, $secondaryText } from '../../../../styles';

export const Styles = styled.div`
    width: 100%;
    padding: 40px 0 22px;
    border-bottom: 1px solid ${$border};

    &:last-child {
        margin-bottom: 20px;
    }

    ${({ $simple }) =>
        $simple
            ? css`
                  &:last-child {
                      margin-bottom: 0;
                      border-bottom: none;
                  }
              `
            : ''};
`;

export const CardHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
`;

export const CardBody = styled.div`
    width: 100%;

    ${({ $simple }) =>
        $simple
            ? css`
                  padding: 0 24px;
              `
            : ''};
`;

export const StyledText = styled.div`
    padding-right: 16px;
    font-weight: 500;
`;

export const CardPrices = styled.div`
    padding-bottom: 16px;
    margin: 0 24px;
    color: ${$secondaryText};

    border-bottom: 1px solid ${$border};
`;

export const CardPricesRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SubtotalPrice = styled.div`
    color: ${$primaryText};
    padding: 16px 24px 0;
`;

export const StyledDivider = styled.div`
    width: 100%;
    height: 1px;
    margin: 16px 0;
    background: ${$border};
`;
