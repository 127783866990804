import { types } from 'mobx-state-tree';

// TODO: ReviewPage customizations model
export const ViewStore = types
    .model({
        route: types.string,
        type: types.optional(types.string, 'default'),
        customizations: types.optional(
            types.model({
                rootAppName: types.maybeNull(types.string),
                marketplaceLink: types.maybeNull(types.string),
                showAddedToCartModal: types.optional(types.boolean, true),
                showAddToCartConfirmModal: types.optional(types.boolean, false),
                buttons: types.optional(
                    types.model({
                        buyNowButtonLabel: types.optional(types.string, 'Buy now'),
                        addToCartButtonLabel: types.optional(types.string, 'Add to cart'),
                        cancelButtonLabel: types.optional(types.string, 'I’ll pass'),
                        showBuyNowButton: types.optional(types.boolean, true),
                        showAddToCartButton: types.optional(types.boolean, true),
                        showBuyNowButtonGiftIcon: types.optional(types.boolean, false),
                        showBuyNowButtonBagIcon: types.optional(types.boolean, false),
                        showAddToCartButtonIcon: types.optional(types.boolean, true),
                        showAddToCartButtonGiftIcon: types.optional(types.boolean, false),
                        pdpButtonsDirection: types.optional(types.string, 'row'),
                        continueShoppingButtonLabel: types.optional(types.string, 'Continue shopping'),
                        addToCartClickOutsideRedirectLink: types.maybeNull(types.string)
                    }),
                    {}
                ),
                prices: types.optional(
                    types.model({
                        originalPriceLabel: types.optional(types.string, 'Market price'),
                        discountLabel: types.optional(types.string, 'You save'),
                        dealPriceLabel: types.optional(types.string, 'You pay')
                    }),
                    {}
                ),
                rewards: types.optional(
                    types.model({
                        showRewards: types.optional(types.boolean, false),
                        color: types.optional(types.string, 'primaryColor')
                    }),
                    {}
                ),
                points: types.optional(
                    types.model({
                        showPoints: types.optional(types.boolean, false),
                        pointsLabel: types.optional(types.string, 'Members earn')
                    }),
                    {}
                ),
                cashBack: types.optional(
                    types.model({
                        showCashBack: types.optional(types.boolean, false),
                        color: types.optional(types.string, 'primary')
                    }),
                    {}
                ),
                reviews: types.optional(
                    types.model({
                        showReviews: types.optional(types.boolean, false)
                    }),
                    {}
                )
            }),
            {}
        ),
        appWidth: types.maybeNull(types.number),
        isLoggedIn: types.maybe(types.boolean)
    })
    .views((self) => ({
        isSimple(size) {
            return size > self.appWidth;
        },
        isUpsell() {
            return self.type === 'upsell';
        },
        withGSConfirmModal() {
            return self.customizations.showAddToCartConfirmModal;
        }
    }))
    .actions((self) => ({
        setRoute(route) {
            self.route = route;
        },
        setAppWidth(width) {
            self.appWidth = width;
        }
    }));
