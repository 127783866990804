import { roundToLocaleString } from '../../../shared';

const MIN_DISCOUNT_PERCENTAGE_TO_SHOW = 3;

export const getPrices = (prices) => {
    const responsePrice = {
        subTotal: `$${roundToLocaleString(prices.subTotal)}`,
        originalSubTotal: `$${roundToLocaleString(prices.originalSubTotal)}`,
        originalDiscount: 0,
        discounts: prices.discounts || 0,
        discountPercentage: '',
        showOriginalPrice: false
    };

    const isShipping = prices.shipping !== undefined && prices.shipping !== null;
    const isTaxes = prices.taxes !== undefined && prices.taxes !== null;

    if (prices.originalSubTotal && prices.originalSubTotal > prices.subTotal) {
        responsePrice.originalDiscount = prices.originalSubTotal - prices.subTotal;
        responsePrice.discounts += responsePrice.originalDiscount;
    }

    if (responsePrice.discounts) {
        const percentage =
            (Number(responsePrice.discounts) / Number(prices.total + responsePrice.originalDiscount)) * 100;
        const showDiscountPercentage = percentage > MIN_DISCOUNT_PERCENTAGE_TO_SHOW;
        responsePrice.discountPercentage = showDiscountPercentage ? `(${roundToLocaleString(percentage, 0)}% off)` : '';

        responsePrice.discounts = `-$${roundToLocaleString(responsePrice.discounts)}`;
    }

    if (isShipping) {
        responsePrice.shipping = prices.shipping === 0 ? 'Free' : `$${roundToLocaleString(prices.shipping)}`;
    }

    if (isTaxes) {
        responsePrice.taxes = prices.taxes === 0 ? 'Free' : `$${roundToLocaleString(prices.taxes)}`;
    }

    responsePrice.fullTotal = responsePrice.fullTotal === 0 ? 'Free' : `$${roundToLocaleString(prices.fullTotal)}`;
    return responsePrice;
};
