import styled from 'styled-components';

export const NotificationsProviderWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: transparent;
    display: flex;
    flex-direction: column;
`;
