import React from 'react';
import { inject, observer } from 'mobx-react';
import { PROMOTIONS_PRICE_STATE } from '../../../../../../../../shared/constants';
import { convertPriceFilter } from '../../../../utils/price';
import { Item } from '../../styles';

export const CheckedPrice = inject('store')(
    observer(({ store }) => {
        const { price, updatePrice: onRemove } = store.marketplaceStore;
        return (
            (price.lte !== PROMOTIONS_PRICE_STATE.lte || price.gte !== PROMOTIONS_PRICE_STATE.gte) && (
                <Item
                    type='secondary'
                    onClose={() =>
                        onRemove({
                            lte: PROMOTIONS_PRICE_STATE.lte,
                            gte: PROMOTIONS_PRICE_STATE.gte
                        })
                    }
                >
                    Price: {convertPriceFilter(price)}
                </Item>
            )
        );
    })
);
