import { CATEGORIES } from '../constants';

export const getAggregatedCategories = (aggregatedCategories, categories) =>
    (aggregatedCategories
        ? CATEGORIES.filter((category) => Object.keys(aggregatedCategories).includes(category.key))
        : CATEGORIES
    ).map((category) => {
        const usedCategory = categories.find((stateCategory) => stateCategory.key === category.key);
        return usedCategory || { ...category, checked: false };
    });
