import React, { Fragment } from 'react';
import { BackArrowIcon, CloseXIcon } from '../../../../../../../../../../../../icons';
import { Button, Carousel, Icon, Typography } from '../../../../../../../../../../../UiLibrary';
import {
    BodyStyles,
    CloseModalIcon,
    GalleryImage,
    GalleryImageWrap,
    HeaderButtonStyles,
    HeaderStyles,
    Styles
} from './styles';

export const Gallery = ({ images, initialSlide = 0, onClose, simple = false }) => {
    return (
        <Fragment>
            <Styles simple={simple}>
                <HeaderStyles>
                    <CloseModalIcon color='canvas' onClick={onClose} component={() => <CloseXIcon />} />
                    {simple ? (
                        <HeaderButtonStyles>
                            <Button type='unstyled' onClick={onClose}>
                                <Icon size='16px' component={() => <BackArrowIcon />} />
                                <Typography.Text type='badgeSmall'>Product details</Typography.Text>
                            </Button>
                            <Button type='unstyled' onClick={onClose}>
                                <Icon size='16px' component={() => <CloseXIcon />} />
                            </Button>
                        </HeaderButtonStyles>
                    ) : null}
                </HeaderStyles>
                <BodyStyles simple={simple}>
                    <Carousel
                        initialSlide={initialSlide}
                        bottomControls={{
                            justify: 'space-between',
                            off: images.length < 2
                        }}
                    >
                        {Boolean(images && images.length) &&
                            images.map((image, index) => {
                                return (
                                    <GalleryImageWrap simple={simple} key={index} id={index}>
                                        <GalleryImage src={image.src} />
                                    </GalleryImageWrap>
                                );
                            })}
                    </Carousel>
                </BodyStyles>
            </Styles>
        </Fragment>
    );
};
