import React from 'react';
import styled, { css } from 'styled-components';
import { ArrowChevronDownIcon, CircleActionsAlertInfoIcon, CloseXIcon } from '../../../../icons';
import { SlideFromRight } from '../../KliAnimations';

export const Label = styled.label`
    position: relative;
    display: block;
    width: 100%;

    .warning {
        display: none;
    }

    textarea,
    select {
        resize: none;
        height: 100%;
        width: 100%;
    }

    input:disabled,
    select:disabled {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    input,
    select,
    textarea {
        position: relative;
        z-index: 3;
        display: block;
        border: 2px solid transparent;
        height: 100%;
        width: 100%;

        padding: ${({ large, withoutClearButton }) => {
            if (withoutClearButton) {
                return large ? '26px 24px 14px 24px' : '22px 24px 10px 24px';
            }

            return large ? '26px 64px 14px 24px' : '22px 64px 10px 24px';
        }};
        padding-left: ${({ withIcon }) => (withIcon ? '60px' : '24px')};

        font: ${({ theme }) => theme.fonts.descriptor1};
        outline: none;
        background-color: transparent;
        transition: ${({ theme }) => theme.transition.default};

        &:disabled {
            padding-right: 24px;
        }

        &:focus:not(:disabled),
        &:active:not(:disabled) {
            border-color: ${({ theme }) => theme.colors.primaryText};
        }

        &:focus ~ div,
        &:active ~ div {
            background-color: transparent;
        }

        ${({ withoutBorderColorsOnValidate }) =>
            withoutBorderColorsOnValidate
                ? ''
                : css`
                      // valid state
                      &:valid:not(:focus)[data-highlight-validate-touch='true']:not([data-invalid='1']) {
                          border-color: ${({ theme }) => theme.colors.success};

                          & ~ span {
                              color: ${({ theme }) => theme.colors.success};
                          }
                      }

                      // invalid
                      &[data-invalid='1']:not(:focus),
                      &[data-highlight-validate-touch='true']:invalid:not(:focus),
                      &:invalid:not(:focus):not(:placeholder-shown) {
                          border-color: ${({ theme }) => theme.colors.error};

                          & ~ .warning {
                              display: block;
                          }

                          & ~ span {
                              color: ${({ theme }) => theme.colors.error};
                          }
                      }
                  `}
        &:disabled {
            cursor: auto;
        }

        // placeholder position on focus;
        &:focus + span,
        &:not(:placeholder-shown) + span {
            font: ${({ theme }) => theme.fonts.descriptor2};
            top: -8px;
        }
    }

    input {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-text-fill-color: inherit;
            -webkit-box-shadow: inherit;
            transition: background-color 5000s ease-in-out 0s;
        }
    }
`;
// placeholder styles;
export const Placeholder = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 3;

    border: 2px solid transparent;

    padding: ${({ large }) => (large ? '18px 24px' : '14px 24px')};
    padding-left: ${({ withIcon }) => (withIcon ? '60px' : '24px')};

    font: ${({ theme }) => theme.fonts.descriptor1};
    transition: ${({ theme }) => theme.transition.default};

    pointer-events: none;
    touch-action: none;
`;

// icons positions
export const ClearInputIcon = styled((props) => <CloseXIcon {...props} />)`
    position: absolute;
    font-size: 24px;
    right: 22px;
    top: ${({ large }) => (large ? '20px' : '15px')};
    z-index: 4;
    animation: ${SlideFromRight} 0.2s ease-in-out;

    cursor: pointer;
`;

export const WarningIcon = styled((props) => <CircleActionsAlertInfoIcon {...props} />)`
    position: absolute;
    font-size: 24px;
    right: 22px;
    top: ${({ large }) => (large ? '20px' : '15px')};
    z-index: 4;
    animation: ${SlideFromRight} 0.2s ease-in-out;
`;

export const ArrowChevronDownSelectIconWrap = styled((props) => <ArrowChevronDownIcon {...props} />)`
    position: absolute;
    font-size: 24px;
    right: 22px;
    top: ${({ large }) => (large ? '20px' : '16px')};
    z-index: 2;
    animation: ${SlideFromRight} 0.2s ease-in-out;

    cursor: pointer;
`;

export const InputIcon = styled.span`
    position: absolute;
    top: 0;
    font-size: 24px;
    left: 22px;
    line-height: ${({ large }) => (large ? '61px' : '53px')};
    height: ${({ large }) => (large ? '61px' : '53px')};
    z-index: 2;
`;

// global input styles;
export const InputWrap = styled.div`
    display: block;
    height: auto;
    position: relative;
    color: ${({ theme }) => theme.colors.secondaryText};
    opacity: ${({ disabled, nonOpacityOnDisabled }) => (nonOpacityOnDisabled ? 1 : disabled ? 0.5 : 1)};

    * {
        box-sizing: border-box;
    }

    select,
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0;
        box-shadow: none;
        white-space: normal;
        appearance: none;
    }

    select::-ms-expand {
        display: none;
    }
`;

export const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.background};
    transition: ${({ theme }) => theme.transition.default};
`;
