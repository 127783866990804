import React from 'react';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { useInterval } from '../../../../../../../../../../hooks';
import { CircleActionsAlertQuestionIcon } from '../../../../../../../../../../icons';
import { roundToLocaleString } from '../../../../../../../../../../shared';
import { Icon } from '../../../../../../../../../UiLibrary';
import { StyledText, StyledWrapper } from './styled';
import { getAvailableRewards, getDiscount, getRewardsData } from './utils';

const RewardsApplied = inject('store')(
    observer(({ store }) => {
        const { onRewardIconClick } = store.callbacks;
        const rewardsData = getRewardsData(getSnapshot(store.cart.bags));
        const { discountInUsd, discountInPercents } = getDiscount(getSnapshot(store.cart.bags));
        const appliedRewards = getAvailableRewards(rewardsData.rewards);

        useInterval(() => {
            if (getAvailableRewards(appliedRewards).length !== appliedRewards.length) {
                window.location.reload();
            }
        });

        return appliedRewards.length ? (
            <StyledWrapper>
                <StyledText type='body2'>
                    {appliedRewards.length} Rewards applied
                    <Icon
                        size='12px'
                        onClick={() => onRewardIconClick(rewardsData)}
                        component={() => <CircleActionsAlertQuestionIcon />}
                    />
                </StyledText>
                <StyledText type='body2'>
                    –${roundToLocaleString(discountInUsd)} ({discountInPercents}% off)
                </StyledText>
            </StyledWrapper>
        ) : null;
    })
);

export default RewardsApplied;
