import { getEnv, types } from 'mobx-state-tree';
import { CartStore } from './CartStore';
import { PointsStore } from './PointsStore';
import { ViewStore } from './ViewStore';

export const RootStore = types
    .model('RootStore', {
        isLoading: true,
        cart: types.optional(CartStore, {}),
        view: types.optional(ViewStore, {}),
        points: types.optional(PointsStore, {})
    })
    .views((self) => ({
        get callbacks() {
            return getEnv(self).callbacks;
        },
        get metadata() {
            return getEnv(self).metadata;
        },
        get redirects() {
            return getEnv(self).redirects;
        },
        get isLoggedIn() {
            return getEnv(self).isLoggedIn;
        }
    }))
    .actions((self) => ({
        markLoading(loading) {
            self.isLoading = loading;
        },
        afterCreate() {
            self.cart.loadCart();
        }
    }));
