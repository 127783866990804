import React, { Fragment, useState } from 'react';
import { BOTTOM_INDENTS, TOP_INDENTS } from '../../../../../../constants';
import { Button, Select, Typography } from '../../../../../UiLibrary';
import { ErrorField, TextArea } from '../../../../components';
import { selectorData } from './constants';
import { ButtonsWrap, Styles } from './styles';

export const CancelOrderForm = ({ onSubmit, errorMessage, onClose, simple }) => {
    const [selectorValue, setSelectorValue] = useState('');
    const [replyValue, setReplyValue] = useState('');
    const [selectorError, setSelectorError] = useState('');
    const [replyError, setReplyError] = useState('');

    const onSelectorValueChange = (value) => {
        setSelectorValue(value);
        setSelectorError('');
    };

    const onReplyValueChange = (e) => {
        setReplyValue(e.target.value);
        setReplyError('');
    };

    const handleSubmit = () => {
        if (!selectorValue) {
            return setSelectorError('This field is required');
        }
        const note = [selectorValue];

        if (selectorValue === 'Other' && (!replyValue || replyValue.length < 10)) {
            return setReplyError('This field is required min 3 words (10 characters)');
        }

        if (replyValue) {
            note.push(replyValue);
        }

        onSubmit(note.join(', '));
    };

    return (
        <Styles $simple={simple}>
            <form>
                <Typography.Text style={BOTTOM_INDENTS.SM}>Please set cancellation reason</Typography.Text>
                <Select
                    size='large'
                    label='Select cancellation reason*'
                    value={selectorValue}
                    data-e2e='cancel-order-reason-select'
                    error={selectorError}
                    onChange={(value) => onSelectorValueChange(value)}
                    options={selectorData.map((reason) => ({
                        label: reason.label,
                        value: reason.value
                    }))}
                />
                {selectorError ? <ErrorField error={selectorError} /> : null}
                <TextArea
                    data-e2e='cancel-order-share-reply-text-input'
                    style={TOP_INDENTS.SM}
                    placeholder='Share a reply (min 3 words)*'
                    value={replyValue}
                    onChange={(e) => onReplyValueChange(e)}
                    error={replyError}
                />
                {errorMessage && (
                    <Fragment>
                        <ErrorField error={errorMessage} />
                    </Fragment>
                )}
                <ButtonsWrap style={TOP_INDENTS.SM} $simple={simple}>
                    <Button type='unstyled' block onClick={onClose} data-e2e='cancel-ord-modal-go-back-btn-final'>
                        <Typography.Text type='badgeLarge'>Go back</Typography.Text>
                    </Button>
                    &nbsp; &nbsp;
                    <Button type='primary' block onClick={handleSubmit} data-e2e='cancel-ord-modal-continue-btn-final'>
                        <Typography.Text type='badgeLarge'>Confirm</Typography.Text>
                    </Button>
                </ButtonsWrap>
            </form>
        </Styles>
    );
};
