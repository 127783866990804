import React, { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from '../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../constants';
import ProductImage from './components/ProductImage';
import ProductInfo from './components/ProductInfo';
import { CheckoutButtons } from './components/ProductInfo/components/checkoutButtons';
import StyledPdpPage from './styled/StyledPdpPage';

const PDPPage = inject('store')(
    observer(({ store, bottomExtraContent, customButtons }) => {
        const optionsRef = useRef(null);

        if (store.isLoading) {
            return <Icon.Loader />;
        }

        const scrollToOptions = () => {
            if (optionsRef && optionsRef.current) {
                optionsRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        };

        const simple = store.view.isSimple(GRID_POINTS.sm);

        return (
            <StyledPdpPage simple={simple}>
                {store.product.isLoading && <Icon.Loader zIndex={10} overlayColor='rgba(255,255,255, 0.8)' />}
                <ProductImage />
                <ProductInfo
                    optionsRef={optionsRef}
                    bottomExtraContent={bottomExtraContent}
                    customButtons={customButtons}
                />
                {/* mobile buttons */}
                {simple && <CheckoutButtons scrollToOptionsCallback={scrollToOptions} customButtons={customButtons} />}
            </StyledPdpPage>
        );
    })
);

export default PDPPage;
