import { types, getParent } from "mobx-state-tree"; //eslint-disable-line

export const UpsellStore = types
    .model('UpsellStore', {
        upsellEndDate: types.optional(types.string, '')
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get endDate() {
            return self.upsellEndDate;
        }
    }));
