import React from 'react';
import { ImageIcon } from '../../../../../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../../../../../UiLibrary';
import { StyledFormItem, StyledLabel, StyledUpload } from './styled';

const ImageUpload = () => {
    const dummyRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return (
        <StyledFormItem name='images' valuePropName='fileList' getValueFromEvent={normFile}>
            <StyledUpload
                listType='picture-card'
                multiple
                showUploadList={{ showPreviewIcon: false }}
                accept='image/*'
                customRequest={dummyRequest}
            >
                <StyledLabel htmlFor='pdp-upload-file'>
                    <Icon size='24px' style={{ marginBottom: '10px' }} component={() => <ImageIcon />} />
                    <Typography.Text type='descriptor2'>Add Image</Typography.Text>
                </StyledLabel>
            </StyledUpload>
        </StyledFormItem>
    );
};

export default ImageUpload;
