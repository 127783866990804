import { types } from 'mobx-state-tree';

export const RewardModel = types.model({
    _id: types.identifier,
    title: types.string,
    description: types.string,
    image: types.string,
    status: types.string,
    expireAt: types.string,
    promotions: types.maybe(
        types.array(
            types.model({
                _id: types.string,
                title: types.string,
                brandName: types.string,
                brandLink: types.string,
                description: types.string,
                handle: types.string,
                strictQuantity: types.maybeNull(types.number),
                images: types.array(types.string),
                options: types.array(
                    types.model({
                        name: types.string,
                        position: types.number,
                        values: types.array(types.string)
                    })
                ),
                variants: types.array(
                    types.model({
                        externalId: types.number,
                        options: types.array(
                            types.model({
                                position: types.number,
                                value: types.string
                            })
                        ),
                        title: types.string,
                        quantity: types.number,
                        price: types.number,
                        pointsDetails: types.array(
                            types.model({
                                commissionType: types.string,
                                commissionValue: types.number,
                                pointsPrice: types.number
                            })
                        ),
                        rewards: types.array(
                            types.model({
                                _id: types.maybe(types.string),
                                title: types.string,
                                description: types.string,
                                image: types.string,
                                expireAt: types.maybeNull(types.string),
                                appliedTo: types.maybe(types.string),
                                amount: types.number
                            })
                        ),
                        priceDetails: types.model({
                            originalPrice: types.number,
                            rewardsPrice: types.number,
                            rewardsPercent: types.number,
                            youPayPrice: types.number
                        }),
                        strictQuantity: types.maybeNull(types.number)
                    })
                ),
                metadata: types.array(
                    types.model({
                        name: types.string,
                        value: types.string
                    })
                )
            })
        )
    )
});
