import styled, { css } from 'styled-components';

export const KliCardContentDirections = Object.freeze({
    column: 'column',
    row: 'row'
});

export const CardWrap = styled.div`
    white-space: break-spaces;
    box-sizing: border-box;
    display: flex;
    padding: 20px;
    flex-direction: ${({ direction }) => KliCardContentDirections[direction]};
    background: ${({ theme }) => theme.colors.canvas};
    transition: ${({ theme }) => theme.transition.default};
    position: relative;
    align-items: center;

    &:hover {
        box-shadow: ${({ theme }) => theme.shadows.primary};
    }

    & > div {
        width: ${({ direction }) => (direction === KliCardContentDirections.column ? '100%' : '50%')};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const ImageWrap = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    padding: 10px 20px 10px 0;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        padding: 0;
        outline: none;
    }
`;
export const ContentWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    ${({ withButton }) =>
        withButton
            ? css`
                  padding-bottom: 90px;
              `
            : ''}
`;
export const ButtonWrap = styled.div`
    box-sizing: border-box;
    width: ${({ direction }) => (direction === KliCardContentDirections.column ? '100%' : '50%')};
    padding: ${({ direction }) => (direction === KliCardContentDirections.column ? '10px 20px' : '10px 20px 10px 0')};
    position: absolute;
    bottom: 10px;
    left: ${({ direction }) => (direction === KliCardContentDirections.column ? '0' : '50%')};

    & > button {
        width: 100%;
    }
`;
