import styled from 'styled-components';
import { Button } from '../../../UiLibrary';
import { $primaryText, $white } from '../../styles/colors';
export const StyledButton = styled(Button)`
    &&&,
    &&&:focus {
        height: 100%;
        color: ${$white};
        background-color: ${$primaryText};
    }
    &&&:active {
        background-color: ${$white};
        color: ${$primaryText};
    }
`;

export const StyledButtonsWrapper = styled.div`
    position: relative;
`;

export const StyledClearButton = styled(Button)`
    && {
        position: absolute;
        top: 2px;
        right: 94px;
        width: 53px;
        height: 53px;
        padding: 0;
        color: rgb(119, 131, 143);
        z-index: 3;

        &:focus,
        &:focus:not(:hover) {
            outline: none;
        }

        &:hover:not(:disabled) {
            color: inherit;
        }
    }
`;
