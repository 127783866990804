import styled from 'styled-components';
import Icon from '../../../Icon';

export const StyledIcon = styled(Icon)`
    && {
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;
        margin: auto;
        z-index: 2;
    }

    && ~ .StripeElement {
        padding-left: 65px !important;
    }
    && ~ span {
        left: 40px;
    }
`;
