export const getVariantImage = (images, selectedVariant) => {
    const variantImageId = selectedVariant ? selectedVariant.image : null;

    if (!images || !images.length || !variantImageId) {
        return {};
    }

    const index = images.findIndex((img) => img.externalId === variantImageId) || 0;
    const image = images.find((img) => img.externalId === variantImageId) || {};
    const imageSrc = image.src || '';

    return { index, image, imageSrc };
};
