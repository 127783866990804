import { getParent, types } from 'mobx-state-tree';
import { ItemStore } from './ItemStore';

export const BagStore = types
    .model('BagStore', {
        _id: types.identifier,
        merchantId: types.string,
        merchantName: types.maybeNull(types.string),
        items: types.array(ItemStore),
        priceDetails: types.model({
            qty: types.number,
            originalSubTotal: types.number,
            subTotal: types.number,
            discounts: types.number,
            total: types.number,
            fullTotal: types.number
        }),
        pointsDetails: types.model({
            isWelcome: false,
            points: types.number
        })
    })
    .views((self) => ({
        get cart() {
            return getParent(self, 2);
        }
    }))
    .actions((self) => ({}));
