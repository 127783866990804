import { css } from 'styled-components';
import styled from 'styled-components/macro';

export const Styles = styled.div`
    ${({ simple }) =>
        simple
            ? css`
                  padding: 0 24px;
              `
            : ''};
`;
