import styled from 'styled-components/macro';
import { COLORS } from '../../../../../../../../../../constants';

export const Styles = styled.div`
    width: 100%;

    background-color: ${COLORS.$background};
    padding: 20px 24px;
`;

export const PriceRowWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${COLORS.$primaryText};
`;
