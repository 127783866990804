import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Button, Icon, Typography } from '../../../../../../../UiLibrary';
import { $background } from '../../../../../../styles';

export const Styles = styled.div`
    max-width: 1063px;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;

    ${({ simple }) =>
        !simple
            ? css`
                  height: 100%;
              `
            : ''};
`;

export const BodyStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    padding-bottom: 50px;
`;

export const StyledIcon = styled(Icon)`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: ${$background};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledHeadLine2 = styled(Typography.Title)`
    text-align: center;
`;

export const StyledText = styled(Typography.Text)`
    text-align: center;
`;

export const StyledButton = styled(Button)`
    && {
        ${({ $simple }) =>
            !$simple
                ? css`
                      padding-left: 90px;
                      padding-right: 90px;
                  `
                : css`
                      width: 100%;
                  `};

        .anticon {
            margin-right: 8px;
        }
    }
`;
