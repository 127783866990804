import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getSnapshot, isStateTreeNode } from 'mobx-state-tree';
import { getFilterParamsFromUrl } from '../../../../utils';
import { Icon } from '../../../UiLibrary';
import { getCategoriesArrayFromString } from '../../utils';
import CategoryItem from './components/CategoryItem';
import Title from './components/Title';
import { StyledLoader, StyledWrapper } from './styled';

const TaxonomyTree = inject('store')(
    observer(
        ({
            store,
            taxonomyType,
            brandId,
            brandData,
            selectedSubCategory,
            setSelectedSubCategory,
            onAfterCategorySelect,
            inMobileMenu = false,
            selectCategoryOnArrowClick = false
        }) => {
            const {
                getTaxonomyCategoryDetails,
                getRootCategoryByKey,
                getParentCategoryByChildTitle,
                selectCategory,
                selectedCategories,
                setSelectedCategories,
                getBrandsCategoriesList,
                getTaxonomyBrandCategoryDetails,
                getCategoriesList,
                setBrandId,
                pending
            } = store;

            const searchParams = getFilterParamsFromUrl();

            useEffect(() => {
                (async () => {
                    if (taxonomyType === 'brand' && brandData) {
                        await setBrandId(brandId);
                        await getBrandsCategoriesList(brandData);
                    }

                    if (taxonomyType === 'marketplace') {
                        await getCategoriesList();
                    }
                })();
            }, [brandData]);

            useEffect(() => {
                (async () => {
                    if (searchParams.gt && !pending) {
                        const categories = getCategoriesArrayFromString(searchParams.gt);
                        const category = categories?.[0];
                        await selectCategory(searchParams.gt, true, () => null, category, true);
                        setSelectedSubCategory(getParentCategoryByChildTitle(categories?.[categories?.length - 1]));
                    }
                })();
            }, []);

            useEffect(() => {
                if (!searchParams.gt) {
                    setSelectedSubCategory(null);
                    setSelectedCategories([]);
                }
            }, [searchParams.gt]);

            return (
                <StyledWrapper $pending={pending} $inMobileMenu={inMobileMenu}>
                    {pending && (
                        <StyledLoader>
                            <Icon.Loader />
                        </StyledLoader>
                    )}
                    {inMobileMenu ? null : (
                        <Title
                            title={selectedSubCategory?.title}
                            isRoot={!selectedSubCategory?.children?.length}
                            onBackButtonClick={() =>
                                setSelectedSubCategory(getParentCategoryByChildTitle(selectedSubCategory?.title))
                            }
                        />
                    )}
                    {!selectedSubCategory?.children
                        ? store.categories
                              .slice()
                              .sort((a, b) => a.title.localeCompare(b.title))
                              .map(({ key, title, icon, count }) => (
                                  <CategoryItem
                                      count={count}
                                      title={title}
                                      key={key}
                                      value={key}
                                      icon={inMobileMenu ? icon : null}
                                      checked={selectedCategories.includes(key)}
                                      onChange={(value, isChecked) =>
                                          selectCategory(value, isChecked, onAfterCategorySelect)
                                      }
                                      onClick={async () => {
                                          if (brandId) {
                                              await getTaxonomyBrandCategoryDetails(key);
                                          } else {
                                              await getTaxonomyCategoryDetails(key);
                                          }
                                          const category = getRootCategoryByKey(key);
                                          if (selectCategoryOnArrowClick) {
                                              selectCategory(key, true, onAfterCategorySelect);
                                          }
                                          if (category.children.length) setSelectedSubCategory(category);
                                      }}
                                      buttonVisible
                                  />
                              ))
                        : [
                              ...(isStateTreeNode(selectedSubCategory?.children)
                                  ? getSnapshot(selectedSubCategory?.children)
                                  : selectedSubCategory?.children || [])
                          ]
                              .sort((a, b) => a.title.localeCompare(b.title))
                              .map((category) => (
                                  <CategoryItem
                                      count={category.count}
                                      title={category.title}
                                      key={category.title}
                                      value={category.title}
                                      checked={selectedCategories.includes(category.title)}
                                      onChange={(value, isChecked) =>
                                          selectCategory(category.path, isChecked, onAfterCategorySelect, category)
                                      }
                                      onClick={() => {
                                          if (selectCategoryOnArrowClick) {
                                              selectCategory(category.path, true, onAfterCategorySelect, category);
                                          }
                                          return setSelectedSubCategory(category);
                                      }}
                                      buttonVisible={category?.children?.length}
                                  />
                              ))}
                </StyledWrapper>
            );
        }
    )
);

export default TaxonomyTree;
