import styled from 'styled-components/macro';
import { Button } from '../../../../../../../UiLibrary';

export const CenteredButton = styled(Button)`
    && {
        margin: 0 auto;

        .anticon {
            display: flex;
            align-items: center;
            margin-left: 8px;
        }
    }
`;

export const StyledButton = styled(Button)`
    && {
        width: fit-content;
        padding-left: 0;
        margin: ${({ $simple }) => ($simple ? '0 auto' : '0')};

        .anticon {
            display: flex;
            align-items: center;
            margin-left: 12px;
        }
    }
`;

export const StyledLoaderWrapper = styled.div`
    position: relative;
    height: 160px;

    > * {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
