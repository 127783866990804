import { css } from 'styled-components';
import styled from 'styled-components/macro';

export const Styles = styled.div`
    display: flex;
    height: 100%;
    overflow-y: auto;

    ${({ simple }) =>
        simple
            ? css`
                  flex-direction: column;
              `
            : ''};
`;
