import { InputNumber } from 'antd';
import styled, { css } from 'styled-components';
import { getColor } from '../../utils';

export const StyledInputNumber = styled(InputNumber)`
    ${({ $theme, status, $noValidationBorders }) => {
        const borderColor = getColor({
            $theme,
            status: $noValidationBorders ? null : status,
            isBorder: true
        });

        return css`
            width: 100%;
            border: 2px solid ${borderColor};
            border-radius: unset;
            padding: 22px 24px 10px 24px;
            font: ${({ $theme }) => $theme.fonts.descriptor1};
            outline: none;
            transition: ${({ $theme }) => $theme.transition.default};
            color: ${({ $theme }) => $theme.colors.secondaryText};

            &.ant-input-number {
                background-color: ${({ $theme }) => $theme.colors.background};
            }

            &.ant-input-number-focused,
            &.ant-input-number:focus {
                && {
                    background-color: ${({ $theme }) => $theme.colors.canvas};
                    border-color: ${({ $theme }) => $theme.colors.primaryText};
                    box-shadow: none;
                    border-right-width: 2px;
                }
            }

            &.ant-input-number:hover {
                border-color: ${borderColor};
                border-right-width: 2px;
            }

            .ant-input-number-handler-wrap {
                display: none;
            }
        `;
    }};
`;
