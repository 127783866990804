import React from 'react';
import { CircleActionsCloseIcon } from '../../../../../../../../icons';
import Typography from '../../../../../../../UiLibrary/Typography';
import { StyledHead, StyledIcon, StyledItemsWrap, StyledItemWrap, Styles } from './styles';

export const AlertItemsMessage = ({ items = [], message, type }) => {
    return (
        <Styles $type={type}>
            <StyledHead>
                {type === 'error' && (
                    <StyledIcon size='22px' color='error' component={() => <CircleActionsCloseIcon />} />
                )}
                <Typography.Text type='badgeMedium'>{message}</Typography.Text>
            </StyledHead>
            <StyledItemsWrap>
                {items.length
                    ? items.map((item, key) => {
                          return (
                              <StyledItemWrap key={key}>
                                  <Typography.Title level={3} style={{ marginBottom: '8px' }}>
                                      {item.productTitle}
                                  </Typography.Title>
                                  <Typography.Text type='body2' color='secondaryText'>
                                      {item.variantTitle.replace('Default Title', '')}
                                  </Typography.Text>
                              </StyledItemWrap>
                          );
                      })
                    : null}
            </StyledItemsWrap>
        </Styles>
    );
};
