import React from 'react';
import { Button, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { goToGiftly } from '../../utils';
import CartData from './components/CartData';
import { AppMenuStyles } from './styled';

const HeaderButtons = inject('store')(
    observer(({ store }) => {
        const { klicklyLogoVisible, giftlyControlsVisible } = store.publisherStore;

        if (!klicklyLogoVisible || !giftlyControlsVisible) return null;

        return (
            <AppMenuStyles>
                <CartData />
                <Button size='small' type='unstyled' onClick={() => goToGiftly()} data-e2e='header-log-in-button'>
                    <Typography.Text type='badgeMedium'>Log in</Typography.Text>
                </Button>
                <Button size='small' type='primary' onClick={() => goToGiftly()} data-e2e='header-apply-button'>
                    <Typography.Text type='badgeMedium'>Apply</Typography.Text>
                </Button>
            </AppMenuStyles>
        );
    })
);

export default HeaderButtons;
