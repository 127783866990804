import { flow, getEnv, types } from 'mobx-state-tree';
import HttpClient from '../http';
import { ViewStore } from './ViewStore';

export const RootStore = types
    .model('RootStore', {
        isLoading: true,
        cancelOrderPending: false,
        orderIdForRefund: types.maybeNull(types.string),
        data: types.maybeNull(
            types.model({
                account: types.string,
                cartId: types.string,
                paymentInfo: types.optional(
                    types.model({
                        cardType: types.maybeNull(types.string),
                        expMonth: types.maybeNull(types.number),
                        expYear: types.maybeNull(types.number),
                        last4: types.maybeNull(types.string),
                        type: types.maybeNull(types.string)
                    }),
                    {}
                ),
                cart: types.model({
                    account: types.maybeNull(types.string),
                    bags: types.optional(
                        types.array(
                            types.model({
                                klicklyOrderId: types.maybeNull(types.string),
                                klicklyStatus: types.maybeNull(types.string),
                                isAvailableForCancel: types.maybeNull(types.boolean),
                                merchantId: types.maybeNull(types.string),
                                merchantName: types.string,
                                orderNumber: types.string,
                                pixels: types.array(
                                    types.model({
                                        name: types.string,
                                        src: types.string,
                                        enabled: types.maybeNull(types.boolean)
                                    })
                                ),
                                items: types.array(
                                    types.model({
                                        productId: types.string,
                                        image: types.string,
                                        priceDetails: types.model({
                                            qty: types.number,
                                            originalPrice: types.number,
                                            originalSubTotal: types.number,
                                            price: types.number,
                                            subTotal: types.number,
                                            discounts: types.number,
                                            total: types.number,
                                            fullTotal: types.number
                                        }),
                                        productTitle: types.string,
                                        variantTitle: types.string,
                                        metadata: types.array(types.frozen())
                                    })
                                ),
                                priceDetails: types.optional(
                                    types.model({
                                        qty: types.maybeNull(types.number),
                                        subTotal: types.maybeNull(types.number),
                                        originalSubTotal: types.maybeNull(types.number),
                                        discounts: types.maybeNull(types.number),
                                        shipping: types.maybeNull(types.number),
                                        taxes: types.maybeNull(types.number),
                                        total: types.maybeNull(types.number),
                                        fullTotal: types.maybeNull(types.number)
                                    }),
                                    {}
                                ),
                                shopDomain: types.maybeNull(types.string)
                            })
                        ),
                        []
                    ),
                    priceDetails: types.optional(
                        types.model({
                            discounts: types.maybeNull(types.number),
                            qty: types.maybeNull(types.number),
                            shipping: types.maybeNull(types.number),
                            taxes: types.maybeNull(types.number),
                            originalSubTotal: types.maybeNull(types.number),
                            subTotal: types.maybeNull(types.number),
                            total: types.maybeNull(types.number),
                            fullTotal: types.maybeNull(types.number)
                        }),
                        {}
                    ),
                    customerInfo: types.model({
                        email: types.string
                    })
                })
            })
        ),
        modalState: 'confirmation',
        cancelOrderError: types.maybeNull(types.string),
        view: types.optional(ViewStore, {}),
        cancelOrderUnavailable: types.optional(types.boolean, false),
        env: types.maybe(types.string),
        isLoggedIn: types.maybe(types.boolean)
    })
    .views((self) => ({
        get propsData() {
            return getEnv(self).propsData;
        },
        get asyncHooks() {
            return getEnv(self).asyncHooks;
        },
        get isEmptyOrders() {
            return !self.orders.length;
        },
        get prices() {
            return self.data?.cart?.priceDetails;
        },
        get totalPrice() {
            return self.data?.cart?.priceDetails?.fullTotal;
        },
        get orders() {
            return self.data?.cart?.bags || [];
        },
        get orderNumbers() {
            return self.orders.map(({ orderNumber }) => orderNumber);
        },
        get hasOrdersForRefund() {
            return Boolean(
                self.orders.filter((order) => {
                    return (
                        (order.isAvailableForCancel &&
                            order.klicklyStatus !== 'refunded' &&
                            !self.cancelOrderUnavailable) ||
                        order.klicklyStatus === 'refunded'
                    );
                }).length
            );
        },
        get currentModalState() {
            return self.modalState;
        },
        get pointsEarned() {
            return self.data.cart.bags.reduce((sum, bag) => {
                const bagPoints = bag.items.reduce((sum, item) => {
                    const pointsEarnedMetadataField =
                        item && item.metadata && item.metadata.length
                            ? item.metadata.find((el) => el && el.name === 'pointsEarned')
                            : null;
                    if (pointsEarnedMetadataField) {
                        sum += pointsEarnedMetadataField.value;
                    }

                    return sum;
                }, 0);

                return sum + bagPoints;
            }, 0);
        }
    }))
    .actions((self) => ({
        afterCreate() {
            self.loadOrders();
        },
        markLoading(loading) {
            self.isLoading = loading;
        },
        setOrderForRefund(orderId) {
            self.orderIdForRefund = orderId;
        },
        setOrderHasBeenRefunded(orderIdForRefund) {
            const order = self.orders.find(({ klicklyOrderId }) => klicklyOrderId === orderIdForRefund);
            order.klicklyStatus = 'refunded';
            order.isAvailableForCancel = false;
            self.orderIdForRefund = null;
        },
        loadOrders: flow(function* loadOrders() {
            try {
                self.markLoading(true);

                if (self.propsData.checkoutOrderCode) {
                    self.data = yield HttpClient.fetchOrdersData(self.propsData.checkoutOrderCode);
                }

                if (self.propsData.checkoutOrderId) {
                    self.data = yield HttpClient.fetchOrdersDataByOrderId(self.propsData.checkoutOrderId);
                }

                if (typeof self.asyncHooks.onFetchOrdersSuccess === 'function') {
                    self.asyncHooks.onFetchOrdersSuccess(self.data);
                }

                self.markLoading(false);
            } catch (err) {
                console.error("Failed to load ", err); // eslint-disable-line
            }
        }),
        resetModalState() {
            self.modalState = 'confirmation';
            self.orderIdForRefund = null;
            self.cancelOrderError = null;
        },
        toConfirmationReasonModalState() {
            self.modalState = 'cancellation-reason';
        },
        cancelOrder: flow(function* cancelOrder(note, onModalClose) {
            try {
                self.cancelOrderPending = true;

                const orderIdForRefund = self.orderIdForRefund;

                yield HttpClient.cancelOrder(orderIdForRefund, note);

                self.setOrderHasBeenRefunded(orderIdForRefund);

                if (typeof onModalClose === 'function') {
                    onModalClose();
                }

                if (typeof self.asyncHooks.onCancelOrderSuccess === 'function') {
                    self.asyncHooks.onCancelOrderSuccess({
                        orderIdForRefund,
                        note
                    });
                }
            } catch (err) {
                if (err && err.message) {
                    self.cancelOrderError = err.message;
                }

                if (err && err.response && err.response.data) {
                    self.cancelOrderError = err.response.data.message;
                }
            } finally {
                self.cancelOrderPending = false;
            }
        }),
        getItemPointsEarned(item) {
            const pointsEarnedMetadataField =
                item.metadata && item.metadata.length
                    ? item.metadata.find((el) => el && el.name === 'pointsEarned')
                    : null;
            if (pointsEarnedMetadataField) {
                return pointsEarnedMetadataField.value;
            }

            return null;
        }
    }));
