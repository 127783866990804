import { Chip } from '@klickly/front-packages';
import { keyframes } from 'styled-components';
import styled from 'styled-components/macro';
import { GRID_DEVICES_MAX } from '../../../../../../shared/constants';

const $animation = keyframes`
    from {
        transform: translate3d(0, 0, 0) scale(0, 0);
    }

    to {
        transform: translate3d(0, 0, 0) scale(1, 1);
    }
`;

export const Styles = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;

    @media (${GRID_DEVICES_MAX.md}) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
`;

export const Filters = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media (${GRID_DEVICES_MAX.md}) {
        width: 100%;
        margin: 20px 0;
    }
`;

export const Item = styled(Chip)`
    margin: 0 10px 10px 0;
    animation: ${$animation} 0.3s;
`;

export const Total = styled.div`
    width: 100%;
    padding: 10px 0;
`;
