import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { $white } from '../../../../styles';

export const Styles = styled.div`
    width: 100%;
    background-color: ${$white};
    padding: ${({ $simple }) => ($simple ? '40px 24px' : '52px 40px')};
`;

export const ButtonsWrap = styled.div`
    width: 100%;
    display: flex;

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column-reverse;
              `
            : ''};
`;
