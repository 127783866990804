import { css } from 'styled-components';
import styled from 'styled-components/macro';

export default styled.div`
    max-width: 1200px;
    margin: 0 auto;

    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: 100%;
              `
            : ''};
`;
