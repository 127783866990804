import React from 'react';
import styled from 'styled-components';
import { CheckIcon } from '../../../icons';

export const Lablel = styled.label`
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked ~ .checkmark {
        border-color: ${({ fillSelected, theme }) => (fillSelected ? 'transparent' : theme.colors.primary)};
        background: ${({ fillSelected, theme }) => (fillSelected ? theme.colors.primaryBackground : 'inherit')};
        color: ${({ fillSelected, theme }) => (fillSelected ? theme.colors.primary : 'inherit')};

        .fake-icon-round {
            background-color: ${({ theme }) => theme.colors.primary};
            border-color: ${({ theme }) => theme.colors.primary};
        }

        .fake-icon {
            opacity: 1;
        }
    }

    .fake-icon {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
`;

export const KliSelectedSquareWrap = styled.div`
  display: flex;
  position: relative;
  padding: ${({ withContent }) => (withContent ? '15px 60px 15px 15px' : '15px')};
  transition: ${({ theme }) => theme.transition.default};
  background: ${({ theme }) => theme.colors.canvas};
  cursor: pointer;
  border: 2px solid transparent;
  align-items: center;
}
`;

export const CheckboxWrap = styled.div`
    position: absolute;
    height: 24px;
    margin: auto;
    right: 15px;
    top: ${({ selectorOnTop }) => (selectorOnTop ? '15px' : 0)};
    bottom: ${({ selectorOnTop }) => (selectorOnTop ? 'auto' : 0)};
`;

export const ImageWrap = styled.div`
    width: ${({ withContent }) => (withContent ? '' : '100%')};
    padding: ${({ withContent }) => (withContent ? '0' : '10px')};

    img {
        max-height: 100%;
        max-width: 100%;
    }
`;

export const ContentWrap = styled.div`
    padding: 0 15px 0 15px;
    width: ${({ withImg }) => (withImg ? '75%' : '100%')};
`;

export const Checkmark = styled.span`
    display: block;
    height: ${({ size }) => (size ? size + 'px' : '28px')};
    width: ${({ size }) => (size ? size + 'px' : '28px')};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primaryText};
    background-color: ${({ theme }) => theme.colors.background};
    transition: ${({ theme }) => theme.transition.default};
`;
export const CheckmarkIcon = styled((props) => <CheckIcon {...props} />)`
    position: absolute;
    opacity: 0;
    color: ${({ theme }) => theme.colors.canvas};
    transition: ${({ theme }) => theme.transition.default};
`;
