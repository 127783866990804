import React from 'react';
import {
    BOTTOM_INDENTS,
    Button,
    ConfigureIcon,
    getFilterParamsFromUrl,
    GRID_POINTS,
    MARKETPLACE,
    Typography,
    useSimpleInterface
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { toLocalUSString } from '../../../../../../shared/utils';
import { CheckedFiltersList } from './components/CheckedFiltersList';
import { CheckedPrice } from './components/CheckedPrice';
import { Sorting } from './components/Sorting';
import { Filters, Item, Styles, Total } from './styles';
import { getTotalCountViewValue } from './utils';

export const CheckedFilters = inject('store')(
    observer(({ store }) => {
        const {
            totalHits,
            totalShown,
            query,
            updateCategory,
            updateBrand,
            updateIsOpenOnMobile,
            clearAll
        } = store.marketplaceStore;
        const { price, categories, brands } = query;

        const checkedBrands = brands.filter((brand) => brand.checked);
        const checkedCategories = categories.filter((category) => category.checked);
        const simple = useSimpleInterface(GRID_POINTS.md);

        return (
            <Styles style={BOTTOM_INDENTS.SM}>
                {simple && (
                    <Button type='primary' onClick={() => updateIsOpenOnMobile(true)}>
                        <ConfigureIcon />
                        <Typography.Text>Filter</Typography.Text>
                    </Button>
                )}

                <Sorting />

                <Filters>
                    {simple && (totalHits || totalShown) ? (
                        <Total>
                            <Typography.Text type='descriptor2' color='secondaryText'>
                                {toLocalUSString(totalShown)} of {getTotalCountViewValue(totalHits)} results
                            </Typography.Text>
                        </Total>
                    ) : null}

                    <CheckedFiltersList list={checkedCategories} onRemove={updateCategory} />
                    <CheckedFiltersList list={checkedBrands} onRemove={updateBrand} />
                    <CheckedPrice />
                    {(checkedCategories.length ||
                        checkedBrands.length ||
                        price.lte !== MARKETPLACE.PROMOTIONS_PRICE_STATE.lte ||
                        price.gte !== MARKETPLACE.PROMOTIONS_PRICE_STATE.gte) && (
                        <Item
                            cantClose
                            onClick={() => clearAll({ isCategoryPage: getFilterParamsFromUrl().gt, sort: true })}
                            type='primary'
                        >
                            Clear All
                        </Item>
                    )}

                    {!simple && (totalHits || totalShown) ? (
                        <Total>
                            <Typography.Text type='descriptor2' color='secondaryText'>
                                {toLocalUSString(totalShown)} of {getTotalCountViewValue(totalHits)} results
                            </Typography.Text>
                        </Total>
                    ) : null}
                </Filters>
            </Styles>
        );
    })
);
