import React, { useContext } from 'react';
import { GRID_POINTS } from '../../../../../../constants';
import { ArrowChevronForwardIcon } from '../../../../../../icons';
import { useSimpleInterface } from '../../../../../../utils';
import { Checkbox, Col, Icon, KliThemeContext, Typography } from '../../../../../UiLibrary';
import { StyledButton, StyledCol, StyledNum, StyledRow, StyledWrapper } from './styled';

const CategoryItem = ({ count, value, title, checked, onChange, onClick, buttonVisible, icon: ItemIcon }) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    const theme = useContext(KliThemeContext);
    const checkbox = (
        <Checkbox
            checked={checked}
            type='rounded'
            checkedColor='primary'
            size='20px'
            id={value}
            value={value}
            onChange={({ target: { checked: isChecked } }) => onChange(value, isChecked)}
        />
    );
    return (
        <StyledWrapper align='middle' justify='space-between' wrap={false}>
            {ItemIcon ? (
                <Col span={3}>
                    <Icon color={theme.colors.primary} size='24px' component={() => <ItemIcon />} />
                </Col>
            ) : null}
            <Col flex={simple && 'auto'}>
                <StyledRow $theme={theme} align='middle' gutter={12} wrap={false}>
                    <StyledCol>{checkbox}</StyledCol>
                    <Col>
                        <label htmlFor={value}>
                            <Typography.Text type='body2' data-e2e='item-category-title'>
                                {title}
                            </Typography.Text>
                            <StyledNum $checked={checked} $theme={theme}>
                                {count}
                            </StyledNum>
                        </label>
                    </Col>
                </StyledRow>
            </Col>
            <StyledButton
                disabled={!buttonVisible}
                $theme={theme}
                onClick={onClick}
                icon={<Icon component={() => <ArrowChevronForwardIcon />} />}
                type='unstyled'
            />
        </StyledWrapper>
    );
};

export default CategoryItem;
