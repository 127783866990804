import { types } from 'mobx-state-tree';

export const BillingProfileModel = types.model({
    _id: types.identifier,
    address1: types.maybeNull(types.string),
    address2: types.maybeNull(types.string),
    brand: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    expMonth: types.maybeNull(types.number),
    expYear: types.maybeNull(types.number),
    firstName: types.maybeNull(types.string),
    isDefault: types.boolean,
    last4: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    nameOnCard: types.maybeNull(types.string),
    province: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string)
});
