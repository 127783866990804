import { Carousel } from 'antd';
import styled, { css } from 'styled-components';
import { sharedCarouselStyles } from '../../styled';

export const StyledWrapper = styled.div``;

export const StyledCarousel = styled(Carousel)`
    margin-bottom: 20px;

    ${sharedCarouselStyles};

    .slick-dots {
        margin: 0;
    }

    .slick-dots-bottom {
        bottom: ${({ dots }) => dots?.bottom || '12px'}; // 12px - default Antd value, could be changed if needed

        ${({ $bottomControls }) =>
            $bottomControls &&
            $bottomControls?.justify === 'space-between' &&
            css`
                bottom: -35px;
                left: 50px;
                width: calc(100% - 100px);
            `};
    }
`;
