import React, { useContext } from 'react';
import { KliThemeContext } from '../../../KliThemeProvider';
import { StyledClearIcon } from './styled';

const ClearIcon = ({ size }) => {
    const theme = useContext(KliThemeContext);
    return <StyledClearIcon $theme={theme} $size={size} />;
};

export default ClearIcon;
