import styled, { css } from 'styled-components';

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    border: ${({ theme, withoutBorders }) => `2px solid ${withoutBorders ? 'transparent' : theme.colors.primaryText}`};
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    box-sizing: border-box;
    flex-wrap: wrap-reverse;
    background-color: ${({ theme, backgroundColor }) =>
        theme.colors[backgroundColor] || backgroundColor || 'transparent'};
    color: ${({ theme, color }) => theme.colors[color] || color || 'inherit'};

    button {
        white-space: nowrap;
    }

    .button-wrap {
        display: flex;
        width: ${({ simple }) => (simple ? 'auto' : '100%')};
        justify-content: ${({ withBackIcon }) => (withBackIcon ? 'space-between' : 'flex-end')};
        align-items: center;
        padding-bottom: 20px;
    }
`;

export const AvatarWrap = styled.div`
    display: flex;
    align-items: center;

    .avatar {
        margin-right: 24px;
    }
`;

export const BackIconWrap = styled.div`
    margin-right: 10px;
`;

export const TitlesWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: ${({ titleOnTop }) => (titleOnTop ? 'column-reverse' : 'column')};
    text-align: ${({ textAlign }) => textAlign || 'inherit'};

    ${({ titleOnTop }) =>
        titleOnTop
            ? css`
                  h1 {
                      margin-bottom: 14px;
                  }
              `
            : css`
                  p {
                      margin-bottom: 8px;
                  }
              `}
`;
