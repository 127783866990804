import React, { useCallback, useContext, useState } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import { Background, Label } from './KliToggle.styles';

export const KliToggle = React.forwardRef(({ value, checked, onChange, selectedColor, large, ...rest }, ref) => {
    const themeContext = useContext(KliThemeContext);
    const [selected, toggle] = useState(checked);

    const _onChange = useCallback(
        (e) => {
            toggle(e.target.checked);

            if (typeof onChange !== 'undefined') {
                onChange(e);
            }
        },
        [toggle]
    );

    return (
        <Label large={large ? 1 : 0} theme={themeContext} selectedColor={selectedColor}>
            <input checked={selected} onChange={_onChange} value={value} type='checkbox' {...rest} ref={ref} />
            <Background large={large ? 1 : 0} theme={themeContext} selectedColor={selectedColor} />
        </Label>
    );
});

KliToggle.displayName = 'KliToggle';
