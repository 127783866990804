import {
    BathroomShowerTubIcon,
    BikeIcon,
    BottleIcon,
    // BriefcasePortfolioIcon,
    Component5Icon as TeaIcon,
    DevicePhoneIcon,
    DiamondIcon,
    FeatherIcon,
    GamesIconRed,
    GymWeightIcon,
    HairDryerIcon,
    HangersIcon,
    HomeIcon,
    ImageIcon,
    LipsIcon,
    PetDogIcon,
    VideoIcon
} from '../../../icons';

export const PSEUDO_ROOT_CATEGORIES_KEYS = {
    MAKEUP: 'Health & Beauty > Personal Care > Cosmetics > Makeup',
    SKIN_CARE: 'Health & Beauty > Personal Care > Cosmetics > Skin Care',
    HAIR_CARE: 'Health & Beauty > Personal Care > Hair Care',
    BATH_BODY: 'Health & Beauty > Personal Care > Cosmetics > Bath & Body',
    CLOTHING: 'Apparel & Accessories > Clothing',
    JEWELRY: 'Apparel & Accessories > Jewelry',
    FITNESS_NUTRITION: 'Health & Beauty > Health Care > Fitness & Nutrition'
};

export const TAXONOMY_ROOT_CATEGORIES = [
    {
        title: 'Makeup',
        key: PSEUDO_ROOT_CATEGORIES_KEYS.MAKEUP,
        order: 1,
        icon: LipsIcon
    },
    {
        title: 'Skin Care',
        key: PSEUDO_ROOT_CATEGORIES_KEYS.SKIN_CARE,
        order: 4,
        icon: FeatherIcon
    },
    {
        title: 'Hair Care',
        key: PSEUDO_ROOT_CATEGORIES_KEYS.HAIR_CARE,
        order: 7,
        icon: HairDryerIcon
    },
    {
        title: 'Bath & Body',
        key: PSEUDO_ROOT_CATEGORIES_KEYS.BATH_BODY,
        order: 10,
        icon: BathroomShowerTubIcon
    },
    {
        title: 'Clothing',
        key: PSEUDO_ROOT_CATEGORIES_KEYS.CLOTHING,
        order: 13,
        icon: HangersIcon
    },
    {
        title: 'Jewelry',
        key: PSEUDO_ROOT_CATEGORIES_KEYS.JEWELRY,
        order: 16,
        icon: DiamondIcon
    },
    {
        title: 'Fitness & Nutrition',
        key: PSEUDO_ROOT_CATEGORIES_KEYS.FITNESS_NUTRITION,
        order: 2,
        icon: BottleIcon
    },
    {
        title: 'Home & Garden',
        key: 'Home & Garden',
        order: 5,
        icon: HomeIcon
    },
    {
        title: 'Arts & Entertainment',
        key: 'Arts & Entertainment',
        order: 8,
        icon: ImageIcon
    },
    {
        title: 'Food & Beverages',
        key: 'Food, Beverages & Tobacco',
        order: 11,
        icon: TeaIcon
    },
    {
        title: 'Toys & Games',
        key: 'Toys & Games',
        order: 14,
        icon: GamesIconRed
    },
    {
        title: 'Sporting Goods',
        key: 'Sporting Goods',
        order: 17,
        icon: GymWeightIcon
    },
    {
        title: 'Media',
        key: 'Media',
        order: 3,
        icon: VideoIcon
    },
    {
        title: 'Electronics',
        key: 'Electronics',
        order: 6,
        icon: DevicePhoneIcon
    },
    {
        title: 'Animals & Pet Supplies',
        key: 'Animals & Pet Supplies',
        order: 9,
        icon: PetDogIcon
    },
    {
        title: 'Baby & Toddler',
        key: 'Baby & Toddler',
        order: 12,
        icon: BikeIcon
    }
    // {
    //     title: 'Luggage & Bags',
    //     key: 'Luggage & Bags',
    //     order: 15,
    //     icon: BriefcasePortfolioIcon
    // }
];
