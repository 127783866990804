import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $tartRed, GRID_DEVICES_MAX } from '../../../../constants';

export const StyledBadge = styled(Typography.Text)`
    && {
        position: ${({ $isMobile }) => ($isMobile ? 'absolute' : 'static')};
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: fit-content;
        height: 24px;
        margin-top: 8px;
        padding: 0 4px;
        white-space: nowrap;
        overflow: hidden;
        background: ${$tartRed};

        @media (${GRID_DEVICES_MAX.sm}) {
            width: auto;
        }
    }
`;

export const StyledPointsCount = styled(Typography.Text)`
    font-weight: 500;
`;
