import styled, { css } from 'styled-components';

export const StyledDropdown = styled.div`
    box-shadow: 0 20px 40px rgb(0 0 0 / 9%);
    background: ${({ $theme }) => $theme.colors.canvas};

    ${({ $menuMaxHeight }) =>
        $menuMaxHeight
            ? css`
                  max-height: ${$menuMaxHeight};
                  overflow-x: auto;
              `
            : ''};

    > ul {
        padding: 8px;
        border-radius: 4px;
        box-shadow: none;
    }

    .ant-dropdown-menu-item {
        padding: 0;
    }

    [data-non-menu-item='true'] {
        cursor: unset;

        &:hover {
            background: transparent;
        }
    }
`;
