import React from 'react';
import { useModal } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { PAGE_TYPES } from '../../constants';
import CashBackBadge from './components/CashBackBadge';
import LearnMore from './components/LearnMore';

/* eslint-disable */

const CashBackInfo = inject("store")(
    observer(
        ({
            store,
            badgeText,
            learnMoreText,
            simple,
            publisherName,
            pageType,
            showZeroCashback = false,
            backgroundColor,
        }) => {
            const { closePDP } = store.pdpStore;
            const { removeCashbackValue } = store.marketplaceStore;
            const { setModal, hideModal } = useModal();

            return (
                <CashBackBadge
                    pageType={pageType}
                    badgeText={badgeText}
                    publisherName={publisherName}
                    showZeroCashback={showZeroCashback}
                    backgroundColor={backgroundColor}
                    onClick={() =>
                        setModal(
                            () => (
                                <LearnMore
                                    text={learnMoreText}
                                    onClose={hideModal}
                                    onLogoClick={() => {
                                        if (pageType === PAGE_TYPES.PDP) {
                                            closePDP();
                                            removeCashbackValue();
                                        }
                                        return hideModal();
                                    }}
                                    simple={simple}
                                />
                            ),
                            { fullscreen: true, closable: false }
                        )
                    }
                />
            );
        }
    )
);

export default CashBackInfo;
