import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { GRID_POINTS } from '../../../../../../../../../constants';

export default styled.div`
    width: 425px;
    padding-right: 64px;
    padding-left: 44px;

    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.md
            ? css`
                  width: 300px;
                  padding-right: 64px;
                  padding-left: 44px;
              `
            : ''};

    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.sm
            ? css`
                  width: 100%;
                  padding: 0 38px;
              `
            : ''};
`;
