import React, { Fragment, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS, GRID_POINTS } from '../../../../../../../../constants';
import { useSimpleInterface } from '../../../../../../../../utils';
import Promotion from '../../../../../../../Promotion';
import { Col, Divider, KliThemeContext, Row, Typography } from '../../../../../../../UiLibrary';
import { StyledBestSellersWrapper, StyledBestSellerTitleWrapper } from './styled';

const BestSellers = inject('store')(
    observer(({ store, onBuy }) => {
        const { selectedTaxonomyData } = store;
        const theme = useContext(KliThemeContext);
        const simple = useSimpleInterface(GRID_POINTS.xl);

        const bestsellersCount = simple ? 2 : 3;

        return selectedTaxonomyData?.bestSellers?.length > 0 ? (
            <Fragment>
                <StyledBestSellersWrapper>
                    <StyledBestSellerTitleWrapper>
                        <Typography.Title
                            align={simple ? 'center' : 'left'}
                            level={simple ? 2 : 1}
                            color={theme.colors.secondaryText}
                            style={simple && BOTTOM_INDENTS.XXS}
                        >
                            Bestsellers
                        </Typography.Title>
                    </StyledBestSellerTitleWrapper>
                    <Row gutter={10} wrap={false}>
                        {selectedTaxonomyData?.bestSellers?.slice(0, bestsellersCount)?.map((product) => (
                            <Col key={product.id} span={simple ? 12 : 8} style={simple && BOTTOM_INDENTS.L}>
                                <Promotion onBuy={onBuy} withoutButton showPoints={false} promotion={product} />
                            </Col>
                        ))}
                    </Row>
                </StyledBestSellersWrapper>
                <Divider height='1px' color={theme.colors.lines} />
            </Fragment>
        ) : null;
    })
);

export default BestSellers;
