import React from 'react';
import { inject, observer } from 'mobx-react';
import { ArrowChevronDownIcon, ArrowChevronForwardIcon, BagIcon } from '../../../../../../icons';
import { Icon, Typography } from '../../../../../UiLibrary';
import { ExpansionHeadRow, ExpansionHeadStyles, OrderSummaryWrap } from './styles';

export const ExpansionHead = inject('store')(
    observer(({ store, opened, summaryPrice, pointsEarned }) => {
        return (
            <ExpansionHeadStyles $opened={opened}>
                <ExpansionHeadRow>
                    <OrderSummaryWrap>
                        <Icon size='16px' component={() => <BagIcon />} />
                        &nbsp;
                        <Typography.Text type='badgeSmall'>Order summary</Typography.Text>
                        &nbsp;
                        <Icon
                            size='16px'
                            component={() => (opened ? <ArrowChevronDownIcon /> : <ArrowChevronForwardIcon />)}
                        />
                    </OrderSummaryWrap>
                    <Typography.Text type='badgeMedium'>{summaryPrice}</Typography.Text>
                </ExpansionHeadRow>
                {pointsEarned && store.isLoggedIn ? (
                    <ExpansionHeadRow>
                        <Typography.Text type='body2' color='primary'>
                            Points earned
                        </Typography.Text>
                        <Typography.Text type='body2' color='primary'>
                            up to {pointsEarned} pts
                        </Typography.Text>
                    </ExpansionHeadRow>
                ) : null}
            </ExpansionHeadStyles>
        );
    })
);
