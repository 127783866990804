import BaseInput from './components/BaseInput';
import InputCurrency from './components/InputCurrency';
import InputNumber from './components/InputNumber';
import TextArea from './components/TextArea';

const Input = BaseInput;
Input.Currency = InputCurrency;
Input.Number = InputNumber;
Input.TextArea = TextArea;
export default Input;
