import React from 'react';
import { ArrowChevronForwardIcon, Button, Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import NoRewardsAvailable from './components/NoRewardsAvailable';
import Reward from './components/Reward';
import { StyledRewardsWrapper, StyledWrapper } from './styled';

const RewardsModalContent = inject('store')(
    observer(({ store, variant, onCloseModal }) => {
        const { closePDP } = store.pdpStore;

        const getButtonText = () => {
            if (variant?.rewards?.length) return 'Apply now';
            return 'Learn more';
        };

        return (
            <StyledWrapper>
                {!variant?.rewards?.length ? (
                    <NoRewardsAvailable />
                ) : (
                    <StyledRewardsWrapper>
                        {(variant?.rewards || []).map((reward) => (
                            <Reward reward={reward} key={`${reward.title}${reward.amount}${reward._id}`} />
                        ))}
                    </StyledRewardsWrapper>
                )}
                {(variant?.rewards?.length || 0) > 0 ? null : (
                    <Button
                        size='large'
                        type='unstyled'
                        onClick={() => {
                            onCloseModal();
                            closePDP();
                        }}
                    >
                        <span>{getButtonText()}</span>
                        <Icon component={() => <ArrowChevronForwardIcon />} />
                    </Button>
                )}
            </StyledWrapper>
        );
    })
);

export default RewardsModalContent;
