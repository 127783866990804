import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Typography from '../../../../../UiLibrary/Typography';
import { $secondaryText } from '../../../../styles';
import { getPrices, GRID_POINTS } from '../../../../utils';
import ProductInfo from './components/ProductInfo';
import { CardBody, CardBodyLeft, Info, Prices, ProductTitle, StyledImage, Styles } from './styles';

export const ProductCard = inject('store')(
    observer(({ store, item }) => {
        const priceDetails = item.priceDetails;
        const { fullTotal, discountPercentage } = getPrices({
            ...priceDetails
        });

        const simple = store.view.isSimple(GRID_POINTS.lg);
        const points = store.getItemPointsEarned(item);

        return (
            <Styles simple={simple}>
                <CardBody data-e2e='orderCard-block'>
                    <CardBodyLeft>
                        <StyledImage src={item.image} alt={item.productTitle} />
                        <Info>
                            <ProductTitle data-e2e='order-productTitle' level={3}>
                                {item.productTitle}
                            </ProductTitle>
                            <Typography.Text type='descriptor2' color={$secondaryText}>
                                {item.variantTitle.replace('Default Title', '')}
                            </Typography.Text>
                        </Info>
                    </CardBodyLeft>
                    <Prices>
                        {!store.env && (
                            <Fragment>
                                <Typography.Text type='body2' color={$secondaryText} style={{ marginBottom: '5px' }}>
                                    {`${priceDetails.qty} x ${fullTotal}`}
                                </Typography.Text>
                                <Typography.Text type='body2' align='right' color={$secondaryText}>
                                    {discountPercentage}
                                </Typography.Text>
                            </Fragment>
                        )}
                        {store.env === 'kcp' && (
                            <Typography.Text data-e2e='order-priceFullTotal' type='body2' color={$secondaryText}>
                                {fullTotal}
                            </Typography.Text>
                        )}
                        {points && store.isLoggedIn ? (
                            <Typography.Text data-e2e='orderPoints-val' type='body2' color='primary' align='right'>
                                up to {points} pts
                            </Typography.Text>
                        ) : null}
                    </Prices>
                </CardBody>
                {store.env === 'kcp' && <ProductInfo item={item} />}
            </Styles>
        );
    })
);
