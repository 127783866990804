export const COLORS = {
    $white: '#FFFFFF',
    $border: '#D2D6DA',
    $background: '#F4F4F4',
    $bordered: '#DCDCDC',
    $shadow: '0px 8px 40px rgba(0, 0, 0, 0.16)',
    $tartRed: '#FFEBEB',
    $primary: '#F25858',
    $primaryLight: '#FFEBEB',
    $primaryText: '#04080F',
    $error: '#EF2E2E',
    $success: '#1CA355',
    $secondaryText: '#77838F',
    $info: '#33B6DF'
};
