import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Button, Typography } from '../../../../../UiLibrary';
import { ReactComponent as ThanksImage } from '../../../../assets/img/Icon.svg';
import { $background, $primaryText, $white } from '../../../../styles';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const Styles = styled.div`
    width: 100%;
    max-width: 630px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${$background};
    padding: 90px 150px;

    ${({ $appWidth }) =>
        $appWidth < 1050
            ? css`
                  padding: 90px;
              `
            : ''};

    ${({ $simple }) =>
        $simple
            ? css`
                  width: 100%;
                  background-color: ${$white};
                  padding: 40px 24px;
              `
            : ''};
`;

export const StyledImg = styled(ThanksImage)`
    && {
        width: 80px;
        height: 80px;
        object-fit: contain;
    }
`;

export const InfoWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    color: ${$primaryText};

    .anticon {
        margin-left: 8px;
    }
`;

export const CancelInfoWrap = styled.div`
    display: flex;
    align-items: center;
    color: ${$primaryText};

    @media (${GRID_DEVICES_MAX.md}) {
        width: 100%;
        padding: 12px 24px;
        background: ${$background};
    }
`;

export const CancelOrderButton = styled(Button)`
    && {
        color: inherit;
        padding: 0;
        display: inline-block;
        margin: 0 5px;
        text-decoration: underline;
        text-decoration-color: ${({ $textDecorationColor = $primaryText }) => $textDecorationColor};
    }
`;

export const StyledConfirmationInfo = styled(Typography.Text)`
    @media (${GRID_DEVICES_MAX.xxxs}) {
        margin-bottom: 36px;
    }
`;
