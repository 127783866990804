import React, { Fragment } from 'react';
import nextId from 'react-id-generator';
import { PLACEHOLDERS } from '../../constants';
import { StyledPubText, StyledWord } from './styled';

function BadgeTextMarketplace({ badgeTextArray, publisherName }) {
    return (
        <Fragment>
            {badgeTextArray.map((word, index) => {
                if (word === PLACEHOLDERS.PUBLISHER_NAME) {
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <StyledPubText key={nextId()} data-e2e='dp_badge-brandName'>
                            {publisherName}
                        </StyledPubText>
                    );
                }
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <StyledWord key={`${index}${word}`}>{word}</StyledWord>
                );
            })}
        </Fragment>
    );
}

export default BadgeTextMarketplace;
