import styled from 'styled-components/macro';
import { Icon } from '../../../../../../../../../UiLibrary';
import { $tartRed } from '../../../../../../../../styles';

export const StyledWrap = styled.div`
    width: fit-content;
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;

    padding: 4px;
    background-color: ${$tartRed};
`;

export const StyledIcon = styled(Icon)`
    margin-right: 4px;
`;
