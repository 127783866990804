import React from 'react';
import { observer } from 'mobx-react';
import { ReviewsStarRatingWrap, StyledStars } from './styles';

export const ReviewsStarRating = observer(({ rate, starSize = '24px' }) => {
    const ratePercent = `${(rate / 5) * 100}%`;
    return (
        <ReviewsStarRatingWrap>
            <StyledStars $ratePercent={ratePercent} $starSize={starSize} />
        </ReviewsStarRatingWrap>
    );
});
