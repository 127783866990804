import { PSEUDO_ROOT_CATEGORIES_KEYS } from '../constants';

export const getCategoriesArrayFromString = (categoriesString) => {
    const pseudoRootCategory = Object.values(PSEUDO_ROOT_CATEGORIES_KEYS).find((key) =>
        categoriesString.startsWith(key)
    );
    return pseudoRootCategory
        ? [pseudoRootCategory, ...categoriesString.split(`${pseudoRootCategory}`).join('').split(' > ')].filter(
              (t) => t
          )
        : categoriesString.split(' > ');
};
