import styled from 'styled-components';

export const KliStepperOrient = Object.freeze({
    Horizontal: 'row',
    Vertical: 'column-reverse'
});

export const StepperWrapper = styled.div`
    display: flex;
    flex-direction: ${({ orient }) => orient};
    justify-content: space-between;
    align-items: center;
    width: ${(orient) => (orient === KliStepperOrient.Vertical ? 'auto' : '120px')};
    height: ${({ orient }) => (orient === KliStepperOrient.Vertical ? '120px' : 'auto')};
`;
