import { device } from '@klickly/front-packages';
import styled, { css } from 'styled-components';
import { $bordered, $white, GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../../../shared/constants';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const Wrap = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 140px;

    position: relative;

    overflow: hidden;

    @media (${GRID_DEVICES_MAX.md}) {
        ${({ $withPDP }) =>
            $withPDP
                ? css`
                      padding-bottom: 0;
                  `
                : ''};
        padding-top: ${({ $klicklyLogoVisible }) => ($klicklyLogoVisible ? '140px' : '60px')};
    }
`;

export const Header = styled.header`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 140px;
    border-bottom: 1px solid ${$bordered};
    background: ${$white};

    @media (${GRID_DEVICES_MAX.md}) {
        height: ${({ $klicklyLogoVisible }) => ($klicklyLogoVisible ? '140px' : '60px')};
    }
`;

export const LinkWrap = styled.div`
    width: calc(100% - 10px);

    img {
        @media (${GRID_DEVICES_MAX.md}) {
            width: 100%;
            object-fit: contain;
        }
    }

    a {
        width: 80px;
    }

    @media (${GRID_DEVICES_MIN.md}) {
        max-width: 315px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        max-width: 112px;
    }
`;

export const HeaderContainer = styled.div`
    width: 100%;
    height: 80px;
    max-width: 1400px;
    margin: auto;
    padding: 0 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (${GRID_DEVICES_MAX.md}) {
        height: ${({ $klicklyLogoVisible }) => ($klicklyLogoVisible ? '80px' : '0')};
    }

    ${({ $giftlyControlsVisible }) =>
        !$giftlyControlsVisible &&
        css`
            justify-content: space-between;
            > div {
                margin-right: initial;
            }
        `};
`;

export const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'auto')};
    position: static;

    ${({ $hasAfterHeaderContent }) =>
        $hasAfterHeaderContent &&
        css`
            flex-wrap: wrap;
            > * {
                &:first-child {
                    width: 100%;
                }
                &:not(:first-child) {
                    flex: 1;
                }
            }
        `};
`;

export const SideBar = styled.aside`
    width: 100%;

    @media (${GRID_DEVICES_MIN.md}) {
        max-width: 315px;
        padding: 20px;

        height: 100%;
        overflow-y: auto;

        position: sticky;
        top: 0;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        position: ${() => (isSafari && isIos ? 'fixed' : 'absolute')};
        bottom: 0;
        left: 0;
        z-index: 10;

        max-width: 100%;
        height: 0;
        border-top: 1px solid ${$bordered};
        background: ${$white};
    }

    ${({ $withPDP }) =>
        $withPDP
            ? css`
                  z-index: 51;
              `
            : ''}
`;

export const Content = styled.main`
    width: 100%;
    padding: 35px 20px;

    @media (${GRID_DEVICES_MIN.md}) {
        padding-left: 40px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        padding-bottom: ${() => (isSafari && isIos ? '90px' : 0)};
    }

    ${({ $withSideBar }) =>
        $withSideBar
            ? css`
                  @media (${GRID_DEVICES_MIN.md}) {
                      width: calc(100% - 315px);
                      max-width: calc(1400px - 315px);
                  }
              `
            : ''}

    ${({ $contentFullHeight }) =>
        $contentFullHeight
            ? css`
                  height: 100%;
                  overflow-y: auto;
              `
            : ''}
`;

export const CheckoutComponentWrapper = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    top: 140px;
    z-index: 50;
    background: #ffffff;
    width: 100%;
    height: calc(100% - 140px);

    @media (${GRID_DEVICES_MAX.md}) {
        flex-direction: column-reverse;
        justify-content: flex-end;

        top: ${({ $klicklyLogoVisible }) => (!$klicklyLogoVisible ? '60px' : '140px')};
        height: ${({ $klicklyLogoVisible }) => (!$klicklyLogoVisible ? 'calc(100% - 60px)' : 'calc(100% - 140px)')};
    }
`;

export const PDPComponentWrap = styled.div`
    width: calc(100% - 315px);
    max-width: calc(1400px - 315px);

    @media (${GRID_DEVICES_MIN.md}) {
        padding-left: 40px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        max-width: 100%;
        width: 100%;
        height: 100%;
    }
`;

export const SidebarSpace = styled.div`
    width: 100%;
    visibility: hidden;
    pointer-events: none;

    @media (${GRID_DEVICES_MIN.md}) {
        max-width: 315px;
        padding: 20px;
        height: 100%;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        display: none;
    }
`;
