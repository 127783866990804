import React, { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import HeaderButtons from '../../../../shared/components/HeaderButtons';
import { Logo } from '../../../../shared/components/Logo';
import { LayoutContext } from '../../../../shared/constants/defaultLayoutContext';
import { goToHomePage } from '../../../../shared/utils';
import { PDPComponent } from './components/PDPComponent';
import {
    Body,
    CheckoutComponentWrapper,
    Content,
    Header,
    HeaderContainer,
    LinkWrap,
    PDPComponentWrap,
    SideBar,
    SidebarSpace,
    Wrap
} from './styles';

export const Layout = inject('store')(
    observer(
        ({
            store,
            productId,
            sidebar,
            header,
            contentFullHeight = false,
            children,
            cashbackInfo,
            afterHeaderContent = null
        }) => {
            const location = useLocation();
            const headerRef = useRef();
            const sideRef = useRef();
            const bodyRef = useRef();
            const contentRef = useRef();
            const wrapRef = useRef();

            const { showPDP } = store.pdpStore;
            const { klicklyLogoVisible, giftlyControlsVisible } = store.publisherStore;

            const value = useMemo(
                () => ({
                    headerRef,
                    sideRef,
                    bodyRef,
                    contentRef,
                    wrapRef
                }),
                [headerRef, sideRef, bodyRef, contentRef, wrapRef]
            );

            return (
                <LayoutContext.Provider value={value}>
                    <Wrap
                        $withSideBar={sidebar}
                        $withPDP={showPDP}
                        $klicklyLogoVisible={klicklyLogoVisible}
                        ref={wrapRef}
                    >
                        <Header ref={headerRef} $klicklyLogoVisible={klicklyLogoVisible}>
                            <HeaderContainer
                                $giftlyControlsVisible={giftlyControlsVisible}
                                $klicklyLogoVisible={klicklyLogoVisible}
                            >
                                <LinkWrap onClick={() => goToHomePage(true, location)}>
                                    <Logo />
                                </LinkWrap>
                                {header}
                                <HeaderButtons />
                            </HeaderContainer>
                            {cashbackInfo && cashbackInfo}
                        </Header>
                        <Body
                            ref={bodyRef}
                            overflowHidden={showPDP}
                            $hasAfterHeaderContent={Boolean(afterHeaderContent)}
                        >
                            {afterHeaderContent}
                            {sidebar && (
                                <SideBar ref={sideRef} $withPDP={showPDP}>
                                    {sidebar}
                                </SideBar>
                            )}

                            <Content
                                ref={contentRef}
                                $withSideBar={sidebar}
                                $contentFullHeight={contentFullHeight}
                                $withPDP={showPDP}
                            >
                                {children}
                            </Content>
                            {productId ? (
                                <CheckoutComponentWrapper $klicklyLogoVisible={klicklyLogoVisible}>
                                    <SidebarSpace />
                                    <PDPComponentWrap>
                                        <PDPComponent promotionId={productId} />
                                    </PDPComponentWrap>
                                </CheckoutComponentWrapper>
                            ) : null}
                        </Body>
                    </Wrap>
                </LayoutContext.Provider>
            );
        }
    )
);
