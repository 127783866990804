import React from 'react';
import { inject, observer } from 'mobx-react';
import { useSimpleInterface } from '../../../../../../../../utils';
import Typography from '../../../../../../../UiLibrary/Typography';
import { $info } from '../../../../../../styles';
import { GRID_DEVICES_MAX } from '../../../../../../utils';
import { CancelOrderButton, InfoWrap } from '../../styles';

export const CancelMuiltiplyOrderLine = inject('store')(
    observer(({ store, order, orderIndex, onCancelOrder }) => {
        const simple = useSimpleInterface(GRID_DEVICES_MAX.md);
        return (
            <div key={order.klicklyOrderId}>
                {order.isAvailableForCancel && order.klicklyStatus !== 'refunded' && !store.cancelOrderUnavailable ? (
                    <InfoWrap>
                        <Typography.Text as='div' type='descriptor2'>
                            - Order #{orderIndex + 1} (#{order.orderNumber}) - Cancel
                            <CancelOrderButton
                                $textDecorationColor={$info}
                                type='unstyled'
                                onClick={() => {
                                    store.setOrderForRefund(order.klicklyOrderId);

                                    return onCancelOrder();
                                }}
                            >
                                <Typography.Text type='descriptor2' color='info'>
                                    here
                                </Typography.Text>
                            </CancelOrderButton>
                        </Typography.Text>
                    </InfoWrap>
                ) : null}
                {order.klicklyStatus === 'refunded' ? (
                    <InfoWrap>
                        <Typography.Text as='div' type={simple ? 'descriptor1' : 'descriptor2'}>
                            - Order #{orderIndex + 1} (${order.orderNumber}) - Your order{' '}
                            <Typography.Text as='span' type={simple ? 'descriptor1' : 'descriptor2'} color='primary'>
                                has been cancelled
                            </Typography.Text>
                        </Typography.Text>
                    </InfoWrap>
                ) : null}
            </div>
        );
    })
);
