const storageKey = 'query_params';

class ParamsStorage {
    constructor() {
        this.data = {};
        const queryParams = localStorage.getItem(storageKey);
        try {
            this.data = JSON.parse(queryParams) || {};
        } catch (_) {
            // nothing to do
        }
    }

    get(field, defaultValue = null) {
        return typeof this.data[field] !== 'undefined' ? this.data[field] : defaultValue;
    }

    set(field, value) {
        this.data[field] = value;
        return this;
    }

    has(field) {
        return typeof this.data[field] !== 'undefined';
    }

    save() {
        localStorage.setItem(storageKey, JSON.stringify(this.data));
    }
}
const paramsStorage = new ParamsStorage();
export { paramsStorage };
