import React from 'react';
import { notification as antdNotification } from 'antd';
import { KliIcon } from '../components/UiLibrary/KliIcon';
import { CloseXIcon } from '../icons';

antdNotification.config({
    placement: 'bottomRight',
    closeIcon: (
        <KliIcon>
            <CloseXIcon />
        </KliIcon>
    )
});

export const notification = antdNotification;
