export const dynamicFontSize = (bannerRef, setFontSize, fontSize, bannerText, mobile, setBannerSizePending) => {
    if (!bannerText) {
        setBannerSizePending(false);
        return;
    }

    const height = bannerRef.current.offsetHeight;
    const bannerWidthWithIndent = bannerRef.current.offsetWidth - 48;
    const width = mobile ? bannerWidthWithIndent : bannerWidthWithIndent / 2;

    const createBannerText = document.createElement('h1');
    createBannerText.style.fontSize = fontSize + 'px';
    createBannerText.style.visibility = 'hidden';
    createBannerText.style.opacity = '0';
    createBannerText.style.position = 'absolute';
    createBannerText.style.display = 'block';
    createBannerText.style.lineHeight = '112%';
    createBannerText.style.fontFamily = 'IBM Plex serif';
    createBannerText.style.wordBreak = 'break-word';
    createBannerText.style.width = width + 'px';
    createBannerText.innerHTML = bannerText;
    document.querySelector('#root').appendChild(createBannerText);

    if (createBannerText.offsetHeight > height) {
        setFontSize((prevFontSize) => prevFontSize - 2);
    }

    if (height > createBannerText.offsetHeight) {
        setTimeout(() => {
            setBannerSizePending(false);
        }, 100);
    }

    document.querySelector('#root').removeChild(createBannerText);
};
