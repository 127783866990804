import { MARKETPLACE } from '../constants';

export const getPriceRange = (payload) => {
    if (!payload) return MARKETPLACE.PROMOTIONS_PRICE_STATE;
    const { lte, gte } = MARKETPLACE.PROMOTIONS_PRICE_STATE;
    return {
        lte: payload.lte && !Number.isNaN(Number(payload.lte)) ? Number(payload.lte) : lte,
        gte: payload.gte && !Number.isNaN(Number(payload.gte)) ? Number(payload.gte) : gte
    };
};
