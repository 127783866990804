import { Button } from 'antd';
import styled, { css } from 'styled-components';

export const StyledAntdButton = styled(Button)`
    ${({ $theme }) => css`
        &.ant-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;

            width: auto;
            height: auto;
            padding: 14px 20px;

            & > span:not(:only-child):first-child.kli-icon,
            & > svg:not(:only-child):first-child {
                margin-right: 10px;
            }

            & > span:not(:only-child):last-child.kli-icon,
            & > svg:not(:only-child):last-child {
                margin-left: 10px;
            }

            & span:not(.kli-icon):not(.kli-text),
            & span:not(.kli-icon):not(.kli-text):focus {
                color: ${$theme.colors.primaryText};
                font: ${$theme.fonts.badgeMedium};
            }

            & span,
            & p,
            & div,
            & svg,
            & span.kli-icon {
                transition-duration: 0s;
            }

            & > svg {
                width: 24px;
                height: 24px;
            }
        }

        // ----- TYPE STYLES ----- //
        // type = 'default'
        &.ant-btn-default {
            border-width: 2px;

            &:active,
            &:focus {
                color: ${$theme.colors.primaryText};
                border-color: ${$theme.colors.primaryText};
            }

            @media (hover: hover) {
                &:hover {
                    color: ${$theme.colors.primaryText};
                    border-color: ${$theme.colors.primaryText};
                }
            }
        }

        // type = 'primary'
        &.ant-btn-background-ghost.ant-btn-primary:not(.ant-btn-dangerous),
        &.ant-btn-primary:not(.ant-btn-disabled) {
            &,
            &:focus,
            &:hover {
                background-color: ${$theme.colors.canvas};
                border: 2px solid ${$theme.colors.primaryText};
                color: ${$theme.colors.primaryText};
            }

            &:active {
                background-color: ${$theme.colors.primaryText};
                border: 2px solid ${$theme.colors.primaryText};
                color: ${$theme.colors.canvas};
            }

            @media (hover: hover) {
                &:hover {
                    background-color: ${$theme.colors.primaryText};
                    border: 2px solid ${$theme.colors.primaryText};
                    color: ${$theme.colors.canvas};
                }
            }

            &:disabled,
            &:hover:disabled {
                color: ${$theme.colors.primaryText};
                background-color: ${$theme.colors.canvas};
                border: 2px solid ${$theme.colors.primaryText};
                opacity: 0.5;
            }

            & > span:not(.kli-icon):not(.kli-text) {
                color: inherit;
            }

            // ghost = true
            &.ant-btn-background-ghost {
                &,
                &:focus,
                &:hover {
                    background-color: transparent;
                    color: ${$theme.colors.primaryText};
                }

                &:active {
                    background-color: ${$theme.colors.primaryText};
                    border: 2px solid ${$theme.colors.primaryText};
                    color: ${$theme.colors.canvas};
                }

                @media (hover: hover) {
                    &:hover {
                        background-color: ${$theme.colors.primaryText};
                        border: 2px solid ${$theme.colors.primaryText};
                        color: ${$theme.colors.canvas};
                    }
                }

                &:disabled,
                &:hover:disabled {
                    background-color: transparent;
                    color: ${$theme.colors.primaryText};
                }

                & span:not(.kli-icon):not(.kli-text) {
                    color: inherit;
                }
            }
        }

        // type = 'primary-inverted'
        &.ant-btn-primary-inverted {
            &,
            &:focus,
            &:hover {
                color: ${$theme.colors.canvas};
                background-color: ${$theme.colors.primaryText};
                border: 2px solid ${$theme.colors.canvas};
            }

            &:active {
                background-color: ${$theme.colors.canvas};
                border: 2px solid ${$theme.colors.primaryText};
                color: ${$theme.colors.primaryText};
            }

            @media (hover: hover) {
                &:not(:disabled):hover {
                    background-color: ${$theme.colors.canvas};
                    border: 2px solid ${$theme.colors.primaryText};
                    color: ${$theme.colors.primaryText};
                }
            }

            &:disabled,
            &:hover:disabled {
                background-color: ${$theme.colors.primaryText};
                border: 2px solid ${$theme.colors.canvas};
                color: ${$theme.colors.canvas};
                opacity: 0.5;
            }

            & > span:not(.kli-icon):not(.kli-text) {
                color: inherit;
            }

            // ghost = true
            &.ant-btn-background-ghost {
                &,
                &:active,
                &:hover,
                &:hover:disabled,
                &:focus {
                    background-color: transparent;
                }
            }
        }

        // type = 'secondary'
        &.ant-btn-secondary {
            &,
            &:focus,
            &:hover {
                background-color: ${$theme.colors.background};
                border: 2px solid ${$theme.colors.background};
                color: ${$theme.colors.primaryText};
            }

            &:active {
                background-color: ${$theme.colors.lines};
                border: 2px solid ${$theme.colors.lines};
                color: ${$theme.colors.primaryText};
            }

            @media (hover: hover) {
                &:hover {
                    background-color: ${$theme.colors.lines};
                    border: 2px solid ${$theme.colors.lines};
                    color: ${$theme.colors.primaryText};
                }
            }

            &:disabled,
            &:hover:disabled {
                background-color: ${$theme.colors.background};
                border: 2px solid ${$theme.colors.background};
                color: ${$theme.colors.primaryText};
                opacity: 0.5;
            }

            & > span:not(.kli-icon):not(.kli-text) {
                color: inherit;
            }
        }

        // type = 'text'
        &.ant-btn-text {
            &,
            &:focus,
            &:hover {
                background-color: transparent;
                border: 2px solid transparent;
            }

            &:active {
                background-color: transparent;
                border: 2px solid transparent;
                color: ${$theme.colors.primary};
            }

            @media (hover: hover) {
                &:hover {
                    background-color: transparent;
                    border: 2px solid transparent;
                    color: ${$theme.colors.primary};
                }
            }

            &:disabled,
            &:hover:disabled {
                background-color: transparent;
                border: 2px solid transparent;
                color: ${$theme.colors.primaryText};
                opacity: 0.5;
            }

            & > span:not(.kli-icon):not(.kli-text) {
                color: inherit;
            }
        }

        // type = 'unstyled'
        &.ant-btn-unstyled {
            &,
            &:active,
            &:focus,
            &:hover {
                background-color: transparent;
                border: 2px solid transparent;
                color: ${$theme.colors.primaryText};
                box-shadow: none;

                &:after {
                    display: none;
                }
            }

            &:disabled,
            &:hover:disabled {
                background-color: transparent;
                border: 2px solid transparent;
                color: ${$theme.colors.primaryText};
                box-shadow: none;
                opacity: 0.5;
            }

            & > span:not(.kli-icon):not(.kli-text) {
                color: inherit;
            }
        }

        // type = 'dashed'
        &.ant-btn-dashed {
            &,
            &:focus,
            &:hover {
                background-color: ${$theme.colors.canvas};
                color: ${$theme.colors.secondaryText};
                border-color: ${$theme.colors.secondaryText};
                border-width: 2px;
            }

            &:active {
                color: ${$theme.colors.primaryText};
                border-color: ${$theme.colors.primaryText};
            }

            @media (hover: hover) {
                &:hover {
                    color: ${$theme.colors.primaryText};
                    border-color: ${$theme.colors.primaryText};
                }
            }

            &:disabled,
            &:hover:disabled {
                background-color: ${$theme.colors.canvas};
                color: ${$theme.colors.secondaryText};
                border-color: ${$theme.colors.secondaryText};
                opacity: 0.5;
            }

            &.ant-btn-background-ghost {
                background-color: transparent;

                &:disabled,
                &:hover:disabled {
                    background-color: transparent;
                }
            }

            & > span:not(.kli-icon):not(.kli-text) {
                color: inherit;
            }
        }
        // ----- TYPE STYLES END ----- //

        // ----- SIZE STYLES ----- //
        // size = 'large'
        &.ant-btn-lg {
            & {
                padding: 18px 24px;
            }

            &,
            &:active,
            &:focus {
                font: ${$theme.fonts.badgeLarge};
            }

            & > span:not(.kli-icon):not(.kli-text) {
                font: inherit;
            }
        }

        // size = 'medium'
        &.ant-btn-md {
            & {
                padding: 14px 20px;
            }

            &,
            &:active,
            &:focus {
                font: ${$theme.fonts.badgeMedium};
            }

            span:not(.kli-icon):not(.kli-text) {
                font: inherit;
            }

            & > svg {
                width: 20px;
                height: 20px;
            }
        }

        // size = 'small'
        &.ant-btn-sm {
            & {
                padding: 10px 16px;
            }

            &,
            &:active,
            &:focus {
                font: ${$theme.fonts.badgeSmall};
            }

            span:not(.kli-icon):not(.kli-text) {
                font: inherit;
            }

            & > svg {
                width: 16px;
                height: 16px;
            }
        }
        // ----- SIZE STYLES END ----- //

        // ----- SHAPE STYLES ----- //
        &.ant-btn-circle {
            width: 40px;
            height: 40px;
            padding: 0;
        }
        // ----- SHAPE STYLES END ----- //

        &.ant-btn-block {
            width: 100%;
        }
    `};
`;
