import React, { useContext } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import { StyledAntdCheckbox } from './styles';

export const Checkbox = ({ children, size = '28px', type = 'default', checkedColor, ...props }) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <StyledAntdCheckbox $theme={themeContext} $size={size} $type={type} $checkedColor={checkedColor} {...props}>
            {children}
        </StyledAntdCheckbox>
    );
};
