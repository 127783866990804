import axios from 'axios';

/* eslint-disable */

class HttpClient {
    init(links) {
        this.links = links;
        this.axiosInstance = axios.create({
            withCredentials: true,
        });
    }

    async __requestWrapper(request) {
        try {
            const { data } = await request;
            return data;
        } catch (error) {
            throw error;
        }
    }

    fetchOrdersData(code) {
        return this.__requestWrapper(
            this.axiosInstance.get(`${this.links.getOrderByCode}?code=${code}`)
        );
    }

    fetchOrdersDataByOrderId(orderId) {
        return this.__requestWrapper(
            this.axiosInstance.get(`${this.links.getOrderById}/${orderId}`)
        );
    }

    cancelOrder(orderId, note) {
        return this.__requestWrapper(
            this.axiosInstance.post(`${this.links.cancelOrder}/${orderId}`, {
                note,
            })
        );
    }
}

export default new HttpClient();
