import React from 'react';
import { Logo } from '../../../../../Logo';
import { Body, Content, Header, HeaderContainer, LinkWrap, Wrap } from './styles';

function LearnMoreLayout({ children, onClose }) {
    return (
        <Wrap>
            <Header>
                <HeaderContainer>
                    <LinkWrap onClick={onClose}>
                        <Logo />
                    </LinkWrap>
                </HeaderContainer>
            </Header>
            <Body>
                <Content>{children}</Content>
            </Body>
        </Wrap>
    );
}

export default LearnMoreLayout;
