import React from 'react';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { CircleActionsAlertQuestionIcon, PointsIcon } from '../../../../../../../../../../icons';
import { roundToLocaleString } from '../../../../../../../../../../shared';
import { Icon } from '../../../../../../../../../UiLibrary';
import { $primary } from '../../../../../../../../styles';
import { StyledIcon, StyledText, StyledWrapper } from './styled';
import { getProducts } from './utils';

const EarnedPoints = inject('store')(
    observer(({ store }) => {
        const { onPointsIconClick } = store.callbacks;
        const products = getProducts(getSnapshot(store.cart.bags));

        return (
            <StyledWrapper data-e2e='points-block'>
                <StyledText type='body2' color='primary'>
                    {store.isLoggedIn ? 'Points you’ll earn' : 'Members earn'}
                    <Icon
                        size='12px'
                        onClick={() => onPointsIconClick(products)}
                        color={$primary}
                        component={() => <CircleActionsAlertQuestionIcon />}
                    />
                </StyledText>
                <StyledText data-e2e='points-value' type='body2' color='primary'>
                    <StyledIcon size='14px' component={() => <PointsIcon />} /> +
                    {roundToLocaleString(store.cart.pointsEarned, 0)}
                </StyledText>
            </StyledWrapper>
        );
    })
);

export default EarnedPoints;
