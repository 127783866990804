import styled, { css } from 'styled-components';

export const ReviewsStarRatingWrap = styled.div`
    margin-right: 16px;
`;

export const StyledStars = styled.div`
    display: inline-block;
    font-size: ${({ $starSize }) => $starSize};
    font-family: Times, serif;
    line-height: 1;

    &::before {
        content: '★★★★★';
        letter-spacing: 3px;
        background: ${({ $ratePercent }) =>
            $ratePercent ? css`linear-gradient(90deg, #fc0 ${$ratePercent}, #F4F4F4 ${$ratePercent});` : ''}
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;
