import React, { useEffect, useState } from 'react';
import { roundToLocaleString } from '../../../../utils';
import { getOriginalPrice } from '../../../../utils/getOriginalPrice';
import { getYouPayPrice } from '../../../../utils/getYouPayPrice';
import { Typography } from '../../../UiLibrary';
import { OriginalPrice } from './styles';
import { processOriginalPrice } from './utils';

export const PromotionPrice = ({ variant }) => {
    const [originalPrice, setOriginalPrice] = useState();
    const [isDisplayOriginalPrice, setIsDisplayOriginalPrice] = useState(false);

    useEffect(() => {
        if (!variant) {
            return;
        }
        const [_originalPrice, _isDisplayOriginalPrice] = processOriginalPrice(
            getOriginalPrice(variant),
            getYouPayPrice(variant),
            variant.customPrice
        );

        setOriginalPrice(_originalPrice);
        setIsDisplayOriginalPrice(_isDisplayOriginalPrice);
    }, [variant, originalPrice, setOriginalPrice, isDisplayOriginalPrice, setIsDisplayOriginalPrice]);

    return (
        <div>
            {isDisplayOriginalPrice && (
                <OriginalPrice data-e2e='original-price' type='badgeSmall' color='secondaryText'>
                    ${roundToLocaleString(originalPrice)}
                </OriginalPrice>
            )}

            <Typography.Text
                type='badgeSmall'
                data-e2e='you-pay-price'
                color={isDisplayOriginalPrice ? 'primary' : 'primaryText'}
            >
                {variant?.priceDetails?.youPayPrice <= 0 || variant?.price <= 0
                    ? 'FREE'
                    : '$' + roundToLocaleString(getYouPayPrice(variant))}
            </Typography.Text>
        </div>
    );
};
