import { useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';

export const useDebounce = (func, delay = 150) => {
    const debouncedResults = useMemo(() => debounce(func, delay), []);

    useEffect(() => () => {
        debouncedResults.cancel();
    });
    return debouncedResults;
};
