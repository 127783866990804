import React, { useContext } from 'react';
import { KliThemeContext } from '../../../KliThemeProvider';
import { StyledIcon } from './styled';

const BaseIcon = ({
    color,
    size,
    stylePriority,
    roundedColor,
    roundedSize,
    roundedOpacity = 0.7,
    hoverRoundedColor,
    marginLeft,
    marginRight,
    ...props
}) => {
    const theme = useContext(KliThemeContext);
    return (
        <StyledIcon
            $roundedColor={roundedColor}
            $roundedSize={roundedSize}
            $roundedOpacity={roundedOpacity}
            $theme={theme}
            $color={color}
            $size={size}
            $stylePriority={stylePriority}
            $hoverRoundedColor={hoverRoundedColor}
            $marginLeft={marginLeft}
            $marginRight={marginRight}
            {...props}
        />
    );
};

export default BaseIcon;
