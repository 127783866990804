import styled from 'styled-components/macro';
import { Button } from '../../../../../../../../../UiLibrary';

export const StarRatingStyles = styled.div`
    width: 100%;
`;

export const StyledButton = styled(Button)`
    margin-bottom: ${({ $simple }) => ($simple ? '130px' : '0')};
`;
