import styled from 'styled-components';
import Typography from '../../Typography';

export const CouponDetails = styled.div`
    width: 100%;
`;

export const CouponTitle = styled(Typography.Title)`
    margin-bottom: 16px;
`;
