import React from 'react';
import { Provider } from 'mobx-react';
import store from '../../_store/RootStore';
import Typos from './Typos';

const TyposItem = () => {
    return (
        <Provider store={store}>
            <Typos />
        </Provider>
    );
};

export default TyposItem;
