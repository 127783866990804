import styled from 'styled-components';

export const Label = styled.label`
    display: inline-block;
    height: ${({ large }) => (large ? '32px' : '20px')};
    width: ${({ large }) => (large ? '64px' : '40px')};
    border-radius: 40px;
    box-sizing: content-box;

    input {
        opacity: 0;
        position: fixed;
        z-index: 0;
        height: 0;
        width: 0;

        &:focus:not(:disabled) ~ span {
            box-shadow: 0 0 0 6px ${({ theme }) => theme.colors.lines};
        }

        &:checked:hover:not(:disabled) ~ span {
            background-color: ${({ theme, selectedColor }) =>
                theme.colors[selectedColor] || selectedColor || theme.colors.primary};
        }

        &:hover:not(:disabled) ~ span {
            border-color: ${({ theme, selectedColor }) =>
                theme.colors[selectedColor] || selectedColor || theme.colors.primary};
        }

        &:checked ~ span {
            border-color: ${({ theme, selectedColor }) =>
                theme.colors[selectedColor] || selectedColor || theme.colors.primaryText};
            background-color: ${({ theme, selectedColor }) =>
                theme.colors[selectedColor] || selectedColor || theme.colors.primaryText};

            &::before {
                left: ${({ large }) => (large ? '30px' : '16px')};
                background-color: ${({ theme }) => theme.colors.canvas};
            }
        }

        &:not(:disabled) ~ * {
            cursor: pointer;
        }

        &:disabled ~ * {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
`;

export const Background = styled.span`
    display: block;
    height: ${({ large }) => (large ? '28px' : '18px')};
    width: ${({ large }) => (large ? '60px' : '36px')};
    border-radius: inherit;
    position: relative;
    border: 2px solid ${({ theme }) => theme.colors.primaryText};
    background-color: ${({ theme }) => theme.colors.background};
    transition: ${({ theme }) => theme.transition.default};
    box-sizing: content-box;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -2px;
        left: -2px;
        border-radius: 50%;
        height: ${({ large }) => (large ? '28px' : '18px')};
        width: ${({ large }) => (large ? '28px' : '18px')};
        border: inherit;
        background-color: ${({ theme }) => theme.colors.background};
        transition: left 0.2s ease-in-out;
        box-sizing: content-box;
    }
`;
