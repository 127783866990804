import { types } from 'mobx-state-tree';

export const ViewStore = types
    .model({
        appWidth: types.maybeNull(types.number),
        customizations: types.optional(
            types.model({
                showProductPoints: types.optional(types.boolean, false),
                showApplyPointsBlock: types.optional(types.boolean, false),
                showRewards: types.optional(types.boolean, false)
            }),
            {}
        )
    })
    .views((self) => ({
        isSimple(size) {
            return size > self.appWidth;
        }
    }))
    .actions((self) => ({
        setAppWidth(width) {
            self.appWidth = width;
        }
    }));
