import styled from 'styled-components/macro';
import { $border } from '../../../../styles';

export const ExpansionHeadStyles = styled.div`
    width: 100%;
    cursor: pointer;
    padding: 24px;

    border-bottom: ${({ $opened }) => ($opened ? `1px solid ${$border}` : '1px solid transparent')};
`;

export const ExpansionHeadRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const OrderSummaryWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
