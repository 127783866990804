import { types } from 'mobx-state-tree';

export const TaxonomyBestSellersModel = types.model('TaxonomyBestSellersModel', {
    id: types.identifier,
    _id: types.string,
    externalId: types.maybeNull(types.string),
    account: types.maybeNull(types.string),
    campaign: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    brandName: types.maybeNull(types.string),
    shopDomain: types.maybeNull(types.string),
    category: types.maybeNull(types.string),
    images: types.array(types.frozen()),
    options: types.array(types.frozen()),
    variants: types.array(types.frozen()),
    creativeSettings: types.frozen(),
    brandLink: types.maybeNull(types.string)
});
