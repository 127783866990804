import styled, { css } from 'styled-components';
import { Icon } from '../../../../../../../UiLibrary';

export const StyledInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    ${({ $justify }) =>
        $justify &&
        css`
            justify-content: ${$justify};
        `};
`;

export const StyledInfoWrapper = styled.div`
    margin-top: 60px;
`;

export const StyledIcon = styled(Icon)`
    margin-left: 6px;
`;
