import { Collapse } from 'antd';
import styled, { css } from 'styled-components';

export const StyledAntdCollapse = styled(Collapse)`
    ${({ $iconSize }) => css`
        &.ant-collapse,
        &.ant-collapse-borderless {
            border-radius: 0;
            background-color: transparent;
        }

        &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
            align-items: center;
        }

        &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
            font-size: ${$iconSize};
        }

        &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
            display: inline-flex;
        }

        ${({ $withoutPadding }) =>
            $withoutPadding
                ? css`
                      &.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header,
                      &.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header,
                      & .ant-collapse-content > .ant-collapse-content-box {
                          padding: 0;
                      }

                      &.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
                          right: 0;
                      }
                  `
                : ''}
    `}
`;

export const StyledAntdPanel = styled(Collapse.Panel)``;
