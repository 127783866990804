export const arrayIncludes = (sourceArray, searchArray) => {
    if (!Array.isArray(sourceArray) || !Array.isArray(searchArray) || !sourceArray.length || !searchArray.length)
        return false;

    for (const search of searchArray) {
        const searchKeys = Object.keys(search);
        const exists = sourceArray.find((source) => {
            for (const searchKey of searchKeys) {
                if (!source[searchKey] || source[searchKey] !== search[searchKey]) {
                    return false;
                }
            }
            return true;
        });
        if (!exists) {
            return false;
        }
    }

    return true;
};
