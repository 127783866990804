import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../constants';

export const AppMenuStyles = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    @media (${GRID_DEVICES_MAX.md}) {
        margin-left: auto;
    }

    & > p {
        display: flex;
        align-items: center;
    }

    > button,
    > p {
        width: auto;
        margin-left: 16px;
        white-space: nowrap;

        @media (${GRID_DEVICES_MAX.xs}) {
            margin-left: 8px;
        }
    }
`;
