import React from 'react';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../utils';
import { Sidebar } from '../Sidebar';
import { ChildrenWrap, Wrapper } from './styles';

export const LayoutWithSidebar = inject('store')(
    observer(({ store, children }) => {
        const simple = store.view.isSimple(GRID_POINTS.lg);

        return (
            <Wrapper simple={simple}>
                <Sidebar />
                <ChildrenWrap simple={simple}>{children}</ChildrenWrap>
            </Wrapper>
        );
    })
);
