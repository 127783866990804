import { getParent, types } from 'mobx-state-tree';
import { arrayIncludes } from '../utils';

export const OptionsStore = types
    .model('OptionsStore', {
        list: types.array(
            types.model({
                name: types.string,
                position: types.number,
                selected: types.maybeNull(types.string),
                values: types.array(
                    types.model({
                        value: types.string,
                        disabled: types.boolean
                    })
                )
            })
        ),
        notSelectedErrorVisible: false
    })
    .views((self) => ({
        get product() {
            return getParent(self);
        },
        get allOptionsSelected() {
            return self.amountOptionsSelected === self.list.length;
        },
        get selectedOptions() {
            return self.list.reduce((acc, option) => {
                if (option.selected) {
                    acc.push({
                        position: option.position,
                        value: option.selected
                    });
                }
                return acc;
            }, []);
        },
        get amountOptionsSelected() {
            return self.list.reduce((res, option) => {
                const value = option.selected !== null ? 1 : 0;
                return res + value;
            }, 0);
        }
    }))
    .actions((self) => ({
        selectOption(key, value) {
            self.list[key].selected = value;
            self.checkOptionsForDisabling();
            self.product.variants.checkStrictQty();
        },
        setOptions(options) {
            self.list = options.map((option) => {
                const isSingleOption = option.values.length === 1;
                return {
                    name: option.name,
                    position: option.position,
                    selected: isSingleOption ? option.values[0] : null,
                    values: option.values.map((value) => {
                        return {
                            value,
                            disabled: false
                        };
                    })
                };
            });
        },
        checkOptionsForDisabling() {
            const variants = self.product.variants.list;
            __enableAllOptions(self.list);

            const otherSelectedOptions = self.list.reduce((acc, otherOption) => {
                if (otherOption.selected) {
                    acc.push({
                        value: otherOption.selected,
                        position: otherOption.position
                    });
                }
                return acc;
            }, []);

            for (const option of self.list) {
                option.values.forEach((value) => {
                    const possibleOption = {
                        position: option.position,
                        value: value.value
                    };
                    const possibleVariant = otherSelectedOptions.filter((element) => {
                        return element.position !== possibleOption.position;
                    });
                    possibleVariant.push(possibleOption);

                    const anyVariantExists = variants.some((variant) => {
                        return arrayIncludes(variant.options, possibleVariant);
                    });
                    value.disabled = !anyVariantExists;
                });
            }
        },
        toggleOptionsError(value) {
            self.notSelectedErrorVisible = value;
        }
    }));

function __enableAllOptions(options) {
    options.forEach((option) => {
        option.values.forEach((value) => {
            value.disabled = false;
        });
    });
}
