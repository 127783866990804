import styled from 'styled-components/macro';
import Typography from '../../../UiLibrary/Typography';

export const FieldStyles = styled.div`
    width: 100%;
`;

export const FieldErrorStyles = styled(Typography.Text)`
    padding: 8px 0 0;
`;
