export const GRID_POINTS = Object.freeze({
    xxxs: 300,
    xxs: 370,
    xs: 480,
    sm: 680,
    md: 850,
    lg: 950,
    xl: 1100,
    xxl: 1600
});

export const GRID_DEVICES_MIN = Object.freeze({
    xxs: `min-width: ${GRID_POINTS.xxs + 1}px`,
    xs: `min-width: ${GRID_POINTS.xs + 1}px`,
    sm: `min-width: ${GRID_POINTS.sm + 1}px`,
    md: `min-width: ${GRID_POINTS.md + 1}px`,
    lg: `min-width: ${GRID_POINTS.lg + 1}px`,
    xl: `min-width: ${GRID_POINTS.xl + 1}px`,
    xxl: `min-width: ${GRID_POINTS.xxl + 1}px`
});

export const GRID_DEVICES_MAX = Object.freeze({
    xxxs: `max-width: ${GRID_POINTS.xxxs}px`,
    xxs: `max-width: ${GRID_POINTS.xxs}px`,
    xs: `max-width: ${GRID_POINTS.xs}px`,
    sm: `max-width: ${GRID_POINTS.sm}px`,
    md: `max-width: ${GRID_POINTS.md}px`,
    lg: `max-width: ${GRID_POINTS.lg}px`,
    xl: `max-width: ${GRID_POINTS.xl}px`,
    xxl: `max-width: ${GRID_POINTS.xxl}px`
});
