export const roundToLocaleString = (value, maxDigits = 2) => {
    const toLocaleStringOptions = {
        minimumFractionDigits: maxDigits,
        maximumFractionDigits: maxDigits
    };

    return Number(value).toLocaleString('en-US', toLocaleStringOptions).replace(/,$/, '.');
};

export const toLocalUSString = (value) => {
    if (!value) {
        return '';
    }

    return Number(value).toLocaleString('en-US');
};

export const roundCashbackToLocaleString = (value) => {
    if (Number(value) > 100) {
        return Math.trunc(Number(value)).toLocaleString('en-US');
    }

    return roundToLocaleString(value);
};
