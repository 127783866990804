import React, { useContext } from 'react';
import { ArrowChevronBackIcon, ArrowChevronForwardIcon } from '../../../../../icons';
import { KliThemeContext } from '../../../KliThemeProvider';
import ControlButton from '../ControlButton';
import { StyledWrapper } from './styled';

const BottomControls = ({ bottomControls, carouselRef, prevDisabled, nextDisabled }) => {
    const theme = useContext(KliThemeContext);

    if (!bottomControls || bottomControls?.off) return null;

    return (
        <StyledWrapper $theme={theme} $bottomControls={bottomControls}>
            <ControlButton carouselRef={carouselRef} disabled={prevDisabled}>
                <ArrowChevronBackIcon />
            </ControlButton>
            <ControlButton carouselRef={carouselRef} disabled={nextDisabled} type='next'>
                <ArrowChevronForwardIcon />
            </ControlButton>
        </StyledWrapper>
    );
};

export default BottomControls;
