import styled, { css } from 'styled-components';

export const KliButtonStyled = styled.button`
    display: flex;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    outline: transparent;
    padding: 14px 20px;
    font: ${({ theme }) => theme.fonts.badgeMedium};

    transition: ${({ theme }) => theme.transition.default};
    will-change: outline, color, bacground-color;

    & > svg {
        width: 24px;
        height: 24px;
    }

    & > *:not(:last-child) {
        margin-right: 10px;
    }

    // Default states;
    :focus:not(:hover) {
        border-color: ${({ theme }) => theme.colors.lines};
        outline: 4px solid ${({ theme }) => theme.colors.lines};
    }

    :disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    ${(props) =>
        props.withoutPadding
            ? css`
                  padding: 0;
              `
            : ''}

    // Sizes;
  ${(props) =>
      props.small
          ? css`
                padding: 10px 16px;
                font: ${({ theme }) => theme.fonts.badgeSmall};

                svg {
                    width: 16px;
                    height: 16px;
                }
            `
          : ''}

  ${(props) =>
      props.large
          ? css`
                padding: 18px 24px;
                font: ${({ theme }) => theme.fonts.badgeLarge};
            `
          : ''} // Primary styles

  ${(props) =>
      props.primary
          ? css`
                border: 2px solid ${({ theme }) => theme.colors.primaryText};
                color: ${({ theme }) => theme.colors.primaryText};
                background-color: ${({ theme }) => theme.colors.canvas};

                :hover:not(:disabled):not(:active) {
                    color: ${({ theme }) => theme.colors.canvas};
                    background-color: ${({ theme }) => theme.colors.primaryText};
                    cursor: pointer;
                }

                :active {
                    color: ${({ theme }) => theme.colors.primaryText};
                    background-color: ${({ theme }) => theme.colors.background};
                }
            `
          : ''} // Secondary styles

  ${(props) =>
      props.secondary
          ? css`
                border: 2px solid ${({ theme }) => theme.colors.background};
                color: ${({ theme }) => theme.colors.primaryText};
                background-color: ${({ theme }) => theme.colors.background};

                :hover:not(:disabled):not(:active) {
                    color: ${({ theme }) => theme.colors.primaryText};
                    background-color: ${({ theme }) => theme.colors.lines};
                    cursor: pointer;
                }

                :active {
                    color: ${({ theme }) => theme.colors.primaryText};
                    background-color: ${({ theme }) => theme.colors.lines};
                    cursor: pointer;
                }
            `
          : ''} // Without Background styles

  ${(props) =>
      props.linked
          ? css`
                border: none;
                color: ${({ theme }) => theme.colors.primaryText};
                background-color: transparent;

                :hover:not(:disabled):not(:active) {
                    color: ${({ theme }) => theme.colors.primary};
                    cursor: pointer;
                }

                :active:not(:disabled) {
                    color: ${({ theme }) => theme.colors.primary};
                    cursor: pointer;
                }
            `
          : ''}

  ${(props) =>
      props.unstyled
          ? css`
                border: none;
                color: ${({ theme }) => theme.colors.primaryText};
                background-color: transparent;
                cursor: pointer;
                outline: none;

                :active:not(:disabled) {
                    color: ${({ theme }) => theme.colors.primary};
                }

                :hover:not(:disabled) {
                    color: ${({ theme }) => theme.colors.primary};
                }
            `
          : ''}
`;
