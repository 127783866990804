import styled from 'styled-components';

export const KliTimerMessageWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
    color: ${({ theme }) => theme.colors.canvas};
    background-color: ${({ theme, color }) => theme.colors[color]};
`;

export const TimerMessageWrap = styled.div`
    display: flex;
    align-items: ${({ simple }) => (simple ? 'flex-start' : 'space-between')};
    justify-content: ${({ simple }) => (simple ? 'space-between' : 'flex-start')};
    flex-direction: ${({ simple, reverseOnSimple }) =>
        simple ? 'row' : reverseOnSimple ? 'column-reverse' : 'column'};
    color: inherit;
    background: inherit;
    width: 100%;
`;
