import React, { Fragment } from 'react';
import { Icon } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import PromotionsSlider from './components/PromotionsSlider';
import { StyledWrapper } from './styled';

const RecommendedPromotions = observer(({ promotions, pending, showPDP, closePDP }) => {
    return pending ? (
        <Icon.Loader />
    ) : (
        <Fragment>
            {promotions.length > 0 ? (
                <StyledWrapper data-e2e='recommended-promotions-block'>
                    <PromotionsSlider
                        promotions={promotions}
                        title='You may also like'
                        isRecommendation
                        showPDP={showPDP}
                        closePDP={closePDP}
                    />
                </StyledWrapper>
            ) : null}
        </Fragment>
    );
});

export default RecommendedPromotions;
