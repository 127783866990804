import { getParent, types } from 'mobx-state-tree';
import { delay } from '../shared/utils';

export const TrackingStore = types
    .model('TrackingStore', {})
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const sendEvent = ({ type, name, data }) => {
            if (window.klpixel) {
                window.klpixel(type, name, data);
            }
        };

        const buildPromotionData = (promotion, additionalData = {}) => ({
            _id: promotion._id,
            externalId: promotion.externalId,
            title: promotion.title,
            accountId: promotion.account,
            campaignId: promotion.campaign,
            shopDomain: promotion.shopDomain,
            campaignName: promotion.campaignName,
            brandName: promotion.brandName,
            ...additionalData
        });

        const trackAddToCart = (promotion, additionalData = {}) => {
            if (!promotion) return;

            return sendEvent({
                type: 'event',
                name: 'addToCart',
                data: [
                    {
                        partner: {
                            promotion: buildPromotionData(promotion, additionalData)
                        }
                    }
                ]
            });
        };

        const trackGoToPdpClick = (_promotion) => {
            if (!_promotion) return null;

            return sendEvent({
                type: 'event',
                name: 'click',
                data: {
                    partner: { promotion: buildPromotionData(_promotion) }
                }
            });
        };

        const trackViewPromotions = (promotions) => {
            if (!promotions || !promotions?.length) return null;

            return sendEvent({
                type: 'event',
                name: 'pageView',
                data: promotions.map((promotion) => ({
                    partner: { promotion: buildPromotionData(promotion) }
                }))
            });
        };

        const trackViewPromotion = (promotion) => {
            if (!promotion) return null;

            return sendEvent({
                type: 'event',
                name: 'pageView',
                data: {
                    partner: { promotion: buildPromotionData(promotion) }
                }
            });
        };

        const trackClick = (promotion, brandLink) => {
            const data = {
                partner: {
                    promotion: {
                        _id: promotion._id,
                        externalId: promotion.externalId,
                        title: promotion.title,
                        accountId: promotion.account,
                        campaignId: promotion.campaign,
                        shopDomain: promotion.shopDomain
                    }
                }
            };
            if (brandLink) data.partner.redirectToBrandLink = brandLink;
            return sendEvent({ type: 'event', name: 'click', data });
        };

        const sendAddToCartPostMessage = ({ pixels = [], data, delayTime = 0 }) => {
            pixels.forEach((pixel) => {
                const iframe = document.getElementById(`kl_${pixel.name}_iframe`);

                if (iframe) {
                    let eventData = {};

                    if (pixel.name === 'tw') {
                        eventData = { add_to_cart: 1, item: data.productExternalId, qty: data.qty };
                    }

                    iframe.contentWindow.postMessage({ name: 'add_to_cart', data: eventData }, '*');
                    return delay(delayTime);
                }
            });
        };

        return {
            trackViewPromotion,
            trackClick,
            trackViewPromotions,
            trackGoToPdpClick,
            sendEvent,
            sendAddToCartPostMessage,
            trackAddToCart
        };
    });
