import styled from 'styled-components/macro';
import { Icon } from '../../../../../../../../../UiLibrary';

export const ErrorFieldStyles = styled.div`
    display: flex;
    align-items: center;
`;

export const ErrorFieldIcon = styled(Icon)`
    padding: 0 16px 0 0;
`;
