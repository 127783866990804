import React from 'react';
import { useForm } from 'react-hook-form';
import { inject, observer } from 'mobx-react';
import { Field } from '../../../../../../shared/components/Field';
import { $secondaryText, EMAIL_REGEX } from '../../../../../../shared/constants';
import {
    StyledCancelButton,
    StyledForm,
    StyledLogo,
    StyledLogoWrap,
    StyledSubmitButton,
    StyledText,
    StyledTitle,
    StyledWrap
} from './styles';

const EmailSubscriptionModal = inject('store')(
    observer(({ store, simple, onCloseModal }) => {
        const { publisherLogo } = store.publisherStore;

        const {
            register,
            handleSubmit,
            formState: { errors, isValid }
        } = useForm({ mode: 'onBlur' });

        const handleCloseModal = () => {
            window.sessionStorage.setItem('email-subscription-modal', 'true');
            onCloseModal();
        };

        const handleSubmitData = ({ email }) => {
            store.publisherStore.saveCustomerEmail({ email });
            return handleCloseModal();
        };

        return (
            <StyledWrap>
                {publisherLogo && (
                    <StyledLogoWrap>
                        <StyledLogo src={publisherLogo} />
                    </StyledLogoWrap>
                )}
                <StyledTitle level={2} align='center'>
                    Enter your email to get notified about new offers and savings
                </StyledTitle>
                <StyledForm onSubmit={handleSubmit(handleSubmitData)}>
                    <Field
                        large
                        showWarningIcon
                        type='email'
                        placeholder='Email'
                        autoComplete='off'
                        error={errors?.email?.message}
                        {...register('email', {
                            required: 'This field is required',
                            pattern: {
                                value: EMAIL_REGEX,
                                message: 'Please enter a valid e-mail address'
                            }
                        })}
                    />
                    <StyledSubmitButton disabled={!isValid} htmlType='submit' type='primary' size='large' block>
                        Submit
                    </StyledSubmitButton>
                    <StyledCancelButton type='text' size='large' block onClick={handleCloseModal}>
                        Continue as Guest
                    </StyledCancelButton>
                </StyledForm>
                <StyledText type='descriptor2' align='center' color={$secondaryText} $simple={simple}>
                    By continuing you&apos;re agreeing to the{' '}
                    <a href='/terms' target='_blank'>
                        Terms
                    </a>{' '}
                    and{' '}
                    <a href='/privacy' target='_blank'>
                        Privacy Policy
                    </a>
                </StyledText>
            </StyledWrap>
        );
    })
);

export default EmailSubscriptionModal;
