import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../../../constants';
import { BagIcon, GiftIcon } from '../../../../../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../../../../../constants';
import { getTimeLeft } from '../../../../../../../../../../utils';
import { useAddedToCardModal } from '../../../../../../hooks/useAddedToCardModal';
import { Timer } from './components/Timer';
import StyledButtonsBody from './styled/StyledButtonsBody';
import StyledButtonsWrapper from './styled/StyledButtonsWrapper';
import StyledBuyNowButton from './styled/StyledBuyNowButton';
import StyledCancelButton from './styled/StyledCancelButton';
import StyledCartButton from './styled/StyledCartButton';

export const CheckoutButtons = inject('store')(
    observer(({ store, scrollToOptionsCallback, customButtons, hasBottomIndent = false }) => {
        const simple = store.view.isSimple(GRID_POINTS.sm);
        const smallerButtonText = store.view.isSimple(380);
        const openAddedToCardModal = useAddedToCardModal(simple, store.callbacks?.onAfterAddToCartModalClose);

        const title = store.product.getData('title');
        const brandName = store.product.getData('brandName');
        const images = store.product.getData('images');
        const onGoToShoppingCart = store.callbacks.onGoToShoppingCart;
        const selectedVariant = store.product.variants.selectedVariant;
        const customizations = store.view.customizations?.buttons;
        const isUpsell = store.view.isUpsell();
        const upsellEndDate = store.upsell.endDate;
        const isUpsellTimeLeft = store.view.isUpsell() && getTimeLeft(store.upsell.endDate) <= 0;

        const addedToCartModalData = {
            title,
            brandName,
            images,
            selectedVariant,
            onGoToShoppingCart
        };

        const onCancel = () => store.product.onCancel();
        const onBuyNow = (e) => {
            e.stopPropagation();
            e.preventDefault();
            store.product.buyNow({
                onOptionsErrorCallback: scrollToOptionsCallback
            });
        };

        const onAddToCart = () =>
            store.product.addToCart({
                onOptionsErrorCallback: scrollToOptionsCallback,
                onAddToCartSuccessCallback: (promotion) => {
                    openAddedToCardModal({
                        data: addedToCartModalData,
                        promotion
                    });
                }
            });

        const showAddToCartConfirmModal = store.callbacks.showAddToCartConfirmModal({
            simple,
            onConfirm: onAddToCart
        });

        const handleAddToCartClick = (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (!store.view.withGSConfirmModal()) {
                onAddToCart();
            } else {
                // show confirm GS modal
                return store.product.showConfirmAddToCartModal({
                    onOptionsErrorCallback: scrollToOptionsCallback,
                    onSuccess: showAddToCartConfirmModal
                });
            }
        };

        return (
            <Fragment>
                <StyledButtonsWrapper style={hasBottomIndent ? BOTTOM_INDENTS.SM : {}} $simple={simple}>
                    {isUpsell ? (
                        <Fragment>
                            <Timer simple={simple} endDate={upsellEndDate} onTimeLeft={onCancel} />
                        </Fragment>
                    ) : null}
                    {customButtons || (
                        <StyledButtonsBody $simple={simple} $direction={customizations?.pdpButtonsDirection}>
                            {customizations?.showAddToCartButton && !isUpsell ? (
                                <Fragment>
                                    <StyledCartButton
                                        data-e2e='add-to-cart-button'
                                        type='primary'
                                        size='large'
                                        disabled={selectedVariant && selectedVariant.inCart}
                                        onClick={handleAddToCartClick}
                                        $simple={simple}
                                        $smallerButtonText={smallerButtonText}
                                    >
                                        {selectedVariant && selectedVariant.inCart
                                            ? [
                                                  customizations?.showAddToCartButtonIcon ? (
                                                      <Icon
                                                          stylePriority
                                                          key='icon-key'
                                                          size={smallerButtonText ? '18px' : '24px'}
                                                          component={() => <BagIcon />}
                                                      />
                                                  ) : null,
                                                  <Typography.Text
                                                      key='text-key'
                                                      type={smallerButtonText ? 'badgeSmall' : 'badgeLarge'}
                                                  >
                                                      In cart
                                                  </Typography.Text>
                                              ]
                                            : [
                                                  customizations?.showAddToCartButtonIcon ? (
                                                      <Icon
                                                          stylePriority
                                                          key='icon-key'
                                                          size={smallerButtonText ? '18px' : '24px'}
                                                          component={() =>
                                                              customizations?.showAddToCartButtonGiftIcon ? (
                                                                  <GiftIcon />
                                                              ) : (
                                                                  <BagIcon />
                                                              )
                                                          }
                                                      />
                                                  ) : null,
                                                  <Typography.Text
                                                      key='text-key'
                                                      type={smallerButtonText ? 'badgeSmall' : 'badgeLarge'}
                                                  >
                                                      {customizations?.addToCartButtonLabel}
                                                  </Typography.Text>
                                              ]}
                                    </StyledCartButton>
                                    {customizations?.showBuyNowButton ? <Fragment>&nbsp;&nbsp;&nbsp;</Fragment> : null}
                                </Fragment>
                            ) : null}
                            {customizations?.showBuyNowButton ? (
                                <StyledBuyNowButton
                                    data-e2e='buy-now-button'
                                    type='primary'
                                    size='large'
                                    disabled={isUpsellTimeLeft}
                                    onClick={onBuyNow}
                                    $simple={simple}
                                    $singleButton={!customizations?.showAddToCartButton}
                                >
                                    {customizations?.showBuyNowButtonGiftIcon && (
                                        <Icon size='24px' stylePriority component={() => <GiftIcon />} />
                                    )}
                                    {customizations?.showBuyNowButtonBagIcon && (
                                        <Icon size='24px' stylePriority component={() => <BagIcon />} />
                                    )}
                                    <Typography.Text type={smallerButtonText ? 'badgeSmall' : 'badgeLarge'}>
                                        {customizations?.buyNowButtonLabel}
                                    </Typography.Text>
                                </StyledBuyNowButton>
                            ) : null}
                            {isUpsell ? (
                                <Fragment>
                                    &nbsp; &nbsp; &nbsp;
                                    <StyledCancelButton
                                        type='secondary'
                                        size='large'
                                        onClick={onCancel}
                                        $simple={simple}
                                        $smallerButtonText={smallerButtonText}
                                        disabled={isUpsellTimeLeft}
                                    >
                                        <Typography.Text type={smallerButtonText ? 'badgeSmall' : 'badgeLarge'}>
                                            {customizations?.cancelButtonLabel}
                                        </Typography.Text>
                                    </StyledCancelButton>
                                </Fragment>
                            ) : null}
                        </StyledButtonsBody>
                    )}
                </StyledButtonsWrapper>
            </Fragment>
        );
    })
);
