import React, { useCallback } from 'react';
import { useModal } from '../../../../../../UiLibrary';
import { WriteReviewModal } from '../components/WriteReviewModal';

export const useWriteReviewModal = (simple = false, store) => {
    const { setModal, hideModal } = useModal();

    return useCallback(() => {
        setModal(<WriteReviewModal store={store} simple={simple} onModalClose={hideModal} />, {
            fullscreen: simple
        });
    }, [setModal, hideModal, simple]);
};
