import { getEnv, types } from 'mobx-state-tree';
import { ErrorsStore } from './ErrorsStore';
import { OptionsStore } from './OptionsStore';
import { ProductStore } from './ProductStore';
import { ReviewsStore } from './ReviewsStore';
import { UpsellStore } from './UpsellStore';
import { VariantsStore } from './VariantsStore';
import { ViewStore } from './ViewStore';

export const RootStore = types
    .model('RootStore', {
        isLoading: true,
        product: types.optional(ProductStore, {}),
        variants: types.optional(VariantsStore, {}),
        options: types.optional(OptionsStore, {}),
        reviews: types.optional(ReviewsStore, {}),
        upsell: types.optional(UpsellStore, {}),
        view: types.optional(ViewStore, {
            route: 'pdp'
        }),
        notifications: types.maybeNull(types.frozen()),
        errors: ErrorsStore
    })
    .volatile(() => ({
        useOnProductReviewSubmit: types.function,
        useProductReviewNotification: types.function
    }))
    .views((self) => ({
        get testMode() {
            return getEnv(self).testMode;
        },
        get callbacks() {
            return getEnv(self).callbacks;
        },
        get metadata() {
            return getEnv(self).metadata;
        },
        get redirects() {
            return getEnv(self).redirects;
        }
    }))
    .actions((self) => ({
        afterCreate() {
            self.product.loadProduct();
        },
        markLoading(loading) {
            self.isLoading = loading;
        },
        setHooks(useOnProductReviewSubmit, useProductReviewNotification, notificationMessages) {
            self.notifications = notificationMessages;
            self.useOnProductReviewSubmit = useOnProductReviewSubmit;
            self.useProductReviewNotification = useProductReviewNotification;
        }
    }));
