import React from 'react';
import { CloseXIcon } from '@klickly/front-packages';
import { CloseModalIcon, StyledGlobal, StyledModalContent, StyledModalWrapper, StyledTitle } from './styled';

function Modal({ onClose, title, children, options = {}, simple }) {
    const { modalWrapperOptions, titleOptions, modalContentOptions, withCloseButton } = options;

    return (
        <StyledModalWrapper {...modalWrapperOptions} onClick={(e) => e.stopPropagation()}>
            {simple && <StyledGlobal />}
            {withCloseButton ? (
                <CloseModalIcon
                    size='24px'
                    roundedSize='200%'
                    color='canvas'
                    onClick={onClose}
                    component={() => <CloseXIcon />}
                />
            ) : null}
            <StyledTitle {...titleOptions}>{title}</StyledTitle>
            <StyledModalContent {...modalContentOptions} $simple={simple}>
                {children}
            </StyledModalContent>
        </StyledModalWrapper>
    );
}

export default Modal;
