import React, { Fragment, useCallback, useLayoutEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../constants';
import PDPPage from './components/PDPPage';
import ReviewPage from './components/ReviewPage';
import { ReviewsPage } from './components/ReviewsPage';
import { UnavailablePage } from './components/unavailable';
import StyledContainer from './styled/StyledContainer';
import StyledWrapper from './styled/StyledWrapper';
import { debounce } from './utils';

const AppContainer = inject('store')(
    observer(({ store, extraContent, bottomExtraContent, customButtons }) => {
        const [node, setNode] = useState(null);

        const appRef = useCallback((node) => {
            let timeout;
            if (node !== null) {
                setNode(node);
                store.view.setAppWidth(node.offsetWidth);

                // reset initial width for cases when component mount inside modal
                timeout = setTimeout(() => store.view.setAppWidth(node.offsetWidth), 100);
            }
            clearTimeout(timeout);
            return null;
        }, []);

        useLayoutEffect(() => {
            if (node) {
                const onResize = debounce(() => {
                    store.view.setAppWidth(node.offsetWidth);
                }, 500);

                window.addEventListener('resize', onResize);

                return () => {
                    window.removeEventListener('resize', onResize);
                };
            }
        }, [node]);

        const simple = store.view.isSimple(GRID_POINTS.sm);

        const trackPdpUiEvents = () => {
            if (store.view.route !== 'pdp') return;

            store.callbacks.onPdpPageClick(store.product.trackingData);
        };

        return (
            <StyledWrapper $simple={simple} ref={appRef}>
                <StyledContainer
                    $simple={simple}
                    {...(store.view.route === 'pdp' && { 'data-omit-event-block': true })}
                    onClick={trackPdpUiEvents}
                >
                    {extraContent || null}
                    {store.view.route === 'pdp' && (
                        <PDPPage bottomExtraContent={bottomExtraContent} customButtons={customButtons} />
                    )}
                    {store.view.customizations?.reviews?.showReviews ? (
                        <Fragment>
                            {store.view.route === 'reviews' && <ReviewsPage />}
                            {store.view.route === 'review' && <ReviewPage />}
                            {store.view.route === 'unavailable' && <UnavailablePage />}
                        </Fragment>
                    ) : null}
                    {!simple && bottomExtraContent ? bottomExtraContent : null}
                </StyledContainer>
            </StyledWrapper>
        );
    })
);

export default AppContainer;
