import { axiosInstance } from './axiosInstance';

/* eslint-disable no-useless-catch */
export const INITIAL_STORE = {
    get: async (url, params) => {
        const { data } = await axiosInstance.get(url, params && { params });
        return data;
    },
    post: async (url, body = {}) => {
        const { data } = await axiosInstance.post(url, body);
        return data;
    },
    patch: async (url, body = {}) => {
        const { data } = await axiosInstance.patch(url, body);
        return data;
    },
    put: async (url, body = {}) => {
        const { data } = await axiosInstance.put(url, body);
        return data;
    },
    delete: async (url, params) => {
        const { data } = await axiosInstance.delete(url, params && { params });
        return data;
    }
};
