import { types } from 'mobx-state-tree';
export const PromotionModel = types.model({
    campaign: types.string,
    isValidForMarketplace: types.maybe(types.boolean),
    campaignName: types.maybeNull(types.string),
    id: types.identifier,
    externalId: types.string,
    isValidForWidget: types.maybe(types.boolean),
    account: types.string,
    title: types.string,
    description: types.maybeNull(types.string),
    brandName: types.string,
    shopDomain: types.string,
    createdAt: types.maybe(types.string),
    category: types.string,
    keywords: types.array(types.string),
    labels: types.array(types.string),
    storeType: types.maybe(types.string),
    descriptions: types.array(types.string),
    images: types.array(
        types.model({
            externalUpdatedAt: types.maybe(types.string),
            src: types.string,
            externalId: types.identifierNumber,
            position: types.number,
            externalProductId: types.number
        })
    ),
    options: types.array(
        types.model({
            values: types.array(types.string),
            name: types.string,
            externalId: types.identifierNumber,
            position: types.number,
            externalProductId: types.number
        })
    ),
    variants: types.array(
        types.model({
            quantity: types.number,
            parentAttributesValues: types.array(types.string),
            originalPrice: types.maybeNull(types.number),
            inventoryPolicy: types.string,
            externalId: types.identifierNumber,
            title: types.string,
            requiresShipping: types.boolean,
            price: types.maybeNull(types.number),
            fulfillmentService: types.string,
            option1: types.string,
            position: types.number,
            sku: types.maybeNull(types.string),
            externalProductId: types.number,
            pointsPrice: types.maybeNull(types.number),
            cashbackPrice: types.maybeNull(types.number),
            priceDetails: types.maybe(
                types.maybeNull(
                    types.model({
                        originalPrice: types.maybeNull(types.number),
                        rewardsPrice: types.maybeNull(types.number),
                        rewardsPercent: types.maybeNull(types.number),
                        youPayPrice: types.maybeNull(types.number)
                    })
                )
            )
        })
    ),
    customizableOptions: types.array(types.string),
    _id: types.string,
    score: types.maybeNull(types.number),
    creativeSettings: types.maybe(
        types.model({
            adImageView: types.string
        })
    )
});
