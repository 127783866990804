import React, { useContext } from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import { device } from '../../../utils';
import { KliThemeContext } from '../KliThemeProvider';
import { StyledAntdSlider } from './styles';

export const Slider = ({ children, ...props }) => {
    const isIos = device.iOS();
    const isSafari = device.isSafari();
    const themeContext = useContext(KliThemeContext);

    return (
        <StyleProvider hashPriority={isSafari && !isIos ? 'high' : 'low'}>
            <StyledAntdSlider $theme={themeContext} {...props}>
                {children}
            </StyledAntdSlider>
        </StyleProvider>
    );
};
