import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Background, ClearInputIcon, InputWrap, Label, Placeholder } from '../KliField/Base/styles';
import { KliThemeContext } from '../KliThemeProvider';

export const KliTextarea = React.forwardRef(
    (
        {
            value = '',
            onChange,
            placeholder,
            large,
            invalid,
            nonOpacityOnDisabled,
            withoutClearButton,
            highlightValidateOnFirstTouch = true,
            ...rest
        },
        ref
    ) => {
        const [inputValue, setInputValue] = useState(value);
        const [touched, setTouched] = useState(false);
        const theme = useContext(KliThemeContext);

        useEffect(() => {
            setInputValue(value);
        }, [value, setInputValue]);

        const onInputChange = useCallback(
            (e) => {
                setInputValue(e.target.value);

                if (typeof onChange !== 'undefined') {
                    onChange(e);
                }
            },
            [setInputValue]
        );

        return (
            <InputWrap disabled={rest.disabled} theme={theme} nonOpacityOnDisabled={nonOpacityOnDisabled}>
                <Label theme={theme} large={large ? 1 : 0} withoutClearButton={withoutClearButton}>
                    <textarea
                        value={inputValue}
                        placeholder=' '
                        onFocus={() => setTouched(true)}
                        onReset={() => setTouched(false)}
                        data-highlight-validate-touch={highlightValidateOnFirstTouch && touched}
                        onChange={onInputChange}
                        data-invalid={invalid ? 1 : 0}
                        ref={ref}
                        {...rest}
                    />

                    <Placeholder large={large ? 1 : 0} theme={theme}>
                        {placeholder}
                    </Placeholder>

                    {inputValue && !rest.disabled && !withoutClearButton && (
                        <span>
                            <ClearInputIcon large={large ? 1 : 0} onClick={() => setInputValue('')} />
                        </span>
                    )}

                    <Background theme={theme} />
                </Label>
            </InputWrap>
        );
    }
);

KliTextarea.displayName = 'KliTextarea';
