import styled from 'styled-components/macro';

export default styled.a`
    display: flex;
    padding: 12px 0;

    .anticon {
        margin-left: 10px;
    }
`;
