/* eslint-disable */

const storageCheckValue = "1";
const storageCheckKey = "_klpixel_storage_check";

export class LocalStorage {
    static get(key) {
        try {
            return localStorage.getItem(key) || undefined;
        } catch (e) {
            return undefined;
        }
    }

    static set(key, value) {
        try {
            localStorage.setItem(key, value);
            return LocalStorage.get(key) === value;
        } catch (e) {
            return false;
        }
    }

    static remove(key) {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            return false;
        }
    }

    static isSupport() {
        LocalStorage.set(storageCheckKey, storageCheckValue);
        return LocalStorage.get(storageCheckKey) === storageCheckValue;
    }
}
