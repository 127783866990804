import React from 'react';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { CircleActionsAlertQuestionIcon, PointsIcon } from '../../../../../../../../../../../../../../icons';
import { useSimpleInterface } from '../../../../../../../../../../../../../../utils';
import { Icon, Typography } from '../../../../../../../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../../../../../../../constants';
import { geEntityFromStoreByPath } from '../../utils';
import PopoverPoints from './components/PopoverPoints';
import { StyledIcon, StyledText, StyledWrapper } from './styled';

const EarnedPoints = inject('store')(
    observer(({ store }) => {
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const points = store.product.pointsValue;
        const isWelcome = store.product.pointsIsWelcome;
        const pointsLabel = geEntityFromStoreByPath(store.view.customizations, 'points.pointsLabel', 'Members earn');

        if (!points) return null;

        return (
            <StyledWrapper data-e2e='points-earn-block'>
                <StyledText $appWidth={store.view.appWidth} type='body2' color='primary'>
                    <Typography.Text as='span'>
                        {pointsLabel}
                        {isWelcome ? ' as Welcome Gift' : ''}
                    </Typography.Text>
                    <Icon
                        size='12px'
                        color='primary'
                        style={{ marginLeft: '6px' }}
                        onClick={() =>
                            typeof store.callbacks.onPointsQuestionClick === 'function' &&
                            store.callbacks.onPointsQuestionClick(getSnapshot(store.product))
                        }
                        component={() => <CircleActionsAlertQuestionIcon />}
                    />
                </StyledText>
                <StyledText data-e2e='points-earn-value' type='body2' color='primary'>
                    <PopoverPoints points={points} showUsdOnly={simple} placement={simple ? 'topRight' : 'bottomRight'}>
                        <StyledIcon size='14px' component={() => <PointsIcon />} /> +{points}
                    </PopoverPoints>
                </StyledText>
            </StyledWrapper>
        );
    })
);

export default EarnedPoints;
