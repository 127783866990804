import { Carousel } from 'antd';
import styled from 'styled-components';
import { sharedCarouselStyles } from '../../styled';

export const StyledWrapper = styled.div`
    ${sharedCarouselStyles};
`;

export const StyledPreview = styled(Carousel)`
    && {
        .slick-slide {
            padding: 0 4px;
            height: 60px;
        }
    }
`;

export const StyledCarousel = styled(Carousel)`
    margin-bottom: 16px;

    .slick-slide {
        padding: 0 4px;
    }
`;
