import React from 'react';
import { Promotion as BasePromotion } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import placeholder from '../../../assets/img/image-placeholder.svg';
import CashbackBadge from './components/CashbackBadge';

export const Promotion = observer(({ promotion, onBuy, variant }) => {
    return (
        <BasePromotion
            promotion={promotion}
            showPoints={false}
            afterPointsContent={<CashbackBadge cashback={variant?.cashbackPrice} />}
            placeHolder={placeholder}
            onBuy={onBuy}
        />
    );
});
