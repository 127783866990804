import { Button, device, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $bordered, $white, GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../constants';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const Wrap = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 80px;

    position: relative;

    overflow: hidden;

    @media (${GRID_DEVICES_MAX.md}) {
        padding-bottom: 80px;
    }
`;

export const Header = styled.header`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;

    border-bottom: 1px solid ${$bordered};
`;

export const HeaderContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'auto')};
    position: static;
`;

export const SideBar = styled.aside`
    width: 100%;

    @media (${GRID_DEVICES_MIN.md}) {
        max-width: 305px;
        padding: 20px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        position: ${() => (isSafari && isIos ? 'fixed' : 'absolute')};
        bottom: 0;
        left: 0;
        z-index: 10;

        max-width: 100%;
        height: 80px;
        border-top: 1px solid ${$bordered};
        background: ${$white};
    }
`;

export const ContentStyles = styled.div`
    max-width: 495px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
`;

export const StyledTitle = styled(Typography.Text)`
    margin: 60px 0 24px 0 !important;
`;

export const StyledButton = styled(Button)`
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }
`;
