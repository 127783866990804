import { css } from 'styled-components';

export const sharedCarouselStyles = css`
    && {
        .slick-prev,
        .slick-prev:hover,
        .slick-prev:focus {
            left: -38px;
            margin-top: 0;
            font-size: inherit;
            z-index: 2;
        }

        .slick-next,
        .slick-next:hover,
        .slick-next:focus {
            right: -38px;
            margin-top: 0;
            font-size: inherit;
            z-index: 2;
        }
    }

    .slick-track {
        display: flex;
    }
    .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
    }
`;
