import React, { useCallback, useState } from 'react';
import { StyledAntdModal } from './styles';

const ModalContext = React.createContext(null);

export const Modal = ({
    children,
    width = '630px',
    centered = true,
    padding = '0',
    defaultFooter = false,
    fullscreen = false,
    maskClosable = true,
    onClose,
    ...props
}) => {
    let customProps = {
        $padding: padding,
        $defaultFooter: defaultFooter,
        $fullscreen: fullscreen,
        width: fullscreen ? null : width,
        onCancel: onClose,
        maskClosable,
        centered
    };

    customProps = defaultFooter ? { ...customProps } : { ...customProps, footer: null };

    return (
        <StyledAntdModal {...props} {...customProps}>
            {children}
        </StyledAntdModal>
    );
};

export const ModalProvider = ({ children, ...rest }) => {
    const [modal, _setModal] = useState();
    const [settings, setSettings] = useState({});

    const hideModal = useCallback(() => {
        _setModal();
    }, [_setModal]);

    const setModal = useCallback(
        (modal, settings = {}) => {
            _setModal(modal);
            if (typeof settings !== 'undefined') {
                setSettings({ ...settings });
            }
        },
        [_setModal, setSettings]
    );

    const setParams = useCallback(
        (params) => {
            if (typeof params !== 'undefined') {
                setSettings({ ...params });
            }
        },
        [setSettings]
    );

    return (
        <ModalContext.Provider value={{ hideModal, setModal, setParams }} {...rest}>
            {children}
            {modal && (
                <Modal open={Boolean(modal)} onClose={hideModal} {...settings}>
                    {modal}
                </Modal>
            )}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = React.useContext(ModalContext);

    if (context === undefined) {
        throw new Error('useModal must be used within a ModalProvider');
    }

    return context;
};
