import React, { Fragment, useState } from 'react';
import { GRID_POINTS } from '../../../../../constants';
import { CloseXIcon } from '../../../../../icons';
import { roundToLocaleString, useSimpleInterface } from '../../../../../utils';
import { BasePopover } from '../BasePopover';
import { StyledContent, StyledIcon, StyledPopover } from './styled';

const PopoverPoints = ({
    children,
    points,
    showUsdOnly,
    showCloseIconOnMobile = true,
    textType = 'body2',
    textLineHeight,
    ...props
}) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    const [clicked, setClicked] = useState(false);
    const [hovered, setHovered] = useState(false);

    const handleHoverChange = (open) => {
        setHovered(open);
        setClicked(false);
    };
    const handleClickChange = (open) => {
        setHovered(false);
        setClicked(open);
    };

    return (
        <Fragment>
            <StyledPopover />
            <BasePopover
                open={simple ? clicked : hovered}
                onOpenChange={simple ? handleClickChange : handleHoverChange}
                overlayClassName='popover-points'
                trigger={simple ? 'click' : 'hover'}
                getPopupContainer={(node) => node}
                type='primary'
                content={
                    <StyledContent type={textType} color='canvas' $textLineHeight={textLineHeight}>
                        {!showUsdOnly && `${points} points = `}
                        <span>{(points / 100) % 1 ? roundToLocaleString(points / 100) : points / 100} USD</span>
                        {simple && showCloseIconOnMobile && (
                            <StyledIcon
                                size='12px'
                                onClick={() => handleClickChange(false)}
                                component={() => <CloseXIcon />}
                            />
                        )}
                    </StyledContent>
                }
                {...props}
            >
                {children}
            </BasePopover>
        </Fragment>
    );
};

export default PopoverPoints;
