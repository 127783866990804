import React, { Fragment, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { CircleActionsCloseIcon } from '../../../../../../../../../../../../../../icons';
import { useFitContent } from '../../../../../../../../../../../../../../shared';
import { Typography } from '../../../../../../../../../../../../../UiLibrary';
import { OptionError } from '../OptionError';
import { OptionsBlockRow } from '../optionsBlockRow';
import { ContentStyles, Input, ItemsWrap, Label, OptionsBlockWrap, StyledSelect, WrapStyles } from './styles';

export const Option = observer(({ appWidth, optionKey, option, highlightError = false, onClick }) => {
    const wrapperRef = useRef();
    const contentRef = useRef();

    if (!(option && option.values && option.values.length)) {
        return null;
    }

    const simple = useFitContent({ wrapperRef, contentRef });

    return (
        <WrapStyles ref={wrapperRef} simple={simple}>
            <ContentStyles ref={contentRef} style={{ width: 'fit-content' }}>
                {simple ? (
                    <StyledSelect
                        onClick={(e) => e.stopPropagation()}
                        $appWidth={appWidth}
                        value={option.selected || ''}
                        label={`Select ${option.name}`}
                        error={highlightError}
                        onChange={(value) => onClick(optionKey, value)}
                        options={option.values.map((option) => ({
                            label: option.value,
                            value: option.value,
                            disabled: option.disabled
                        }))}
                    />
                ) : (
                    <ItemsWrap>
                        {option.values.map((value, valueKey) => {
                            return (
                                <Label
                                    key={`option-${valueKey}`}
                                    data-e2e={`option-value-${valueKey}`}
                                    active={value.value === option.selected}
                                    disabled={value.disabled}
                                >
                                    <Typography.Text type='descriptor2'>{value.value}</Typography.Text>
                                    <Input
                                        onClick={(e) => e.stopPropagation()}
                                        type='checkbox'
                                        name={option.name}
                                        onChange={() => onClick(optionKey, value.value)}
                                    />
                                </Label>
                            );
                        })}
                    </ItemsWrap>
                )}
            </ContentStyles>
        </WrapStyles>
    );
});

export const Options = inject('store')(
    observer(({ store }) => {
        const options = store.product.optionsList;
        return (
            <Fragment>
                {options && options.length
                    ? options.map((option, key) => {
                          const highlightNotSelectedError =
                              !option.selected && store.product.notSelectedOptionsErrorVisible;
                          return (
                              <OptionsBlockWrap key={`options-block-row-${key}`}>
                                  <OptionsBlockRow title={option.name}>
                                      <Option
                                          appWidth={store.view.appWidth}
                                          optionKey={key}
                                          option={option}
                                          highlightError={highlightNotSelectedError}
                                          onClick={(key, value) => {
                                              store.product.options.selectOption(key, value);
                                          }}
                                      />
                                  </OptionsBlockRow>
                                  {highlightNotSelectedError ? (
                                      <OptionError
                                          error={`Choose ${option.name} option`}
                                          icon={<CircleActionsCloseIcon />}
                                          showIcon
                                      />
                                  ) : null}
                              </OptionsBlockWrap>
                          );
                      })
                    : null}
            </Fragment>
        );
    })
);
