import { getYouPayPrice } from '../../../utils/getYouPayPrice';
export const getVariantByPrice = (variants) => {
    let variant = variants[0];

    variants.forEach((_variant) => {
        const price = getYouPayPrice(variant);
        const _price = getYouPayPrice(_variant);
        variant = price < _price ? variant : _variant;
    });

    return variant;
};
