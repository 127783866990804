import React from 'react';
import { CartIcon, Icon, Typography } from '@klickly/front-packages';
import { goToGiftly } from '../../../../utils';

function CartData() {
    return (
        <Typography.Text style={{ cursor: 'pointer' }} color='primaryText' onClick={() => goToGiftly()}>
            <Icon component={() => <CartIcon />} />
            &nbsp;
            <Typography.Text type='badgeMedium' as='span' data-e2e='header-cart-amount'>
                0
            </Typography.Text>
        </Typography.Text>
    );
}

export default CartData;
