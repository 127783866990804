import styled, { css } from 'styled-components';
import { Button, KliField, Typography } from '../../../../../../../../../UiLibrary';
import { $border, $error, $white } from '../../../../../../../../styles';

export const StyledPayWithPointsForm = styled.div`
    margin-bottom: 16px;
    padding: 10px 0 20px;
    border-bottom: 1px solid ${$border};
    position: relative;
    min-height: 119px;
`;

export const StyledText = styled(Typography.Text)`
    margin-bottom: 8px;
`;

export const StyledFormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledFormTitle = styled.div`
    display: flex;
    justify-content: space-between;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
    }
`;

export const StyledField = styled(KliField)`
    && {
        width: 230px;
        background: ${$white};
        margin-right: 16px;
        padding: 16px 24px;
        outline: none !important;
        box-shadow: none !important;

        ${({ $invalid }) =>
            $invalid
                ? css`
                      border: 2px solid ${$error};
                  `
                : ''}
    }
`;

export const StyledErrorText = styled(Typography.Text)`
    margin-top: 12px;
`;

export const AppliedPointsWrap = styled.div``;

export const StyledPointsButton = styled(Button)`
    && {
        padding: 0;
        margin-bottom: 8px;

        p {
            margin-bottom: 0;
            margin-right: 4px !important;
        }
    }
`;
