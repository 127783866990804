import React, { Fragment } from 'react';
import { Form } from 'antd';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../constants';
import { useSimpleInterface } from '../../../../../../../../../../utils';
import { Input, Typography } from '../../../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../../../constants';
import { StarRating } from '../StarRating';
import ErrorField from './components/ErrorField';
import ImageUpload from './components/ImageUpload';
import { StarRatingStyles, StyledButton } from './styles';

export const WriteReviewForm = inject('store')(
    observer(({ store, onModalClose }) => {
        const { writeReview, errorMessage } = store.reviews;
        const submitChallenge = store.useOnProductReviewSubmit();
        const onSubmit = (data) => writeReview(data, onModalClose, submitChallenge);
        const [form] = Form.useForm();
        const simple = useSimpleInterface(GRID_POINTS.md);

        const submitted = ({ images, ...data }) => {
            if (typeof onSubmit !== 'undefined') {
                const formData = new window.FormData();

                Object.keys(data).forEach((field) => {
                    formData.append(field, data[field]);
                });
                if (Array.isArray(images)) {
                    images.forEach((image) => {
                        formData.append('images', image.originFileObj);
                    });
                }

                if (typeof submitChallenge === 'function') {
                    submitChallenge({ rate: data.rating, review: data.content });
                }
                onSubmit(formData);
            }
        };

        return (
            <Form form={form} onFinish={submitted} validateTrigger={['onBlur']}>
                <Form.Item hidden name='rating' rules={[{ required: true, message: 'Rate is required' }]}>
                    <Input />
                </Form.Item>
                <Typography.Text style={BOTTOM_INDENTS.SM} type='body1'>
                    Overall rating
                </Typography.Text>
                <Form.Item noStyle shouldUpdate>
                    {() => (
                        <Fragment>
                            <StarRatingStyles>
                                <StarRating
                                    value={form.getFieldValue('rating')}
                                    onChange={(value) => {
                                        form.setFields([{ name: 'rating', touched: true, errors: [], value }]);
                                    }}
                                />
                            </StarRatingStyles>
                            {form.getFieldError('rating').length ? (
                                <Typography.Text type='description1' color='error' style={BOTTOM_INDENTS.M}>
                                    {form.getFieldError('rating')[0]}
                                </Typography.Text>
                            ) : null}
                        </Fragment>
                    )}
                </Form.Item>
                <Form.Item
                    name='content'
                    rules={[
                        { required: true, message: 'This field is mandatory' },
                        { min: 50, message: 'Should be at least 50 characters' }
                    ]}
                >
                    <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        labelHeight={simple ? '48px' : '24px'}
                        label='What did you like about this product? What did you use this product for?'
                    />
                </Form.Item>
                <ImageUpload />
                {errorMessage ? <ErrorField error={errorMessage} /> : null}
                <StyledButton $simple={simple} htmlType='submit' type='primary' block>
                    Submit a review
                </StyledButton>
            </Form>
        );
    })
);
