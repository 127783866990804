import { Switch } from 'antd';
import styled, { css } from 'styled-components';

export const StyledAntdSwitch = styled(Switch)`
    ${({ $theme, $checkedColor }) => css`
        &.ant-switch:focus {
            outline: none;
            box-shadow: none;
        }

        &.ant-switch-checked {
            background-color: ${$theme.colors[$checkedColor]
                ? $theme.colors[$checkedColor]
                : $theme.colors.primaryText};
        }
    `}
`;
