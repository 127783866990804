import { Form, Upload } from 'antd';
import styled from 'styled-components/macro';
import { COLORS } from '../../../../../../../../../../constants';

export const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 80px;
    height: 80px;
    background-color: ${COLORS.$background};
    cursor: pointer;
`;

export const StyledFormItem = styled(Form.Item)`
    position: relative;
    min-height: 100px;
`;

export const StyledUpload = styled(Upload)`
    &.ant-upload-picture-card-wrapper {
        display: flex;
        overflow-x: auto;
        padding-left: 88px;

        .ant-upload-list {
            display: flex;
        }

        .ant-upload.ant-upload-select-picture-card,
        .ant-upload-list-picture-card-container {
            width: 80px;
            height: 80px;
        }

        .ant-upload.ant-upload-select-picture-card {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .ant-upload.ant-upload-select-picture-card,
    .ant-upload.ant-upload-select-picture-card:hover {
        border: none;
    }
`;
