export const defaultTheme = {
    colors: {
        primary: '#F25858',
        tartRed: '#FFEBEB',
        secondary: '#2DC8C8',
        primaryText: '#04080F',
        darkBackground: '#1C3144',
        secondaryText: '#77838F',
        disabledState: '#A4ADB4',
        lines: '#D2D6DA',
        background: '#F4F4F4',
        primaryBackground: '#FED8D8',
        canvas: '#FFFFFF',
        error: '#EF2E2E',
        alert: '#FFC53A',
        success: '#1CA355',
        info: '#33B6DF',
        disabledPrimaryText: '#EAECEE',
        robinBlue: '#26A6A6'
    },

    RGB: {
        general: '119, 131, 143',
        error: '239, 46, 46',
        success: '28, 163, 85',
        alert: '255, 197, 58',
        overlay: '0, 0, 0'
    },

    fontsSrc: {
        sans:
            'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap',
        serif:
            'https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'
    },

    fonts: {
        headline1: 'normal 500 32px/40px "IBM Plex Serif", Serif',
        headline2: 'normal 500 24px/36px "IBM Plex Serif", Serif',
        headline3: 'normal 500 16px/24px "IBM Plex Serif", Serif',
        em0: 'normal 500 48px/64px "IBM Plex Serif", Serif',
        body1: 'normal normal 20px/28px "IBM Plex Sans", Sans',
        body2: 'normal normal 14px/24px "IBM Plex Sans", Sans',
        descriptor1: 'normal normal 16px/24px "IBM Plex Sans", Sans',
        descriptor2: 'normal 500 12px/16px "IBM Plex Sans", Sans',
        badgeLarge: 'normal 500 20px/24px "IBM Plex Sans", Sans',
        badgeMedium: 'normal 500 16px/24px "IBM Plex Sans", Sans',
        badgeSmall: 'normal 500 14px/16px "IBM Plex Sans", Sans',
        caps: 'Capitalized normal 14px/20px "IBM Plex Sans", Sans, SemiBold'
    },

    shadows: {
        primary: '0px 20px 40px rgba(0, 0, 0, 0.09)'
    },

    transition: {
        default: 'all 0.2s ease-in',
        slider: 'all 0.5s ease'
    }
};
