import styled, { css } from 'styled-components';
import { COLORS } from '../../../../../../../../../../../constants';

export default styled.div`
    ${({ $simple }) =>
        $simple
            ? css`
                  position: sticky;
                  bottom: 0;
                  z-index: 15;

                  max-width: unset;
                  background-color: ${COLORS.$white};
                  border-top: 1px solid ${COLORS.$border};
              `
            : ''};
`;
