import React, { useEffect } from 'react';
import { CheckoutPDP, CheckoutPDPHTTPWrapper, CloseXIcon, Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import RecommendedPromotions from '../../../../../../shared/components/RecommendedPromotions';
import { useRewardsModal } from '../../../../../../shared/hooks';
import { get, getEndPoints, loadPixels, removeEl } from '../../../../../../shared/utils';
import { useCustomerCCVerification, useMetaData } from '../../hooks';
import { CloseCheckoutButtonWrap, StyledButton, Styles, WrapStyles } from './styles';

export const PDPComponent = inject('store')(
    observer(({ store, promotionId }) => {
        const { handleBuyNow, closePDP, isPageReloaded } = store.pdpStore;
        const { resetIsNewCustomer } = store.coldCustomerStore;
        const { removeCashbackValue, setCashBackValue, promotions } = store.marketplaceStore;
        const {
            pending,
            data: publisherInfo,
            creditCardsLast4,
            customerCCVerificationNeeded,
            irclickid
        } = store.publisherStore;
        const { trackClick, trackViewPromotions, trackViewPromotion } = store.trackingStore;

        const sessionIrclickid = sessionStorage.getItem('irclickid');
        const metadata = useMetaData({
            publisherId: publisherInfo?._id,
            creditCardsLast4,
            irclickid: irclickid || sessionIrclickid
        });

        const endpoints = getEndPoints(promotionId, publisherInfo?._id);
        const { openRewardModal } = useRewardsModal();
        const openCCVerificationModal = useCustomerCCVerification();

        const handleClickThroughClick = (promotion, brandLink) => {
            return trackClick(promotion, brandLink);
        };

        const handleBeforeBuyNowButtonClick = async (payload, selectedVariant, trackingData) => {
            const price = get(payload, 'prices.price', 0);
            const productExternalId = get(trackingData, 'externalId');
            const itemName = get(trackingData, 'title');
            const qty = get(payload, 'qty');
            const pixels = get(trackingData, 'pixels', []);

            store.trackingStore.trackAddToCart(trackingData, { price, qty });

            await store.trackingStore.sendAddToCartPostMessage({
                pixels,
                data: {
                    price,
                    productExternalId,
                    itemName,
                    qty
                },
                delayTime: 500
            });
        };

        const handleBuyNowClick = (cartId, checkoutKey, payload, selectedVariant, trackingData) => {
            return handleBuyNow({ cartId, checkoutKey, payload, selectedVariant, trackingData, promotionId });
        };

        const handleInsteadBuyNowButtonClick = (payload, selectedVariant, trackingData) => {
            const price = get(payload, 'prices.price', 0);
            const qty = get(payload, 'qty');
            store.trackingStore.trackAddToCart(trackingData, { price: price, qty });
            openCCVerificationModal({ payload, selectedVariant, trackingData, promotionId });
        };

        const handleProductFetchSuccess = (promotion) => {
            if (isPageReloaded) {
                trackViewPromotion(promotion);
            }

            if (!promotion?.pixels) return;

            loadPixels(promotion.pixels);
        };

        useEffect(() => {
            resetIsNewCustomer();

            return () => {
                removeEl('kl_tw_iframe');
            };
        }, []);

        useEffect(() => {
            if (promotionId) {
                store.recommendedPromotionsStore.getRecommendedPromotions(promotionId);
            }
        }, [promotionId]);

        return pending || !publisherInfo?._id ? null : (
            <Styles>
                <CloseCheckoutButtonWrap>
                    <StyledButton
                        data-e2e='close-pdp-btn'
                        type='unstyled'
                        onClick={() => {
                            closePDP(true);
                            trackViewPromotions(promotions);
                            return removeCashbackValue();
                        }}
                    >
                        <Icon stylePriority size='24px' component={() => <CloseXIcon />} />
                    </StyledButton>
                </CloseCheckoutButtonWrap>
                <WrapStyles>
                    <CheckoutPDPHTTPWrapper
                        links={{
                            productFetch: endpoints.productFetchURL,
                            buyNow: endpoints.buyNowURL,
                            cartItemsManage: endpoints.cartItemsManageURL
                        }}
                        onBeforeBuyNowButtonClick={handleBeforeBuyNowButtonClick}
                        onInsteadBuyNowButtonClick={
                            customerCCVerificationNeeded ? handleInsteadBuyNowButtonClick : null
                        }
                        onBuyNowButtonClick={handleBuyNowClick}
                        onClickThroughClick={handleClickThroughClick}
                        onProductFetchSuccess={handleProductFetchSuccess}
                        onGoToMarketplaceClick={() => closePDP(true)}
                        onVariantChange={setCashBackValue}
                        onRewardQuestionClick={openRewardModal}
                    >
                        <CheckoutPDP
                            customizations={{
                                rootAppName: 'dp',
                                buttons: { showAddToCartButton: false },
                                rewards: { showRewards: true },
                                cashBack: { showCashBack: true },
                                prices: {
                                    originalPriceLabel: 'Original price'
                                }
                            }}
                            metadata={metadata}
                            bottomExtraContent={
                                <RecommendedPromotions
                                    pending={store.recommendedPromotionsStore.pending}
                                    promotions={store.recommendedPromotionsStore.recommendedPromotions}
                                    showPDP={store.pdpStore.showPDP}
                                    closePDP={store.pdpStore.closePDP}
                                />
                            }
                        />
                    </CheckoutPDPHTTPWrapper>
                </WrapStyles>
            </Styles>
        );
    })
);
