export const get = (obj, path, defValue) => {
    // If path is not defined or it has false value
    if (!path) return undefined;
    // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    // Find value
    const result = pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
    // If found value is undefined return default value; otherwise return the value
    return result === undefined ? defValue : result;
};

export const round = (value, factor = 100) => {
    return Math.round(value * factor) / factor;
};

export const arrayIncludes = (sourceArray, searchArray) => {
    for (const search of searchArray) {
        const searchKeys = Object.keys(search);
        const exists = sourceArray.find((source) => {
            for (const searchKey of searchKeys) {
                if (!source[searchKey] || source[searchKey] !== search[searchKey]) {
                    return false;
                }
            }
            return true;
        });
        if (!exists) {
            return false;
        }
    }

    return true;
};

export const debounce = (func, delay, { leading } = {}) => {
    let timerId;

    return (...args) => {
        if (!timerId && leading) {
            func(...args);
        }
        clearTimeout(timerId);

        timerId = setTimeout(() => func(...args), delay);
    };
};
