import { css } from 'styled-components';
import styled from 'styled-components/macro';
import Typography from '../../../../../UiLibrary/Typography';
import { $white } from '../../../../styles';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const BodyStyles = styled.div`
    background-color: ${$white};
    padding: ${({ $simple }) => ($simple ? '40px 24px' : '50px 40px')};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ButtonsWrap = styled.div`
    width: 100%;
    display: flex;

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column-reverse;
              `
            : ''};
`;

export const StyledText = styled(Typography.Text)`
    text-align: center;

    @media (${GRID_DEVICES_MAX.md}) {
        text-align: left;
    }
`;
