import React from 'react';
import { inject, observer } from 'mobx-react';
import { ReactComponent as LogoIcon } from '../../../assets/img/logo.svg';
import { KliLogo, StyledImg, StyledLoader } from './Logo.styles';

export const Logo = inject('store')(
    observer(({ store, size, color, onClick = () => null }) => {
        const { data: publisherInfo, pending, klicklyLogoVisible } = store.publisherStore;

        const klicklyLogo = klicklyLogoVisible ? <LogoIcon /> : null;
        const logo = publisherInfo?.logo ? (
            <StyledImg src={publisherInfo.logo} data-e2e='dp_brandLogo' alt={publisherInfo.name} />
        ) : (
            klicklyLogo
        );

        if (pending) {
            return <StyledLoader />;
        }

        if (!logo) {
            return null;
        }

        return (
            <KliLogo color={color} size={size} onClick={logo ? onClick : null}>
                {logo}
            </KliLogo>
        );
    })
);
