import { css } from 'styled-components';
import styled from 'styled-components/macro';

export const Styles = styled.div`
    width: 630px;
    margin: 70px 0;
    background-color: #ffffff;

    ${({ simple }) =>
        simple
            ? css`
                  width: 100%;
                  height: 100%;
                  margin: 0;
              `
            : ''}
`;
