export const device = {
    Android: () => !!navigator.userAgent.match(/Android/i),
    iOSMobile: () => !!navigator.userAgent.match(/iPhone|iPod/i),
    iOS: () => !!navigator.userAgent.match(/iPhone|iPad|iPod/i),
    isMobile: () => device.Android() || device.iOSMobile(),
    isSafari: () =>
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1,
    isChrome: () => !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
};
