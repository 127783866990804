import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../constants';
import { CloseXIcon } from '../../../../../../icons';
import { Icon, Typography } from '../../../../../UiLibrary';
import { CancelOrderConfirmation } from '../CancelOrderConfirmation';
import { CancelOrderForm } from '../CancelOrderForm';
import { CloseModalIcon, ContentStyles, HeadStyles, WrapStyles } from './styles';

export const CancelOrderModal = inject('store')(
    observer(({ store, onModalClose, simple }) => {
        const modalState = store.currentModalState;
        const pending = store.cancelOrderPending;
        const error = store.cancelOrderError;

        const onSubmit = (note) => {
            store.cancelOrder(note, onModalClose);
        };

        return (
            <WrapStyles $simple={simple}>
                {!simple ? (
                    <CloseModalIcon color='canvas' onClick={onModalClose} component={() => <CloseXIcon />} />
                ) : null}
                <ContentStyles>
                    <HeadStyles $simple={simple}>
                        {simple ? (
                            <Fragment>
                                <Icon
                                    style={BOTTOM_INDENTS.SM}
                                    size='24px'
                                    color='primary'
                                    onClick={onModalClose}
                                    component={() => <CloseXIcon />}
                                />
                            </Fragment>
                        ) : null}
                        <Typography.Title>Cancel order</Typography.Title>
                    </HeadStyles>
                    {modalState === 'confirmation' ? (
                        <CancelOrderConfirmation
                            simple={simple}
                            onModalClose={onModalClose}
                            onConfirm={store.toConfirmationReasonModalState}
                        />
                    ) : null}
                    {modalState === 'cancellation-reason' ? (
                        <CancelOrderForm
                            onClose={onModalClose}
                            onSubmit={onSubmit}
                            errorMessage={error}
                            simple={simple}
                        />
                    ) : null}
                    {pending && <Icon.Loader />}
                </ContentStyles>
            </WrapStyles>
        );
    })
);
