import React from 'react';
import axios from 'axios';
const isFunction = (fn) => typeof fn === 'function';

const CheckoutPDPHTTPWrapper = ({
    children,
    links,
    onBuyNowButtonClick,
    onBeforeBuyNowButtonClick,
    onInsteadBuyNowButtonClick,
    onCancelButtonClick,
    onGoToMarketplaceClick,
    onVariantChange,
    onGoToShoppingCartClick,
    onBeforeAddToCartButtonClick,
    onAddToCartButtonClick,
    onAfterAddToCartModalClose,
    onRewardQuestionClick,
    onPointsQuestionClick,
    onProductFetchSuccess,
    rewardUuid,
    onClickThroughClick,
    onAddToCartConfirmModal,
    onBrandNameButtonClick
}) => {
    const { productFetch, cartItemsManage, buyNow, reviewsFetch, reviewsWrite } = links;

    const axiosInstance = axios.create({ withCredentials: true });

    const onProductFetch = async () => {
        const { data } = await axiosInstance.get(
            productFetch,
            rewardUuid ? { params: { couponUUID: rewardUuid } } : {}
        );

        if (isFunction(onProductFetchSuccess)) {
            onProductFetchSuccess(data);
        }

        return data;
    };

    const onReviewsFetch = async (params) => {
        const { data } = await axiosInstance.get(`${reviewsFetch}${params || ''}`);

        return data;
    };

    const onReviewsWrite = (data) => {
        return axiosInstance.post(reviewsWrite, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    const onBuyNow = async (payload, selectedVariant, trackingData) => {
        if (isFunction(onBeforeBuyNowButtonClick)) {
            await onBeforeBuyNowButtonClick(payload, selectedVariant, trackingData);
        }

        if (isFunction(onInsteadBuyNowButtonClick)) {
            return onInsteadBuyNowButtonClick(payload, selectedVariant, trackingData);
        }

        const { data } = await axiosInstance.post(buyNow, payload);

        if (isFunction(onBuyNowButtonClick)) {
            await onBuyNowButtonClick(data.cartId, data.checkoutKey, payload, selectedVariant, trackingData);
        }

        return true;
    };

    const onAddToCart = async (data, trackingData) => {
        if (isFunction(onBeforeAddToCartButtonClick)) {
            await onBeforeAddToCartButtonClick(data, trackingData);
        }

        await axiosInstance.post(cartItemsManage, {
            action: 'itemAdd',
            data
        });

        if (isFunction(onAddToCartButtonClick)) {
            onAddToCartButtonClick(data, trackingData);
        }

        return true;
    };

    const onCancel = () => {
        if (isFunction(onCancelButtonClick)) {
            onCancelButtonClick();
        }

        return true;
    };

    const onGoToShoppingCart = () => {
        if (isFunction(onGoToShoppingCartClick)) {
            onGoToShoppingCartClick();
        }

        return true;
    };

    const onPdpPageClick = (trackingData) => {
        onClickThroughClick(trackingData);
    };

    const onBrandNameClick = (trackingData, brandLink, brandId) => {
        if (isFunction(onBrandNameButtonClick)) {
            return onBrandNameButtonClick(brandId);
        }

        if (isFunction(onClickThroughClick)) {
            onClickThroughClick(trackingData, brandLink);
        }

        window.open(brandLink, '_blank');

        return true;
    };

    const showAddToCartConfirmModal = ({ ...props }) => {
        if (isFunction(onAddToCartConfirmModal)) {
            return onAddToCartConfirmModal(props);
        }

        return true;
    };

    return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                callbacks: {
                    onPdpPageClick,
                    onProductFetch,
                    onReviewsFetch,
                    onReviewsWrite,
                    onAddToCart,
                    onBuyNow,
                    onCancel,
                    onGoToMarketplaceClick,
                    onGoToShoppingCart,
                    onVariantChange,
                    onAfterAddToCartModalClose,
                    onRewardQuestionClick,
                    onPointsQuestionClick,
                    onBrandNameClick, // for tracking click-through event,
                    showAddToCartConfirmModal // for new GS
                }
            });
        }
        return child;
    });
};

export default CheckoutPDPHTTPWrapper;
