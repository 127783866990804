import styled from 'styled-components/macro';
import { Button } from '../../../../../../../../../../../UiLibrary';

export const Styles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: ${({ $simple }) => ($simple ? '16px' : '0')};
`;

export const ReviewsRatingData = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledButton = styled(Button)`
    && {
        padding: 0;
        margin-left: 16px;
    }
`;

export const LoaderWrap = styled.div`
    position: relative;
`;
