import React, { useContext } from 'react';
import { KliThemeContext } from '../../../KliThemeProvider';
import { StyledText } from './styled';

const Text = ({ children, type, color, align, as = 'p', marginBottom, ...props }) => {
    const theme = useContext(KliThemeContext);
    return (
        <StyledText
            $type={type}
            $color={color}
            $align={align}
            $theme={theme}
            as={as}
            $marginBottom={marginBottom}
            {...props}
        >
            {children}
        </StyledText>
    );
};

export default Text;
