export const selectorData = [
    {
        label: 'Not receiving any shipping information',
        value: 'Not receiving any shipping information'
    },
    {
        label: 'Accidentally added an upsell purchase',
        value: 'Accidentally added an upsell purchase'
    },
    {
        label: 'Didn’t notice the shipping fee',
        value: 'Didn’t notice the shipping fee'
    },
    {
        label: 'Ordered the incorrect item',
        value: 'Ordered the incorrect item'
    },
    {
        label: 'Other',
        value: 'Other'
    }
];
