import { Icon, Typography } from '@klickly/front-packages';
import styled, { createGlobalStyle, css } from 'styled-components';
import { $primary, $tartRed, GRID_DEVICES_MAX } from '../../constants';

export const StyledModalWrapper = styled.div`
    background-color: #fff;
    width: ${({ $width }) => $width || '630px'};
    min-height: auto;
    position: relative;

    ${({ $borderRadius }) =>
        $borderRadius
            ? css`
                  border-radius: ${$borderRadius};
              `
            : ''}

    @media (${GRID_DEVICES_MAX.sm}) {
        width: 100%;
        min-height: 100%;
    }
`;

export const StyledTitle = styled(Typography.Title)`
    display: ${({ $display }) => $display || 'block'};
    padding: ${({ $padding }) => $padding || '24px'};
    line-height: 48px;
    text-align: center;
    color: ${$primary};
    background: ${$tartRed};

    @media (${GRID_DEVICES_MAX.sm}) {
        height: 128px;
        line-height: 40px;
        text-align: left;
        display: ${({ $display }) => $display || 'flex'};
        align-items: flex-end;
    }
`;

export const StyledModalContent = styled.div`
    height: auto;
    padding: ${({ $simple }) => ($simple ? '40px 24px 24px' : '40px 40px 24px')};

    ${({ $padding }) =>
        $padding
            ? css`
                  padding: ${$padding};
              `
            : ''}

    overflow-y: auto;
`;

export const CloseModalIcon = styled(Icon)`
    position: absolute;
    bottom: 100%;
    margin-bottom: 30px;
    font-size: 24px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    @media (${GRID_DEVICES_MAX.sm}) {
        top: 24px;
        bottom: auto;
        left: 36px;
        color: ${$primary};
    }
`;

export const StyledGlobal = createGlobalStyle`
    @media (${GRID_DEVICES_MAX.md}) {
        .ant-modal .ant-modal-close {
            inset-inline-end: auto;
            left: 24px;
        }
    }
`;
