import React from 'react';
import { observer } from 'mobx-react';
import { CircleActionsAlertInfoIcon } from '../../../../../../../../../../../../../../icons';
import { Typography } from '../../../../../../../../../../../../../UiLibrary';
import { ErrorFieldIcon, ErrorFieldStyles } from './styles';

export const OptionError = observer(
    ({ error, showIcon = false, icon = <CircleActionsAlertInfoIcon />, textType = 'descriptor1' }) => {
        return (
            <ErrorFieldStyles>
                {showIcon && <ErrorFieldIcon color='error' size='24px' component={() => icon} />}
                <Typography.Text color='primaryText' type={textType}>
                    {error}
                </Typography.Text>
            </ErrorFieldStyles>
        );
    }
);
