import { createGlobalStyle, css } from 'styled-components';

export const StyledNotification = createGlobalStyle`
    .ant-notification {
        margin-right: 48px;

        ${({ $simple }) =>
            $simple &&
            css`
                width: 100%;
                margin: 0;

                > div {
                    width: 100%;
                }
            `};

        .ant-notification-notice {
            padding: 0 44px 0 0;
            border: 1px solid ${({ $theme }) => $theme.colors.disabledState};
            width: 414px;
            border-radius: unset;
            box-shadow: none;

            ${({ $simple }) =>
                $simple &&
                css`
                    width: 100%;
                    max-width: 100%;
                    margin: 0;
                    padding: 0 32px 0 0;
                    border-left: none;
                    border-right: none;
                `};
        }
    }

    .ant-notification-notice-content {
        padding: 24px 16px;
        border-right: 1px solid ${({ $theme }) => $theme.colors.background};

        ${({ $simple }) =>
            $simple &&
            css`
                padding: 16px 8px;
            `};
    }

    .ant-notification-notice-close {
        top: 50%;
        right: 10px;
        transform: translateY(-50%);

        .kli-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
        }

        ${({ $simple }) =>
            $simple &&
            css`
                right: 4px;
            `};
    }

    .ant-notification-notice-close-x {
        width: 100%;
    }

    .ant-notification-notice-message {
        margin-bottom: 0;
    }
`;
