import React from 'react';
import { ROUTE_PATHS } from '../../../shared/constants';
import PromotionsPage from '../components/PromotionsPage';

export const ROUTES = [
    {
        element: <PromotionsPage />,
        index: true
    },
    {
        path: ROUTE_PATHS.marketplacePDP(),
        element: null
    }
];
