import { MARKETPLACE } from '../constants';
import { getFilterParamsFromUrl } from './getFilterParamsFromUrl';
import { getPriceQueryParams } from './getPriceQueryParams';

export const getUrlParamsFromFilter = (
    { categories, brands, price, sort, search, strategy, strategyId, gt },
    params = {}
) => {
    const urlParams = getFilterParamsFromUrl();
    const optionalParams = {};
    const priceRange = getPriceQueryParams(price);
    const sorting = sort.find((sortOption) => sortOption.checked).key;

    if (priceRange) optionalParams.price = priceRange;
    if (search) optionalParams.q = search;
    if (sorting !== MARKETPLACE.DEFAULT_SORT_KEY) optionalParams.sort = sorting;
    if (strategy) optionalParams.strategyName = strategy;
    if (strategyId) optionalParams.strategyId = strategyId;
    if (gt) optionalParams.gt = gt;

    return {
        categories: categories.filter(({ checked }) => checked).map(({ key }) => key),
        brands: brands?.length
            ? brands.filter(({ checked }) => checked).map(({ key }) => key)
            : urlParams.selectedBrands,
        ...optionalParams,
        ...params
    };
};
