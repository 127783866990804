import { types } from 'mobx-state-tree';

export const ShippingAddressModel = types.model({
    _id: types.identifier,
    zipCode: types.maybeNull(types.string),
    isDefault: types.maybe(types.boolean),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    address1: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    province: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    countryCode: types.maybeNull(types.string),
    countryName: types.maybeNull(types.string),
    provinceCode: types.maybeNull(types.string)
});
