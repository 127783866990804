import React, { Fragment, useContext } from 'react';
import { Popover as AntdPopover } from 'antd';
import { KliThemeContext } from '../../../KliThemeProvider';
import { StyledWrap } from './styles';

export const BasePopover = ({ children, ...props }) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <Fragment>
            <StyledWrap $theme={themeContext} {...props} />
            <AntdPopover {...props}>{children}</AntdPopover>
        </Fragment>
    );
};
