import styled from 'styled-components';

export const StyledLoader = styled.div`
    position: ${({ $position }) => $position || 'absolute'};
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ $theme, $overlayColor }) => $theme.colors[$overlayColor] || $overlayColor || 'transparent'};
    z-index: ${({ $zIndex }) => $zIndex};
`;
