import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ModalProvider } from '@klickly/front-packages';
import { Provider as MobxProvider } from 'mobx-react';
import { RootStore } from './_store/RootStore';
import { InitWrapper } from './components/InitWrapper';
import { INITIAL_STORE } from './constants';

const mobxStore = RootStore.create({}, INITIAL_STORE);

export function App() {
    const navigate = useNavigate();
    const location = useLocation();
    mobxStore.setHistoryState(navigate, location);

    return (
        <MobxProvider store={mobxStore}>
            <InitWrapper>
                <ModalProvider>
                    <Outlet />
                </ModalProvider>
            </InitWrapper>
        </MobxProvider>
    );
}
