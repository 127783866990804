import { Input } from 'antd';
import styled from 'styled-components';
import { sharedInputStyles } from '../../styled';

export const StyledWrapper = styled.div`
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
        height: ${({ $labelHeight = '24px' }) => $labelHeight};
        z-index: 1;
        background: ${({ $theme }) => $theme.colors.background};
        transition: all 0.2s ease-in;
    }

    &:has(textarea:focus):before,
    &:has(.ant-input-status-error):before {
        background: ${({ $theme }) => $theme.colors.canvas};
    }

    .ant-input-affix-wrapper {
        border: none;
        border-radius: unset;

        &:hover {
            border: none;
        }
    }

    && {
        textarea {
            padding-top: calc(${({ $labelHeight = '24px' }) => $labelHeight} - 2px);
        }
    }
`;

export const StyledTextArea = styled(Input.TextArea)`
    && {
        ${sharedInputStyles};
        resize: none;
        border-radius: unset;
    }
`;
