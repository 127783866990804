import React, { useCallback } from 'react';
import { useModal } from '../../../../../../UiLibrary';
import { AddedToCardModal } from '../components/addedToCardModal';

export const useAddedToCardModal = (simple = false, onAfterClose) => {
    const { setModal, hideModal } = useModal();

    const onModalClose = useCallback(() => {
        hideModal();
        if (typeof onAfterClose === 'function') onAfterClose();
    }, [hideModal]);

    return useCallback(
        ({ data }) => {
            setModal(<AddedToCardModal data={data} onModalClose={onModalClose} />, {
                fullscreen: simple,
                closable: false
            });
        },
        [setModal, onModalClose, simple]
    );
};
