import React, { useCallback, useLayoutEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from '../../../UiLibrary';
import { LayoutWithSidebar } from '../../components';
import { debounce, GRID_POINTS } from '../../utils';
import { OrdersInfo } from './components/OrdersInfo';
import { Styles, WrapStyles } from './styles';

export const ThankYouPage = inject('store')(
    observer(({ store, bottomExtraContent, hideContinueShoppingButton }) => {
        const [node, setNode] = useState(null);

        const appRef = useCallback((node) => {
            if (node !== null) {
                setNode(node);
                store.view.setAppWidth(node.offsetWidth);
            }
        }, []);

        useLayoutEffect(() => {
            if (node) {
                const onResize = debounce(() => {
                    store.view.setAppWidth(node.offsetWidth);
                }, 500);

                window.addEventListener('resize', onResize);

                return () => {
                    window.removeEventListener('resize', onResize);
                };
            }
        }, [node]);

        const simple = store.view.isSimple(GRID_POINTS.lg);
        const loading = store.isLoading;

        return (
            <WrapStyles ref={appRef} id='cancel-order-modal'>
                {!loading ? (
                    <LayoutWithSidebar>
                        <Styles simple={simple} className='thank-you-page-main-content' $env={store.env}>
                            <OrdersInfo hideContinueShoppingButton={hideContinueShoppingButton} />
                            {bottomExtraContent || null}
                        </Styles>
                    </LayoutWithSidebar>
                ) : (
                    <Icon.Loader />
                )}
            </WrapStyles>
        );
    })
);
