import styled from 'styled-components/macro';
import { Icon } from '../../../../../../../UiLibrary';
import { COLORS } from '../../../../../../constants';

export const Styles = styled.div`
    width: 100%;
    padding: 12px 25px;
    display: flex;
    align-items: center;
    background-color: ${COLORS.$primaryBackground};
`;

export const StyledIcon = styled(Icon)`
    margin-right: 17px;
    padding: 0;
`;
