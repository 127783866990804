import styled from 'styled-components/macro';
import { Button, Typography } from '../../../../../../../UiLibrary';

export const WrapStyles = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    margin-top: 24px;
    padding: 40px 0;

    .anticon {
        margin-bottom: 64px;
    }
`;

export const ContentStyles = styled.div`
    max-width: 455px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledButton = styled(Button)`
    && {
        padding-left: 60px;
        padding-right: 60px;
    }
`;

export const StyledTitle = styled(Typography.Title)`
    margin: 60px 0 24px 0;
`;

export const StyledTypography = styled(Typography.Text)`
    margin-bottom: 48px;
`;
