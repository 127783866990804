import { createGlobalStyle, css } from 'styled-components';

export const StyledWrap = createGlobalStyle`
    ${({ $theme }) => css`
        .ant-popover .ant-popover-content > .ant-popover-inner > .ant-popover-title {
            color: ${$theme.colors.canvas};
            margin: 0;
            border: none;
            font: ${() => $theme.fonts.badgeLarge};
            padding: 24px 32px 12px;
        }

        .ant-popover .ant-popover-content > .ant-popover-inner {
            padding: 0;
        }

        .ant-popover-inner-content {
            ${({ title }) =>
                title
                    ? css`
                          padding: 0 32px 24px;
                      `
                    : css`
                          padding: 24px 32px;
                      `}
        }
    `};

    ${({ maxWidth }) =>
        maxWidth
            ? css`
                  .ant-popover {
                      max-width: ${maxWidth};
                  }
              `
            : ''};

    ${({ $theme, type }) =>
        type === 'primary'
            ? css`
                  .ant-popover-title,
                  .ant-popover-inner-content {
                      background-color: ${$theme.colors.primary};

                      span {
                          color: ${$theme.colors.canvas};
                      }
                  }

                  .ant-popover .ant-popover-arrow {
                      &:before {
                          background: ${$theme.colors.primary};
                      }
                  }

                  .ant-popover-arrow-content {
                      --antd-arrow-background-color: ${$theme.colors.primary};
                  }

                  .ant-popover-title {
                      color: ${$theme.colors.canvas};
                  }
              `
            : ''};

    ${({ $theme, type }) =>
        type === 'primary-black'
            ? css`
                  .ant-popover-title,
                  .ant-popover-inner-content {
                      background-color: ${$theme.colors.primaryText};
                      color: ${$theme.colors.canvas};

                      span {
                          color: ${$theme.colors.canvas};
                      }
                  }

                  .ant-popover .ant-popover-arrow {
                      &:before {
                          background: ${$theme.colors.primaryText};
                      }
                  }

                  .ant-popover-arrow-content {
                      --antd-arrow-background-color: ${$theme.colors.primaryText};
                  }
              `
            : ''};
`;
