import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { useSimpleInterface } from '../../../../../../../../utils';
import Typography from '../../../../../../../UiLibrary/Typography';
import { GRID_DEVICES_MAX } from '../../../../../../utils';
import { CancelOrderButton } from '../../styles';

export const CancelSingleOrderLine = inject('store')(
    observer(({ store, order, onCancelOrder }) => {
        const simple = useSimpleInterface(GRID_DEVICES_MAX.md);
        return (
            <Fragment>
                {order.isAvailableForCancel && order.klicklyStatus !== 'refunded' && !store.cancelOrderUnavailable ? (
                    <Typography.Text as='div' type='descriptor2'>
                        Error with your order? <br />
                        Cancel in the next 5 mins by clicking
                        <CancelOrderButton
                            type='unstyled'
                            onClick={() => {
                                store.setOrderForRefund(order.klicklyOrderId);

                                return onCancelOrder();
                            }}
                        >
                            <Typography.Text type='descriptor2'>here</Typography.Text>
                        </CancelOrderButton>
                    </Typography.Text>
                ) : null}
                {order.klicklyStatus === 'refunded' ? (
                    <Typography.Text
                        data-e2e='order-cancelled-confirmation-text'
                        as='div'
                        type={simple ? 'descriptor1' : 'descriptor2'}
                    >
                        This order has been cancelled{simple && <br />} and your credit card refunded
                    </Typography.Text>
                ) : null}
            </Fragment>
        );
    })
);
