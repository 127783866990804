import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledRewardsWrapper = styled.div`
    width: 100%;
    padding: 20px 0;
`;
