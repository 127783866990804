import React from 'react';
import { prepareImageUrl } from '../../../../utils';
import Image from '../../../UiLibrary/Image';

export const PromotionImg = ({ images, width = '106px', height = '106px', isStatic, placeHolder, ...rest }) => {
    const findCurrent = images?.find((img) => img.position === 1);
    const style = { width: width, height: height, objectFit: 'contain' };

    if (findCurrent) {
        if (isStatic) return <Image style={style} src={findCurrent.src} {...rest} />;
        const preparedImage = prepareImageUrl(findCurrent.src, `&width=${width.replace(/\D/g, '')}`, placeHolder);
        return <Image data-e2e='promotion-image' style={style} src={preparedImage} {...rest} />;
    }

    return <Image data-e2e='promotion-image' style={style} src={placeHolder} {...rest} />;
};
