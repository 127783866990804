import styled, { css } from 'styled-components';
import { PortalBottomAnimation, PortalLeftAnimation, PortalRightAnimation, PortalTopAnimation } from '../KliAnimations';
import { PortalPositions } from './portalPositions';

export const PortalTriggerWrap = styled.span`
    display: inline-block;
    vertical-align: inherit;
`;

// TODO: Fix Positions in Iframe;
export const PortalContentPosition = styled.span`
    position: absolute;
    z-index: 9999;
    max-width: 325px;
    padding: 20px;
    left: ${({ coords, position, offsetLeft }) => {
        if (position === PortalPositions.TOP) {
            return coords.left + offsetLeft + 'px';
        }
        if (position === PortalPositions.RIGHT) {
            return coords.left + offsetLeft + coords.width + 'px';
        }
        if (position === PortalPositions.BOTTOM) {
            return coords.left + offsetLeft + 'px';
        }
        if (position === PortalPositions.LEFT) {
            return coords.left + offsetLeft - coords.width / 2 + 'px';
        }
    }};
    top: ${({ coords, position, offsetTop }) => {
        if (position === PortalPositions.TOP) {
            return coords.top + offsetTop - 5 + 'px';
        }
        if (position === PortalPositions.RIGHT) {
            return coords.top + offsetTop - 5 + 'px';
        }
        if (position === PortalPositions.BOTTOM) {
            return coords.top + offsetTop + 5 + 'px';
        }
        if (position === PortalPositions.LEFT) {
            return coords.top + offsetTop - 5 + 'px';
        }
    }};
    transform: ${({ position }) => {
        if (position === PortalPositions.TOP) {
            return 'translate3d(-50%, -100%, 0)';
        }
        if (position === PortalPositions.RIGHT) {
            return 'translate3d(0, -50%, 0)';
        }
        if (position === PortalPositions.BOTTOM) {
            return 'translate3d(-50%, 0, 0)';
        }
        if (position === PortalPositions.LEFT) {
            return 'translate3d(-100%, -50%, 0)';
        }
    }};
    animation: ${({ position }) => {
        if (position === PortalPositions.TOP) {
            return css`
                ${PortalTopAnimation} 0.2s ease-in-out
            `;
        }
        if (position === PortalPositions.RIGHT) {
            return css`
                ${PortalRightAnimation} 0.2s ease-in-out
            `;
        }
        if (position === PortalPositions.BOTTOM) {
            return css`
                ${PortalBottomAnimation} 0.2s ease-in-out
            `;
        }
        if (position === PortalPositions.LEFT) {
            return css`
                ${PortalLeftAnimation} 0.2s ease-in-out
            `;
        }
    }};
`;
