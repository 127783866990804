import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Button } from '../../../../../../../../../../../../UiLibrary';

export default styled(Button)`
    && {
        width: 100%;
        min-width: 197px;

        ${({ $smallerButtonText }) =>
            $smallerButtonText
                ? css`
                      padding-left: 10px;
                      padding-right: 10px;
                      min-width: unset;
                  `
                : ''};

        .anticon > svg {
            margin-right: 8px;
        }
    }
`;
