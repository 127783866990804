import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { BackArrowIcon } from '../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../UiLibrary';
import Review from '../Review';
import { BodyStyles, HeadStyles, StyledButton, Styles } from './styles';

const ReviewPage = inject('store')(
    observer(({ store }) => {
        const pageRef = useRef(null);

        const activeReview = store.reviews.getActiveReview();

        useEffect(() => {
            if (pageRef?.current) pageRef.current.scrollIntoView();
        }, []);

        return (
            <Styles ref={pageRef}>
                <HeadStyles>
                    <StyledButton type='unstyled' onClick={store.reviews.backToPDP}>
                        <Icon component={() => <BackArrowIcon />} />
                        <Typography.Text type='badgeSmall'>Reviews</Typography.Text>
                    </StyledButton>
                </HeadStyles>
                <BodyStyles>
                    <Review fullReview review={activeReview} />
                </BodyStyles>
            </Styles>
        );
    })
);

export default ReviewPage;
