import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Button, Icon, Typography } from '../../../../../../../../../UiLibrary';
import { COLORS } from '../../../../../../../../constants';

export const Styles = styled.div`
    background-color: ${COLORS.$white};
    position: relative;
`;

export const WrapStyles = styled.div`
    max-width: 630px;
    width: 100%;

    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: 100%;
                  height: 100%;
              `
            : ''};
`;

export const HeadStyles = styled.div`
    position: relative;
    width: 100%;
    height: 92px;
    background-color: ${COLORS.$tartRed};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BodyStyles = styled.div`
    width: 100%;
    background-color: ${COLORS.$white};
    padding: 40px;
    display: flex;
    align-items: center;
`;

export const ProductImage = styled.div`
    margin-right: 16px;
`;

export const StyledImg = styled.img`
    && {
        width: 80px;
        height: 80px;
        object-fit: contain;
    }
`;

export const ProductBody = styled.div``;

export const BrandName = styled(Typography.Text)``;

export const SelectedVariant = styled(Typography.Text)``;

export const FootStyles = styled.div`
    padding: 0 40px 40px;
`;

export const StyledButton = styled(Button)`
    && {
        width: 100%;

        &:first-child {
            margin-bottom: 16px;
        }
    }
`;

export const CloseModalIcon = styled(Icon)`
    position: absolute;
    bottom: 100%;
    margin-bottom: 24px;
    font-size: 24px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    ${({ $simple }) =>
        $simple
            ? css`
                  bottom: 50%;
                  left: 24px;
                  margin-bottom: 0;
                  transform: translate3d(0, 50%, 0);
              `
            : ''};
`;
