import qs from 'qs';

const { useLocation } = require('react-use');

export const useQuery = () => {
    const { search } = useLocation();
    return new URLSearchParams(search);
};

export const getSearchParams = (search) => new URLSearchParams(search || window.location.search);
export const queryParser = (search) => qs.parse(search);
export const parseQuery = () => queryParser(window.location.search.substr(1));
