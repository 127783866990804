import { device } from '@klickly/front-packages';
import { createGlobalStyle, css } from 'styled-components';
import { $primaryText, $white } from './shared/constants/styles';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;

        box-sizing: border-box;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;
        -webkit-backface-visibility: hidden;
    }

    html,
    body,
    #root {
        position: relative;
        overflow: hidden;
        min-width: 320px;
        background: ${$white};
        height: 100%;
        ${() =>
            isSafari &&
            isIos &&
            css`
                min-height: -webkit-fill-available;
            `}

    }

    html,
    body {
        overflow: hidden;
    }

    #root {
        overflow-y: auto;
    }

    body {
        margin: 0;
        font-family: 'IBM Plex Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #fff;
        color: ${$primaryText};


        > iframe {
            position: absolute !important;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }
`;
