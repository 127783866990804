import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI, LOCALHOST, TEST_DOMAIN_PART } from '../shared/constants';

export const PublisherStore = types
    .model('PublisherStore', {
        data: types.maybeNull(
            types.model({
                accessToken: types.string,
                advertiseStrategy: types.maybeNull(types.string),
                _id: types.identifier,
                commissions: types.model({
                    klickly: types.number,
                    publisher: types.number,
                    customer: types.number
                }),
                domain: types.string,
                logo: types.maybe(types.string),
                url: types.maybe(types.string),
                name: types.string,
                text: types.optional(
                    types.model({
                        learnMore: types.optional(types.string, ''),
                        topBanner: types.optional(
                            types.model({
                                marketplace: types.optional(types.string, ''),
                                pdp: types.optional(types.string, ''),
                                thankYouPage: types.optional(types.string, '')
                            }),
                            {}
                        )
                    }),
                    {}
                ),
                customerCCVerificationNeeded: types.optional(types.boolean, false),
                customerEmailCaptureEnabled: types.optional(types.boolean, false)
            })
        ),
        pending: true,
        error: types.maybeNull(types.string),
        creditCardsLast4: types.optional(types.string, ''),
        customerId: types.maybeNull(types.string),
        irclickid: types.maybeNull(types.string),
        klicklyLogoVisible: true,
        giftlyControlsVisible: true
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get publisherInfo() {
            return self.data ? self.data : {};
        },
        get strategy() {
            const queryParams = new URLSearchParams(window.location.search);
            const strategyIdByQuery = queryParams.get('strategyId');
            const strategyNameByQuery = queryParams.get('strategy');

            if (strategyIdByQuery) {
                return { strategyId: strategyIdByQuery };
            }

            if (strategyNameByQuery) {
                return { strategyName: strategyNameByQuery };
            }

            return self.data?.advertiseStrategy ? { strategyId: self.data?.advertiseStrategy } : '';
        },
        get publisherId() {
            return self.data ? self.data._id : null;
        },
        get publisherURL() {
            return self.data ? self.data.url : null;
        },
        get publisherName() {
            return self.data ? self.data.name : '';
        },
        get customerCCVerificationNeeded() {
            return self.data ? self.data.customerCCVerificationNeeded : false;
        },
        get publisherLogo() {
            return self.data?.logo || null;
        },
        get customerEmailCaptureEnabled() {
            return self.data?.customerEmailCaptureEnabled || false;
        }
    }))
    .actions((self) => {
        const setPublisherData = (data) => {
            self.data = data;
            self.pending = false;
        };

        const getPublisherInfoByDomain = flow(function* getPublisherInfoByDomain({
            getBrands = true,
            forceUpdate = false,
            customerId
        } = {}) {
            const domain = window.location.hostname.split('.')[0];
            const id = customerId || self.customerId;
            if (!self.data || forceUpdate) {
                try {
                    self.pending = true;
                    self.klicklyLogoVisible = false;
                    self.giftlyControlsHidden = false;
                    const data = yield self.root.get(kcpAPI.publishers.get, {
                        domain: domain === LOCALHOST ? TEST_DOMAIN_PART : domain,
                        id
                    });
                    self.data = data;
                    self.klicklyLogoVisible = !data?.klicklyLogoHidden;
                    self.giftlyControlsVisible = !data?.giftlyControlsHidden;
                    if (getBrands) yield self.root.marketplaceStore.getBrands();
                    self.pending = false;
                } catch (error) {
                    self.pending = false;
                    self.error = error.response.data.message;
                }
            }
            return self.data?._id;
        });

        const saveCustomerEmail = flow(function* saveCustomerEmail({ email } = {}) {
            yield self.root.post(kcpAPI.publishers.saveCustomerEmail(self.publisherId), {
                email,
                publisherName: self.publisherName
            });
        });

        const setCreditCardsLast4 = (creditCardsLast4) => {
            self.creditCardsLast4 = creditCardsLast4;
        };

        const setCustomerId = (id) => {
            self.customerId = id;
        };

        const setIrclickid = (irclickid) => {
            self.irclickid = irclickid;
        };

        return {
            setPublisherData,
            getPublisherInfoByDomain,
            setCreditCardsLast4,
            setCustomerId,
            setIrclickid,
            saveCustomerEmail
        };
    });
