import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Icon } from '../../../../../UiLibrary';
import { $primary, $primaryBackground, $white } from '../../../../styles';

export const WrapStyles = styled.div`
    width: 630px;
    background-color: ${$white};
    position: relative;

    ${({ $simple }) =>
        $simple
            ? css`
                  width: 100%;
                  height: 100%;
              `
            : ''};
`;

export const ContentStyles = styled.div``;

export const HeadStyles = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${$primaryBackground};
    color: ${$primary};
    padding: 24px;

    ${({ $simple }) =>
        $simple
            ? css`
                  flex-direction: column;
                  align-items: flex-start;
              `
            : ''}
`;

export const CloseModalIcon = styled(Icon)`
    position: absolute;
    bottom: 100%;
    margin-bottom: 30px;
    font-size: 24px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
`;
