import React, { Fragment, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS, LEFT_INDENTS, RIGHT_INDENTS, TOP_INDENTS } from '../../../../../../../../constants';
import { ArrowChevronDownIcon, Comment1Icon, ForwardArrowIcon } from '../../../../../../../../icons';
import { Button, Icon, Typography } from '../../../../../../../UiLibrary';
import { useWriteReviewModal } from '../../hooks';
import Review from '../Review';
import NoReview from './components/NoReview';
import { CenteredButton, StyledButton, StyledLoaderWrapper } from './styles';

export const ReviewsContainer = inject('store')(
    observer(({ store, withPadding = false, loadMoreEnabled = false, scrollReviewImages = false, simple }) => {
        const maxVisibleReviews = loadMoreEnabled ? 10e6 : 3;
        const { reviews, loadReviews, isLoading, page, totalPages } = store.reviews;
        const showLoadMoreButton = totalPages > page;
        const openWriteReviewModal = useWriteReviewModal(simple, store);

        useEffect(() => {
            if (!reviews?.length) loadReviews();
        }, []);

        if (isLoading) {
            return (
                <StyledLoaderWrapper>
                    <Icon.Loader />
                </StyledLoaderWrapper>
            );
        }

        return reviews && reviews?.length ? (
            <Fragment>
                {store.view.isLoggedIn ? (
                    <Button
                        type='secondary'
                        onClick={openWriteReviewModal}
                        style={{ ...(withPadding ? LEFT_INDENTS.M : {}), ...BOTTOM_INDENTS.M }}
                    >
                        <Icon component={() => <Comment1Icon />} style={RIGHT_INDENTS.XS} />
                        <Typography.Text type='badgeSmall'>Write a review</Typography.Text>
                    </Button>
                ) : null}
                {reviews.map((review, key) => {
                    if (key + 1 <= maxVisibleReviews) {
                        return (
                            <div key={key} style={BOTTOM_INDENTS.XL}>
                                <Review
                                    review={review}
                                    showReview={() => store.reviews.showReview(review._id)}
                                    withPadding={withPadding}
                                    scrollImages={scrollReviewImages}
                                />
                            </div>
                        );
                    }
                    return null;
                })}
                {showLoadMoreButton && !loadMoreEnabled ? (
                    <StyledButton
                        style={TOP_INDENTS.SM}
                        $simple={simple}
                        type='unstyled'
                        onClick={() => store.view.setRoute('reviews')}
                    >
                        <Typography.Text type='badgeMedium'>Read all reviews</Typography.Text>
                        <Icon size='24px' component={() => <ForwardArrowIcon />} />
                    </StyledButton>
                ) : null}

                {showLoadMoreButton && loadMoreEnabled ? (
                    <CenteredButton style={TOP_INDENTS.SM} type='unstyled' onClick={() => loadReviews(true)}>
                        <Typography.Text type='badgeSmall'>Load more reviews</Typography.Text>
                        <Icon size='16px' component={() => <ArrowChevronDownIcon />} />
                    </CenteredButton>
                ) : null}
            </Fragment>
        ) : (
            <NoReview />
        );
    })
);
