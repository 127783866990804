import { types } from 'mobx-state-tree';

export const ErrorsStore = types
    .model('ErrorsStore', {
        list: types.optional(
            types.array(
                types.model({
                    key: types.string,
                    value: types.string
                })
            ),
            []
        )
    })
    .views((self) => ({
        getError(key) {
            if (!(self.list && self.list.length)) {
                return null;
            }
            const error = self.list.find((error) => error.key === key);
            return error ? error.value : null;
        }
    }))
    .actions((self) => ({
        addHttpError(key, err) {
            if (err && err.response && err.response.data) {
                self.addError(key, err.response.data.message);
                return true;
            }

            if (err && err.message) {
                self.addError(key, err.message);
            }
        },
        addError(key, value) {
            if (self.getError(key)) {
                self.updateError(key, value);
            }
            self.list.push({
                key,
                value
            });
        },
        updateError(key, value) {
            // eslint-disable-next-line promise/prefer-await-to-callbacks
            const index = self.list.findIndex((error) => error.key === key);
            if (index !== -1) {
                self.list[index].value = value;
            }
        },
        deleteError(key) {
            // eslint-disable-next-line promise/prefer-await-to-callbacks
            const index = self.list.findIndex((error) => error.key === key);
            if (index !== -1) {
                self.list.splice(index, 1);
            }
        }
    }));
