export const LOCALHOST = 'localhost';
export const TEST_DOMAIN_PART = 'distribution-partner-dev';

export const FIELD_NAMES = {
    PDP: 'pdp',
    MARKETPLACE: 'marketplace',
    THANK_YOU: 'thankYouPage'
};

export const PAGE_TYPES = {
    PDP: 'pdp',
    THANK_YOU: 'ty',
    MARKETPLACE: 'marketplace',
    CHECKOUT: 'checkout'
};

export const CATEGORIES = [
    {
        key: 'clothing, shoes & jewelry',
        name: 'Clothing'
    },
    {
        key: 'beauty',
        name: 'Beauty'
    },
    {
        key: 'health & personal care',
        name: 'Personal'
    },
    {
        key: 'electronics',
        name: 'Electronics'
    },
    {
        key: 'sports & outdoors',
        name: 'Outdoors'
    },
    {
        key: 'pet supplies',
        name: 'Pets'
    },
    {
        key: 'baby',
        name: 'Baby'
    },
    {
        key: 'home & kitchen',
        name: 'Home'
    },
    {
        key: 'grocery & gourmet food',
        name: 'Grocery'
    },
    {
        key: 'cell phones & accessories',
        name: 'Phones'
    },
    {
        key: 'office promotions',
        name: 'Office'
    },
    {
        key: 'patio, lawn & garden',
        name: 'Garden'
    },
    {
        key: 'tools & home improvement',
        name: 'Tools'
    },
    {
        key: 'movies & tv',
        name: 'Movies'
    },
    {
        key: 'musical instruments',
        name: 'Music'
    },
    {
        key: 'toys & games',
        name: 'Games'
    },
    {
        key: 'automotive',
        name: 'Auto'
    },
    {
        key: 'arts, crafts & sewing',
        name: 'Arts & crafts'
    }
];

export const PROMOTIONS_PRICE_STATE = {
    gte: 1,
    lte: 10000
};

export const PROMOTIONS_SORTS_STATE = [
    {
        key: 'relevance',
        type: 'Relevance',
        checked: true,
        default: true
    },
    {
        key: 'price_asc',
        name: 'Low to High',
        type: 'Price',
        checked: false
    },
    {
        key: 'price_desc',
        name: 'High to Low',
        type: 'Price',
        checked: false
    }
];

export const EMAIL_REGEX = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

export * from './api';
export * from './grid';
export * from './routePaths';
export * from './stateNames';
export * from './styles';
