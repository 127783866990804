import { useCallback, useEffect } from 'react';

export const useScrollable = (targetRef, handle = null, onBottom = null) => {
    const onScroll = useCallback(
        (e) => {
            if (!e) {
                return;
            }

            if (handle) {
                handle(e);
            }

            if (Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight && onBottom) {
                onBottom(e);
            }
        },
        [handle, onBottom]
    );

    useEffect(() => {
        const { current } = targetRef;

        if (current) {
            current.addEventListener('scroll', onScroll);
        }

        return () => {
            if (current) {
                current.removeEventListener('scroll', onScroll);
            }
        };
    }, [targetRef, onScroll]);
};
