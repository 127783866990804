import React from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../constants';
import { BagIcon, CartIcon } from '../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../utils';
import { BodyStyles, StyledButton, StyledHeadLine2, StyledIcon, StyledText, Styles } from './styles';

export const EmptyCart = inject('store')(
    observer(({ store }) => {
        const simple = store.view.isSimple(GRID_POINTS.md);
        const { continueShoppingUrl } = store.redirects;
        return (
            <Styles>
                <Typography.Title style={BOTTOM_INDENTS.SM} level={2} color='primaryText'>
                    Shopping cart
                </Typography.Title>
                <BodyStyles>
                    <StyledIcon style={BOTTOM_INDENTS.SM} size='40px' component={() => <BagIcon />} />
                    <StyledHeadLine2 style={BOTTOM_INDENTS.SM} level={2}>
                        No items in cart
                    </StyledHeadLine2>
                    <StyledText style={BOTTOM_INDENTS.XL}>
                        Discover new products and brands in the Giftly Marketplace
                    </StyledText>
                    <StyledButton
                        type='primary'
                        size='large'
                        $simple={simple}
                        onClick={() => window.location.replace(continueShoppingUrl)}
                    >
                        <Icon size='24px' stylePriority component={() => <CartIcon />} />
                        <Typography.Text type='badgeLarge'>Shop Giftly</Typography.Text>
                    </StyledButton>
                </BodyStyles>
            </Styles>
        );
    })
);
