import Icon from '@ant-design/icons';
import styled, { css } from 'styled-components';

const iconStyles = css`
    display: flex;
    align-items: center;
    font-size: ${({ $size }) => $size || 'inherit'};
    line-height: ${({ $size }) => $size || 'inherit'};
    height: ${({ $size }) => $size || 'fit-content'};
`;

export const StyledIcon = styled(Icon)`
    position: relative;
    color: ${({ $theme, $color }) => $theme.colors[$color] || $color || 'inherit'};
    ${iconStyles};

    ${({ $marginRight }) =>
        $marginRight &&
        css`
            margin-right: ${$marginRight};
        `};
    ${({ $marginLeft }) =>
        $marginLeft &&
        css`
            margin-left: ${$marginLeft};
        `};

    &&&&&&&&&& {
        ${({ $stylePriority }) =>
            $stylePriority &&
            css`
                ${iconStyles};
            `};
    }

    ${({ $roundedSize }) =>
        $roundedSize &&
        css`
            cursor: pointer;

            &::after {
                height: ${({ $roundedSize }) => $roundedSize};
                width: ${({ $roundedSize }) => $roundedSize};
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);

                content: '';
                position: absolute;
                border-radius: 50%;
                z-index: 1;
                background-color: ${({ $roundedColor, $theme }) =>
                    $roundedColor ? $theme.colors[$roundedColor] || $roundedColor : 'transparent'};
                opacity: ${({ $roundedOpacity }) => $roundedOpacity};
                transition: ${({ $theme }) => $theme.transition.default};
            }

            &:hover:after {
                background-color: ${({ $theme, $hoverRoundedColor }) =>
                    $theme.colors[$hoverRoundedColor] || $hoverRoundedColor || $theme.colors.lines};
            }
        `};

    > * {
        position: relative;
        z-index: 2;
    }
`;
