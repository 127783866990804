import React, { Fragment, useContext } from 'react';
import { GRID_POINTS } from '../../../../constants';
import { PointsIcon } from '../../../../icons';
import { formatPoints, useSimpleInterface } from '../../../../utils';
import { KliThemeContext, Popover } from '../../../UiLibrary';
import { StyledBadge, StyledIcon, StyledPointsCount, StyledWelcomeText } from './styled';

const PointsBadge = ({ pointsDetails }) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const theme = useContext(KliThemeContext);
    const { maxPoints, isWelcome } = pointsDetails;
    if (!maxPoints) return null;

    return (
        <Fragment>
            <StyledBadge
                $theme={theme}
                $isWelcome={isWelcome}
                $isMobile={simple}
                color='primary'
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Popover.Points
                    points={maxPoints}
                    showUsdOnly={simple}
                    placement={simple ? 'topRight' : 'bottom'}
                    getPopupContainer={(node) => (simple ? document.body : node)}
                >
                    <StyledIcon size='14px' component={() => <PointsIcon />} />
                    <StyledPointsCount type='description1' as='span'>
                        +{formatPoints(maxPoints, { million: true, billion: true })}
                    </StyledPointsCount>
                </Popover.Points>
            </StyledBadge>

            {isWelcome ? (
                <StyledWelcomeText $theme={theme} $isMobile={simple}>
                    Welcome Gift
                </StyledWelcomeText>
            ) : (
                ''
            )}
        </Fragment>
    );
};

export default PointsBadge;
