import styled, { css } from 'styled-components';
import { Icon, Typography } from '../../../UiLibrary';

export const StyledBadge = styled(Typography.Text)`
    && {
        position: ${({ $isMobile }) => ($isMobile ? 'absolute' : 'static')};
        z-index: 1;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: fit-content;
        height: 24px;
        margin-top: 8px;
        padding: 0 4px;
        white-space: nowrap;
        overflow: hidden;
        color: ${({ $isWelcome, $theme }) => ($isWelcome ? $theme.colors.canvas : $theme.colors.primary)};
        background: ${({ $isWelcome, $theme }) => ($isWelcome ? $theme.colors.primary : $theme.colors.tartRed)};

        > span {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
`;

export const StyledIcon = styled(Icon)`
    margin-right: 4px;
`;

export const StyledPointsCount = styled(Typography.Text)`
    font-weight: 500;
`;

export const StyledWelcomeText = styled(Typography.Text)`
    font-size: 12px;
    color: ${({ $theme }) => $theme.colors.primary};
    font-weight: 500;

    ${({ $isMobile }) =>
        $isMobile &&
        css`
            position: absolute;
            bottom: 24px;
            left: 0;
            background: ${({ $theme }) => $theme.colors.canvas};
            padding: 0 5px;
            text-align: left;
        `};
`;
