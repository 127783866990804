import React from 'react';
import { inject, observer } from 'mobx-react';
import { CloseXIcon } from '../../../../../../../../../../icons';
import Typography from '../../../../../../../../../UiLibrary/Typography';
import { GRID_POINTS } from '../../../../../../../../constants';
import { getVariantImage } from '../../../../../../../../utils';
import { getSelectedVariantTitle } from '../../../../../../../../utils/variant';
import {
    BodyStyles,
    BrandName,
    CloseModalIcon,
    FootStyles,
    HeadStyles,
    ProductBody,
    ProductImage,
    SelectedVariant,
    StyledButton,
    StyledImg,
    Styles,
    WrapStyles
} from './styles';

export const AddedToCard = inject('store')(
    observer(({ store, data, onModalClose }) => {
        const simple = store.view.isSimple(GRID_POINTS.sm);
        const variantTitle = getSelectedVariantTitle(data?.selectedVariant);
        const { images, selectedVariant } = data;
        const { imageSrc: variantImgSrc } = getVariantImage(images, selectedVariant);
        const defaultImageSrc = data?.images?.length ? data.images[0].src : '';
        const imageSrc = variantImgSrc || defaultImageSrc;
        return (
            <Styles $simple={simple}>
                {!simple ? (
                    <CloseModalIcon
                        data-e2e='close-modal-button'
                        color='canvas'
                        onClick={onModalClose}
                        component={() => <CloseXIcon />}
                    />
                ) : null}
                <WrapStyles data-e2e='item-added-modal' $simple={simple}>
                    <HeadStyles>
                        {simple ? (
                            <CloseModalIcon
                                color='primaryText'
                                $simple={simple}
                                onClick={onModalClose}
                                component={() => <CloseXIcon />}
                            />
                        ) : null}
                        <Typography.Title data-e2e='modal-title' color='primary'>
                            Item added
                        </Typography.Title>
                    </HeadStyles>
                    <BodyStyles>
                        <ProductImage data-e2e='item-img'>
                            <StyledImg src={imageSrc} />
                        </ProductImage>
                        <ProductBody data-e2e='item-body'>
                            <BrandName data-e2e='item-brand-name' type='body2' color='secondaryText'>
                                {data?.brandName}
                            </BrandName>
                            <Typography.Title data-e2e='item-product-title' level={3} marginBottom='4px'>
                                {data?.title}
                            </Typography.Title>
                            <SelectedVariant data-e2e='item-variant-title' type='descriptor2' color='secondaryText'>
                                {variantTitle}
                            </SelectedVariant>
                        </ProductBody>
                    </BodyStyles>
                    <FootStyles>
                        <StyledButton data-e2e='go-to-cart-button' type='primary' onClick={data?.onGoToShoppingCart}>
                            Go to cart
                        </StyledButton>
                        <StyledButton data-e2e='continue-shopping-button' type='text' onClick={onModalClose}>
                            {store.view.customizations.buttons.continueShoppingButtonLabel}
                        </StyledButton>
                    </FootStyles>
                </WrapStyles>
            </Styles>
        );
    })
);
