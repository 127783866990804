import React from 'react';
import SuggestionContent from '../SuggestionContent';
import { StyledWrapper } from './styled';

const AutoSuggestionPlaceholder = ({ suggestion, searchValue }) => {
    return suggestion ? (
        <StyledWrapper>
            <SuggestionContent suggestion={suggestion} searchValue={searchValue} />
        </StyledWrapper>
    ) : null;
};

export default AutoSuggestionPlaceholder;
