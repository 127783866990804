import { axiosInstance } from '../shared/constants';

export const INITIAL_STORE = {
    get: async (url, params) => {
        try {
            const { data } = await axiosInstance.get(url, params && { params });
            return data;
        } catch (e) {
            return e;
        }
    },
    post: async (url, body = {}) => {
        try {
            const { data } = await axiosInstance.post(url, body);
            return data;
        } catch (e) {
            return e;
        }
    },
    patch: async (url, body = {}) => {
        try {
            const { data } = await axiosInstance.patch(url, body);
            return data;
        } catch (e) {
            return e;
        }
    }
};
