import styled, { css } from 'styled-components';
import { Button } from '../../../Button';

export const StyledButton = styled(Button)`
    &.ant-btn-circle {
        border-radius: 50%;

        ${({ ghost, $theme }) =>
            ghost &&
            css`
                color: ${$theme.colors.primaryText};

                &:hover,
                &:focus {
                    color: ${$theme.colors.primaryText};
                }
            `}

        > svg {
            width: 16px;
            height: 16px;
            margin: 0;

            &:not(:only-child):last-child {
                margin: 0;
            }
        }

        &:last-child {
            margin-left: ${({ $withoutMargin }) => ($withoutMargin ? 0 : '20px')};
        }

        &.slick-disabled,
        &.slick-disabled.ant-btn-background-ghost.ant-btn-primary:not(.ant-btn-dangerous):hover,
        &.slick-disabled.ant-btn-primary:hover {
            color: ${({ $theme }) => $theme.colors.disabledState};
            border-color: ${({ $theme }) => $theme.colors.disabledState};
            background: transparent;
            cursor: not-allowed;
        }

        &[ant-click-animating-without-extra-node='true']::after {
            display: none;
        }
    }
`;
