import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TimeIcon } from '../../../../../../../../../../icons';
import Typography from '../../../../../../../../../UiLibrary/Typography';
import { StyledIcon, StyledWrap } from './styles';

export const Timer = ({ endDate, speed = 1000, discountActive = true }) => {
    const [timeLeft, setTimeLeft] = useState(0);

    const timer = useRef(null);

    const getTimeLeft = (date) => {
        if (!date) {
            return date;
        }

        return new Date(date) - Date.now();
    };

    const msToDisplayFormat = (timeLeftInMilliseconds) => {
        if (!timeLeftInMilliseconds) {
            return timeLeftInMilliseconds;
        }

        const timeLeftInSeconds = timeLeftInMilliseconds / 1000;

        if (timeLeftInSeconds <= 0) {
            return null;
        }

        const d = Math.floor(timeLeftInSeconds / (3600 * 24));
        const h = Math.floor((timeLeftInSeconds % (3600 * 24)) / 3600);
        const m = Math.floor((timeLeftInSeconds % 3600) / 60);
        const s = Math.floor((timeLeftInSeconds % 3600) % 60);

        if (d >= 1) {
            return `${d} day${d > 1 ? 's' : ''}`;
        }

        if (h >= 1) {
            return `${h} hour${h > 1 ? 's' : ''}`;
        }

        if (m >= 1) {
            return `${m} min`;
        }

        if (s >= 1) {
            return `${s} sec`;
        }
    };

    useEffect(() => {
        setTimeLeft(getTimeLeft(endDate));
    }, [setTimeLeft, endDate]);

    useEffect(() => {
        timer.current = setTimeout(() => {
            const newTimeLeft = getTimeLeft(endDate);

            if (!timeLeft) {
                clearTimeout(timer.current);
                return;
            }

            setTimeLeft(newTimeLeft);

            clearTimeout(timer.current);
        }, speed);

        return () => clearTimeout(timer.current);
    }, [speed, timeLeft, setTimeLeft, endDate]);

    const getDisplayTime = useCallback(() => {
        return msToDisplayFormat(timeLeft);
    }, [timeLeft]);

    return timeLeft ? (
        discountActive && timeLeft > 0 ? (
            <StyledWrap>
                <StyledIcon size='12px' color='primary' component={() => <TimeIcon />} />
                <Typography.Text color='primary' type='descriptor2'>
                    {getDisplayTime()}
                </Typography.Text>
            </StyledWrap>
        ) : (
            <StyledWrap>
                <Typography.Text color='primary' type='descriptor2'>
                    expired
                </Typography.Text>
            </StyledWrap>
        )
    ) : null;
};
