import { useEffect, useState } from 'react';

// This hook helps handle browser button events and do some actions according to location params

export const useBrowserButtonsEvents = ({
    handleBackArrowClick = () => null,
    handleForwardArrowClick = () => null,
    navigationType,
    location
}) => {
    const [locationKeys, setLocationKeys] = useState([]);
    const deps = typeof history?.listen === 'function' ? locationKeys : location;

    useEffect(() => {
        if (navigationType === 'PUSH') {
            setLocationKeys([location.key]);
        }

        if (navigationType === 'POP') {
            if (locationKeys[1] === location.key) {
                setLocationKeys(([, ...keys]) => keys);
                // Handle "Forward" browser button click
                handleForwardArrowClick(location);
            } else {
                setLocationKeys((keys) => [location.key, ...keys]);
                // Handle "Back" browser button click
                handleBackArrowClick(location);
            }
        }
    }, [deps]);
};
