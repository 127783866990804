import { flow, getParent, types } from 'mobx-state-tree';

export const PointsStore = types
    .model('PointsStore', {
        pointsBalance: types.maybeNull(types.number),
        pointsApplied: types.maybeNull(types.number),
        availablePointsBalance: types.maybeNull(types.number),
        loading: false,
        errorMessage: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const markLoading = (loading) => {
            self.loading = loading;
        };

        const setErrorMessage = (err) => {
            if (err && err.response && err.response.data) {
                self.errorMessage = err.response.data.message;
                return true;
            }

            if (err && err.message) {
                self.errorMessage = err.message;
            }
        };

        const fetchPoints = flow(function* fetchPoints() {
            markLoading(true);
            try {
                const {
                    pointsApplied,
                    pointsBalance,
                    availablePointsBalance
                } = yield self.root.callbacks.onPointsFetch();
                self.pointsBalance = pointsBalance;
                self.pointsApplied = pointsApplied;
                self.availablePointsBalance = availablePointsBalance;
                markLoading(false);
            } catch (err) {
                setErrorMessage(err);
            }
        });

        const applyPoints = flow(function* applyPoints(points) {
            markLoading(true);
            try {
                yield self.root.callbacks.onPointsApply(points);
                yield fetchPoints();
                yield self.root.cart.loadCart();
                markLoading(false);
            } catch (err) {
                setErrorMessage(err);
            }
        });

        const removeAppliedPoints = flow(function* removeAppliedPoints() {
            markLoading(true);
            try {
                yield self.root.callbacks.onPointsRemove();
                yield fetchPoints();
                yield self.root.cart.loadCart();
                markLoading(false);
            } catch (err) {
                setErrorMessage(err);
            }
        });

        return {
            fetchPoints,
            applyPoints,
            removeAppliedPoints
        };
    });
