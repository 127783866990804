import { Select } from 'antd';
import styled, { createGlobalStyle, css } from 'styled-components';

export const StyledSelect = styled(Select)`
    width: 100%;

    ${({ $theme }) => {
        return css`
            &&&& {
                &:hover {
                    .ant-select-selector {
                        border-color: transparent;
                        border-right-width: 2px;
                    }
                }
                .ant-select-selector {
                    border: 2px solid transparent;
                    border-radius: unset;
                    padding: ${({ size }) => (size === 'large' ? '26px 64px 14px 24px' : '20px 64px 10px 24px')};
                    font: ${$theme.fonts.descriptor1};
                    outline: none;
                    transition: ${$theme.transition.default};
                    color: ${$theme.colors.secondaryText};
                    background: ${$theme.colors.background};

                    &:hover {
                        border-color: transparent;
                        border-right-width: 2px;
                    }

                    ${({ $error }) =>
                        $error &&
                        css`
                            border: 2px solid ${$theme.colors.error};

                            &:hover {
                                border: 2px solid ${$theme.colors.error};
                            }
                        `};

                    .ant-select-selection-item .anticon {
                        display: none;
                    }
                }

                &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customized-input)
                    .ant-select-selector {
                    box-shadow: none;
                    border-color: transparent;

                    ${({ $error }) =>
                        $error &&
                        css`
                            border: 2px solid ${$theme.colors.error};

                            &:hover {
                                border: 2px solid ${$theme.colors.error};
                            }
                        `};
                }

                &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
                &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                    height: auto;

                    &:after {
                        line-height: 24px;
                    }
                }

                &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
                    .ant-select-selector
                    .ant-select-selection-item,
                .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
                    .ant-select-selector
                    .ant-select-selection-placeholder,
                .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after,
                &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
                .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
                    .ant-select-selector
                    .ant-select-selection-placeholder,
                .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after {
                    line-height: 24px;
                }

                .ant-select-arrow {
                    right: 22px;
                    color: ${$theme.colors.secondaryText};
                }

                &.ant-select-single .ant-select-selector .ant-select-selection-item:after,
                .ant-select-single .ant-select-selector .ant-select-selection-placeholder:after,
                .ant-select-single .ant-select-selector:after {
                    content: none;
                }

                .ant-select-selection-item {
                    color: ${$theme.colors.primaryText};
                }

                &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused
                    .ant-select-selector,
                .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open
                    .ant-select-selector {
                    border-right-width: 2px;
                }
            }
        `;
    }};
`;

export const StyledOption = createGlobalStyle`
    .ant-select-item, .ant-select-item-empty {
        padding: 10px;
        color: ${({ $theme }) => $theme.colors.secondaryText};

        .anticon {
            visibility: hidden;
        }
    }

    .ant-select-item-option-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: transparent;
        color: ${({ $theme }) => $theme.colors.secondaryText};
        font-weight: normal;

        .anticon {
            visibility: visible;
        }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background: transparent;
    }
`;
