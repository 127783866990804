import { useEffect, useState } from 'react';
import { device } from './device';
import { useWindowResize } from './resizeWindow';

export const useSimpleInterface = (SIMPLE_GRID_POINT = 668) => {
    const size = useWindowResize();
    const mobile = device.isMobile();
    const [simple, setSimple] = useState(mobile);

    useEffect(() => {
        if (!mobile) {
            setSimple(size.width <= SIMPLE_GRID_POINT);
        }
    }, [SIMPLE_GRID_POINT, mobile, size, setSimple]);

    return simple;
};
