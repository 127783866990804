import styled from 'styled-components';
import { COLORS } from '../../../../../../../../../../constants';

export const TabHead = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const TabHeadItem = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    color: ${({ active }) => (active ? COLORS.$primaryText : COLORS.$secondaryText)};

    width: 50%;
    padding: 16px;
    cursor: pointer;

    border-bottom: ${({ active }) => (active ? `4px solid ${COLORS.$primary}` : `1px solid ${COLORS.$bordered}`)};
`;
