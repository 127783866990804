export const $white = '#FFFFFF';
export const $border = '#D2D6DA';
export const $background = '#F4F4F4';
export const $bordered = '#DCDCDC';
export const $shadow = '0px 8px 40px rgba(0, 0, 0, 0.16)';
export const $tartRed = '#FFEBEB';
export const $primary = '#F25858';
export const $primaryLight = '#FFEBEB';
export const $primaryText = '#04080F';
export const $error = '#EF2E2E';
export const $success = '#1CA355';
export const $secondaryText = '#77838F';
export const $info = '#33B6DF';
export const $primaryBackground = '#FFEEEE';
