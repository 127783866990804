import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Icon, Image } from '../../../../../../../../../../../UiLibrary';
import { COLORS } from '../../../../../../../../../../constants';

export const Styles = styled.div`
    position: relative;
    max-width: 630px;
    max-height: 600px;
    width: 100%;
    background-color: ${COLORS.$white};

    ${({ simple }) =>
        simple
            ? css`
                  max-width: 100%;
                  max-height: 100%;
                  height: 100%;
              `
            : ''}
`;

export const HeaderStyles = styled.div`
    border-bottom: 1px solid ${COLORS.$background};
`;

export const HeaderButtonStyles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
`;

export const BodyStyles = styled.div`
    padding: 40px;
    overflow: hidden;
    background-color: ${COLORS.$white};

    ${({ simple }) =>
        !simple
            ? css`
                  min-width: 400px;
              `
            : ''};
`;

export const GalleryImageWrap = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ simple }) => (simple ? '50px' : '20px')};

    > img {
        margin: 0 auto;
    }
`;

export const GalleryImage = styled(Image)`
    max-width: 100%;
    max-height: 500px;
    object-fit: contain;
`;

export const CloseModalIcon = styled(Icon)`
    && {
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-bottom: 24px;
        font-size: 24px;
        transform: translate3d(-50%, 0, 0);
    }
`;
