import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI, ROUTE_PATHS } from '../shared/constants';

export const ColdCustomerStore = types
    .model('ColdCustomerStore', {
        showEmailForm: false,
        pending: false,
        error: types.maybeNull(types.string),
        cartId: types.maybeNull(types.string),
        checkoutKey: types.maybeNull(types.string),
        selectedVariantId: types.maybeNull(types.number),
        isNewCustomer: types.maybeNull(types.boolean)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const setError = (error) => {
            if (error.data?.message) {
                self.error = error.data.message;
                return true;
            }

            self.error = error.message;
            return true;
        };

        const toggleShowEmailForm = (value) => {
            self.showEmailForm = value;
        };

        const setPDPData = ({ cartId, checkoutKey, selectedVariantId }) => {
            self.cartId = cartId;
            self.checkoutKey = checkoutKey;
            self.selectedVariantId = selectedVariantId;
        };

        const resetIsNewCustomer = () => {
            self.isNewCustomer = null;
        };

        const checkIsColdCustomer = flow(function* checkIsColdCustomer(params) {
            if (self.isNewCustomer === null) {
                self.pending = true;
                try {
                    const data = yield self.root.get(
                        kcpAPI.publishers.checkColdCustomer(
                            params?.publisherId || self.root.routeLocation?.state?.publisherId
                        ),
                        {
                            email: params?.email || self.root.routeLocation?.state?.email,
                            promotionId: params?.promotionId || self.root.routeLocation?.state?.promotionId,
                            variantId: params?.variantId || self.root.routeLocation?.state?.variantId
                        }
                    );
                    self.isNewCustomer = data?.isNewCustomer;

                    if (data?.variant?.cashbackPrice) {
                        const cartData = self.root.preCheckoutStore.cartData;
                        self.root.marketplaceStore.setCashBackValue(
                            data?.variant?.cashbackPrice * cartData.priceDetails.qty
                        );
                    }
                } catch (error) {
                    setError(error);
                } finally {
                    self.pending = false;
                }
            }
        });

        const checkEmail = flow(function* checkEmail({
            cartId,
            email,
            promotionId,
            variantId,
            checkoutKey,
            publisherId,
            onAfterCustomerWasFound
        }) {
            self.pending = true;
            try {
                yield checkIsColdCustomer({ email, publisherId });
                yield self.root.patch(kcpAPI.cart.update(cartId), {
                    customerInfo: { email }
                });
                if (self.isNewCustomer) {
                    return self.root.routeNavigate(ROUTE_PATHS.checkout(), {
                        state: {
                            cartId,
                            email,
                            promotionId,
                            variantId,
                            checkoutKey,
                            publisherId,
                            cashBack: self.root.marketplaceStore.cashBack,
                            previousCashBack: self.root.marketplaceStore.previousCashBack
                        }
                    });
                }
                if (self.isNewCustomer === false) return onAfterCustomerWasFound();
            } catch (error) {
                setError(error);
            } finally {
                self.pending = false;
            }
            return true;
        });

        return {
            toggleShowEmailForm,
            setPDPData,
            checkIsColdCustomer,
            checkEmail,
            resetIsNewCustomer
        };
    });
