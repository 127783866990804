import { getParent, types } from 'mobx-state-tree';
import { ROUTE_PATHS } from '../shared/constants';
import { getEndPoints } from '../shared/utils';

export const PDPStore = types
    .model('PDPStore', {
        showPDP: false,
        isPageReloaded: true,
        productId: types.maybeNull(types.string),
        endpoints: types.optional(
            types.model({
                productFetchURL: types.optional(types.string, ''),
                cartItemsManageURL: types.optional(types.string, ''),
                buyNowURL: types.optional(types.string, '')
            }),
            {}
        )
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const openPDP = ({ promotionId, addToHistory = false, promotion = null }) => {
            self.productId = promotionId;
            self.showPDP = true;
            self.endpoints = getEndPoints(promotionId, self.root.publisherStore.publisherId);
            if (promotion) self.root.trackingStore.trackClick(promotion);
            if (addToHistory) {
                self.isPageReloaded = false;
                self.root.routeNavigate({
                    pathname: `${ROUTE_PATHS.marketplace()}/${promotionId}`,
                    search: self.root.routeLocation.search
                });
            }
        };

        const closePDP = (addToHistory = true) => {
            self.showPDP = false;
            self.productId = null;

            if (addToHistory) {
                self.root.routeNavigate({
                    pathname: ROUTE_PATHS.marketplace(),
                    search: self.root.routeLocation.search
                });
            }
        };

        const handleBuyNow = ({ cartId, checkoutKey, selectedVariant, promotionId, customerEmail }) => {
            if (selectedVariant.cashbackType === 'cc') {
                self.root.checkoutStore.openCheckout({ cartId, checkoutKey });
                return closePDP(false);
            }

            const selectedVariantId = selectedVariant.externalId;

            self.root.coldCustomerStore.setPDPData({
                cartId,
                checkoutKey,
                selectedVariantId
            });

            return self.root.routeNavigate(ROUTE_PATHS.preCheckout(), {
                state: {
                    cartId,
                    checkoutKey,
                    variantId: selectedVariantId,
                    promotionId,
                    customerEmail,
                    cashBack: self.root.marketplaceStore.cashBack,
                    previousCashBack: self.root.marketplaceStore.previousCashBack
                }
            });
        };

        return { openPDP, closePDP, handleBuyNow };
    });
