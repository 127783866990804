import React from 'react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../constants';
import { CircleActionsAlertInfoIcon } from '../../../../../../../../../../icons';
import { Typography } from '../../../../../../../../../UiLibrary';
import { ErrorFieldIcon, ErrorFieldStyles } from './styles';

export const ErrorField = ({
    error,
    showIcon = false,
    icon = <CircleActionsAlertInfoIcon />,
    textType = 'descriptor2'
}) => {
    return (
        <ErrorFieldStyles style={BOTTOM_INDENTS.SM}>
            {showIcon && <ErrorFieldIcon color='error' size='130%' component={() => icon} />}
            <Typography.Text color='error' type={textType}>
                {error}
            </Typography.Text>
        </ErrorFieldStyles>
    );
};
