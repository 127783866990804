import React, { useCallback, useLayoutEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from '../../../UiLibrary';
import { debounce } from '../../utils';
import { ShoppingCart } from './components/shoppingCart';
import { ContainerStyles } from './styles';

export const AppContainer = inject('store')(
    observer(({ store, ...props }) => {
        const [node, setNode] = useState(null);

        const appRef = useCallback((node) => {
            if (node !== null) {
                setNode(node);
                store.view.setAppWidth(node.offsetWidth);
            }
        }, []);

        useLayoutEffect(() => {
            if (node) {
                const onResize = debounce(() => {
                    store.view.setAppWidth(node.offsetWidth);
                }, 500);

                window.addEventListener('resize', onResize);

                return () => {
                    window.removeEventListener('resize', onResize);
                };
            }
        }, [node]);

        return (
            <ContainerStyles ref={appRef}>
                {store.cart.isLoading && <Icon.Loader overlayColor='rgba(255,255,255, 0.8)' />}
                <ShoppingCart {...props} />
            </ContainerStyles>
        );
    })
);
