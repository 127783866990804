import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../../../constants';

export const StyledBestSellersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > * {
        &:first-child {
            width: 315px;
            @media (${GRID_DEVICES_MAX.lg}) {
                width: 240px;
            }
        }
        &:last-child {
            flex-grow: 1;
            padding-left: 20px;
            max-width: calc(100% - 315px);
            @media (${GRID_DEVICES_MAX.lg}) {
                max-width: calc(100% - 240px);
            }
        }
    }
`;

export const StyledBestSellerTitleWrapper = styled.div`
    height: 100%;
`;
