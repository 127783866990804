import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../constants';
import { BagIcon } from '../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../UiLibrary';
import { $secondaryText } from '../../../../../../styles';
import { getMetaByName, getPrices } from '../../../../../../utils';
import { ProductCardRemoved } from './components/ProductCardRemoved';
import { Qty } from './components/Qty';
import { Timer } from './components/Timer';
import {
    Buttons,
    BuyNowButton,
    CardBody,
    CardBodyLeft,
    CardFoot,
    Info,
    Prices,
    ProductTitle,
    RemoveButton,
    StyledImage,
    StyledPriceLine,
    Styles
} from './styles';

export const ProductCard = inject('store')(
    observer(({ store, item, onBuyNow }) => {
        const couponTimer = useRef(null);
        const [buttonsHidden, setButtonsHidden] = useState(false);
        const { showOriginalPrice, originalSubTotal, fullTotal, discountPercentage } = getPrices({
            ...item.priceDetails
        });
        const discounts = store.cart.discounts;

        const expirationMetadata = getMetaByName(item.metadata, 'expireAt');
        const isGSProduct = getMetaByName(item.metadata, 'suiteId');
        const couponExpireAt = expirationMetadata?.value;
        const itemDiscountActive =
            Array.isArray(discounts) && discounts.length
                ? discounts.some((discount) => discount.levelId === item._id)
                : false;

        useEffect(() => {
            const suiteCouponTimerEnd = couponExpireAt ? new Date(couponExpireAt) - Date.now() : 0;

            if (suiteCouponTimerEnd && suiteCouponTimerEnd > 0 && itemDiscountActive) {
                setButtonsHidden(true);
                couponTimer.current = setTimeout(() => setButtonsHidden(false), suiteCouponTimerEnd);
            }

            return () => clearTimeout(couponTimer.current);
        }, [couponExpireAt, itemDiscountActive]);

        return !item.removed ? (
            <Styles>
                <CardBody style={BOTTOM_INDENTS.SM}>
                    <CardBodyLeft>
                        <StyledImage src={item.image} alt={item.productTitle} />
                        <Info>
                            <ProductTitle data-e2e='product-title' level={3}>
                                {item.productTitle}
                            </ProductTitle>
                            <Typography.Text type='descriptor2' color={$secondaryText}>
                                {item.variantTitle.replace('Default Title', '')}
                            </Typography.Text>
                        </Info>
                    </CardBodyLeft>
                    <Prices>
                        <StyledPriceLine>
                            {showOriginalPrice ? (
                                <Typography.Text
                                    data-e2e='product-price'
                                    type='descriptor2'
                                    color={$secondaryText}
                                    style={{ textDecoration: 'line-through' }}
                                    align='right'
                                >
                                    {originalSubTotal}
                                </Typography.Text>
                            ) : null}
                            <Typography.Text
                                data-e2e='full-total-price'
                                type='body2'
                                color={$secondaryText}
                                align='right'
                            >
                                {fullTotal}
                            </Typography.Text>
                        </StyledPriceLine>
                        {!expirationMetadata ? (
                            <Typography.Text
                                data-e2e='discount-percentage'
                                type='body2'
                                color={$secondaryText}
                                align='right'
                            >
                                {discountPercentage}
                            </Typography.Text>
                        ) : null}
                        {couponExpireAt ? <Timer endDate={couponExpireAt} discountActive={itemDiscountActive} /> : null}
                    </Prices>
                </CardBody>
                <CardFoot>
                    <Qty
                        isGSProduct={isGSProduct}
                        value={item.qty}
                        maxValue={item.maxQty}
                        decreaseValue={() => {
                            item.decrementQty();
                            item.updateQty();
                        }}
                        increaseValue={() => {
                            item.incrementQty();
                            item.updateQty();
                        }}
                    />
                    {!buttonsHidden ? (
                        <Buttons>
                            <RemoveButton data-e2e='remove-button' type='unstyled' onClick={() => item.softRemove()}>
                                <Typography.Text type='badgeSmall'>Remove</Typography.Text>
                            </RemoveButton>
                            <BuyNowButton data-e2e='buy-now-button' type='unstyled' onClick={() => onBuyNow(item)}>
                                <Icon size='16px' stylePriority component={() => <BagIcon />} />
                                <Typography.Text type='badgeSmall'>Buy now</Typography.Text>
                            </BuyNowButton>
                        </Buttons>
                    ) : null}
                    {buttonsHidden && typeof store.callbacks.onCartCouponRemove === 'function' ? (
                        <Buttons>
                            <RemoveButton
                                type='unstyled'
                                onClick={() => {
                                    item.hardRemove(store.callbacks.onCartCouponRemove);
                                }}
                            >
                                <Typography.Text type='badgeSmall'>Change reward</Typography.Text>
                            </RemoveButton>
                        </Buttons>
                    ) : null}
                </CardFoot>
            </Styles>
        ) : (
            <ProductCardRemoved item={item} />
        );
    })
);
