export const getProducts = (bags) =>
    bags.reduce((result, bag) => {
        return [
            ...result,
            ...bag.items.map((item) => ({
                data: {
                    images: [item?.image],
                    brandName: bag?.merchantName,
                    title: item?.productTitle
                },
                variants: {
                    list: [
                        {
                            pointsDetails: item.metadata.find((metaItem) => metaItem.name === 'pointsEarned')?.details,
                            price: item?.priceDetails?.subTotal / item?.priceDetails?.qty || 0
                        }
                    ]
                }
            }))
        ];
    }, []);
