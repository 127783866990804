import styled, { css } from 'styled-components';
import { Icon, Typography } from '../../../../../../../../../../../../../UiLibrary';

export const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const StyledIcon = styled(Icon)`
    margin-right: 4px;
`;

export const StyledText = styled(Typography.Text)`
    && {
        display: flex;
        align-items: center;

        ${({ $appWidth }) =>
            $appWidth <= 320
                ? css`
                      max-width: 55%;
                  `
                : ''};

        > span {
            display: flex;
            align-items: center;
        }
    }
`;
