const DEFAULT_SORT_KEY = 'relevance';

const PROMOTIONS_SORTS_STATE = [
    {
        key: DEFAULT_SORT_KEY,
        type: 'Relevance',
        checked: true,
        default: true
    },
    {
        key: 'price_asc',
        name: 'Low to High',
        type: 'Price',
        checked: false
    },
    {
        key: 'price_desc',
        name: 'High to Low',
        type: 'Price',
        checked: false
    }
];

const PROMOTIONS_PRICE_STATE = {
    gte: 0,
    lte: 10000
};

export const MARKETPLACE = {
    DEFAULT_SORT_KEY,
    PROMOTIONS_SORTS_STATE,
    PROMOTIONS_PRICE_STATE
};
