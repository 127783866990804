import styled from 'styled-components/macro';
import { Button } from '../../../../../../../../../../UiLibrary';

export default styled(Button)`
    && {
        position: absolute;
        left: 0;
        bottom: 0;
        padding-left: 0;
    }
`;
