import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { roundToLocaleString } from '../../../../../../shared';
import { Collapse, Panel } from '../../../../../UiLibrary';
import { ExpansionHead } from '../ExpansionHead';
import { Styles } from './styles';

export const Topbar = inject('store')(
    observer(({ store, children }) => {
        const [opened, setOpened] = useState(false);
        const summaryPrice = `$${roundToLocaleString(store.totalPrice)}`;
        const pointsEarned = store.pointsEarned;

        return (
            <Styles>
                <Collapse withoutPadding bordered={false} onChange={(props) => setOpened(props && props[0])}>
                    <Panel
                        header={
                            <ExpansionHead opened={opened} summaryPrice={summaryPrice} pointsEarned={pointsEarned} />
                        }
                        showArrow={false}
                    >
                        {children}
                    </Panel>
                </Collapse>
            </Styles>
        );
    })
);
