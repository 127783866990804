import React, { useContext } from 'react';
import { AvatarIconNew } from '../../../icons';
import { KliThemeContext } from '../KliThemeProvider';
import { StyledAvatar } from './styled';

const Avatar = ({ ...props }) => {
    const theme = useContext(KliThemeContext);
    return <StyledAvatar $theme={theme} size={40} icon={<AvatarIconNew />} {...props} />;
};

export default Avatar;
