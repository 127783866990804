import styled from 'styled-components';

export const StyledLoader = styled.div`
    display: flex;
    align-items: center;

    > div {
        position: relative;
        width: 60px;
        height: 60px;
        > span {
            font-size: 40px;

            &:after {
                content: none;
            }
        }
    }
`;
