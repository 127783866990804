import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS, TOP_INDENTS } from '../../../../../../constants';
import { ArrowGoingUpIcon, CircleActionsAlertInfoIcon } from '../../../../../../icons';
import { Button, Icon, Typography } from '../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../utils';
import { useCancelOrderModal } from '../CancelOrderModal/useCancelOrderModal';
import { CancelMuiltiplyOrderLine } from './components/CancelMuiltiplyOrderLine';
import { CancelSingleOrderLine } from './components/CancelSingleOrderLine';
import { CancelInfoWrap, InfoWrap, StyledConfirmationInfo, StyledImg, Styles } from './styles';

export const OrdersInfo = inject(
    'store',
    'globalProps'
)(
    observer(({ store, globalProps, hideContinueShoppingButton }) => {
        const continueShoppingText = globalProps.continueShoppingText;
        const customContent = globalProps.customContent;
        const orderNumbers = store.orderNumbers;

        const continueShoppingClick = () => {
            if (globalProps.continueShoppingClick !== undefined) {
                globalProps.continueShoppingClick();
            }
        };

        let prefix1 = '';
        let prefix2 = 'has';

        const isMultipleOrders = orderNumbers && orderNumbers.length > 1;

        if (isMultipleOrders) {
            prefix1 = 's';
            prefix2 = 'have';
        }

        const simpleModal = store.view.isSimple(GRID_POINTS.sm);
        const simple = store.view.isSimple(GRID_POINTS.lg);
        const appWidth = store.view.appWidth;

        const onCancelOrder = useCancelOrderModal(simpleModal, store.resetModalState);

        const getOrdersNumbers = (orderNumbers) => {
            let string = '';
            orderNumbers &&
                orderNumbers.length &&
                orderNumbers.forEach((number, index) => {
                    if (index > 0 && index + 1 !== orderNumbers.length) {
                        string = string + `, ${number}`;
                        return;
                    }

                    if (index > 0 && index + 1 === orderNumbers.length) {
                        string = string + ` and ${number}`;
                        return;
                    }

                    string = string + `${number}`;
                });

            return string;
        };

        return (
            <Styles data-e2e='thank-you-block' $simple={simple} $appWidth={appWidth}>
                <StyledImg style={BOTTOM_INDENTS.XL} />
                <Typography.Title style={TOP_INDENTS.SM} data-e2e='klickly-order-number' level={2} align='center'>
                    {`Your order${prefix1} ${getOrdersNumbers(orderNumbers)} ${prefix2} been received`}
                </Typography.Title>
                <StyledConfirmationInfo align='center' type='descriptor1'>
                    A confirmation will be sent to your email shortly
                </StyledConfirmationInfo>
                {customContent ||
                    (store.hasOrdersForRefund ? (
                        <Fragment>
                            <CancelInfoWrap style={TOP_INDENTS.XL} data-e2e='cancel-block'>
                                <Icon
                                    size='22px'
                                    style={{ marginRight: '16px' }}
                                    color='primary'
                                    component={() => <CircleActionsAlertInfoIcon />}
                                />
                                {store.orders && store.orders.length === 1 ? (
                                    <CancelSingleOrderLine order={store.orders[0]} onCancelOrder={onCancelOrder} />
                                ) : null}
                                {store.orders && store.orders.length > 1 ? (
                                    <Typography.Text as='div' type='descriptor2'>
                                        Error with your order? Cancel in the next 5 minutes
                                        <div style={TOP_INDENTS.SM}>
                                            {store.orders.map((order, index) => (
                                                <CancelMuiltiplyOrderLine
                                                    key={order.klicklyOrderId}
                                                    orderIndex={index}
                                                    order={order}
                                                    onCancelOrder={onCancelOrder}
                                                />
                                            ))}
                                        </div>
                                    </Typography.Text>
                                ) : null}
                            </CancelInfoWrap>
                        </Fragment>
                    ) : null)}
                {!hideContinueShoppingButton && (
                    <Fragment>
                        <InfoWrap style={TOP_INDENTS.XL}>
                            <Button
                                data-e2e='continue-shopping-button'
                                onClick={continueShoppingClick}
                                type='primary'
                                size='large'
                                ghost
                                block
                            >
                                <Typography.Text type='badgeLarge'>{continueShoppingText}</Typography.Text>
                                <Icon size='24px' component={() => <ArrowGoingUpIcon />} />
                            </Button>
                        </InfoWrap>
                    </Fragment>
                )}
            </Styles>
        );
    })
);
