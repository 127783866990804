import styled from 'styled-components';
import { $primary, $primaryText } from '../../../../../../constants';

export const StyledText = styled.span`
    color: ${({ $color = $primaryText }) => $color};
`;

export const StyledPubText = styled.span`
    display: inline-flex;
    padding-right: 4px;
    color: ${({ $color = $primary }) => $color};

    &&& {
        margin-right: ${({ $marginRight }) => $marginRight || 0};
    }
`;
