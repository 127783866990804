import React from 'react';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../../constants';
import { AddedToCard } from './components/addedToCard';
import { Styles } from './styles';

export const AddedToCardModal = inject('store')(
    observer(({ store, data, onModalClose }) => {
        const simple = store.view.isSimple(GRID_POINTS.sm);
        return (
            <Styles simple={simple}>
                <AddedToCard data={data} onModalClose={onModalClose} />
            </Styles>
        );
    })
);
