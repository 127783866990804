const KCP_API_URL = process.env.REACT_APP_KCP_API_URL;
const SECURE_CHECKOUT_API_URL = process.env.REACT_APP_SECURE_CHECKOUT_URL;

const makeURL = (url) => `${KCP_API_URL}${url}`;

export const kcpAPI = {
    cart: {
        update: (cartId) => makeURL(`/cart/${cartId}`),
        getById: (cartId) => makeURL(`/cart/${cartId}`),
        makeCheckoutUrl: (cartId, checkoutKey) => `${SECURE_CHECKOUT_API_URL}/${cartId}/${checkoutKey}`,
        itemsManage: makeURL('/cart/items'),
        buyNow: makeURL('/cart/buy-now')
    },
    customer: {
        applyFit: makeURL('/customer/apply-fit'),
        exists: makeURL('/customer/exists')
    },
    customerData: {
        orders: {
            cancelOrder: (orderId = null) => makeURL(`/customer-data/orders/refund${orderId ? `/${orderId}` : ''}`),
            getByCode: makeURL('/customer-data/orders/by-code'),
            getById: makeURL('/customer-data/orders/by-id')
        }
    },
    orders: {
        createCheckoutOrder: makeURL('/orders')
    },
    marketplace: {
        getStrategyInfo: (strategyName) => makeURL(`/marketplace/strategy?name=${strategyName}`),
        getStrategyInfoById: (strategyId) => makeURL(`/marketplace/strategy?id=${strategyId}`),
        brands: {
            get: makeURL('/marketplace/brands')
        },
        promotions: {
            get: makeURL('/marketplace/promotions'),
            recommendations: (promotionId) => makeURL(`/marketplace/promotions/${promotionId}/recommendations`)
        },
        search: {
            basic: makeURL('/marketplace/search'),
            autoSuggestions: makeURL('/marketplace/search/autosuggestions')
        },
        taxonomy: {
            getDetails: makeURL('/marketplace/taxonomy/details'),
            list: makeURL('/marketplace/taxonomy')
        }
    },
    publishers: {
        get: makeURL('/publishers'),
        promotion: (publisherId, promotionId) => makeURL(`/publishers/${publisherId}/promotions/${promotionId}`),
        checkColdCustomer: (publisherId) => makeURL(`/publishers/${publisherId}/check-cold-customer`),
        creditCardVerification: (publisherId) => makeURL(`/publishers/${publisherId}/credit-card-verification`),
        saveCustomerEmail: (publisherId) => makeURL(`/publishers/${publisherId}/save-customer-email`)
    }
};
