import { css } from 'styled-components';

export const sharedTypographyStyles = css`
    margin: 0;
    color: ${({ $color, $theme }) => $theme.colors[$color] || $color || 'inherit'};
    text-align: ${({ $align }) => $align || 'left'};

    ${({ $marginBottom }) =>
        $marginBottom &&
        css`
            margin-bottom: ${$marginBottom};
        `};
`;
