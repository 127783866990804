import React from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS, TOP_INDENTS } from '../../../../../../../../constants';
import { BackArrowIcon, CloseXIcon } from '../../../../../../../../icons';
import { Icon } from '../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../utils';
import { AlertItemsMessage } from '../AlertItemsMessage';
import { BrandCard } from '../BrandCard';
import { Error } from '../Error';
import { BackButton, StyledHeadLine2, Styles, WrapStyles } from './styles';

export const Details = inject('store')(
    observer(({ store, bottomExtraContent }) => {
        const simple = store.view.isSimple(GRID_POINTS.md);
        const showBackButton = Boolean(store.callbacks.onBackButtonClick);
        const { showProductPoints } = store.view.customizations;

        return (
            <WrapStyles $simple={simple}>
                <Styles $simple={simple}>
                    {showBackButton ? (
                        <BackButton
                            data-e2e='back-button'
                            $simple={simple}
                            type='unstyled'
                            onClick={store.callbacks.onBackButtonClick}
                        >
                            <Icon
                                size='24px'
                                stylePriority
                                component={() => (simple ? <CloseXIcon /> : <BackArrowIcon />)}
                            />
                        </BackButton>
                    ) : null}
                    <StyledHeadLine2
                        style={{ ...TOP_INDENTS.SM, ...BOTTOM_INDENTS.SM }}
                        data-e2e='shopping-cart-title'
                        level={2}
                        $simple={simple}
                        color='primaryText'
                    >
                        Shopping cart
                    </StyledHeadLine2>
                    {store.cart.failedProductsIds ? (
                        <AlertItemsMessage
                            type='error'
                            message='An item(s) you added can’t be shipped to your address. Remove an item(s) to complete the order.'
                            items={store.cart.failedProductsData}
                        />
                    ) : null}
                    {store.cart.errorMessage ? <Error message={store.cart.errorMessage} /> : null}
                    {store.cart.bags &&
                        store.cart.bags.length &&
                        store.cart.bags.map((bag, key) => {
                            return (
                                <BrandCard
                                    key={key}
                                    orderNumber={key + 1}
                                    bag={bag}
                                    simple={simple}
                                    showProductPoints={showProductPoints}
                                    onBuyNow={store.cart.buyNow}
                                />
                            );
                        })}
                </Styles>
                {!simple && bottomExtraContent ? bottomExtraContent : null}
            </WrapStyles>
        );
    })
);
