import styled from 'styled-components';

export default styled.div`
    position: absolute;
    top: 50%;
    right: 21px;
    height: 24px;
    transform: translateY(-50%);
    cursor: pointer;
`;
