import { keyframes } from 'styled-components';
import styled from 'styled-components/macro';
import { Typography } from '../../../../../../../../../../../../../UiLibrary';
import { COLORS } from '../../../../../../../../../../../../constants';

export const Styles = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${COLORS.$white};
    background-color: ${COLORS.$primary};
    padding: 12px 24px;
`;

export const BodyStyles = styled.div`
    display: flex;
    align-items: center;
`;

export const Text = styled(Typography.Text)`
    line-height: 0.8;
`;

const $animated = keyframes`
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const Dots = styled.span`
    display: inline-block;
    vertical-align: middle;
    animation: 1s infinite ${$animated};
    line-height: 1;

    &::before {
        content: ':';
    }
`;

export const Value = styled.span`
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
`;
