import React, { useEffect, useMemo } from 'react';
import { Provider } from 'mobx-react';
import WebFont from 'webfontloader';
import { ModalProvider } from '../UiLibrary';
import { ThankYouPage } from './features/thanksYouPage';
import { RootStore } from './stores/RootStore';
import { Sidebar } from './components';
import HttpClient from './http';

export const CheckoutThankYou = ({
    links,
    data,
    continueShoppingText = 'Continue shopping',
    continueShoppingClick,
    loadFonts = true,
    asyncHooks = {},
    cancelOrderUnavailable,
    customContent,
    bottomExtraContent,
    onRewardIconClick = () => null,
    env,
    isLoggedIn,
    hideContinueShoppingButton
}) => {
    HttpClient.init(links);

    const store = useMemo(
        () =>
            RootStore.create(
                { cancelOrderUnavailable, env, isLoggedIn },
                {
                    propsData: data,
                    asyncHooks
                }
            ),
        []
    );

    const globalProps = useMemo(() => {
        return {
            continueShoppingText,
            continueShoppingClick,
            customContent,
            onRewardIconClick
        };
    }, []);

    useEffect(() => {
        if (loadFonts) {
            WebFont.load({
                google: {
                    families: [
                        'IBM Plex Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700',
                        'IBM Plex Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700'
                    ]
                }
            });
        }
    }, []);

    return (
        <Provider store={store} globalProps={globalProps}>
            <ModalProvider>
                <ThankYouPage
                    bottomExtraContent={bottomExtraContent}
                    hideContinueShoppingButton={hideContinueShoppingButton}
                />
            </ModalProvider>
        </Provider>
    );
};

export const CheckoutOrdersSidebar = ({ totalPrice, orders = [], simple = false, loadFonts = true }) => {
    useEffect(() => {
        if (loadFonts) {
            WebFont.load({
                google: {
                    families: [
                        'IBM Plex Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700',
                        'IBM Plex Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700'
                    ]
                }
            });
        }
    }, []);

    return (
        <React.StrictMode>
            <Sidebar totalPrice={totalPrice} orders={orders} simple={simple} />
        </React.StrictMode>
    );
};
