export const getTaxonomyByPath = (categories, path) => {
    if (!categories) return null;

    for (const category of categories) {
        if (category.path === path) {
            return category;
        }

        const foundInChildren = getTaxonomyByPath(category.children, path);
        if (foundInChildren) return foundInChildren;
    }

    return null;
};
