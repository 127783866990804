import { Typography } from '@klickly/front-packages';
import styled, { css } from 'styled-components';

export const StyledWrap = styled.div`
    width: 100%;
`;

export const StyledBannerWrap = styled.div`
    position: relative;
    width: 100%;
    height: 205px;
    margin-bottom: 24px;
    max-width: 1025px;
`;

export const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const StyledBannerText = styled(Typography.Title)`
    && {
        width: 50%;
        position: absolute;
        right: 75px;
        top: 50%;
        transform: translateY(-50%);

        font-size: ${({ $fontSize = '56px' }) => $fontSize};
        overflow: hidden;

        text-align: right;
        line-height: 110%;
        padding-bottom: 4px;
        word-break: initial;

        ${({ $tablet }) =>
            $tablet
                ? css`
                      right: 24px;
                      width: calc(100% - 48px);
                  `
                : ''}

        ${({ $simple }) =>
            $simple
                ? css`
                      text-align: center;
                  `
                : ''}
    }
`;
