import React from 'react';
import { inject, observer } from 'mobx-react';
import { CircleActionsAlertQuestionIcon } from '../../../../../../../../icons';
import { roundToLocaleString } from '../../../../../../../../shared';
import Typography from '../../../../../../../UiLibrary/Typography';
import { $secondaryText } from '../../../../../../styles';
import { StyledIcon, StyledInfoRow, StyledInfoWrapper } from './styled';

const ProductInfo = inject('globalProps')(
    observer(({ item, globalProps }) => {
        const rewardsData = item.metadata.find((item) => item.name === 'rewardsDetails')?.value;
        const priceDetails = rewardsData?.priceDetails || {};
        const onRewardIconClick = globalProps?.onRewardIconClick ? globalProps?.onRewardIconClick : () => null;
        return (
            <StyledInfoWrapper>
                <StyledInfoRow data-e2e='NumberItems-block'>
                    <Typography.Text type='body2' color={$secondaryText}>
                        Number of items
                    </Typography.Text>
                    <Typography.Text data-e2e='NumberItems-val' type='body2' color={$secondaryText}>
                        {priceDetails.qty}
                    </Typography.Text>
                </StyledInfoRow>
                <StyledInfoRow data-e2e='originalPrice-block'>
                    <Typography.Text type='body2' color={$secondaryText}>
                        Original price
                    </Typography.Text>
                    <Typography.Text data-e2e='originalPrice-val' type='body2' color={$secondaryText}>
                        ${roundToLocaleString(priceDetails.originalPrice)}
                    </Typography.Text>
                </StyledInfoRow>
                <StyledInfoRow data-e2e='rewardApplied-block'>
                    <StyledInfoRow $justify='flex-start'>
                        <Typography.Text type='body2' color={$secondaryText}>
                            {rewardsData?.rewards?.length || 0} Reward applied
                        </Typography.Text>
                        <StyledIcon
                            size='12px'
                            onClick={() => onRewardIconClick(item)}
                            component={() => <CircleActionsAlertQuestionIcon />}
                        />
                    </StyledInfoRow>
                    <Typography.Text data-e2e='rewardApplied-val' type='body2' color={$secondaryText}>
                        -${roundToLocaleString(priceDetails?.rewardsPrice)} ({priceDetails?.rewardsPercent}% off)
                    </Typography.Text>
                </StyledInfoRow>
            </StyledInfoWrapper>
        );
    })
);

export default ProductInfo;
