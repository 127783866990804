import React from 'react';
import { inject, observer } from 'mobx-react';
import { CircleActionsAlertQuestionIcon } from '../../../../../../../../../../../../../../icons';
import { roundToLocaleString } from '../../../../../../../../../../../../../../shared';
import { Icon, Typography } from '../../../../../../../../../../../../../UiLibrary';
import { StyledText, StyledWrapper } from './styled';

const RewardsApplied = inject('store')(
    observer(({ store }) => {
        const variant = store.product.variants.currentVariantData;
        const color = store.view.customizations.rewards.color;
        const rootAppName = store.view.customizations.rootAppName;
        const filteredRewards = variant?.rewards?.length
            ? variant.rewards.filter((reward) => reward.appliedTo !== 'taxes')
            : [];
        const rewardsLength = `${filteredRewards.length || 0} Rewards applied`;

        if (rootAppName === 'dp' && !filteredRewards?.length) return null;

        return (
            <StyledWrapper data-e2e='rewards-applied-block'>
                <StyledText $appWidth={store.view.appWidth} type='body2' color={color}>
                    <Typography.Text as='span'>{rewardsLength}</Typography.Text>
                    <Icon
                        data-e2e='reward-question-icon'
                        size='12px'
                        onClick={() =>
                            typeof store.callbacks.onRewardQuestionClick === 'function' &&
                            store.callbacks.onRewardQuestionClick(variant)
                        }
                        component={() => <CircleActionsAlertQuestionIcon />}
                    />
                </StyledText>
                <StyledText data-e2e='rewards-applied-value' type='body2' color={color}>
                    –${roundToLocaleString(variant?.priceDetails?.rewardsPrice * store.product.variants.qty)} (
                    {variant?.priceDetails?.rewardsPercent || 0}% off)
                </StyledText>
            </StyledWrapper>
        );
    })
);

export default RewardsApplied;
