import React from 'react';
import { inject, observer } from 'mobx-react';
import { cashbackCalculation } from '../../../../../../../../../../../../../../utils/commissionCalculation';
import { StyledText, StyledWrapper } from '../RewardsApplied/styled';

const CashBackInfo = inject('store')(
    observer(({ store }) => {
        const color = store.view.customizations.cashBack.color;

        const cashBack = cashbackCalculation(store);

        return (
            <StyledWrapper>
                <StyledText type='body2' color={color}>
                    Cash back
                </StyledText>
                <StyledText data-e2e='cashback-value' type='body2' color={color}>
                    ${cashBack}
                </StyledText>
            </StyledWrapper>
        );
    })
);

export default CashBackInfo;
