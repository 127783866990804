import styled from 'styled-components';
import Typography from '../../../../../../../../../UiLibrary/Typography';

export const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0 10px;
`;

export const StyledText = styled(Typography.Text)`
    display: flex;
    align-items: center;

    > span {
        margin-left: 6px;
    }
`;
