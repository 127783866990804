import { createElement } from 'react';
import { Typography as AntdTypography } from 'antd';
import Text from './components/Text';
import Title from './components/Title';

const Typography = (props) => createElement(AntdTypography, props);

Typography.Title = Title;
Typography.Text = Text;

export default Typography;
