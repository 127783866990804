import React from 'react';
import { Provider } from 'mobx-react';
import store from './_store/RootStore';
import SearchInput from './components/SearchInput';
import TyposItem from './components/TyposItem';
const Search = ({ type, location, navigationType, onBeforeSearchRoute }) => {
    return (
        <Provider store={store}>
            <SearchInput
                type={type}
                location={location}
                navigationType={navigationType}
                onBeforeSearchRoute={onBeforeSearchRoute}
            />
        </Provider>
    );
};
Search.store = store;
Search.Typos = TyposItem;

export default Search;
