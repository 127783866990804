import { Alert } from 'antd';
import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../constants';

export const StyledAlert = styled(Alert)`
    align-items: center;
    color: ${({ $theme }) => $theme.colors.primaryText};
    background-color: ${({ $theme }) => $theme.colors.canvas};
    border: 2px solid ${({ $theme }) => $theme.colors.primaryText};
    border-radius: unset;
    cursor: pointer;

    ${({ $marginBottom }) =>
        $marginBottom &&
        css`
            margin-bottom: ${$marginBottom};
        `};

    .ant-alert-description {
        font: ${({ $theme }) => $theme.fonts.badgeMedium};
    }

    &.ant-alert-with-description {
        padding: 15px 20px;
    }

    &.ant-alert-info .ant-alert-icon {
        color: ${({ $theme }) => $theme.colors.primaryText};
    }

    svg {
        width: 22px;
        height: 22px;
        vertical-align: middle;
    }

    .ant-alert-action {
        margin-left: 6px;
    }

    ${({ $isFixedOnMobile, $theme }) =>
        $isFixedOnMobile &&
        css`
            @media (${GRID_DEVICES_MAX.sm}) {
                position: fixed;
                bottom: 0;
                left: 0;
                margin: 0;
                z-index: 10;
                border-top: 1px solid ${$theme.colors.disabledState};
                border-bottom: 1px solid ${$theme.colors.disabledState};
                border-left: 0;
                border-right: 0;
                width: 100%;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 32px;
                height: 100%;
                background: ${$theme.colors.background};
                width: 1px;
            }

            svg {
                @media (${GRID_DEVICES_MAX.sm}) {
                    width: 16px;
                    height: 16px;
                }
            }

            .ant-alert-description {
                @media (${GRID_DEVICES_MAX.sm}) {
                    font: ${({ $theme }) => $theme.fonts.descriptor2};
                    margin-right: 10px;
                }
            }

            &.ant-alert-with-description {
                @media (${GRID_DEVICES_MAX.sm}) {
                    padding: 8px;
                }
            }
        `};
`;
