import React from 'react';
import { CloseXIcon } from '../../../icons';
import { Button } from '../Button';

export const Chip = ({ onClose, children, cantClose, ...rest }) => {
    return (
        <Button size='small' {...rest}>
            <span>{children}</span>
            {/* {!cantClose && <CloseIcon onClick={onClose || null} />} */}
            {!cantClose && <CloseXIcon onClick={onClose || null} />}
        </Button>
    );
};

export default Chip;
