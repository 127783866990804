import { Col } from 'antd';
import styled from 'styled-components';
import { GRID_DEVICES_MAX } from '../../../../../../constants';
import { Button, Row } from '../../../../../UiLibrary';

export const StyledButton = styled(Button)`
    && {
        height: 24px;
        width: 24px;
        padding: 0;
        margin-left: 12px;
        border: 0;

        &:disabled {
            opacity: 0;
            pointer-events: none;
        }

        &:focus,
        &:active,
        &:hover,
        &:disabled {
            border: 0;
        }

        .anticon {
            width: 20px;
            height: 20px;

            > svg {
                font-size: 20px;
                color: ${({ $theme }) => $theme.colors.disabledState};
            }
        }
    }
`;

export const StyledCol = styled(Col)`
    display: none;
`;

export const StyledWrapper = styled(Row)`
    .ant-col {
        width: 100%;
    }
`;

export const StyledNum = styled.div`
    padding: 6px 12px;
    border-radius: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${({ $checked, $theme }) => ($checked ? $theme.colors.tartRed : $theme.colors.secondaryText)};
    background: ${({ $checked, $theme }) => ($checked ? $theme.colors.primary : $theme.colors.background)};
`;

export const StyledRow = styled(Row)`
    && {
        padding-top: 8px;
        padding-bottom: 8px;

        * {
            cursor: pointer;
        }

        label {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: space-between;

            @media (${GRID_DEVICES_MAX.md}) {
                height: 32px;
            }
        }

        &:hover {
            .ant-checkbox-checked {
                &:after {
                    border-color: ${({ $theme }) => $theme.colors.primary}!important;
                }
                .ant-checkbox-inner {
                    background-color: ${({ $theme }) => $theme.colors.primary}!important;
                }
            }
            .ant-checkbox-wrapper .ant-checkbox {
                .ant-checkbox-input,
                .ant-checkbox-inner {
                    border-color: ${({ $theme }) => $theme.colors.primary};
                }
            }
        }
    }
`;
