import React, { Fragment, useState } from 'react';
import { CloseXIcon } from '../../../../../../../../../../../../../../../../icons';
import { roundToLocaleString } from '../../../../../../../../../../../../../../../../shared';
import { useSimpleInterface } from '../../../../../../../../../../../../../../../../utils';
import { Popover } from '../../../../../../../../../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../../../../../../../../../constants';
import { StyledContent, StyledKliIcon, StyledPopover, StyledValue } from './styled';

const PopoverPoints = ({ children, points, showUsdOnly, ...props }) => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    const [clicked, setClicked] = useState(false);
    const [hovered, setHovered] = useState(false);

    const handleHoverChange = (open) => {
        setHovered(open);
        setClicked(false);
    };
    const handleClickChange = (open) => {
        setHovered(false);
        setClicked(open);
    };
    return (
        <Fragment>
            <StyledPopover />
            <Popover
                open={simple ? clicked : hovered}
                onOpenChange={simple ? handleClickChange : handleHoverChange}
                overlayClassName='popover-points'
                trigger={simple ? 'click' : 'hover'}
                getPopupContainer={(node) => node}
                type='primary'
                content={
                    <StyledContent type='body2' color='canvas'>
                        {!showUsdOnly && `${points} points = `}
                        <StyledValue>
                            {(points / 100) % 1 ? roundToLocaleString(points / 100) : points / 100} USD
                        </StyledValue>
                        {simple && (
                            <StyledKliIcon
                                size='12px'
                                onClick={() => handleClickChange(false)}
                                component={() => <CloseXIcon />}
                            />
                        )}
                    </StyledContent>
                }
                {...props}
            >
                {children}
            </Popover>
        </Fragment>
    );
};

export default PopoverPoints;
