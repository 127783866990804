import styled from 'styled-components';

export const StyledSuggestionPart = styled.span`
    && {
        margin: 0;
        white-space: pre;
    }

    > b {
        font-weight: 700;
    }
`;
