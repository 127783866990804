import { getParent, getSnapshot, types } from 'mobx-state-tree';
import { arrayIncludes } from '../utils';

const VariantModel = types.model({
    externalId: types.number,
    options: types.array(
        types.model({
            position: types.number,
            value: types.string
        })
    ),
    title: types.string,
    image: types.maybeNull(types.number),
    quantity: types.number,
    strictQuantity: types.maybeNull(types.number),
    price: types.number,
    originalPrice: types.maybeNull(types.number),
    inCart: types.optional(types.boolean, false),
    cashbackPrice: types.maybeNull(types.number), // For Distribution Partners only
    cashbackType: types.maybeNull(types.string), // For Distribution Partners only
    cashbackDetails: types.maybeNull(
        types.array(
            types.model({
                // For Distribution Partners only
                name: types.string,
                value: types.number
            })
        )
    ),
    pointsDetails: types.maybeNull(
        types.model({
            maxPoints: types.number,
            isWelcome: types.boolean,
            list: types.array(
                types.model({
                    type: types.string,
                    value: types.number
                })
            )
        })
    ),
    rewards: types.maybe(
        types.maybeNull(
            types.array(
                types.model({
                    _id: types.maybe(types.maybeNull(types.string)),
                    title: types.maybe(types.maybeNull(types.string)),
                    description: types.maybe(types.maybeNull(types.string)),
                    image: types.maybe(types.maybeNull(types.string)),
                    expireAt: types.maybe(types.maybeNull(types.string)),
                    appliedTo: types.maybe(types.maybeNull(types.string)),
                    amount: types.maybe(types.maybeNull(types.number)),
                    rewardType: types.maybe(types.maybeNull(types.string)),
                    value: types.maybe(types.maybeNull(types.number))
                })
            )
        )
    ),
    priceDetails: types.maybe(
        types.maybeNull(
            types.model({
                originalPrice: types.maybeNull(types.number),
                rewardsPrice: types.maybeNull(types.number),
                rewardsPercent: types.maybeNull(types.number),
                youPayPrice: types.maybeNull(types.number)
            })
        )
    )
});

export const VariantsStore = types
    .model('VariantsStore', {
        list: types.array(VariantModel),
        qty: 1
    })
    .views((self) => ({
        get product() {
            return getParent(self);
        },
        get originalPrice() {
            if (
                self.currentVariantData?.priceDetails?.originalPrice ||
                self.currentVariantData?.priceDetails?.originalPrice === 0
            ) {
                return self.currentVariantData?.priceDetails?.originalPrice;
            }
            return self.currentVariantData?.originalPrice;
        },
        get youPayPrice() {
            if (
                self.currentVariantData?.priceDetails?.youPayPrice ||
                self.currentVariantData?.priceDetails?.youPayPrice === 0
            ) {
                return self.currentVariantData?.priceDetails?.youPayPrice;
            }
            return self.currentVariantData?.price;
        },
        get currentVariantData() {
            const currentVariant = self.currentVariant;
            const data = [...getSnapshot(self.list)];
            return data.find((variant) => variant.externalId === currentVariant.externalId);
        },
        get selectedVariant() {
            if (self.product.options.allOptionsSelected) {
                const selectedOptions = self.product.options.selectedOptions;
                const index = self.list.findIndex((variant) => {
                    return arrayIncludes(variant.options, selectedOptions);
                });
                return self.list[index];
            }
            return null;
        },
        get currentVariant() {
            if (self.selectedVariant) {
                return self.selectedVariant;
            }
            return self.list[0];
        },
        get minQty() {
            if (self.currentVariant.strictQuantity) {
                return self.currentVariant.strictQuantity;
            }

            return 1;
        },
        get maxQty() {
            if (self.currentVariant.strictQuantity) {
                return self.currentVariant.strictQuantity;
            }

            let variantQuantity = 0;
            if (self.selectedVariant) {
                variantQuantity = self.selectedVariant.quantity;
            } else {
                variantQuantity = Math.max(...self.list.map(({ quantity }) => quantity));
            }
            return Math.max(variantQuantity, 1);
        }
    }))
    .actions((self) => ({
        setQty(value) {
            self.qty = value;
        },
        incrementQty() {
            self.qty += 1;
            if (self.qty > self.maxQty) {
                self.qty = self.maxQty;
            }
        },
        decrementQty() {
            self.qty -= 1;
            if (self.qty < 1) {
                self.qty = 1;
            }
        },
        setVariants(variants) {
            self.list = variants;
        },
        checkStrictQty() {
            if (self.currentVariant.strictQuantity) {
                self.setQty(self.currentVariant.strictQuantity);
            }
        }
    }));
