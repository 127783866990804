import { createIframe } from '../elements';

export const loadGA4 = ({ src }, options = {}) => {
    const scriptUrl = new URL(src);
    const { id, queryParams = {} } = options;

    for (const [key, value] of Object.entries(queryParams)) {
        scriptUrl.searchParams.set(key, value);
    }

    createIframe({
        id: id || 'kl_ga4_iframe',
        src: scriptUrl.toString()
    });
};
