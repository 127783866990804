import styled, { css } from 'styled-components/macro';
import { GRID_POINTS } from '../../../../../../../../../constants';

export default styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.md
            ? css`
                  padding-bottom: 30px;
              `
            : ''};
`;
