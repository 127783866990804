import styled from 'styled-components';
import { $white } from '../../../../styles/colors';

export const StyledWrapper = styled.div`
    position: absolute;
    top: 24px;
    left: 62px;
    width: calc(100% - 205px);
    height: calc(100% - 27px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background: ${$white};
    z-index: 3;
`;
