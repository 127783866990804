import styled from 'styled-components/macro';
import Icon from '../../../../../../../UiLibrary/Icon';
import { COLORS } from '../../../../../../constants';

export const Styles = styled.div`
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    background-color: ${({ $type }) => ($type === 'error' ? COLORS.$primaryBackground : COLORS.$infoBackground)};
`;

export const StyledHead = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
`;

export const StyledIcon = styled(Icon)`
    margin-right: 17px;
    padding: 0;
`;

export const StyledItemsWrap = styled.div`
    padding-left: 40px;
`;

export const StyledItemWrap = styled.div`
    &:not(:last-child) {
        margin-bottom: 24px;
    }
`;
