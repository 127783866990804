import React from 'react';
import { Item } from '../../styles';

export const CheckedFiltersList = ({ list, onRemove }) =>
    list.map((item) => (
        <Item
            type='secondary'
            key={item.key}
            onClose={() =>
                onRemove({
                    ...item,
                    checked: false
                })
            }
        >
            {item.name}
        </Item>
    ));
