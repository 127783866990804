import { css } from 'styled-components';
import styled from 'styled-components/macro';

export default styled.div`
    display: flex;
    flex-direction: ${({ $direction }) => $direction || 'row'};
    max-width: 550px;

    ${({ $simple }) =>
        $simple
            ? css`
                  max-width: 100%;
                  padding: 22px 24px;
              `
            : ''};
`;
