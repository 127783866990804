import { roundToLocaleString } from '../../../shared';

const MIN_DISCOUNT_PERCENTAGE_TO_SHOW = 3;

export const getPrices = (prices) => {
    const responsePrice = {
        fullTotal: prices.fullTotal,
        originalDiscount: 0,
        subTotal: `$${roundToLocaleString(prices.subTotal)}`,
        discounts: prices.discounts,
        discountPercentage: '',
        showOriginalPrice: false
    };

    if (prices.originalSubTotal && prices.originalSubTotal > prices.fullTotal) {
        responsePrice.showOriginalPrice = true;
        responsePrice.originalSubTotal = `$${roundToLocaleString(prices.originalSubTotal)}`;
        responsePrice.originalDiscount = prices.originalSubTotal - prices.subTotal;
        responsePrice.discounts += responsePrice.originalDiscount;
    } else {
        responsePrice.originalSubTotal = `$${roundToLocaleString(prices.subTotal)}`;
    }

    if (responsePrice.discounts) {
        const percentage =
            (Number(responsePrice.discounts) / Number(prices.total + responsePrice.originalDiscount)) * 100;
        const showDiscountPercentage = percentage > MIN_DISCOUNT_PERCENTAGE_TO_SHOW;
        responsePrice.discountPercentage = showDiscountPercentage ? `(${roundToLocaleString(percentage, 0)}% off)` : '';

        responsePrice.discounts = `-$${roundToLocaleString(responsePrice.discounts)}`;
    }

    responsePrice.fullTotal = responsePrice.fullTotal === 0 ? 'Free' : `$${roundToLocaleString(prices.fullTotal)}`;
    return responsePrice;
};
