import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Image } from '../../../../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../../../../constants';

export default styled(Image)`
    width: 100%;
    object-fit: contain;

    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.sm
            ? css`
                  max-height: 400px;
              `
            : ''};

    ${({ $appWidth }) =>
        $appWidth <= GRID_POINTS.xs
            ? css`
                  max-height: 238px;
              `
            : ''};
`;
