import React, { Fragment, useContext, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS, GRID_POINTS } from '../../../../../../constants';
import { useScrollable } from '../../../../../../hooks';
import { useSimpleInterface } from '../../../../../../utils';
import { Divider, Icon, KliThemeContext, Typography } from '../../../../../UiLibrary';
import BestSellers from './components/BestSellers';
import { StyledContent, StyledImage, StyledImageWrapper } from './styled';

const CategoryPageHeader = inject('store')(
    observer(({ store, onBuy }) => {
        const { selectedTaxonomyData, pending } = store;
        const theme = useContext(KliThemeContext);
        const simple = useSimpleInterface(GRID_POINTS.md);
        const ref = useRef(null);

        useScrollable(ref, null, () => null);
        if (!pending && !selectedTaxonomyData) return null;

        return (
            <div
                style={BOTTOM_INDENTS.M}
                id='shop-promotions-container' /* this id is required to avoid initial scrolling to content. Couldn't be resolved by another way. Need to rework Layout and scrollToPage logic */
            >
                {pending ? (
                    <Icon.Loader />
                ) : (
                    <Fragment>
                        {selectedTaxonomyData?.imgSrc ? (
                            <StyledImageWrapper>
                                <StyledImage src={selectedTaxonomyData?.imgSrc} alt={selectedTaxonomyData?.title} />
                            </StyledImageWrapper>
                        ) : (
                            <div style={BOTTOM_INDENTS.S} />
                        )}
                        <StyledContent>
                            <Typography.Text type={simple ? 'headline1' : 'em0'} align={simple ? 'center' : 'left'}>
                                {selectedTaxonomyData?.title}
                            </Typography.Text>
                            <Divider height='1px' color={theme.colors.lines} />
                            <BestSellers onBuy={onBuy} />
                        </StyledContent>
                    </Fragment>
                )}
            </div>
        );
    })
);

export default CategoryPageHeader;
