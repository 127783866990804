import styled from 'styled-components/macro';
import { Button } from '../../../../../../../UiLibrary/Button';
import { COLORS } from '../../../../../../constants';

export const Styles = styled.div`
    width: 100%;
`;

export const HeadStyles = styled.div`
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid ${COLORS.$background};
    margin-bottom: 20px;
`;

export const BodyStyles = styled.div`
    padding: 0 24px;
`;

export const StyledButton = styled(Button)`
    && {
        padding: 0;
        margin-right: 16px;
        margin-left: 20px;

        .anticon {
            display: flex;
            align-items: center;
            margin-right: 12px;
        }
    }
`;
