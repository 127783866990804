import { css } from 'styled-components';
import { getColor } from './utils';

export const sharedInputStyles = css`
    ${({ $theme, status, $noValidationBorders }) => {
        const borderColor = getColor({
            $theme,
            status: $noValidationBorders ? null : status,
            isBorder: true
        });
        const borderColorError = $noValidationBorders ? 'transparent' : $theme.colors.error;
        const color = getColor({ $theme, status });

        return css`
            border: 2px solid ${borderColor};
            border-radius: unset;
            padding: 22px 24px 10px 24px;
            font: ${({ $theme }) => $theme.fonts.descriptor1};
            outline: none;
            transition: ${({ $theme }) => $theme.transition.default};
            color: ${({ $theme }) => $theme.colors.secondaryText};

            &[aria-invalid='true'] + .ant-input-suffix {
                color: ${getColor({ $theme, status: 'error' })};
            }
            &[aria-invalid='true'],
            &[aria-invalid='true']:hover {
                border-color: ${borderColorError};
            }

            &:hover {
                border-color: ${borderColor};
                border-right-width: 2px;
            }

            .ant-input-focused,
            &.ant-input:focus {
                background-color: ${({ $theme }) => $theme.colors.canvas};
                border-color: ${({ $theme }) => $theme.colors.primaryText};
                box-shadow: none;
                border-right-width: 2px;
            }

            &.ant-input {
                background-color: ${({ $theme }) => $theme.colors.background};

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    margin: 0;
                    box-shadow: none;
                    white-space: normal;
                    appearance: none;
                }
            }

            &.ant-input-lg {
                padding: 26px 24px 14px 24px;
            }

            &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
            &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
                border-color: ${({ $theme }) => $theme.colors.primaryText};
                box-shadow: none;
                border-right-width: 2px;
            }

            &.ant-input-affix-wrapper {
                position: relative;
                padding: 22px 64px 10px 24px;
                background-color: ${({ $theme }) => $theme.colors.background};
                border: 2px solid ${borderColor};
                border-radius: unset;

                ${({ prefix }) =>
                    prefix &&
                    css`
                        padding-left: 60px;
                    `};

                .ant-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 22px 64px 10px 24px;
                    font: ${({ $theme }) => $theme.fonts.descriptor1};
                    color: ${({ $theme }) => $theme.colors.secondaryText};
                    background-color: ${({ $theme }) => $theme.colors.background};

                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:active {
                        -webkit-text-fill-color: inherit;
                        -webkit-box-shadow: ${({ $theme }) =>
                            `0 0 0px 1000px ${$theme.colors.background} inset;`} !important;
                        transition: background-color 5000s ease-in-out 0s !important;
                    }

                    ${({ prefix }) =>
                        prefix &&
                        css`
                            padding-left: 60px;
                        `};
                }

                &.ant-input-affix-wrapper-lg {
                    padding: 26px 64px 14px 24px;

                    ${({ prefix }) =>
                        prefix &&
                        css`
                            padding-left: 60px;
                        `};
                }

                .ant-input-prefix {
                    position: absolute;
                    top: 0;
                    width: 24px;
                    height: 100%;
                    color: ${color};
                    font-size: 24px;
                    left: 18px;
                    margin-right: 0;
                }

                .ant-input-suffix {
                    position: absolute;
                    top: 0;
                    width: 24px;
                    height: 100%;
                    color: ${color};
                    font-size: 24px;
                    right: 18px;
                    margin-left: 0;
                }

                &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
                    border-color: ${borderColor};
                    border-right-width: 2px;
                }

                [aria-invalid='true'] + .ant-input-suffix {
                    color: ${getColor({ $theme, status: 'error' })};
                }
            }

            &.ant-input-affix-wrapper,
            &.ant-input-affix-wrapper-status-error {
                &.ant-input-affix-wrapper-focused,
                &.ant-input-affix-wrapper-focused:hover {
                    && {
                        border-color: ${({ $theme }) => $theme.colors.primaryText};
                        background-color: ${({ $theme }) => $theme.colors.canvas};
                        border-width: 2px;
                        box-shadow: none;

                        .ant-input {
                            background-color: ${({ $theme }) => $theme.colors.canvas};
                        }

                        .ant-input-clear-icon *,
                        .ant-input-prefix * {
                            color: ${({ $theme }) => $theme.colors.secondaryText};
                        }

                        .ant-input-suffix {
                            display: none;
                        }
                    }
                }
            }

            &.ant-input-affix-wrapper-status-error,
            &:has([aria-invalid='true']) {
                && {
                    border-color: ${borderColorError};
                    background-color: ${({ $theme }) => $theme.colors.background};

                    &:not(.ant-input-affix-wrapper-disabled):hover {
                        border-right-width: 2px;
                        background-color: ${({ $theme }) => $theme.colors.background};
                        border-color: ${borderColorError};
                    }
                }

                &.ant-input-affix-wrapper-focused,
                &.ant-input-affix-wrapper-focused:hover {
                    .ant-input-prefix,
                    .ant-input-suffix {
                        color: ${({ $theme }) => $theme.colors.secondaryText};

                        .ant-input-clear-icon * {
                            color: ${({ $theme }) => $theme.colors.secondaryText};
                        }
                    }
                }
            }

            &.ant-input[disabled],
            &.ant-input-affix-wrapper-disabled {
                border-color: transparent;
                opacity: ${({ $nonOpacityOnDisabled }) => ($nonOpacityOnDisabled ? 1 : 0.5)};
            }

            .ant-input-clear-icon * {
                color: ${color};
            }

            &.ant-input:-webkit-autofill,
            &.ant-input:-webkit-autofill:hover,
            &.ant-input:-webkit-autofill:focus,
            &.ant-input:-webkit-autofill:active {
                -webkit-text-fill-color: inherit;
                -webkit-box-shadow: ${({ $theme }) => `0 0 0px 1000px ${$theme.colors.background} inset;`} !important;
                transition: background-color 5000s ease-in-out 0s !important;
            }
        `;
    }};
`;
