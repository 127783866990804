import React, { useContext } from 'react';
import { BackArrowIcon, EditIcon } from '../../../icons';
import Avatar from '../Avatar';
import { Button } from '../Button';
import { KliIcon } from '../KliIcon';
import { KliThemeContext } from '../KliThemeProvider';
import Typography from '../Typography';
import { AvatarWrap, BackIconWrap, TitlesWrap, Wrap } from './KliHero.styles';

export const KliHero = ({
    avatar,
    title,
    titleComponent,
    subTitle,
    editText,
    simple = true,
    icon = <EditIcon />,
    backIcon = <BackArrowIcon />,
    withoutBorders = false,
    titleOnTop = false,
    textAlign,
    backgroundColor,
    color,
    onBack,
    onEdit,
    ...rest
}) => {
    const themeContext = useContext(KliThemeContext);
    const Title = titleComponent || Typography.Title;

    return (
        <Wrap
            theme={themeContext}
            withoutBorders={withoutBorders}
            withBackIcon={!!onBack}
            simple={simple}
            backgroundColor={backgroundColor}
            color={color}
            {...rest}
        >
            <AvatarWrap>
                {!!onBack && simple && (
                    <BackIconWrap theme={simple}>
                        <Button style={{ padding: 0 }} type='unstyled' onClick={onBack}>
                            <KliIcon color={color} size='24px'>
                                {backIcon}
                            </KliIcon>
                        </Button>
                    </BackIconWrap>
                )}

                {avatar && <Avatar className='avatar' src={avatar} />}

                {(subTitle || title) && (
                    <div>
                        <TitlesWrap titleOnTop={titleOnTop} textAlign={textAlign}>
                            {subTitle && (
                                <Typography.Text type='systemBody2' align={textAlign}>
                                    {subTitle}
                                </Typography.Text>
                            )}
                            {title && <Title align={textAlign}>{title}</Title>}
                        </TitlesWrap>
                    </div>
                )}
            </AvatarWrap>

            {(!!onBack || !!onEdit) && (
                <div className='button-wrap'>
                    {!!onBack && !simple && (
                        <BackIconWrap theme={simple}>
                            <Button style={{ padding: 0 }} type='unstyled' onClick={onBack}>
                                <KliIcon color={color} size='24px'>
                                    {backIcon}
                                </KliIcon>
                            </Button>
                        </BackIconWrap>
                    )}

                    {!!onEdit && (
                        <Button type='unstyled' style={{ padding: '14px 0' }} onClick={onEdit}>
                            {!!icon && <KliIcon color={color}>{icon}</KliIcon>}
                            {editText && (
                                <Typography.Text color={color} type='badgeSmall' as='span'>
                                    {editText}
                                </Typography.Text>
                            )}
                        </Button>
                    )}
                </div>
            )}
        </Wrap>
    );
};
