import styled, { css } from 'styled-components';
import { GRID_DEVICES_MAX } from '../../constants';
import { KliCard, Typography } from '../UiLibrary';

export const PromotionStyles = styled(KliCard)`
    position: relative;
    width: 100%;
    cursor: pointer;

    ${({ $height }) =>
        $height &&
        css`
            height: ${$height};
        `};

    @media (${GRID_DEVICES_MAX.sm}) {
        &:hover {
            box-shadow: none;
        }

        flex-direction: column;
        padding: 0;

        > div {
            width: 100%;

            &:first-child {
                padding: 0;
            }
        }
    }
`;

export const StyledPromotionImgWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 150px;
    margin-bottom: 16px;

    img.ant-image-img {
        display: block;
        object-fit: contain;
    }

    > div {
        position: relative;

        > div {
            display: inline-block;
        }
    }

    @media (${GRID_DEVICES_MAX.md}) {
        width: 100%;
        > div {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
`;

export const StyledTitle = styled(Typography.Title)`
    @media (${GRID_DEVICES_MAX.md}) {
        word-break: break-word;
    }
`;
