import { keyframes } from 'styled-components';

export const moveSlideshow = keyframes`
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
`;
