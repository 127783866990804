import { getParent, types } from 'mobx-state-tree';
import { ROUTE_PATHS } from '../shared/constants';

export const CHECKOUT_PLACE_ORDER_SUCCESS_MESSAGE = 'checkoutPlaceOrderSuccessful';
export const CHECKOUT_CONTINUE_SHOPPING_MESSAGE = 'checkoutContinueShoppingClick';

export const EventListenerStore = types
    .model('EventListenerStore', {})
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const addEventListener = ({ eventName, eventHandler }) => window.addEventListener(eventName, eventHandler);

        const removeEventListener = ({ eventName, eventHandler }) =>
            window.removeEventListener(eventName, eventHandler);

        const orderPlaced = (data) => {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('ch_order_code', data?.orderCode);
            self.root.routeNavigate(
                {
                    pathname: ROUTE_PATHS.thankYouPage(),
                    search: searchParams.toString()
                },
                {
                    state: self.root.routeLocation.state
                }
            );
        };

        const continueShopping = () => {
            self.root.routeNavigate({
                pathname: ROUTE_PATHS.marketplace(),
                search: self.root.routeLocation.search
            });
        };

        const getEventHandlerMapper = () => ({
            [CHECKOUT_PLACE_ORDER_SUCCESS_MESSAGE]: orderPlaced,
            [CHECKOUT_CONTINUE_SHOPPING_MESSAGE]: continueShopping
        });

        const onEventMessage = ({ data, action }) => {
            const eventMapper = getEventHandlerMapper();
            const handler = eventMapper[action];
            if (handler) {
                handler(data);
            }
        };

        return {
            addEventListener,
            removeEventListener,
            onEventMessage
        };
    });
