import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../constants';
import { KliHero } from '../../../../../../../UiLibrary';
import { ReviewsContainer } from '../ReviewsContainer';
import { Styles } from './styles';

export const ReviewsPage = inject('store')(
    observer(({ store }) => {
        const headRef = useRef(null);

        useEffect(() => {
            if (headRef) {
                headRef.current.scrollIntoView();
            }
        }, [headRef]);

        return (
            <Styles ref={headRef}>
                <KliHero
                    simple={false}
                    title='Reviews'
                    subTitle={store.product.getData('title', '')}
                    withoutBorders
                    style={{ padding: 0, ...BOTTOM_INDENTS.M }}
                    onBack={store.reviews.backToPDP}
                />
                <ReviewsContainer loadMoreEnabled scrollReviewImages />
            </Styles>
        );
    })
);
