import React, { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { SearchIcon } from '../../../../icons';
import { useSearch } from '../../hooks/useSearch';
import AutoSuggestions from '../AutoSuggestions';
import SearchButtons from '../SearchButtons';
import { StyledIcon, StyledInput, Styles } from './styled';

const SearchInput = inject('store')(
    observer(({ type, location, navigationType, store }) => {
        const ref = useRef(null);
        const inputValue = ref?.current?.value;
        const {
            onBeforeSearch,
            shouldClearOnBackArrowClick,
            search,
            onSearchChange,
            setSearch,
            getAutoSuggestions,
            setAutoSuggestions
        } = store;
        const { value, setValue, debounce, handleClear, onSubmit } = useSearch({
            shouldClearOnBackArrowClick,
            onBeforeSearch,
            search,
            onSearchChange,
            setSearch,
            getAutoSuggestions,
            setAutoSuggestions,
            location,
            navigationType
        });

        return (
            <Styles $type={type}>
                <StyledInput
                    ref={ref}
                    placeholder='Search'
                    value={value}
                    withoutClearButton
                    withoutBorderColorsOnValidate
                    onChange={({ target: { value: newValue } }) => debounce(newValue)}
                    onKeyPress={({ key }) => key === 'Enter' && onSubmit({ search: inputValue })}
                    data-e2e='search-input'
                >
                    <StyledIcon size='24px' component={() => <SearchIcon />} />
                </StyledInput>
                <SearchButtons
                    inputValue={inputValue}
                    value={value}
                    inputRef={ref}
                    onClear={handleClear}
                    onSubmit={onSubmit}
                />
                <AutoSuggestions searchValue={inputValue} onSetSearchValue={setValue} onSubmitSearch={onSubmit} />
            </Styles>
        );
    })
);

export default SearchInput;
