import { css, keyframes } from 'styled-components';
import styled from 'styled-components/macro';
import { GRID_DEVICES_CUSTOM_MAX, GRID_DEVICES_CUSTOM_MIN, GRID_DEVICES_MAX } from '../../constants';

const animation$ = keyframes`
    from {
        opacity: 0.25;
        transform: translate3d(0, 0, 0) scale(0.99);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
`;

export const Styles = styled.div`
    display: flex;
    position: relative;
    padding: 10px;
    animation: ${animation$} 0.3s ease-in-out;
    will-change: transform, opcity;

    &:hover {
        z-index: 1;
    }

    @media (${GRID_DEVICES_MAX.sm}) {
        padding: 10px 8px;
    }

    ${({ $fullHeight }) =>
        $fullHeight &&
        css`
            height: 100%;
        `};

    ${({ $fullWidth }) =>
        !$fullWidth &&
        css`
            @media (${GRID_DEVICES_CUSTOM_MAX.small}) {
                width: ${({ $inSlider }) => ($inSlider ? 100 : 50)}%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.small}) {
                width: 50%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.medium}) {
                width: ${({ $inSlider }) => ($inSlider ? 100 : 50)}%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.large}) {
                width: 50%;
            }

            @media (${GRID_DEVICES_CUSTOM_MIN.biggest}) {
                width: 33%;
            }
        `};
`;
