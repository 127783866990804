import styled, { css } from 'styled-components';

export const KliButtonCircleStyled = styled.button`
    display: flex;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    outline: transparent;
    height: 40px;
    width: 40px;
    font: ${({ theme }) => theme.fonts.badgeMedium};
    border-radius: 50%;
    transition: ${({ theme }) => theme.transition.default};
    will-change: outline, color, bacground-color;

    // Default states;
    :disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    :focus:not(:hover) {
        box-shadow: 0 0 0 6px ${({ theme }) => theme.colors.lines};
    }

    // Sizes;
    ${(props) =>
        props.large
            ? css`
                  height: 56px;
                  width: 56px;
                  font: ${({ theme }) => theme.fonts.badgeLarge};
              `
            : ''} // Primary styles;
  ${(props) =>
      props.primary
          ? css`
      border: 2px solid ${({ theme }) => theme.colors.primaryText};
      color: ${({ theme }) => theme.colors.canvas};
      background-color: ${({ theme }) => theme.colors.primaryText};

      :hover:not(:disabled):not(:active) {
        color: ${({ theme }) => theme.colors.canvas};
        background-color: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.primary};
        box-shadow: ${({ theme }) => theme.shadows.primary};
        cursor: pointer;
      }

      :active {
        color: ${({ theme }) => theme.colors.canvas};
        background-color: ${({ theme }) => theme.colors.primaryText};
    `
          : ''} // Secondary styles
  ${(props) =>
      props.secondary
          ? css`
                border: 2px solid ${({ theme }) => theme.colors.primaryText};
                color: ${({ theme }) => theme.colors.primaryText};
                background-color: transparent;

                :hover:not(:disabled):not(:active) {
                    color: ${({ theme }) => theme.colors.primary};
                    border-color: ${({ theme }) => theme.colors.primary};
                    cursor: pointer;
                }

                :active {
                    color: ${({ theme }) => theme.colors.primaryText};
                    background-color: transparent;
                    cursor: pointer;
                }
            `
          : ''}
`;
