import React, { useCallback } from 'react';
import { useModal } from '../../../../../UiLibrary';
import { CancelOrderModal } from './index';

export const useCancelOrderModal = (simple, onCancel) => {
    const { setModal, hideModal } = useModal();

    const onModalClose = useCallback(() => {
        hideModal();

        if (onCancel !== undefined) {
            onCancel();
        }
    }, [hideModal, onCancel]);

    return useCallback(() => {
        setModal(<CancelOrderModal simple={simple} onModalClose={onModalClose} />, {
            closable: false,
            fullscreen: simple
        });
    }, [setModal, onModalClose, simple]);
};
