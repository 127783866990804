import styled from 'styled-components';
import { Icon } from '../../../../../../../../../../../UiLibrary';

export const StyledErrorField = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;

export const StyledIcon = styled(Icon)`
    margin: 0 16px 0 0;
`;
