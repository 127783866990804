import React, { useMemo } from 'react';
import ArrowButton from '../components/ArrowButton';

export const useSliderSettings = (sliderSettings) =>
    useMemo(
        () => ({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0,
            prevArrow: <ArrowButton />,
            nextArrow: <ArrowButton type='next' />,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 668,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true,
                        arrows: true
                    }
                }
            ],
            ...sliderSettings
        }),
        [sliderSettings]
    );
