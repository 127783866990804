export const getFontByThemeAndLevel = (theme, level) => {
    switch (level) {
        default:
            return theme.fonts.headline1;
        case 2:
            return theme.fonts.headline2;
        case 3:
            return theme.fonts.headline3;
    }
};
