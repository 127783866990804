import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../../../../../constants';
import { TimeIcon } from '../../../../../../../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../../../../../../../UiLibrary';
import { getTimeLeft, msToDisplayFormat } from '../../../../../../../../../../../../utils';
import { AnimatedTime } from './animatedTime';
import { BodyStyles, Styles, Text } from './styles';

export const Timer = observer(
    ({
        simple,
        endDate,
        speed = 1000,
        isTimerPaused,
        color = '#ffffff',
        onTimeLeft,
        type = 'body2',
        showIcon = true
    }) => {
        const [timeLeft, setTimeLeft] = useState();
        const timer = useRef(null);

        useEffect(() => {
            setTimeLeft(getTimeLeft(endDate));
        }, [setTimeLeft, endDate]);

        useEffect(() => {
            timer.current = setTimeout(() => {
                const newTimeLeft = getTimeLeft(endDate);

                if (!timeLeft && isTimerPaused) {
                    clearTimeout(timer.current);
                    return;
                }

                if (timeLeft && newTimeLeft > 0) {
                    setTimeLeft(newTimeLeft);
                }

                if (newTimeLeft <= 0 && onTimeLeft) {
                    onTimeLeft();
                }

                clearTimeout(timer.current);
            }, speed);

            return () => clearTimeout(timer.current);
        }, [speed, timeLeft, setTimeLeft, endDate, onTimeLeft, isTimerPaused]);

        const getDisplayTime = useCallback(() => {
            return msToDisplayFormat(timeLeft);
        }, [timeLeft]);

        return (
            <Styles style={simple ? BOTTOM_INDENTS.SM : {}}>
                <Typography.Text type={type} color={color}>
                    This offer ends in
                </Typography.Text>
                <BodyStyles>
                    {showIcon && (
                        <Fragment>
                            <Icon color={color} component={() => <TimeIcon />} />
                            &nbsp;&nbsp;
                        </Fragment>
                    )}
                    <Text color={color} type={type}>
                        <AnimatedTime time={getDisplayTime()} />
                    </Text>
                </BodyStyles>
            </Styles>
        );
    }
);
