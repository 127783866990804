import React from 'react';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../../../../../../constants';
import { ReviewsStarRating } from '../../../../../ReviewStartRating';
import { Styles } from './styles';

export const ReviewsRating = inject('store')(
    observer(({ store }) => {
        const simple = store.view.isSimple(GRID_POINTS.sm);
        const rating = store.product.getData('rating');
        return (
            <Styles $simple={simple}>
                <ReviewsStarRating rate={rating} />
            </Styles>
        );
    })
);
