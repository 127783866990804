import { Icon } from '@klickly/front-packages';
import styled from 'styled-components';
import { $primaryText } from '../../constants';

export const KliLogo = styled.div`
    font-size: ${({ size }) => size || '32px'};
    width: fit-content;
    height: ${({ size }) => size || '32px'};
    color: ${({ color }) => color || $primaryText};
    cursor: pointer;
`;

export const StyledImg = styled.img`
    display: block;
    width: auto;
    height: 100%;
`;

export const StyledLoader = styled(Icon.Loader)`
    position: static;

    span {
        font-size: 24px;
    }
`;
