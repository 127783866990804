import {
    getFilterParamsFromUrl,
    getPriceRange,
    getUrlParamsFromFilter,
    MARKETPLACE,
    Taxonomy
} from '@klickly/front-packages';
import { flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import qs from 'qs';
import { kcpAPI } from '../shared/constants';
import { getAggregatedBrands, getAggregatedCategories, processBrandsList, scrollIntoView } from '../shared/utils';
import { getQueryParams } from '../shared/utils/getQueryParams';
import { models } from './constants';

const { getCategoriesArrayFromString } = Taxonomy;

export const MarketplaceStore = types
    .model('MarketplaceStore', {
        promotions: types.array(models.PromotionModel),
        pending: types.boolean,
        error: models.ErrorModel,
        brands: types.array(models.BrandModel),
        allBrands: types.array(models.BrandModel),
        brandsPending: types.boolean,
        brandsError: models.ErrorModel,
        categories: types.array(models.CategoryModel),
        price: models.PriceModel,
        sort: types.array(models.SortModel),
        search: types.maybe(types.string),
        query: types.maybe(models.QueryModel),
        page: types.number,
        nextPage: types.maybeNull(types.number),
        totalHits: types.maybeNull(types.number),
        totalShown: types.maybeNull(types.number),
        isOpenOnMobile: types.boolean,
        previousCashBack: types.maybeNull(types.number),
        cashBack: types.maybeNull(types.number),
        strategyInfo: types.maybeNull(models.StrategyInfoModel),
        strategyPending: types.boolean,
        bannerTextSizePending: true
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => ({
        setError(error) {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }

            return false;
        },
        clearStrategyInfo() {
            self.strategyInfo = null;
        },
        getStrategyInfoById: flow(function* getStrategyInfoById(strategyId) {
            self.strategyPending = true;
            try {
                self.strategyInfo = yield self.root.get(kcpAPI.marketplace.getStrategyInfoById(strategyId));
            } catch (e) {
                // do nothing
            } finally {
                self.strategyPending = false;
            }
        }),
        getStrategyInfoByName: flow(function* getStrategyInfoByName(strategyName) {
            self.strategyPending = true;
            try {
                self.strategyInfo = yield self.root.get(`${kcpAPI.marketplace.getStrategyInfo(strategyName)}`);
            } catch (e) {
                // do nothing
            } finally {
                self.strategyPending = false;
            }
        }),
        getPromotions: flow(function* getPromotions(isNextPage = false, customQueryParams = null, isNewSearch = false) {
            const { search } = getFilterParamsFromUrl();

            if (isNewSearch) {
                self.page = 1;
                self.nextPage = 1;
            }
            if ((self.page !== 1 && self.page === self.nextPage) || self.nextPage === null || self.pending) {
                return false;
            }

            const url = search ? kcpAPI.marketplace.search.basic : kcpAPI.marketplace.promotions.get;
            self.pending = true;
            try {
                const page = isNextPage ? self.nextPage : self.page;
                const queryParams = getUrlParamsFromFilter({ ...self.query, search: search }, { page });

                const publisher = self.root.publisherStore.publisherId;

                const queryParamsObj = getQueryParams(queryParams, customQueryParams, publisher);

                const data = yield self.root.get(`${url}?${qs.stringify(queryParamsObj)}`);
                self.page = data.page;
                self.promotions =
                    self.page === 1 || (!isNextPage && isNewSearch)
                        ? [...data.promotions]
                        : [...getSnapshot(self.promotions), ...data.promotions];
                self.nextPage = data.pagination.next;
                self.totalHits = data.totalHits;
                self.totalShown = data.totalShown;
                self.categories = getAggregatedCategories(data?.aggregations?.categories, self.categories);
                self.brands = getAggregatedBrands(data?.aggregations?.brands, self.brands, self.allBrands);

                if (!isNextPage && !customQueryParams) scrollIntoView();

                self.root.trackingStore.trackViewPromotions(data.promotions);

                return data?.suggestions;
            } catch (error) {
                self.setError(error);
            } finally {
                self.pending = false;
            }
            return self.promotions;
        }),
        getBrands: flow(function* getBrands() {
            const { search } = getFilterParamsFromUrl();
            self.brandsPending = true;
            try {
                const data = yield self.root.get(kcpAPI.marketplace.brands.get);
                const brands = processBrandsList(data);
                self.query.brands = brands
                    .map((brand) => ({ ...brand, checked: self.query.selectedBrands.includes(brand?.key) || false }))
                    .filter((brand) => (search ? brand.checked : brand));
                self.query.allBrands = [...brands];
                self.brandsPending = false;
            } catch (brandsError) {
                self.brandsError = brandsError;
                self.brandsPending = false;
            }
        }),
        updatePromotions: flow(function* updatePromotions(params = {}) {
            const { queryParams = {}, isNewSearch = false, searchValue = '' } = params;

            if (isNewSearch) self.promotions = [];
            self.root.pdpStore.closePDP(true);

            const search = qs.stringify(getUrlParamsFromFilter({ ...self.query, search: searchValue }, queryParams));
            self.root.routeNavigate({ pathname: window.location.pathname, search });
            self.getPromotions(false, null, isNewSearch);
            return yield self.getPromotions(false, null, isNewSearch);
        }),
        updateIsOpenOnMobile(isOpenOnMobile) {
            self.isOpenOnMobile = isOpenOnMobile;
        },
        updateCategory(data) {
            self.query.categories = self.query.categories.map((category) =>
                category.key === data.key ? { ...category, ...data } : { ...category }
            );
            self.updatePromotions({ isNewSearch: true });
        },
        updateBrand(data) {
            self.query.brands = self.query.brands.map((brand) =>
                brand.key === data.key ? { ...brand, ...data } : brand
            );
            self.updatePromotions({ isNewSearch: true });
        },
        updatePrice(data) {
            self.query.price = { ...data };
            self.updatePromotions({ isNewSearch: true });
        },
        updateQuery(data) {
            self.query = { ...self.query, ...data };
        },
        selectSorting(data) {
            self.query.sort = self.query.sort.map((sortItem) => ({ ...sortItem, checked: sortItem.key === data.key }));
            self.updatePromotions({ isNewSearch: true });
        },
        clearSearch() {
            self.query.q = '';
            self.updatePromotions({ isNewSearch: true });
        },
        clearAll(params) {
            const clearParams = {
                shouldClearSearch: false,
                shouldClearStrategy: false,
                isCategoryPage: false,
                sort: false,
                ...(typeof params === 'object' ? params : {})
            };
            self.query.price = { ...getPriceRange() };
            self.query.sort = clearParams.sort ? self.query.sort : [...MARKETPLACE.PROMOTIONS_SORTS_STATE];
            self.query.brands = self.query.allBrands.map((brand) => ({ ...brand, checked: false }));
            self.query.gt = clearParams.isCategoryPage ? getCategoriesArrayFromString(self.query.gt)?.[0] : '';

            if (clearParams.shouldClearStrategy) {
                self.query.strategy = '';
                self.query.strategyId = '';
            }
            self.updatePromotions({ isNewSearch: true });
        },
        setCashBackValue(cashBack) {
            self.previousCashBack = self.cashBack;
            self.cashBack = cashBack;
        },
        removeCashbackValue() {
            self.previousCashBack = null;
            self.cashBack = null;
        },
        setBannerSizePending(status) {
            self.bannerTextSizePending = status;
        },
        afterCreate() {
            const { ...query } = getFilterParamsFromUrl();
            self.query = query;
        }
    }));
