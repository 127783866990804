import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../utils';
import { OrdersData } from './components/OrdersData';
import { OrderSummary } from './components/orderSummary';
import { Topbar } from './components/Topbar';
import { WrapStyles } from './styles';

export const Sidebar = inject('store')(
    observer(({ store }) => {
        const simple = store.view.isSimple(GRID_POINTS.lg);

        return (
            <WrapStyles simple={simple}>
                {simple ? (
                    <Topbar>
                        <OrdersData />
                    </Topbar>
                ) : (
                    <Fragment>
                        <OrderSummary />
                        <OrdersData />
                    </Fragment>
                )}
            </WrapStyles>
        );
    })
);
