import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Button } from '../../../../../../../../../../../../UiLibrary';

export default styled(Button)`
    && {
        width: 100%;

        ${({ $smallerButtonText }) =>
            $smallerButtonText
                ? css`
                      padding-left: 10px;
                      padding-right: 10px;
                  `
                : ''};
    }
`;
