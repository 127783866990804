import styled, { css } from 'styled-components';
import Typography from '../../Typography';

export const CouponImgWrap = styled.div`
    margin-right: 16px;
`;

export const CouponDetails = styled.div``;

export const CouponTitle = styled(Typography.Title)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CouponDescription = styled(Typography.Text)`
    margin: 6px 0;

    ${({ $showFullDescription }) =>
        $showFullDescription
            ? css``
            : css`
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
              `};
`;
