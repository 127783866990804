import styled from 'styled-components';
import { Image, Typography } from '../../../../../UiLibrary';

export const Styles = styled.div`
    width: 100%;
    padding: ${({ simple }) => (simple ? '' : '0 24px')};
`;

export const CardBody = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

export const CardBodyLeft = styled.div`
    display: flex;
    align-items: flex-start;
    padding-right: 16px;
`;

export const StyledImage = styled(Image)`
    && {
        width: 76px;
        height: 76px;
        object-fit: contain;
        margin-right: 10px;
    }
`;

export const ProductTitle = styled(Typography.Title)`
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const Info = styled.div``;

export const Prices = styled.div`
    white-space: nowrap;
    text-align: right;
`;
