import React from 'react';
import { BOTTOM_INDENTS, Button, Form, Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import {
    StyledCard,
    StyledCardWrap,
    StyledCartNumber,
    StyledError,
    StyledIconWrap,
    StyledLast4,
    StyledLast4Wrap,
    StyledNumberWrap,
    StyledWrap
} from './styled';

export const CustomerCCVerificationModalContent = inject('store')(
    observer(({ store, pdpData, onClose }) => {
        const publisherName = store.publisherStore.publisherName;
        const { pending, creditCardVerification, handleBuyNow } = store.cartStore;
        const [form] = Form.useForm();

        const processBuyNow = async (email) => {
            const checkoutData = await handleBuyNow(pdpData?.payload);
            store.pdpStore.handleBuyNow({
                cartId: checkoutData.cartId,
                checkoutKey: checkoutData.checkoutKey,
                selectedVariant: pdpData.selectedVariant,
                promotionId: pdpData.promotionId,
                customerEmail: email
            });
        };

        const submitForm = async (data) => {
            const last4 = data.last4;
            const customerId = store.publisherStore.customerId;
            const { email } = await creditCardVerification({ last4, customerId });
            await processBuyNow(email);
            return onClose();
        };

        const onSkip = async () => {
            await processBuyNow();
            return onClose();
        };

        const formatter = (val) => val.replace(/\D/g, '');

        return (
            <StyledWrap>
                {pending ? <Icon.Loader /> : null}
                <Typography.Text type='body1' style={BOTTOM_INDENTS.XS}>
                    Save time during checkout by pre-filling your billing info.
                </Typography.Text>
                <Typography.Text type='body1' style={BOTTOM_INDENTS.M} data-e2e='prefill-modal_publisher-name'>
                    Simply enter the last 4 digits of the card attached to your {publisherName} account to verify your
                    identity.
                </Typography.Text>
                <Form form={form} onFinish={submitForm} validateTrigger={['onBlur']}>
                    <StyledCardWrap>
                        <StyledIconWrap>
                            <StyledCard />
                        </StyledIconWrap>
                        <StyledNumberWrap>
                            <StyledCartNumber level={2} color='secondaryText'>
                                ****
                            </StyledCartNumber>
                            <StyledCartNumber level={2} color='secondaryText'>
                                ****
                            </StyledCartNumber>
                            <StyledCartNumber level={2} color='secondaryText'>
                                ****
                            </StyledCartNumber>
                            <StyledLast4Wrap>
                                <Form.Item
                                    name='last4'
                                    rules={[
                                        { required: true, message: 'This field is required' },
                                        { min: 4, message: 'This field should contain 4 numbers' },
                                        { max: 4, message: 'This field should contain 4 numbers' }
                                    ]}
                                >
                                    <StyledLast4 formatter={formatter} maxLength={4} autoComplete='off' />
                                </Form.Item>
                            </StyledLast4Wrap>
                        </StyledNumberWrap>
                    </StyledCardWrap>
                    <Form.Item noStyle shouldUpdate>
                        {() => (
                            <StyledError type='badgeSmall' color='error'>
                                {form.getFieldError('last4')}
                            </StyledError>
                        )}
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                        <Button
                            htmlType='submit'
                            type='primary'
                            size='large'
                            block
                            style={BOTTOM_INDENTS.XXS}
                            data-e2e='prefill-mdl-next-btn'
                        >
                            <Typography.Text type='badgeLarge'>Next</Typography.Text>
                        </Button>
                    </Form.Item>
                    <Button
                        type='unstyled'
                        block
                        style={BOTTOM_INDENTS.XXS}
                        onClick={onSkip}
                        data-e2e='prefill-mdl-do-not-prefill-btn'
                    >
                        <Typography.Text type='badgeLarge'>Do not pre-fill my billing info</Typography.Text>
                    </Button>
                </Form>
            </StyledWrap>
        );
    })
);
