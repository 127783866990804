import React from 'react';
import { useModal } from '../../../../../shared/hooks';
import { CustomerCCVerificationModalContent } from '../components/CustomerCCVerificationModalContent';

export const useCustomerCCVerification = () => {
    const { openModal, closeModal } = useModal({
        titleOptions: { color: 'primary', align: 'center' },
        modalOptions: { maskClosable: false },
        withCloseButton: false
    });

    return (pdpData) =>
        openModal({
            title: 'Pre-fill billing info',
            children: <CustomerCCVerificationModalContent pdpData={pdpData} onClose={closeModal} />
        });
};
