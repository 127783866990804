import React from 'react';
import { observer } from 'mobx-react';
import { ActionsMinusIcon, ActionsPlusIcon } from '../../../../../../../../../../icons';
import { Icon } from '../../../../../../../../../UiLibrary';
import { Input, StyledButton, Styles } from './styles';

export const Qty = observer(({ isGSProduct, value, maxValue = 99999, decreaseValue, increaseValue }) => {
    return (
        <Styles>
            <StyledButton
                data-e2e='decrease-value-button'
                type='unstyled'
                disabled={value <= 1 || isGSProduct}
                onClick={decreaseValue}
            >
                <Icon stylePriority size='16px' component={() => <ActionsMinusIcon />} />
            </StyledButton>
            <Input data-e2e='qty-value' readOnly value={value} />
            <StyledButton
                data-e2e='increase-value-button'
                type='unstyled'
                disabled={value === maxValue}
                onClick={increaseValue}
            >
                <Icon stylePriority size='16px' component={() => <ActionsPlusIcon />} />
            </StyledButton>
        </Styles>
    );
});
