export const roundToLocaleString = (value, maxDigits = 2) => {
    const toLocaleStringOptions = {
        minimumFractionDigits: maxDigits,
        maximumFractionDigits: maxDigits
    };

    return Number(value || 0)
        .toLocaleString('en-US', toLocaleStringOptions)
        .replace(/,$/, '.');
};
