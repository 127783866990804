import { Search, Taxonomy } from '@klickly/front-packages';
import { getEnv, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared/constants';
import { CartStore } from './CartStore';
import { CheckoutStore } from './CheckoutStore';
import { ColdCustomerStore } from './ColdCustomerStore';
import { INITIAL_STORES } from './constants';
import { EventListenerStore } from './EventListenerStore';
import { MarketplaceStore } from './MarketplaceStore';
import { PDPStore } from './PDPStore';
import { PreCheckoutStore } from './PreCheckoutStore';
import { PublisherStore } from './PublisherStore';
import { RecommendedPromotionsStore } from './RecommendedPromotionsStore';
import { ThankYouStore } from './ThankYouStore';
import { TrackingStore } from './TrackingStore';

export const RootStore = types
    .model('RootStore', {
        publisherStore: types.optional(PublisherStore, {}),
        pdpStore: types.optional(PDPStore, {}),
        checkoutStore: types.optional(CheckoutStore, {}),
        coldCustomerStore: types.optional(ColdCustomerStore, {}),
        thankYouPageStore: types.optional(ThankYouStore, {}),
        trackingStore: types.optional(TrackingStore, {}),
        eventListenerStore: types.optional(EventListenerStore, {}),
        marketplaceStore: types.optional(MarketplaceStore, INITIAL_STORES.MARKETPLACE),
        recommendedPromotionsStore: types.optional(RecommendedPromotionsStore, INITIAL_STORES.RECOMMENDED_PROMOTIONS),
        preCheckoutStore: types.optional(PreCheckoutStore, {}),
        cartStore: types.optional(CartStore, INITIAL_STORES.CART),
        isLoading: false
    })
    .views((self) => ({
        get navigation() {
            return getEnv(self).navigate;
        },
        get location() {
            return getEnv(self).location;
        },
        get get() {
            return getEnv(self).get;
        },
        get post() {
            return getEnv(self).post;
        },
        get patch() {
            return getEnv(self).patch;
        }
    }))
    .actions((self) => ({
        setIsLoading(isloading) {
            self.isLoading = isloading;
        },
        afterCreate: () => {
            Taxonomy.store.setKcpAPI(kcpAPI);
            Search.store.afterInit({
                kcpAPI,
                shouldClearOnBackArrowClick: self.pdpStore.showPDP,
                onBeforeSearch: self.pdpStore.closePDP,
                onSearch: self.marketplaceStore.updatePromotions,
                onClear: self.marketplaceStore.clearSearch
            });
        },
        setHistoryState(navigate, location) {
            self.routeNavigate = navigate;
            self.routeLocation = location;
        }
    }));
