import { flow, getParent, getSnapshot, types } from 'mobx-state-tree';
import { round } from '../utils';
import { OptionsStore } from './OptionsStore';
import { VariantsStore } from './VariantsStore';

/* eslint-disable */

export const ProductStore = types
    .model('ProductStore', {
        isLoading: false,
        data: types.maybeNull(
            types.model({
                _id: types.string,
                externalId: types.string,
                title: types.string,
                rating: types.maybeNull(types.number),
                campaignCommission: types.maybeNull(types.number),
                publisherCommission: types.maybeNull(types.model({
                    customer: types.maybeNull(types.number),
                })),
                accountCommissions: types.maybeNull(
                    types.model({
                        limitNewColdCustomerCommission: types.maybeNull(types.boolean),
                        coldCustomerCommission: types.maybeNull(types.number),
                        newCustomerCommission: types.maybeNull(types.number),
                        referralCommission: types.maybeNull(types.number)
                    })
                ),
                account: types.string,
                campaign: types.string,
                shopDomain: types.string,
                brandName: types.string,
                brandLink: types.string,
                description: types.string,
                images: types.array(
                    types.model({
                        src: types.string,
                        externalId: types.number
                    })
                ),
                handle: types.string,
                priceDiscount: types.maybeNull(
                    types.model({
                        type: types.string,
                        value: types.number
                    })
                ),
                metadata: types.maybeNull(types.array(types.frozen())),
                prices: types.array(types.model({
                    name: types.string,
                    enabled: types.boolean,
                    src: types.string
                })),
                pixels: types.array(
                    types.model({
                        name: types.string,
                        src: types.string
                    })
                )
            })
        ),
        options: types.optional(OptionsStore, {}),
        variants: types.optional(VariantsStore, {})
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        getData(field, defaultValue = null) {
            return self.data[field] !== undefined ? self.data[field] : defaultValue;
        },
        get prices() {
            const { price, originalPrice } = self.variants.currentVariant;
            const prices = {
                price: round(price * self.variants.qty),
                originalPrice: round(originalPrice * self.variants.qty),
                priceNoQty: price,
                originalPriceNoQty: originalPrice
            };

            if (!prices.originalPrice || prices.originalPrice < prices.price) {
                prices.originalPrice = prices.price;
                prices.originalPriceNoQty = prices.priceNoQty;
            }

            const { type, value } = self.data.priceDiscount || {};
            if (type && value !== null) {
                let discountPrice = prices.price;

                if (type === 'rate') {
                    discountPrice = round(prices.price - value);
                }

                if (type === 'percent') {
                    discountPrice = round(prices.price - prices.price * (value / 100));
                }

                if (type === 'set') {
                    discountPrice = value;
                }

                if (discountPrice > prices.price) {
                    discountPrice = prices.price;
                }

                if (discountPrice !== prices.price) {
                    if (!prices.originalPrice || prices.originalPrice < prices.price) {
                        prices.originalPrice = prices.price;
                        prices.originalPriceNoQty = prices.priceNoQty;
                    }

                    prices.price = discountPrice;
                }
            }

            return prices;
        },
        get pointsValue() {
            const { pointsDetails } = self.variants.currentVariant;

            if (pointsDetails) {
                if (self.variants.qty === 1) {
                    return pointsDetails.maxPoints;
                }

                const qtyPoints = pointsDetails.list.map((l) => {
                    if (l.type === 'percent') {
                        return self.variants.qty * l.value;
                    }

                    return l.value;
                });
                if (!qtyPoints.length) {
                    return null;
                }
                return Math.max(...qtyPoints);
            }

            return null;
        },
        get pointsIsWelcome() {
            const { pointsDetails } = self.variants.currentVariant;
            return pointsDetails.isWelcome;
        },
        get metadata() {
            let data = [];
            if (self.root.metadata) {
                data = data.concat(self.root.metadata);
            }
            if (self.data.metadata) {
                data = data.concat(self.data.metadata);
            }
            return data;
        },
        get requestPayload() {
            return {
                testMode: self.root.testMode,
                productId: self.data._id,
                variantId: self.variants.selectedVariant.externalId,
                qty: self.variants.qty,
                prices: self.prices,
                metadata: self.metadata,
                redirects: self.root.redirects
            };
        },
        get optionsList() {
            return self.options.list.filter((option) => {
                return !(option.name === 'Title' && option.values.length === 1 && option.values[0].value === 'Default Title');
            });
        },
        get notSelectedOptionsErrorVisible() {
            return self.options.notSelectedErrorVisible;
        },
        get trackingData() {
            return {
                _id: self.data._id,
                externalId: self.data.externalId,
                title: self.data.title,
                account: self.data.account,
                campaign: self.data.campaign,
                shopDomain: self.data.shopDomain,
                selectedVariantId: self.variants.selectedVariant?.externalId,
                pixels: self.data.pixels
            };
        }
    }))
    .actions((self) => ({
        markLoading(loading) {
            self.isLoading = loading;
        },
        loadProduct: flow(function* loadProduct() {
            try {
                self.root.markLoading(true);

                const data = yield self.root.callbacks.onProductFetch();

                self.data = data;
                self.variants.setVariants(data.variants);
                self.variants.checkStrictQty();

                self.options.setOptions(data.options);
                self.root.markLoading(false);
            } catch (err) {
                console.log(err); // eslint-disable-line
                self.root.view.setRoute('unavailable');
            }
        }),
        addToCart: flow(function* addToCart({ onOptionsErrorCallback, onAddToCartSuccessCallback }) {
            if (self.root.view.customizations.buttons.addToCartClickOutsideRedirectLink) {
                return window.open(self.root.view.customizations.buttons.addToCartClickOutsideRedirectLink, '_blank');
            }
            try {
                self.markLoading(true);
                self.root.errors.deleteError('httpProductError');

                if (self.options.allOptionsSelected) {
                    yield self.root.callbacks.onAddToCart(self.requestPayload, self.trackingData);

                    self.variants.selectedVariant.inCart = true;

                    typeof onAddToCartSuccessCallback === 'function' && self.root.view.customizations.showAddedToCartModal
                        ? onAddToCartSuccessCallback()
                        : null;
                } else {
                    self.options.toggleOptionsError(true);
                    typeof onOptionsErrorCallback === 'function' ? onOptionsErrorCallback() : null;
                }
            } catch (err) {
                self.root.errors.addHttpError('httpProductError', err);
            }

            self.markLoading(false);
        }),
        buyNow: flow(function* buyNow({ onOptionsErrorCallback }) {
            self.markLoading(true);
            self.root.errors.deleteError('httpProductError');
            try {
                if (self.options.allOptionsSelected) {
                    yield self.root.callbacks.onBuyNow(self.requestPayload, getSnapshot(self.variants.selectedVariant), self.trackingData);
                } else {
                    self.options.toggleOptionsError(true);
                    typeof onOptionsErrorCallback === 'function' ? onOptionsErrorCallback() : null;
                    self.markLoading(false);
                }
            } catch (err) {
                self.root.errors.addHttpError('httpProductError', err);
                self.markLoading(false);
            }
        }),
        onCancel: flow(function* onCancel() {
            self.markLoading(true);
            try {
                yield self.root.callbacks.onCancel();
            } catch (err) {
                console.error('Failed to load ', err);
                self.markLoading(false);
            }
        }),
        showConfirmAddToCartModal({ onOptionsErrorCallback, onSuccess }) {
            if (self.options.allOptionsSelected) {
                onSuccess();
            } else {
                self.options.toggleOptionsError(true);
                typeof onOptionsErrorCallback === 'function' ? onOptionsErrorCallback() : null;
            }
        }
    }));
