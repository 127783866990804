import React, { useEffect, useMemo } from 'react';
import { Provider } from 'mobx-react';
import { elementScrollIntoViewPolyfill } from 'seamless-scroll-polyfill';
import { ModalProvider } from '../../../UiLibrary';
import { RootStore } from '../../stores/RootStore';
import AppContainer from './components/AppContainer';
import StyledGlobal from './styled/StyledGlobal';

const CheckoutPDP = ({
    customizations,
    metadata = [],
    redirects = {},
    callbacks,
    upsellEndDate,
    type = 'default',
    extraContent,
    bottomExtraContent,
    customButtons,
    testMode = false,
    isLoggedIn = false,
    inModal = true,
    useProductReviewNotification,
    useOnProductReviewSubmit,
    notificationMessages
}) => {
    const store = useMemo(
        () =>
            RootStore.create(
                {
                    view: {
                        route: 'pdp',
                        type: type,
                        customizations,
                        isLoggedIn
                    },
                    upsell: {
                        upsellEndDate
                    },
                    errors: {
                        list: []
                    }
                },
                {
                    testMode,
                    callbacks,
                    metadata,
                    redirects
                }
            ),
        [upsellEndDate]
    );

    store.setHooks(useOnProductReviewSubmit, useProductReviewNotification, notificationMessages);

    useEffect(() => {
        // polyfill for smooth scrolling for safari/iOS chrome
        elementScrollIntoViewPolyfill();
    }, []);

    const pdpContent = (
        <AppContainer
            extraContent={extraContent}
            bottomExtraContent={bottomExtraContent}
            customButtons={customButtons}
        />
    );

    return (
        <Provider store={store}>
            <StyledGlobal />
            {inModal ? <ModalProvider>{pdpContent}</ModalProvider> : pdpContent}
        </Provider>
    );
};
export default CheckoutPDP;
