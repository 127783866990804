import React, { useRef } from 'react';
import ControlButton from '../ControlButton';
import { StyledCarousel, StyledPreview, StyledWrapper } from './styled';

const CarouselWithPreview = ({ children, previews, activeSlide, carouselRef = null, ...props }) => {
    const ref = useRef(null);
    const sliderRef = carouselRef || ref;
    const previewRef = useRef(null);

    return (
        <StyledWrapper>
            <StyledCarousel
                ref={sliderRef}
                asNavFor={previewRef?.current}
                infinite={false}
                dots={false}
                arrows
                prevArrow={<ControlButton ghost nativeControls />}
                nextArrow={<ControlButton ghost nativeControls type='next' />}
                {...props}
            >
                {children}
            </StyledCarousel>
            {children?.length > 1 && previews?.length > 1 && (
                <StyledPreview
                    ref={previewRef}
                    asNavFor={sliderRef?.current}
                    infinite={false}
                    dots={false}
                    arrows
                    prevArrow={<ControlButton ghost nativeControls />}
                    nextArrow={<ControlButton ghost nativeControls type='next' />}
                    slidesToShow={previews?.length && previews?.length > 5 ? 5 : previews?.length}
                    swipeToSlide
                    focusOnSelect
                >
                    {previews}
                </StyledPreview>
            )}
        </StyledWrapper>
    );
};

export default CarouselWithPreview;
