import styled from 'styled-components/macro';
import { Animated } from '../Animated';

export const Styles = styled(Animated)`
    min-height: calc(100vh - 180px);
    position: relative;
    scroll-margin-top: 40px;
`;

export const Message = styled.div`
    padding: 25% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
