import React from 'react';
import { GiftLiteIcon, Icon, Typography } from '@klickly/front-packages';
import { StyledTitle, StyledWrapper } from './styled';

function NoRewardsAvailable() {
    return (
        <StyledWrapper>
            <Icon size='80px' component={() => <GiftLiteIcon />} />
            <StyledTitle level={3} type='descriptor1'>
                No Rewards available
            </StyledTitle>
            <Typography.Text type='body2'>Earn Rewards by completing tasks and challenges</Typography.Text>
        </StyledWrapper>
    );
}

export default NoRewardsAvailable;
