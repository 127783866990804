import React from 'react';
import { inject, observer } from 'mobx-react';
import { roundToLocaleString } from '../../../../../../../../../../../../../../shared';
import { StyledText, StyledWrapper } from './styled';

const TotalPrice = inject('store')(
    observer(({ store }) => {
        const price = store.product.variants.youPayPrice;
        return (
            <StyledWrapper data-e2e='total-price-block'>
                <StyledText type='descriptor1'>You pay</StyledText>
                <StyledText data-e2e='total-price-value' type='descriptor1'>
                    ${roundToLocaleString(price * store.product.variants.qty)}
                </StyledText>
            </StyledWrapper>
        );
    })
);

export default TotalPrice;
