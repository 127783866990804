import React, { useContext } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import { IconWrap } from './KliIcon.styles';

export const KliIcon = ({
    size,
    color = '',
    roundedColor,
    hoverRoundedColor,
    roundedSize = '200%',
    roundedOpacity = 0.7,
    children,
    onClick,
    ...rest
}) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <IconWrap
            theme={themeContext}
            clicable={!!roundedColor || !!hoverRoundedColor || !!onClick}
            roundedSize={roundedSize}
            roundedColor={roundedColor}
            roundedOpacity={roundedOpacity}
            hoverRoundedColor={hoverRoundedColor}
            size={size}
            color={color}
            onClick={onClick}
            className='kli-icon'
            {...rest}
        >
            {children}
        </IconWrap>
    );
};
