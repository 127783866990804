import React, { useCallback, useContext, useEffect, useState } from 'react';
import { phoneMask } from '../../../../shared';
import { KliThemeContext } from '../../KliThemeProvider';
import { Background, ClearInputIcon, InputIcon, InputWrap, Label, Placeholder, WarningIcon } from './styles';

const formatInputValueByType = (value, type) => {
    if (type === 'tel') {
        return phoneMask(value);
    }

    return value;
};

// eslint-disable-next-line react/display-name
export const KliBaseField = React.forwardRef(
    (
        {
            type = 'text',
            value = '',
            onChange,
            onBlur,
            onKeyDown,
            placeholder,
            children,
            large,
            invalid,
            showWarningIcon = false,
            withoutClearButton = false,
            onClearButtonCallback = () => null,
            nonOpacityOnDisabled = false,
            highlightValidateOnFirstTouch = true,
            withoutBorderColorsOnValidate = false,
            formatter = null,
            parser = null,
            trim = true,
            ...rest
        },
        ref
    ) => {
        const formatValue = useCallback(
            (value) => {
                const parsedVal = typeof parser === 'function' ? parser(value) : value;
                return typeof formatter === 'function' ? formatter(parsedVal) : formatInputValueByType(value, type);
            },
            [type, formatter, parser]
        );

        const trimValue = (val) => (typeof val === 'string' && trim ? val.trim() : val);

        const theme = useContext(KliThemeContext);

        const [touched, setTouched] = useState(false);
        const [inputValue, setInputValue] = useState(formatValue(value));

        useEffect(() => {
            setInputValue(formatValue(value));
        }, [value, setInputValue]);

        const withIcon = !!children;

        const onInputChange = useCallback(
            (e) => {
                const { value } = e.target;

                const formattedValue = formatValue(value);

                setInputValue(formattedValue);

                if (typeof onChange === 'function') {
                    e.target.value = typeof parser === 'function' ? parser(formattedValue) : formattedValue;
                    onChange(e);
                }
            },
            [setInputValue, onChange]
        );

        const onInputBlur = useCallback(
            (e) => {
                const formattedValue = formatValue(trimValue(e.target.value));
                setInputValue(formattedValue);

                if (typeof onBlur === 'function') {
                    e.target.value =
                        typeof parser === 'function'
                            ? parser(trimValue(e.target.value))
                            : formatValue(trimValue(e.target.value));
                    onBlur(e);
                }
            },
            [onBlur]
        );

        const onInputKeyDown = useCallback(
            (e) => {
                if (typeof onKeyDown === 'function') {
                    e.target.value =
                        typeof parser === 'function' ? parser(e.target.value) : formatValue(e.target.value);
                    onKeyDown(e);
                }
            },
            [onKeyDown]
        );

        return (
            <InputWrap disabled={rest.disabled} theme={theme} nonOpacityOnDisabled={nonOpacityOnDisabled}>
                <Label
                    withIcon={withIcon}
                    withoutBorderColorsOnValidate={withoutBorderColorsOnValidate}
                    withoutClearButton={withoutClearButton}
                    theme={theme}
                    large={large ? 1 : 0}
                >
                    <input
                        value={inputValue}
                        placeholder=' '
                        type={type}
                        onFocus={() => setTouched(true)}
                        onReset={() => setTouched(false)}
                        data-highlight-validate-touch={touched}
                        onChange={onInputChange}
                        onBlur={onInputBlur}
                        onKeyDown={onInputKeyDown}
                        data-invalid={invalid ? 1 : 0}
                        ref={ref}
                        {...rest}
                    />

                    <Placeholder large={large ? 1 : 0} withIcon={withIcon} theme={theme}>
                        {placeholder}
                    </Placeholder>

                    {withIcon && <InputIcon large={large ? 1 : 0}>{children}</InputIcon>}

                    {!withoutClearButton && inputValue && !rest.disabled && !rest.readOnly && (
                        <span>
                            <ClearInputIcon
                                large={large ? 1 : 0}
                                onClick={() => {
                                    onClearButtonCallback();
                                    setInputValue('');
                                }}
                            />
                        </span>
                    )}

                    {withoutClearButton && showWarningIcon && (
                        <span className='warning'>
                            <WarningIcon large={large ? 1 : 0} />
                        </span>
                    )}

                    <Background theme={theme} />
                </Label>
            </InputWrap>
        );
    }
);
