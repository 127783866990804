import styled from 'styled-components/macro';
import { GRID_DEVICES_MAX } from '../../../../../../../../../ThankYouPage/utils';
import { Icon } from '../../../../../../../../../UiLibrary';
import { COLORS } from '../../../../../../../../constants';

export const Styles = styled.div`
    background-color: ${COLORS.$white};
    position: relative;
`;

export const FormWrapper = styled.div`
    padding: ${({ $simple }) => ($simple ? '24px' : '40px')};
`;

export const CloseModalIcon = styled(Icon)`
    position: absolute;
    bottom: 100%;
    margin-bottom: 24px;
    font-size: 24px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
`;

export const StyledHeader = styled.div`
    padding: 40px;
    background: ${COLORS.$tartRed};

    @media (${GRID_DEVICES_MAX.md}) {
        padding: 24px;
    }
`;
