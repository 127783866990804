export const GRID_COLUMNS_COUNT = 12;
export const GRID_GUTTER_WIDTH = 10;

export const GRID_POINTS = Object.freeze({
    xs: 480,
    sm: 668,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600
});

export const GRID_CONTAINER_WIDTHS = Object.freeze({
    xs: 648,
    sm: 748,
    md: 856,
    lg: 992,
    xl: 1400,
    xxl: 1600
});

export const GRID_DEVICES_MIN = Object.freeze({
    xs: `min-width: ${GRID_POINTS.xs + 1}px`,
    sm: `min-width: ${GRID_POINTS.sm + 1}px`,
    md: `min-width: ${GRID_POINTS.md + 1}px`,
    lg: `min-width: ${GRID_POINTS.lg + 1}px`,
    xl: `min-width: ${GRID_POINTS.xl + 1}px`,
    xxl: `min-width: ${GRID_POINTS.xxl + 1}px`
});

export const GRID_DEVICES_MAX = Object.freeze({
    xs: `max-width: ${GRID_POINTS.xs}px`,
    sm: `max-width: ${GRID_POINTS.sm}px`,
    md: `max-width: ${GRID_POINTS.md}px`,
    lg: `max-width: ${GRID_POINTS.lg}px`,
    xl: `max-width: ${GRID_POINTS.xl}px`,
    xxl: `max-width: ${GRID_POINTS.xxl}px`
});

export const GRID_CUSTOM_POINTS = Object.freeze({
    small: 648,
    medium: 768,
    large: 980,
    biggest: 1280,
    hdPlus: 1360
});

export const GRID_DEVICES_CUSTOM_MIN = Object.freeze({
    small: `min-width: ${GRID_CUSTOM_POINTS.small + 1}px`,
    medium: `min-width: ${GRID_CUSTOM_POINTS.medium + 1}px`,
    large: `min-width: ${GRID_CUSTOM_POINTS.large + 1}px`,
    biggest: `min-width: ${GRID_CUSTOM_POINTS.biggest + 1}px`,
    hdPlus: `min-width: ${GRID_CUSTOM_POINTS.hdPlus + 1}px`
});

export const GRID_DEVICES_CUSTOM_MAX = Object.freeze({
    small: `max-width: ${GRID_CUSTOM_POINTS.small}px`,
    medium: `max-width: ${GRID_CUSTOM_POINTS.medium}px`,
    large: `max-width: ${GRID_CUSTOM_POINTS.large}px`,
    biggest: `max-width: ${GRID_CUSTOM_POINTS.biggest}px`,
    hdPlus: `max-width: ${GRID_CUSTOM_POINTS.hdPlus}px`
});
