import { types } from 'mobx-state-tree';

export const OrderModel = types.model({
    _id: types.identifier,
    brandDomain: types.maybeNull(types.string),
    brandLogo: types.maybeNull(types.string),
    brandName: types.maybeNull(types.string),
    checkoutId: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    customerPrice: types.maybeNull(types.number),
    isAvailableForCancel: types.maybeNull(types.boolean),
    items: types.array(
        types.model({
            image: types.maybeNull(types.string),
            price: types.maybeNull(types.number),
            quantity: types.maybeNull(types.number),
            title: types.maybeNull(types.string),
            variantTitle: types.maybeNull(types.string),
            _id: types.string
        })
    ),
    orderNumber: types.maybeNull(types.string),
    orderStatus: types.maybeNull(types.string),
    prices: types.model({
        result: types.model({
            price: types.maybeNull(types.number),
            shipping: types.maybeNull(types.number),
            taxes: types.maybeNull(types.number),
            total: types.maybeNull(types.number),
            totalInCents: types.maybeNull(types.number)
        })
    })
});
