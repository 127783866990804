import React, { useEffect } from 'react';
import { ArrowChevronForwardIcon, CircleActionsAlertInfoIcon, Icon, Typography } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { goToGiftly } from '../../utils';
import HeaderButtons from '../HeaderButtons';
import { Logo } from '../Logo';
import { Body, ContentStyles, Header, HeaderContainer, StyledButton, StyledTitle, Wrap } from './styles';

const NotAvailable = inject('store')(
    observer(({ store }) => {
        const { pending, data: publisherInfo, getPublisherInfoByDomain, publisherURL } = store.publisherStore;

        useEffect(() => {
            if (!publisherInfo) getPublisherInfoByDomain({ getBrands: false });
        }, [publisherInfo]);

        return pending ? (
            <Icon.Loader />
        ) : (
            <Wrap>
                <Header>
                    <HeaderContainer>
                        <Logo />
                        <HeaderButtons />
                    </HeaderContainer>
                </Header>
                <Body>
                    <ContentStyles>
                        <Icon
                            size='40px'
                            color='primary'
                            roundedColor='primaryBackground'
                            roundedSize='280%'
                            component={() => <CircleActionsAlertInfoIcon />}
                        />
                        <StyledTitle type='badgeLarge' align='center'>
                            We’re sorry
                        </StyledTitle>
                        <Typography.Text align='center' type='descriptor1' marginBottom='24px'>
                            These offers are only available to {publisherInfo?.name} customers. Please visit{' '}
                            {publisherInfo?.name} to activate these offers.
                        </Typography.Text>
                        <StyledButton type='primary' size='large' onClick={() => goToGiftly('marketplace')}>
                            <span>Explore other Giftly offers</span>
                            <ArrowChevronForwardIcon />
                        </StyledButton>
                        {publisherURL ? (
                            <StyledButton
                                type='unstyled'
                                size='large'
                                onClick={() => window.open(publisherURL, '_blank')}
                            >
                                <span>{`Visit ${publisherInfo?.name}`}</span>
                                <ArrowChevronForwardIcon />
                            </StyledButton>
                        ) : null}
                    </ContentStyles>
                </Body>
            </Wrap>
        );
    })
);

export default NotAvailable;
