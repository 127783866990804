import React from 'react';

const HtmlViewer = ({ html }) => {
    if (!html) return null;

    const createMarkup = () => ({ __html: html });

    return <span dangerouslySetInnerHTML={createMarkup()} />;
};

export default HtmlViewer;
