import { Button, Image, Typography } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledLogoWrap = styled.div`
    padding: 64px 0 15px;
`;

export const StyledLogo = styled(Image)`
    && {
        width: 100%;
        height: 50px;
        object-fit: contain;
    }
`;

export const StyledBanner = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    display: ${({ $loaded }) => ($loaded ? 'block' : 'none')};
`;

export const StyledDefaultBanner = styled.div`
    width: 100%;
    padding-top: 34%;
    display: ${({ $loaded }) => ($loaded ? 'none' : 'block')};
`;

export const StyledTitle = styled(Typography.Title)`
    padding: 16px 24px;
`;

export const StyledText = styled(Typography.Text)`
    padding: ${({ $simple }) => ($simple ? '16px 90px 32px' : '16px 135px 32px')};
`;

export const StyledSubmitButton = styled(Button)`
    width: 100%;
    justify-content: center;
    margin-top: 16px;
`;

export const StyledCancelButton = styled(Button)`
    width: 100%;
    justify-content: center;
    margin-top: 16px;
`;

export const StyledForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 24px;
`;
