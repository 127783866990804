import React from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../constants';
import Typography from '../../../../../UiLibrary/Typography';
import { getPrices, GRID_POINTS } from '../../../../utils';
import { ProductCard } from '../productCard';
import {
    CardBody,
    CardHead,
    CardPrices,
    CardPricesRow,
    StyledDivider,
    StyledText,
    Styles,
    SubtotalPrice
} from './styles';

export const BrandCard = inject('store')(
    observer(({ store, bag, orderNumber, orderLength }) => {
        const { shipping, taxes, discounts, fullTotal, originalSubTotal } = getPrices({ ...bag.priceDetails });
        const simple = store.view.isSimple(GRID_POINTS.lg);

        return bag.items && bag.items.length ? (
            <Styles data-e2e='orderCard-info' $simple={simple}>
                <CardHead style={BOTTOM_INDENTS.XL}>
                    <StyledText data-e2e='orderNum' type='badgeMedium'>
                        Order №{orderNumber}
                        {`${bag.merchantName ? ` - ${bag.merchantName}` : ''}`}
                    </StyledText>
                </CardHead>
                <CardBody $simple={simple}>
                    {bag.items.map((item, key) => {
                        return (
                            <div key={key} style={BOTTOM_INDENTS.XL}>
                                <ProductCard key={key} item={item} />
                            </div>
                        );
                    })}
                </CardBody>
                {!store.env && (
                    <CardPrices>
                        <CardPricesRow>
                            <Typography.Text type='body2'>{`Brand price ${
                                bag.items.length > 1 ? `(${bag.items.length}x)` : ''
                            }`}</Typography.Text>
                            <Typography.Text type='body2'>{originalSubTotal}</Typography.Text>
                        </CardPricesRow>
                        <CardPricesRow>
                            <Typography.Text type='body2'>Shipping</Typography.Text>
                            <Typography.Text type='body2'>{shipping}</Typography.Text>
                        </CardPricesRow>
                        <CardPricesRow>
                            <Typography.Text type='body2'>Taxes</Typography.Text>
                            <Typography.Text type='body2'>{taxes}</Typography.Text>
                        </CardPricesRow>
                        {discounts ? (
                            <CardPricesRow>
                                <Typography.Text type='body2'>You save</Typography.Text>
                                <Typography.Text type='body2'>{discounts}</Typography.Text>
                            </CardPricesRow>
                        ) : null}
                    </CardPrices>
                )}
                {store.env === 'kcp' && (
                    <CardPrices>
                        <StyledDivider />
                        <CardPricesRow data-e2e='shipping-block'>
                            <Typography.Text type='body2'>Shipping</Typography.Text>
                            <Typography.Text data-e2e='shipping-val' type='body2'>
                                {shipping}
                            </Typography.Text>
                        </CardPricesRow>
                        <CardPricesRow data-e2e='taxes-block'>
                            <Typography.Text type='body2'>Taxes</Typography.Text>
                            <Typography.Text data-e2e='taxes-val' type='body2'>
                                {taxes}
                            </Typography.Text>
                        </CardPricesRow>
                    </CardPrices>
                )}
                <SubtotalPrice>
                    <CardPricesRow data-e2e='fullTotalPrice-block'>
                        <Typography.Text type='badgeLarge'>{orderLength > 1 ? 'Subtotal' : 'Total'}</Typography.Text>
                        <Typography.Text data-e2e='fullTotalPrice-val' type='badgeLarge'>
                            {fullTotal}
                        </Typography.Text>
                    </CardPricesRow>
                </SubtotalPrice>
            </Styles>
        ) : null;
    })
);
