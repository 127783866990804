import React, { useContext } from 'react';
import { KliThemeContext } from '../../../KliThemeProvider';
import { StyledTitle } from './styled';

const Title = ({ children, color, align, marginBottom, ...props }) => {
    const theme = useContext(KliThemeContext);
    return (
        <StyledTitle $theme={theme} $color={color} $align={align} $marginBottom={marginBottom} {...props}>
            {children}
        </StyledTitle>
    );
};

export default Title;
