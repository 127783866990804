import React, { Fragment, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { CloseXIcon } from '../../../../../../../../../../icons';
import { Button, Icon } from '../../../../../../../../../UiLibrary';
import { formatPoints } from '../../../../../../../../utils';
import {
    AppliedPointsWrap,
    StyledErrorText,
    StyledField,
    StyledFormTitle,
    StyledFormWrapper,
    StyledPayWithPointsForm,
    StyledPointsButton,
    StyledText
} from './styled';

const PayWithPointsForm = inject('store')(
    observer(({ store }) => {
        const [value, setValue] = useState('');
        const [error, setError] = useState('');
        const [placeholder, setPlaceholder] = useState('Points to use');
        const {
            pointsBalance,
            availablePointsBalance,
            pointsApplied,
            removeAppliedPoints,

            loading,
            errorMessage,

            fetchPoints,
            applyPoints
        } = store.points;

        const onValueChange = (val) => {
            setValue(val);

            if (val && val < 1000) {
                setError('1,000 points minimum');
                return;
            } else {
                setError('');
            }

            if (val > availablePointsBalance) {
                setError(`You have ${formatPoints(availablePointsBalance)} pts available`);
            } else {
                setError('');
            }
        };

        const onApplyPoints = () => {
            applyPoints(value);
        };

        const checkPlaceholder = () => {
            if (value) setPlaceholder('');
            else setPlaceholder('Points to use');
        };

        useEffect(() => {
            if (pointsBalance === null && !loading && !errorMessage) {
                fetchPoints();
            }
        }, [pointsBalance, loading, errorMessage]);

        return (
            <StyledPayWithPointsForm>
                {loading ? (
                    <Icon.Loader />
                ) : (
                    <Fragment>
                        <StyledFormTitle data-e2e='available-points'>
                            <StyledText type='body2' color='secondaryText'>
                                Pay with points ({formatPoints(availablePointsBalance)} available)
                            </StyledText>
                            <AppliedPointsWrap>
                                {pointsApplied ? (
                                    <StyledPointsButton type='unstyled'>
                                        <StyledText type='body2' color='secondaryText'>{`–${formatPoints(
                                            pointsApplied
                                        )} pts`}</StyledText>
                                        <Icon
                                            stylePriority
                                            size='16px'
                                            color='secondaryText'
                                            onClick={removeAppliedPoints}
                                            component={() => <CloseXIcon />}
                                        />
                                    </StyledPointsButton>
                                ) : null}
                            </AppliedPointsWrap>
                        </StyledFormTitle>
                        <StyledFormWrapper>
                            <StyledField
                                data-e2e='use-points-input'
                                $invalid={Boolean(error)}
                                type='number'
                                placeholder={placeholder}
                                value={value}
                                onFocus={() => setPlaceholder('')}
                                onBlur={() => checkPlaceholder()}
                                onChange={({ target: { value: newValue } }) => onValueChange(newValue)}
                                onClearButtonCallback={() => {
                                    onValueChange('');
                                    checkPlaceholder();
                                }}
                                max={pointsBalance}
                                withoutBorderColorsOnValidate
                            />
                            <Button
                                data-e2e='apply-button'
                                type='primary'
                                ghost
                                disabled={!value || error}
                                onClick={onApplyPoints}
                            >
                                Apply
                            </Button>
                        </StyledFormWrapper>
                        {error ? (
                            <StyledErrorText data-e2e='error-text' type='badgeSmall' color='error'>
                                {error}
                            </StyledErrorText>
                        ) : null}
                    </Fragment>
                )}
            </StyledPayWithPointsForm>
        );
    })
);

export default PayWithPointsForm;
