import { Divider } from 'antd';
import styled, { css } from 'styled-components';

export const StyledDivider = styled(Divider)`
    ${({ $theme, $height, $color, $margin = '24px 0' }) => css`
        border-top-width: ${$height || 1}px;
        border-color: ${$color || $theme.colors.lines};

        &.ant-divider-horizontal {
            margin: ${typeof $margin === 'number' ? `${$margin}px` : $margin};
        }
    `};
`;
