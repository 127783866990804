import { types } from 'mobx-state-tree';

export const StrategyInfoModel = types.model({
    name: types.string,
    styles: types.maybeNull(
        types.model({
            displayName: types.maybeNull(types.boolean),
            bannerImage: types.maybeNull(types.string),
            bannerText: types.maybeNull(types.string)
        })
    )
});
