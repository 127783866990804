import { useEffect } from 'react';

export const useInterval = (action = () => null, timeInterval = 1000, stopInterval) => {
    useEffect(() => {
        const interval = setInterval(() => {
            action();
        }, timeInterval);

        if (stopInterval) clearInterval(interval);

        return () => clearInterval(interval);
    }, [timeInterval, action]);
};
