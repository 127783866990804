import { createContext } from 'react';

const defaultLayoutContext = {
    headerRef: {},
    sideRef: {},
    bodyRef: {},
    contentRef: {},
    wrapRef: {}
};

export const LayoutContext = createContext(defaultLayoutContext);
