import { createGlobalStyle, css } from 'styled-components';
import { device } from '../../utils';
import { $primaryText, $white } from './styles';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;

        box-sizing: border-box;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        -webkit-backface-visibility: hidden;
    }

    html,
    body,
    #root {
        position: relative;
        overflow: hidden;
        min-width: 320px;
        background: ${$white};


        ${() =>
            isSafari && isIos
                ? css`
                      height: 100vh;
                      min-height: -webkit-fill-available;
                  `
                : css`
                      height: 100%;
                  `}
    }

    html,
    body {
        overflow: hidden;
    }

    #root {
        overflow-y: auto;
    }

    body {
        margin: 0;
        font-family: 'IBM Plex Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #fff;
        color: ${$primaryText};
    }

    a {
        text-decoration: none;
        color: inherit;
    }
`;
