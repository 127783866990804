import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from '../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../utils';
import { Details } from './components/Details';
import { EmptyCart } from './components/EmptyCart';
import { PricesDetails } from './components/PricesDetails';
import { Styles } from './styles';

export const ShoppingCart = inject('store')(
    observer(({ store, bottomExtraContent, buttonBadge }) => {
        const simple = store.view.isSimple(GRID_POINTS.md);

        if (store.isLoading) {
            return <Icon.Loader />;
        }

        return (
            <Styles simple={simple}>
                {!store.cart.isEmpty ? (
                    <Fragment>
                        <Details bottomExtraContent={bottomExtraContent} />
                        <PricesDetails withExtraBottomContent={Boolean(bottomExtraContent)} buttonBadge={buttonBadge} />
                        {simple && bottomExtraContent ? bottomExtraContent : null}
                    </Fragment>
                ) : (
                    <EmptyCart />
                )}
            </Styles>
        );
    })
);
