import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;

    ${({ simple }) =>
        simple
            ? css`
                  flex-direction: column;
                  overflow-y: auto;
                  justify-content: flex-start;
              `
            : ''};
`;

export const ChildrenWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    ${({ simple }) =>
        simple
            ? css`
                  height: auto;
                  overflow-y: visible;
              `
            : ''};
`;
