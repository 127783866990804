import { flow, getEnv, getParent, types } from 'mobx-state-tree';
import { getFilterParamsFromUrl } from '../../../utils';
import { TAXONOMY_ROOT_CATEGORIES } from '../constants';
import { getCategoriesArrayFromString, getTaxonomyByPath } from '../utils';
import { INITIAL_STORE } from './constants/initialStore';
import { models } from './constants';

export const RootStore = types
    .model('TaxonomyStore', {
        kcpAPI: types.maybeNull(types.frozen()),
        pending: false,
        brandId: types.maybeNull(types.string),
        error: types.maybeNull(types.string),
        categories: types.array(
            types.model({
                order: types.number,
                title: types.string,
                key: types.string,
                count: types.maybeNull(types.number),
                children: types.array(models.TaxonomyCategoriesModel),
                bestSellers: types.array(models.TaxonomyBestSellersModel)
            })
        ),
        selectedCategories: types.array(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get get() {
            return getEnv(self).get;
        },
        get post() {
            return getEnv(self).post;
        },
        get patch() {
            return getEnv(self).patch;
        },
        get put() {
            return getEnv(self).put;
        },
        get delete() {
            return getEnv(self).delete;
        },

        get selectedTaxonomyData() {
            const selectedTaxonomyKey = self.selectedCategories[0];
            if (!selectedTaxonomyKey) return null;
            return self.getRootCategoryByKey(selectedTaxonomyKey);
        },
        getRootCategoryByKey(key) {
            return self.categories.find((category) => category.key === key);
        },
        getCategoryChildTitles(category) {
            return category.children.reduce((result, child) => {
                return [...result, child.title, ...self.getCategoryChildTitles(child)];
            }, []);
        },
        getParentCategoryByChildTitle(title, categories = self.categories) {
            return categories.reduce((result, category) => {
                if (category.children.some((child) => child.title === title)) {
                    return category;
                }
                return result || self.getParentCategoryByChildTitle(title, category.children);
            }, null);
        },
        getSelectedCategories(path, checked, categories = self.categories) {
            const categoriesTitles = getCategoriesArrayFromString(path);
            return categories.map((category) => {
                if (category.title === categoriesTitles[0] || category.key === categoriesTitles[0]) {
                    return {
                        ...category,
                        checked,
                        children: self.getSelectedCategories(
                            categoriesTitles.slice(1).join(' > '),
                            checked,
                            category.children
                        )
                    };
                } else {
                    return category;
                }
            });
        }
    }))
    .actions((self) => ({
        setKcpAPI(kcpAPI) {
            self.kcpAPI = kcpAPI;
        },
        setBrandId(brandId) {
            self.brandId = brandId;
        },
        getTaxonomyCategoryDetails: flow(function* getTaxonomyCategoryInfo(title) {
            const hasData = self.getRootCategoryByKey(title)?.children?.length;
            if (hasData) return false;

            self.error = null;
            try {
                const data = yield self.get(self.kcpAPI.marketplace.taxonomy.getDetails, {
                    title
                });
                if (data && (data.children || data.bestSellers)) {
                    self.categories = self.categories.map((category) => {
                        if (category.key === title) {
                            return {
                                ...category,
                                children: data.children || [],
                                bestSellers: data.bestSellers || []
                            };
                        }
                        return category;
                    });
                }
            } catch (error) {
                self.error = error?.response?.data?.message;
            } finally {
                self.pending = false;
            }
        }),
        getTaxonomyBrandCategoryDetails: flow(function* getTaxonomyBrandCategoryDetails(title) {
            const hasData = self.getRootCategoryByKey(title)?.children?.length;
            if (hasData) return false;

            self.error = null;
            try {
                const data = yield self.get(self.kcpAPI.marketplace.brands.getBrandData(self.brandId), {
                    title
                });
                if (data && (data.googleTaxonomy?.children || data.bestSellers)) {
                    self.categories = self.categories.map((category) => {
                        if (category.key === title) {
                            return {
                                ...category,
                                children: data.googleTaxonomy?.children || [],
                                bestSellers: data.bestSellers || []
                            };
                        }
                        return category;
                    });
                }
            } catch (error) {
                self.error = error?.response?.data?.message;
            } finally {
                self.pending = false;
            }
        }),
        selectCategory: flow(function* selectCategory(
            path,
            checked,
            onAfterCategorySelect = () => null,
            parentCategory,
            isInitialFetch = false
        ) {
            if (checked && isInitialFetch) yield self.getTaxonomyCategoryDetails(parentCategory);
            if (!path) self.selectedCategories = [];

            const categoryNames = getCategoriesArrayFromString(path);

            if (checked) {
                self.selectedCategories = categoryNames;
            } else {
                self.selectedCategories =
                    categoryNames.length === 1
                        ? []
                        : self.selectedCategories.filter(
                              (category) =>
                                  ![parentCategory.title, ...self.getCategoryChildTitles(parentCategory)].includes(
                                      category
                                  )
                          );
            }

            const newPath = self.selectedCategories.join(' > ');
            if (checked && !isInitialFetch && !self.brandId) yield self.getTaxonomyCategoryDetails(newPath);
            if (checked && !isInitialFetch && self.brandId) yield self.getTaxonomyBrandCategoryDetails(newPath);
            onAfterCategorySelect(newPath);
        }),
        setSelectedCategories(categories) {
            self.selectedCategories = categories;
        },
        getBrandsCategoriesList(brandData) {
            self.categories = TAXONOMY_ROOT_CATEGORIES.map((item) => {
                const taxonomy = getTaxonomyByPath(brandData.googleTaxonomy, item.key);
                if (taxonomy) {
                    return { ...item, count: taxonomy.count };
                }

                return { ...item };
            }).filter((tax) => tax.count);

            self.pending = false;
        },
        getCategoriesList: flow(function* getCategoriesList() {
            const data = yield self.get(self.kcpAPI.marketplace.taxonomy.list);
            self.categories = TAXONOMY_ROOT_CATEGORIES.map((item) => {
                const taxonomy = getTaxonomyByPath(data, item.key);
                if (taxonomy) {
                    return { ...item, count: taxonomy.count };
                }

                return { ...item };
            }).filter((tax) => tax.count);

            self.pending = false;
        }),
        afterCreate() {
            self.pending = true;
            self.taxonomyLoader = true;
            const searchParams = getFilterParamsFromUrl();
            if (searchParams.gt) {
                const newCategories = getCategoriesArrayFromString(searchParams.gt);
                self.selectedCategories = newCategories;
                self.hasActiveSubCategories = newCategories?.length > 1;
            }
        }
    }));

export default RootStore.create({}, INITIAL_STORE);
