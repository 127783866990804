import React from 'react';
import { ArrowChevronBackIcon, ArrowChevronForwardIcon } from '@klickly/front-packages';
import { StyledArrowButton } from './styled';

function ArrowButton({ type = 'prev', ...props }) {
    return (
        <StyledArrowButton type='unstyled' {...props}>
            {type === 'next' ? (
                <ArrowChevronForwardIcon data-e2e='next-recommended-slide' />
            ) : (
                <ArrowChevronBackIcon data-e2e='back-recommended-slide' />
            )}
        </StyledArrowButton>
    );
}

export default ArrowButton;
