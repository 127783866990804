import styled from 'styled-components';

export const StyledField = styled.label`
    position: relative;
    display: block;
    width: 100%;
    min-width: 108px;
    height: ${({ large }) => (large ? '64px' : '55px')};

    & > * {
        box-sizing: border-box;
    }

    && .StripeElement {
        width: 100%;
        border: 2px solid transparent;
        transition: ${({ theme }) => theme.transition.default};
        padding: ${({ large }) => (large ? '26px 24px 14px 24px' : '22px 24px 10px 24px')};
        height: ${({ large }) => (large ? '64px' : '55px')};
        position: relative;
        transition: ${({ theme }) => theme.transition.default};
        background-color: ${({ theme }) => theme.colors.background};

        & + span {
            color: ${({ theme }) => theme.colors.secondaryText};
        }

        &:not(.StripeElement--empty) {
            & + span {
                font: ${({ theme }) => theme.fonts.descriptor2};
                top: -8px;
            }
        }
    }

    && .StripeElement--complete {
        border-color: ${({ theme }) => theme.colors.success};
        border: 2px solid ${({ theme }) => theme.colors.success};

        & + span {
            color: ${({ theme }) => theme.colors.success};
        }
    }

    && .StripeElement--empty {
        background-color: ${({ theme }) => theme.colors.background};
    }

    && .StripeElement--focus {
        border: 2px solid ${({ theme }) => theme.colors.primaryText};
        background-color: transparent;

        & + span {
            font: ${({ theme }) => theme.fonts.descriptor2};
            top: -8px;
        }
    }

    && .StripeElement--invalid {
        border-color: ${({ theme }) => theme.colors.error};
        border: 2px solid ${({ theme }) => theme.colors.error};

        & + span {
            color: ${({ theme }) => theme.colors.error};
        }
    }

    && .StripeElement--webkit-autofill {
        color: transparent;
    }
`;

export const Placeholder = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 3;

    border: 2px solid transparent;

    padding: ${({ large }) => (large ? '18px 24px' : '14px 24px')};
    padding-left: ${({ withIcon }) => (withIcon ? '60px' : '24px')};

    font: ${({ theme }) => theme.fonts.descriptor1};
    transition: ${({ theme }) => theme.transition.default};

    pointer-events: none;
    touch-action: none;
`;
