import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { $primary, $primaryText, $white, GRID_POINTS, PAGE_TYPES } from '../../../../../../constants';
import { useCashBack } from '../../hooks';
import { formatCashBack } from '../../utils';
import { StyledPubText, StyledText } from './styled';

const BadgeTextPdp = inject('store')(
    observer(({ store, publisherName, badgeTextArray, showZeroCashback, pageType }) => {
        const location = useLocation();
        const { isCustomerExists } = store.thankYouPageStore;
        const { isNewCustomer } = store.coldCustomerStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { cashBack, previousCashBack } = useCashBack(store, location);
        const cashBackWasChanged = previousCashBack.value > cashBack.value || !isNewCustomer;

        return (
            <Fragment>
                {!showZeroCashback && pageType !== PAGE_TYPES.PDP && (
                    <StyledPubText
                        $color={isCustomerExists && pageType === PAGE_TYPES.THANK_YOU && $primaryText}
                        $marginRight='10px'
                    >
                        {publisherName}
                    </StyledPubText>
                )}
                <StyledText $color={isCustomerExists && pageType === PAGE_TYPES.THANK_YOU && $white}>
                    {!cashBack.value && !showZeroCashback && pageType === PAGE_TYPES.THANK_YOU
                        ? badgeTextArray.join(formatCashBack('0.00'))
                        : null}
                    {cashBack.value && !showZeroCashback && pageType !== PAGE_TYPES.CHECKOUT
                        ? badgeTextArray.join(cashBack.viewValue)
                        : null}
                    {cashBack.value && !showZeroCashback && pageType === PAGE_TYPES.CHECKOUT && isNewCustomer
                        ? badgeTextArray.join(cashBack.viewValue)
                        : null}
                </StyledText>
                {showZeroCashback ? (
                    <Fragment>
                        <span>Invalid credit card – </span>
                        <StyledText>no reward earned</StyledText>
                    </Fragment>
                ) : null}
                {cashBack.value &&
                !showZeroCashback &&
                cashBackWasChanged &&
                pageType !== PAGE_TYPES.THANK_YOU &&
                pageType !== PAGE_TYPES.PDP ? (
                    <Fragment>
                        <span>Welcome back – </span>
                        <StyledText $color={$primary}>
                            get {cashBack.viewValue}
                            {simple ? ' back' : ' cash back on this order'}
                        </StyledText>
                    </Fragment>
                ) : null}
            </Fragment>
        );
    })
);

export default BadgeTextPdp;
