import React from 'react';
import { BackArrowIcon, BagIcon, Typography } from '@klickly/front-packages';
import LearnMoreLayout from './components/LearnMoreLayout';
import { WORDINGS } from './constants';
import { StyledButton, StyledHeadLine2, StyledText, StyledTextWrapper } from './styled';

function LearnMore({ onClose, text, onLogoClick }) {
    return (
        <LearnMoreLayout onClose={onLogoClick}>
            <StyledButton onClick={onClose} type='unstyled' data-e2e='dp_learnMorePage-back-btn-arrow'>
                <BackArrowIcon />
            </StyledButton>
            <StyledText type='body2' data-e2e='dp_learnMorePage-excl-cash-deals-title'>
                {WORDINGS.EXCLUSIVE_CASHBACK_DEALS}
            </StyledText>
            <StyledHeadLine2 level={2} $marginBottom='48px'>
                {WORDINGS.LEARN_MORE}
            </StyledHeadLine2>
            <StyledTextWrapper>
                <BagIcon />
                <StyledHeadLine2 level={2} $marginBottom='20px' data-e2e='dp_learnMorePage-what-is-this-box'>
                    {WORDINGS.WHAT_IS_THIS}
                </StyledHeadLine2>
                <Typography.Text
                    type='body1'
                    data-e2e='dp_learnMorePage-what-is-this-description'
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            </StyledTextWrapper>
        </LearnMoreLayout>
    );
}

export default LearnMore;
