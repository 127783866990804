import * as moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'dddd, MMMM DD, YYYY';

export const DATE_FORMATS = {
    DEFAULT: 'dddd, MMMM DD, YYYY',
    MMMM_DD: 'MMMM DD'
};

export const convertDateToDefaultFormat = (date, dateFormat = DATE_FORMATS.DEFAULT) => {
    if (!date) {
        return date;
    }

    return moment(date).format(dateFormat);
};

export const msToDisplayFormat = (timeLeftInMilliseconds) => {
    if (!timeLeftInMilliseconds) {
        return timeLeftInMilliseconds;
    }

    const timeLeftInSeconds = timeLeftInMilliseconds / 1000;

    if (timeLeftInSeconds <= 0) {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    const d = Math.floor(timeLeftInSeconds / (3600 * 24));
    const h = Math.floor((timeLeftInSeconds % (3600 * 24)) / 3600);
    const m = Math.floor((timeLeftInSeconds % 3600) / 60);
    const s = Math.floor((timeLeftInSeconds % 3600) % 60);

    return {
        days: d,
        hours: h,
        minutes: m,
        seconds: s
    };
};
