import React, { Fragment, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../../../constants';
import Typography from '../../../../../../../../../../../UiLibrary/Typography';
import { GRID_POINTS } from '../../../../../../../../../../constants';
import { ReviewsContainer } from '../../../../../ReviewsContainer';
import { Details } from './components/details';
import { TabHead, TabHeadItem } from './styles';

export const Tabs = inject('store')(
    observer(({ store }) => {
        const simple = store.view.isSimple(GRID_POINTS.sm);
        const details = store.product.getData('description');
        const reviews = store.reviews.reviews;
        const brandLink = store.product.getData('brandLink');
        const handleParameter = store.product.getData('handle');
        const [currentTab, setCurrentTab] = useState('details');

        useEffect(() => {
            if (!details && reviews) {
                setCurrentTab('reviews');
            }
        }, [details, reviews]);

        if (typeof store.useProductReviewNotification === 'function') {
            store.useProductReviewNotification(store.notifications?.PRODUCT_REVIEW[3], currentTab);
        }

        return (
            <Fragment>
                <TabHead>
                    {details ? (
                        <TabHeadItem
                            data-e2e='details-button'
                            simple={simple}
                            active={currentTab === 'details'}
                            onClick={() => setCurrentTab('details')}
                        >
                            <Typography.Text type='badgeMedium' align='center'>
                                Product description
                            </Typography.Text>
                        </TabHeadItem>
                    ) : null}
                    {store.view.customizations?.reviews?.showReviews ? (
                        <TabHeadItem active={currentTab === 'reviews'} onClick={() => setCurrentTab('reviews')}>
                            <Typography.Text type='badgeMedium' align='center'>
                                Reviews
                            </Typography.Text>
                        </TabHeadItem>
                    ) : null}
                </TabHead>
                <div style={BOTTOM_INDENTS.XL}>
                    {details && currentTab === 'details' ? (
                        <Details
                            simple={simple}
                            data={details}
                            brandLink={brandLink}
                            handleParameter={handleParameter}
                        />
                    ) : null}
                    {currentTab === 'reviews' ? (
                        <ReviewsContainer simple={simple} withPadding={simple} scrollReviewImages />
                    ) : null}
                </div>
            </Fragment>
        );
    })
);
