import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigationType, useParams } from 'react-router-dom';
import { Search, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import CashBackInfo from '../../shared/components/CashBackInfo';
import NotAvailable from '../../shared/components/NotAvailable';
import { GRID_POINTS, PAGE_TYPES } from '../../shared/constants';
import { useCustomerId } from '../../shared/hooks';
import { Layout } from './components/Layout';
import { SuiteSideBar } from './components/SuiteSideBar';
import { ROUTES } from './constants/routes';
import { FIELD_NAMES } from './constants';

const { MARKETPLACE, PDP } = FIELD_NAMES;

const ShopPage = inject('store')(
    observer(({ store }) => {
        const { promotionId } = useParams();
        const location = useLocation();
        const navigationType = useNavigationType();
        const { showPDP, productId, openPDP } = store.pdpStore;
        const { pending, error, data: publisherInfo } = store.publisherStore;

        const customerId = useCustomerId();
        const simple = useSimpleInterface(GRID_POINTS.md);

        useEffect(() => {
            if (promotionId) {
                openPDP({ promotionId });
            }
        }, [promotionId]);

        if (!customerId || error) return <NotAvailable />;

        return (
            <Layout
                productId={productId}
                header={!simple && <Search location={location} navigationType={navigationType} type='dp' />}
                sidebar={<SuiteSideBar />}
                cashbackInfo={
                    !pending &&
                    publisherInfo && (
                        <CashBackInfo
                            pageType={showPDP ? PAGE_TYPES.PDP : PAGE_TYPES.MARKETPLACE}
                            badgeText={publisherInfo && publisherInfo.text.topBanner[showPDP ? PDP : MARKETPLACE]}
                            learnMoreText={publisherInfo && publisherInfo.text.learnMore}
                            simple={simple}
                            publisherName={publisherInfo && publisherInfo.name}
                        />
                    )
                }
            >
                <Outlet />
            </Layout>
        );
    })
);

ShopPage.ROUTES = ROUTES;

export default ShopPage;
