import React, { useCallback } from 'react';
import { useModal } from '../../../../../../../../../../UiLibrary';
import { Gallery } from '../components/gallery';

export const useGalleryModal = (simple = false) => {
    const { setModal, hideModal } = useModal();

    return useCallback(
        (images, mainImageIndex) => {
            setModal(<Gallery images={images} initialSlide={mainImageIndex} onClose={hideModal} simple={simple} />, {
                fullscreen: simple,
                closable: false
            });
        },
        [setModal, hideModal, simple]
    );
};
