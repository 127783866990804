import styled, { css } from 'styled-components';

export const IconWrap = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    height: 1em;
    font-size: ${({ size }) => size || 'inherit'};
    color: ${({ theme, color }) => theme.colors[color] || color || 'inherit'};
    transition: ${({ theme }) => theme.transition.default};

    &:focus {
        outline: none;
    }

    &:focus::after {
        border-color: ${({ theme }) => theme.colors.lines};
    }

    &::after {
        content: '';
        position: absolute;

        height: ${({ roundedSize }) => roundedSize};
        width: ${({ roundedSize }) => roundedSize};
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        border-radius: 50%;
        z-index: 2;
        background-color: transparent;
        border: 4px solid transparent;
        opacity: ${({ roundedOpacity }) => roundedOpacity};
        transition: ${({ theme }) => theme.transition.default};
    }

    ${(props) =>
        props.clicable
            ? css`
                  cursor: pointer;

                  &::before {
                      height: ${({ roundedSize }) => roundedSize};
                      width: ${({ roundedSize }) => roundedSize};
                      top: 50%;
                      left: 50%;
                      transform: translate3d(-50%, -50%, 0);

                      content: '';
                      position: absolute;
                      border-radius: 50%;
                      z-index: 1;
                      background-color: ${({ roundedColor, theme }) =>
                          roundedColor ? theme.colors[roundedColor] || roundedColor : 'transparent'};
                      opacity: ${({ roundedOpacity }) => roundedOpacity};
                      transition: ${({ theme }) => theme.transition.default};
                  }

                  &:hover::before {
                      background-color: ${({ theme, hoverRoundedColor }) =>
                          theme.colors[hoverRoundedColor] || hoverRoundedColor || theme.colors.lines};
                  }
              `
            : ''}
    svg {
        position: relative;
        z-index: 2;
    }
`;
