import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { paramsStorage } from '../utils';

export const useCustomerId = () => {
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const id = queryParams.get('id') || paramsStorage.get('id');

    useEffect(() => {
        if (id) paramsStorage.set('id', id).save();
    }, [id]);

    return id;
};
