import { Modal } from 'antd';
import styled, { css } from 'styled-components';

export const StyledAntdModal = styled(Modal)`
    ${({ $theme }) => css`
        &.ant-modal {
            .ant-modal-content {
                padding: 0;
            }

            ${({ $padding }) =>
                $padding
                    ? css`
                          .ant-modal-body {
                              padding: ${$padding};
                          }
                      `
                    : ''}

            ${({ $fullscreen, $defaultFooter }) =>
                $fullscreen
                    ? css`
                          &,
                          & .ant-modal-content {
                              max-width: 100%;
                              width: 100%;
                              height: 100%;
                              top: 0;
                              padding-bottom: 0;
                              margin: 0;
                          }

                          & .ant-modal-body {
                              height: ${() => ($defaultFooter ? 'calc(100% - 53px)' : '100%')};
                          }
                      `
                    : ''}
        }
    `}
`;
