import React from 'react';
import { KliBaseField } from '../Base/';

export const KliCurrencyField = React.forwardRef(
    ({ currency = 'USD', locales = 'en-us', minimumFractionDigits = 0, ...props }, ref) => {
        const formatter = (value) => {
            return new Intl.NumberFormat(locales, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: minimumFractionDigits
            }).format(value);
        };

        const parser = (value) => {
            return String(value).replace(/[^0-9.]/g, '');
        };

        return (
            <KliBaseField ref={ref} {...props} type='text' formatter={formatter} parser={parser} withoutClearButton />
        );
    }
);

KliCurrencyField.displayName = 'KliField.Currency';
