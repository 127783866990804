import { flow, getEnv, getParent, types } from 'mobx-state-tree';
import { getFilterParamsFromUrl } from '../../../utils';
import { INITIAL_STORE } from './constants/initialStore';
import { models } from './constants';
export const RootStore = types
    .model('SearchStore', {
        kcpAPI: types.maybeNull(types.frozen()),
        search: types.maybeNull(types.string),
        autoSuggestions: types.maybeNull(types.array(types.string)),
        suggestions: types.maybeNull(types.array(models.SuggestionModel))
    })
    .volatile(() => ({
        onSearch: types.function,
        onClear: types.function,
        shouldClearOnBackArrowClick: types.function,
        onBeforeSearch: types.function
    }))
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get get() {
            return getEnv(self).get;
        }
    }))

    .actions((self) => ({
        afterInit(props) {
            self.kcpAPI = props.kcpAPI;
            self.shouldClearOnBackArrowClick = props.shouldClearOnBackArrowClick;
            self.onBeforeSearch = props.onBeforeSearch;
            self.onSearch = props.onSearch;
            self.onClear = props.onClear;
        },

        setAutoSuggestions(data) {
            self.autoSuggestions = data;
        },

        setSuggestions(data) {
            self.suggestions = data;
        },

        setSearch(data) {
            self.search = data;
        },

        onSearchChange: flow(function* onSearchChange(data, queryParams, clear = false) {
            if (self.search !== data && !clear) {
                self.search = data || self.search;
                if (self.search) {
                    const suggestions = yield self.onSearch({
                        queryParams,
                        isNewSearch: true,
                        searchValue: self.search
                    });
                    return suggestions ? (self.suggestions = suggestions) : null;
                } else {
                    self.search = '';
                    return self.onClear();
                }
            } else {
                self.search = '';
                if (clear) return self.onClear();
            }
        }),

        getAutoSuggestions: flow(function* getAutoSuggestions(text) {
            try {
                const data = yield self.get(`${self.kcpAPI.marketplace.search.autoSuggestions}?q=${text}`);
                self.autoSuggestions = data.autosuggestions;
            } catch (e) {
                self.autoSuggestions = [];
            }
        }),

        afterCreate() {
            const { search } = getFilterParamsFromUrl();
            self.search = search;
        }
    }));

export default RootStore.create({}, INITIAL_STORE);
