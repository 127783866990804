const DP_GTM_ID = process.env.REACT_APP_DP_GTM_ID;

export const loadGTM = () => {
    if (!DP_GTM_ID) {
        return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
        window.addEventListener('gtm_loaded', function onGTMLoaded() {
            window.removeEventListener('gtm_loaded', onGTMLoaded);
            return resolve(true);
        });
        // eslint-disable-next-line promise/prefer-await-to-callbacks
        window.addEventListener('gtm_error', function onGTMError(error) {
            window.removeEventListener('gtm_error', onGTMError);
            return reject(error);
        });

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.text = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;
            j.addEventListener('load', function() {
                var _ge = new CustomEvent('gtm_loaded', { bubbles: true });
                d.dispatchEvent(_ge);
            });
            j.addEventListener('error', function() {
                var _ge = new CustomEvent('gtm_error', { bubbles: true });
                d.dispatchEvent(_ge);
            });
            f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${DP_GTM_ID}');`;
        document.head.appendChild(script);
        window.dataLayer = window.dataLayer || []; // required to start working with gtm in react
    });
};
