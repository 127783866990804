import React from 'react';
import { CloseXIcon, GRID_POINTS, Icon, Typography, useSimpleInterface } from '@klickly/front-packages';
import RewardsModalContent from '../components/RewardsModalContent';
import { $primary } from '../constants';
import { useModal } from './useModal';

export const useRewardsModal = () => {
    const simple = useSimpleInterface(GRID_POINTS.md);
    const { openModal, closeModal } = useModal({
        modalOptions: {
            closable: simple,
            closeIcon: <Icon size='24px' component={() => <CloseXIcon color={simple && $primary} />} />
        }
    });

    const openRewardModal = (variant) =>
        openModal({
            title: <Typography.Title color={simple && $primary}>Apply Rewards</Typography.Title>,
            children: <RewardsModalContent variant={variant} onCloseModal={closeModal} />
        });

    return { openRewardModal };
};
