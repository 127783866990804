import React from 'react';
import { KliTextarea } from '../../../UiLibrary';
import { FieldErrorStyles, FieldStyles } from './styles';

// eslint-disable-next-line react/display-name
export const TextArea = React.forwardRef(({ error, ...rest }, ref) => {
    return (
        <FieldStyles>
            <KliTextarea invalid={Boolean(error)} withoutClearButton ref={ref} {...rest} />
            {error && (
                <FieldErrorStyles type='descriptor2' color='error'>
                    {error}
                </FieldErrorStyles>
            )}
        </FieldStyles>
    );
});
