import React, { forwardRef, useContext } from 'react';
import { KliThemeContext } from '../../../KliThemeProvider';
import BaseInput from '../BaseInput';
import { StyledTextArea, StyledWrapper } from './styled';

// eslint-disable-next-line react/display-name
const TextArea = forwardRef(({ labelHeight, ...props }, ref) => {
    const theme = useContext(KliThemeContext);
    return (
        <StyledWrapper $theme={theme} $labelHeight={labelHeight}>
            <BaseInput {...props} ref={ref} component={StyledTextArea} />
        </StyledWrapper>
    );
});

export default TextArea;
