import { Typography } from '@klickly/front-packages';
import styled from 'styled-components/macro';

export const FieldStyles = styled.div`
    width: 100%;
`;

export const FieldErrorStyles = styled(Typography.Text)`
    padding: 8px 0 0;
`;
