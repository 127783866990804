import React, { useContext } from 'react';
import { LoaderIcon } from '../../../../../icons';
import { KliThemeContext } from '../../../KliThemeProvider';
import BaseIcon from '../BaseIcon';
import { StyledLoader } from './styled';

const Loader = ({ overlayColor, position = 'absolute', color = 'primary', size = '80px', zIndex = 5, ...props }) => {
    const theme = useContext(KliThemeContext);
    return (
        <StyledLoader $theme={theme} $position={position} $overlayColor={overlayColor} $zIndex={zIndex}>
            <BaseIcon color={color} size={size} component={() => <LoaderIcon />} {...props} />
        </StyledLoader>
    );
};

export default Loader;
