import React from 'react';
import { inject, observer } from 'mobx-react';
import { ActionsMinusIcon, ActionsPlusIcon } from '../../../../../../../../../../../../../../icons';
import { Icon } from '../../../../../../../../../../../../../UiLibrary';
import { Input, StyledButton, Styles } from './styles';

const Qty = inject('store')(
    observer(({ store }) => {
        return (
            <Styles data-e2e='product-qty'>
                <StyledButton
                    data-e2e='qty-deduct-btn'
                    type='unstyled'
                    disabled={store.product.variants.qty <= store.product.variants.minQty}
                    onClick={() => store.product.variants.decrementQty()}
                >
                    <Icon
                        style={{ pointerEvents: 'none' }}
                        stylePriority
                        size='16px'
                        component={() => <ActionsMinusIcon />}
                    />
                </StyledButton>
                <Input data-e2e='qty-value' readOnly value={store.product.variants.qty} />
                <StyledButton
                    data-e2e='qty-add-btn'
                    type='unstyled'
                    disabled={store.product.variants.qty === store.product.variants.maxQty}
                    onClick={() => store.product.variants.incrementQty()}
                >
                    <Icon
                        style={{ pointerEvents: 'none' }}
                        stylePriority
                        size='16px'
                        component={() => <ActionsPlusIcon />}
                    />
                </StyledButton>
            </Styles>
        );
    })
);

export default Qty;
