import styled from 'styled-components';
import { Button } from '../../../../../UiLibrary';

export const StyledButton = styled(Button)`
    && {
        height: 48px;
        padding: 14px 20px 14px 0;

        .anticon {
            width: 20px;
            height: 20px;

            > svg {
                font-size: 20px;
                color: ${({ $theme }) => $theme.colors.disabledState};
            }
        }
    }
`;
