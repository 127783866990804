import { Button, Typography } from '@klickly/front-packages';
import styled from 'styled-components';
import { $secondaryText } from '../../../../constants';

export const StyledWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const StyledContent = styled.div`
    flex-grow: 1;
    margin-left: 16px;
`;

export const StyledTitle = styled(Typography.Title)`
    margin-bottom: 4px;
`;

export const StyledDescription = styled(Typography.Text)`
    margin-bottom: 4px;
`;

export const StyledExpiredAt = styled(Typography.Text)`
    font-weight: normal;
    color: ${$secondaryText};
`;

export const StyledShowMoreButton = styled(Button)`
    padding-left: 0;

    &:focus:not(:hover) {
        border: none;
        outline: none;
    }

    p {
        font-weight: 500;
    }
`;
