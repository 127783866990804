import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { getVariantByPrice } from '../../../../../components/ShopPage/components/PromotionsPage/utils/price';
import { GRID_DEVICES_MAX } from '../../../../constants';
import { LayoutContentListItem } from '../../../LayoutContentListItem';
import { Promotion } from '../../../Promotion';
import SliderHeader from './components/SliderHeader';
import { useSliderSettings } from './hooks';
import { StyledSliderWrapper, StyledWrapper } from './styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PromotionsSlider = inject('store')(
    observer(({ store, promotions, title, categoryKey, settings = {}, showPDP, closePDP }) => {
        const navigate = useNavigate();
        const location = useLocation();
        const [currentSlide, setCurrentSlide] = useState(0);
        const sliderSettings = useSliderSettings(settings);
        const slider = useRef(null);
        const isLessThan1200px = useSimpleInterface(GRID_DEVICES_MAX.xl);

        return (
            <StyledWrapper>
                <SliderHeader
                    sliderRef={slider}
                    title={title}
                    categoryKey={categoryKey}
                    prevDisabled={currentSlide === 0}
                    nextDisabled={currentSlide + (isLessThan1200px ? 2 : 3) >= promotions.length}
                    currentSlide={currentSlide}
                    slidesCount={promotions.length}
                />
                <StyledSliderWrapper $singleItem={promotions?.length < (isLessThan1200px ? 2 : 3)}>
                    <Slider
                        ref={slider}
                        {...sliderSettings}
                        beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
                    >
                        {promotions.map((promotion) => {
                            const variant = getVariantByPrice(promotion.variants);

                            return (
                                <LayoutContentListItem
                                    inSlider
                                    fullWidth
                                    fullHeight
                                    key={promotion._id}
                                    data-e2e='recommended-product'
                                >
                                    <Promotion
                                        inSlider
                                        variant={variant}
                                        onBuy={() => {
                                            store.marketplaceStore.setCashBackValue(variant?.cashbackPrice);

                                            if (showPDP) closePDP();

                                            navigate({
                                                pathname: `/marketplace/${promotion._id}`,
                                                search: location.search
                                            });
                                        }}
                                        promotion={promotion}
                                    />
                                </LayoutContentListItem>
                            );
                        })}
                    </Slider>
                </StyledSliderWrapper>
            </StyledWrapper>
        );
    })
);

export default PromotionsSlider;
