import React from 'react';
import { useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../../../../../shared/constants';
import { useModal } from '../../../../../../../shared/hooks';
import EmailSubscriptionModal from '../index';

export const useEmailSubscriptionModal = () => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { openModal, closeModal } = useModal({
        modalOptions: {
            closable: false,
            closeIcon: null,
            width: 460
        },
        titleOptions: {
            $display: 'none'
        },
        modalWrapperOptions: {
            $width: '460px',
            $borderRadius: '12px'
        },
        modalContentOptions: {
            $padding: '0px'
        }
    });

    const openEmailSubscriptionModal = () =>
        openModal({
            title: null,
            children: <EmailSubscriptionModal simple={simple} onCloseModal={closeModal} />
        });

    return { openEmailSubscriptionModal };
};
