import React, { createContext } from 'react';
import { AmexIcon, DiscoverIcon, MastercardIcon, VisaIcon } from '../../../../../icons';

export const StripePaymentTokenFields = Object.freeze({
    cardNumber: 'cardNumber',
    cardCvc: 'cardCvc',
    cardExpiry: 'cardExpiry'
});

export const CardsIconByBrand = Object.freeze({
    visa: <VisaIcon />,
    mastercard: <MastercardIcon />,
    discover: <DiscoverIcon />,
    amex: <AmexIcon />
});

export const StripePaymentTokenFieldsPlaceholders = Object.freeze({
    [StripePaymentTokenFields.cardNumber]: 'Card Number',
    [StripePaymentTokenFields.cardCvc]: 'CVC',
    [StripePaymentTokenFields.cardExpiry]: 'MM / YY'
});

export const StripePaymentTokenInitialState = {
    [StripePaymentTokenFields.cardNumber]: {
        complete: false,
        error: null
    },
    [StripePaymentTokenFields.cardCvc]: {
        complete: false,
        error: null
    },
    [StripePaymentTokenFields.cardExpiry]: {
        complete: false,
        error: null
    }
};
export const StripePaymentTokenContext = createContext(StripePaymentTokenInitialState);
