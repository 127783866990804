import styled from 'styled-components';
import { $primary } from '../../../../../../constants';

export const StyledPubText = styled.span`
    display: inline-flex;
    padding-right: 4px;

    &&& {
        color: ${$primary} !important;
        margin-right: ${({ $marginRight }) => $marginRight || 0};
    }
`;

export const StyledWord = styled.span`
    &&& {
        margin-right: 0;
        padding-right: 4px;
    }
`;
