import React, { Fragment, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../constants';
import { CircleActionsAlertInfoIcon } from '../../../../../../../../../../icons';
import { cashbackCalculation } from '../../../../../../../../../../utils/commissionCalculation';
import { GRID_POINTS } from '../../../../../../../../constants';
import { ErrorField } from '../ErrorField';
import { CheckoutButtons } from './components/checkoutButtons';
import { OptionsBlock } from './components/optionsBlock';
import { Prices } from './components/prices';
import { ReviewsRating } from './components/reviewsRating';
import { Tabs } from './components/tabs';
import { StyledBrandName, StyledBrandNameButton, StyledInfo, StyledProductInfoWrapper, StyledTitle } from './styles';

const ProductInfo = inject('store')(
    observer(({ store, optionsRef, bottomExtraContent, customButtons }) => {
        const simple = store.view.isSimple(GRID_POINTS.sm);
        const appWidth = store.view.appWidth;
        const selectedVariant = store.product.variants.selectedVariant;

        const brandLink = store.product.getData('brandLink');
        const brandId = store.product.getData('account');
        const rating = store.product.getData('rating');
        const httpError = store.errors.getError('httpProductError');

        const scrollToOptions = () => {
            if (optionsRef && optionsRef.current) {
                optionsRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        };

        // -----START---Code for Distribution Partners only-----
        const quantity = store.product.variants.qty;
        const onVariantChange = store.callbacks.onVariantChange;
        const cashback = cashbackCalculation(store);

        useEffect(() => {
            if (typeof onVariantChange === 'function' && cashback) {
                const cashbackNumber = Number(cashback.replace(/,/g, ''));

                onVariantChange(cashbackNumber);
            }
        }, [selectedVariant, quantity]);
        // -----END---Code for Distribution Partners only-----

        const onBrandNameClick = (e) => {
            e.stopPropagation();
            if (!brandLink || !brandId) return null;
            store.callbacks.onBrandNameClick(store.product.trackingData, brandLink, brandId);
        };

        return (
            <StyledProductInfoWrapper data-e2e='product-info-block' $appWidth={appWidth}>
                <StyledInfo style={BOTTOM_INDENTS.SM} simple={simple}>
                    {rating !== null && simple ? <ReviewsRating /> : null}
                    <StyledBrandNameButton
                        data-e2e='product-brand-name'
                        type='unstyled'
                        $linkToBrand
                        onClick={onBrandNameClick}
                    >
                        <StyledBrandName $appWidth={appWidth} color='secondaryText' type='body1'>
                            {store.product.getData('brandName', '')}
                        </StyledBrandName>
                    </StyledBrandNameButton>
                    <StyledTitle data-e2e='product-title' level={2} $appWidth={appWidth}>
                        {store.product.getData('title', '')}
                    </StyledTitle>
                    {rating !== null && !simple ? <ReviewsRating /> : null}
                </StyledInfo>
                <OptionsBlock optionsRef={optionsRef} />
                <Prices />
                {simple && bottomExtraContent ? bottomExtraContent : null}
                {httpError ? (
                    <Fragment>
                        <ErrorField showIcon icon={<CircleActionsAlertInfoIcon />} error={httpError} />
                    </Fragment>
                ) : null}
                {/* desktop buttons */}
                {!simple && (
                    <div style={BOTTOM_INDENTS.SM}>
                        <CheckoutButtons
                            scrollToOptionsCallback={scrollToOptions}
                            customButtons={customButtons}
                            hasBottomIndent
                        />
                    </div>
                )}
                <Tabs />
            </StyledProductInfoWrapper>
        );
    })
);

export default ProductInfo;
