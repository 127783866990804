import { Menu } from 'antd';
import styled, { css } from 'styled-components';

export const StyledMenu = styled(Menu)`
    ${({ $theme }) => css`
        border-right: none;

        &.ant-menu-vertical .ant-menu-item {
            margin: 0 0 24px 0;
        }

        &.ant-menu-vertical .ant-menu-item:not(:last-child) {
            margin-bottom: 24px;
        }

        .ant-menu-submenu {
            margin-bottom: 0;
            margin-top: -1px;
            padding: 0;
        }

        .ant-menu-item {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 20px;
            color: ${$theme.colors.secondaryText};
            font: ${$theme.fonts.body2};

            &:before {
                position: absolute;
                left: 0;
                width: 4px;
                height: 100%;
                content: '';
                background: transparent;
                transition: background 0.3s ease-in-out;
            }

            a:not(.active) {
                color: ${$theme.colors.secondaryText};
            }

            .ant-menu-item-icon {
                width: 24px;
                height: 24px;
            }

            &.ant-menu-item-active,
            &:has(.active) {
                color: ${$theme.colors.primaryText};

                &:before {
                    background: ${$theme.colors.primary};
                }
            }

            &.ant-menu-item-selected,
            &:active {
                background: ${$theme.colors.canvas};
            }

            &:hover,
            a:hover,
            &.ant-menu-item-selected a {
                color: ${$theme.colors.primaryText};
            }
        }
    `};
`;
