import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { $background } from '../../styles';

export const WrapStyles = styled.div`
    min-width: 430px;
    width: 430px;
    height: 100%;
    overflow-y: auto;
    background-color: ${$background};

    ${({ simple }) =>
        simple
            ? css`
                  min-width: unset;
                  width: 100%;
                  height: auto;
                  overflow-y: visible;
              `
            : ''};
`;
