import React, { lazy } from 'react';
import { Icon } from '@klickly/front-packages';
import ShopPage from './components/ShopPage';
import { ROUTE_PATHS, ROUTE_TITLES } from './shared/constants';
const InitialPage = lazy(() => import('./components/InitialPage'));
const PreCheckoutPage = lazy(() => import('./components/PreCheckoutPage'));
const CheckoutPage = lazy(() => import('./components/CheckoutPage'));
const TermsPage = lazy(() => import('./components/TermsPage'));
const ThankYouPage = lazy(() => import('./components/ThankYouPage'));
const BetaTermsPage = lazy(() => import('./components/BetaTermsPage'));
const PrivacyPolicyPage = lazy(() => import('./components/PrivacyPolicyPage'));
const PrivacyCaliforniaPage = lazy(() => import('./components/PrivacyCaliforniaPage'));
const PrivacyOptOutPage = lazy(() => import('./components/PrivacyOptOutPage'));
export const routes = [
    {
        path: ROUTE_PATHS.marketplace(),
        title: ROUTE_TITLES.marketplace(),
        fallback: <Icon.Loader />,
        element: <ShopPage />,
        children: ShopPage.ROUTES
    },
    {
        path: ROUTE_PATHS.checkout(),
        title: ROUTE_TITLES.checkout(),
        fallback: <Icon.Loader />,
        exact: true,
        element: <CheckoutPage />
    },
    {
        path: ROUTE_PATHS.preCheckout(),
        title: ROUTE_TITLES.checkout(),
        fallback: <Icon.Loader />,
        exact: true,
        element: <PreCheckoutPage />
    },
    {
        path: ROUTE_PATHS.betaTerms(),
        title: ROUTE_TITLES.betaTerms(),
        fallback: <Icon.Loader />,
        element: <BetaTermsPage />
    },
    {
        path: ROUTE_PATHS.terms(),
        title: ROUTE_TITLES.terms(),
        fallback: <Icon.Loader />,
        element: <TermsPage />
    },
    {
        path: ROUTE_PATHS.privacy(),
        title: ROUTE_TITLES.privacy(),
        fallback: <Icon.Loader />,
        element: <PrivacyPolicyPage />
    },
    {
        path: ROUTE_PATHS.privacyCalifornia(),
        title: ROUTE_TITLES.privacyCalifornia(),
        fallback: <Icon.Loader />,
        element: <PrivacyCaliforniaPage />
    },
    {
        path: ROUTE_PATHS.privacyOptOut(),
        title: ROUTE_TITLES.privacyOptOut(),
        fallback: <Icon.Loader />,
        element: <PrivacyOptOutPage />
    },
    {
        path: ROUTE_PATHS.thankYouPage(),
        title: ROUTE_TITLES.thankYouPage(),
        fallback: <Icon.Loader />,
        element: <ThankYouPage />
    },
    {
        path: ROUTE_PATHS.root(),
        title: ROUTE_PATHS.root(),
        fallback: <Icon.Loader />,
        element: <InitialPage />
    }
];
