import React, { Fragment } from 'react';
import nextId from 'react-id-generator';
import { StyledSuggestionPart } from './styled';
import { getHighlightedText } from './utils';

const SuggestionContent = ({ suggestion, searchValue }) => {
    if (!suggestion) return null;
    const highlightedText = getHighlightedText(suggestion, searchValue);
    const parts = suggestion.split(highlightedText);
    return (
        <Fragment>
            {parts.map((part, index) => (
                <StyledSuggestionPart key={nextId()}>
                    <Fragment>
                        {part}
                        {index !== parts.length - 1 && <b>{highlightedText}</b>}
                    </Fragment>
                </StyledSuggestionPart>
            ))}
        </Fragment>
    );
};

export default SuggestionContent;
