import { checkSelected } from './parseFiltersFromQuery';
import { parseQuery } from './queryParams';

export const processBrandsList = (brandsList) => {
    const brands = brandsList.map((brandData) => ({
        name: brandData.name,
        key: brandData._id,
        count: brandData.count
    }));

    return checkSelected(brands, parseQuery().brands);
};
