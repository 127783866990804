import { useEffect, useState } from 'react';
import { getFilterParamsFromUrl } from '../../../utils';
import { useBrowserButtonsEvents } from './useBrowserButtonsEvents';
import { useDebounce } from './useDebounce';

export const useSearch = ({
    shouldClearOnBackArrowClick,
    onBeforeSearch,
    search,
    onSearchChange,
    setSearch,
    getAutoSuggestions,
    setAutoSuggestions,
    location,
    navigationType
}) => {
    const [value, setValue] = useState('');
    const { search: searchParam } = getFilterParamsFromUrl();

    const debounce = useDebounce((newValue) => {
        setValue(newValue);
        return newValue && newValue.length > 2 ? getAutoSuggestions(newValue) : setAutoSuggestions(null);
    }, 1);

    const handleClear = () => {
        setValue('');
        setSearch('');
        setAutoSuggestions(null);
        return onSearchChange('', null, true);
    };

    useEffect(() => {
        if (search) {
            const searchQueryValue = typeof search === 'string' ? search : search.q;
            if (searchQueryValue !== value) setValue(searchQueryValue);
        }
    }, [search]);

    useEffect(() => {
        if (!searchParam) {
            setSearch('');
            setValue('');
        }
    }, [searchParam]);

    useBrowserButtonsEvents({
        handleBackArrowClick: () => (shouldClearOnBackArrowClick ? handleClear() : null),
        navigationType,
        location
    });

    const onSubmit = (data) => {
        // TODO: refactor after react-router update on Giftly

        onBeforeSearch();

        setAutoSuggestions(null);

        return onSearchChange(data?.search || value);
    };

    return {
        value,
        setValue,
        debounce,
        handleClear,
        onSubmit
    };
};
