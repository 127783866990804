import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
    position: relative;
    ${({ $inMobileMenu }) =>
        $inMobileMenu &&
        css`
            .ant-checkbox-wrapper {
                display: none;
            }

            label {
                display: flex;
                align-items: center;
                height: 64px;
            }
        `}

    min-height: ${({ $pending }) => ($pending ? '200px' : 'initial')}
`;

export const StyledLoader = styled.div`
    min-height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;
