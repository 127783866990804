import { getParent, types } from 'mobx-state-tree';
import { ROUTE_PATHS } from '../shared/constants';

export const CheckoutStore = types
    .model('CheckoutStore', {
        cartId: types.maybeNull(types.string),
        checkoutKey: types.maybeNull(types.string)
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const openCheckout = ({ cartId, checkoutKey }) => {
            self.cartId = cartId;
            self.checkoutKey = checkoutKey;
            self.root.routeNavigate(
                {
                    pathname: ROUTE_PATHS.checkout(),
                    search: self.root.routeLocation.search
                },
                {
                    state: {
                        cartId,
                        checkoutKey,
                        // set cashBack from promotions store after migration
                        email: null
                    }
                }
            );
        };

        const clearState = () => {
            self.cartId = null;
            self.checkoutKey = null;
        };

        return {
            openCheckout,
            clearState
        };
    });
