export const getColor = ({ $theme, status, isBorder = false }) => {
    switch (status) {
        case 'error':
            return $theme.colors.error;
        case 'success':
            return $theme.colors.success;
        default:
            return isBorder ? 'transparent' : $theme.colors.secondaryText;
    }
};
