import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../constants';
import { ForwardArrowIcon } from '../../../../../../../../icons';
import { Icon, Typography } from '../../../../../../../UiLibrary';
import { ReviewsStarRating } from '../ReviewStartRating';
import {
    ReviewBody,
    ReviewHead,
    ReviewHeadLine,
    ReviewImages,
    ReviewStyles,
    ReviewText,
    StyledButton,
    StyledImage
} from './styles';

const Review = observer(({ review, showReview, withPadding = false, scrollImages = false, fullReview = false }) => {
    const createdAt = new Date(review.createdAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return (
        <ReviewStyles withPadding={withPadding}>
            <ReviewHead style={BOTTOM_INDENTS.SM}>
                <ReviewHeadLine style={BOTTOM_INDENTS.SM} align='center'>
                    <Typography.Text type='body1'>{review.customerFullName}</Typography.Text>
                    {!fullReview && (
                        <StyledButton size='medium' type='unstyled' onClick={() => showReview(review)}>
                            <Typography.Text type='badgeSmall'>Read full review</Typography.Text>
                            <Icon size='16px' component={() => <ForwardArrowIcon />} />
                        </StyledButton>
                    )}
                </ReviewHeadLine>
                <ReviewHeadLine style={BOTTOM_INDENTS.SM}>
                    <Typography.Text type='descriptor2' color='secondaryText'>
                        {review?.customer && <Fragment>Giftly member &bull;&nbsp;</Fragment>}
                        Purchased {createdAt}
                    </Typography.Text>
                </ReviewHeadLine>
                <ReviewHeadLine>
                    <ReviewsStarRating rate={review.rating} />
                </ReviewHeadLine>
            </ReviewHead>
            <ReviewBody>
                <ReviewText style={BOTTOM_INDENTS.SM} $fullReview={fullReview} type='body2' color='primaryText'>
                    {review.content}
                </ReviewText>
                <ReviewImages $fullReview={fullReview} scrollImages={scrollImages}>
                    {review.images && review.images.length
                        ? review.images.map((image, key) => (
                              <StyledImage key={key} src={image.src} $fullReview={fullReview} />
                          ))
                        : null}
                </ReviewImages>
            </ReviewBody>
        </ReviewStyles>
    );
});

export default Review;
