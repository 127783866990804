import React from 'react';
import { Alert, InfoIcon } from '@klickly/front-packages';

function RewardInfo({ closable, isFixedOnMobile, bottomIndent = '20px' }) {
    return (
        <Alert
            closable={closable}
            isFixedOnMobile={isFixedOnMobile}
            data-e2e='dp_rewardInfo-box'
            description='Displaying new-customer reward offers. Begin checkout to see offers for returning customers.'
            onClick={() => null}
            marginBottom={bottomIndent}
            showIcon
            icon={<InfoIcon />}
        />
    );
}

export default RewardInfo;
