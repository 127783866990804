import { useEffect, useState } from 'react';

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

export function useWindowResize(resizeHandler) {
    const [size, setSize] = useState({
        width: getWidth(),
        height: getHeight()
    });

    useEffect(
        () => {
            let timeoutId = null;
            const resizeListener = () => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => setSize({ width: getWidth(), height: getHeight() }), 150);

                if (typeof resizeHandler !== 'undefined') {
                    resizeHandler();
                }
            };
            window.addEventListener('resize', resizeListener);

            return () => {
                window.removeEventListener('resize', resizeListener);
            };
        },
        resizeHandler ? [resizeHandler] : []
    );

    return size;
}
