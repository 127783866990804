import { Typography } from 'antd';
import styled from 'styled-components';
import { sharedTypographyStyles } from '../../styled';
import { getFontByThemeAndLevel } from './utils';

export const StyledTitle = styled(Typography.Title)`
    && {
        ${sharedTypographyStyles};
        font: ${({ $theme, level }) => getFontByThemeAndLevel($theme, level)};
    }
`;
