import React, { forwardRef, useContext } from 'react';
import { KliThemeContext } from '../../../KliThemeProvider';
import Label from '../Label';
import { StyledInputNumber } from './styled';

// eslint-disable-next-line react/display-name
const InputNumber = forwardRef(({ size, label, withoutBorderColorsOnValidate = false, trim = true, ...props }, ref) => {
    const theme = useContext(KliThemeContext);

    return (
        <Label label={label} value theme={theme} size={size}>
            <StyledInputNumber
                ref={ref}
                $theme={theme}
                size={size}
                $noValidationBorders={withoutBorderColorsOnValidate}
                {...props}
            />
        </Label>
    );
});

export default InputNumber;
