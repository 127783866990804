import React from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../../../constants';
import { GRID_POINTS } from '../../../../../../../../../../constants';
import { Options } from './components/options';
import { OptionsBlockRow } from './components/optionsBlockRow';
import Qty from './components/Qty';
import { Styles } from './styles';

export const OptionsBlock = inject('store')(
    observer(({ store, optionsRef }) => {
        const simple = store.view.isSimple(GRID_POINTS.sm);
        return (
            <Styles data-e2e='quantity-block' style={BOTTOM_INDENTS.SM} simple={simple} ref={optionsRef}>
                <OptionsBlockRow hasBottomIndent title='Quantity'>
                    <Qty />
                </OptionsBlockRow>
                <Options />
            </Styles>
        );
    })
);
