import React from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../../../../../constants';
import { Styles, Title } from './styles';

export const OptionsBlockRow = inject('store')(
    observer(({ store, hasBottomIndent = false, title, children }) => {
        const appWidth = store.view.appWidth;

        return (
            <Styles style={hasBottomIndent ? BOTTOM_INDENTS.SM : {}}>
                <Title $appWidth={appWidth} type='body2' color='primaryText'>
                    {title}
                </Title>
                {children}
            </Styles>
        );
    })
);
