import { device } from '@klickly/front-packages';
import styled from 'styled-components';
import { $bordered, GRID_DEVICES_MAX, GRID_DEVICES_MIN } from '../../../../../../constants';

const isIos = device.iOS();
const isSafari = device.isSafari();

export const Wrap = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 80px;
    position: relative;
    overflow: hidden;
`;

export const Header = styled.header`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;

    border-bottom: 1px solid ${$bordered};
`;

export const LinkWrap = styled.div`
    width: calc(100% - 10px);

    a {
        width: 80px;
    }

    @media (${GRID_DEVICES_MIN.md}) {
        max-width: 315px;
    }

    @media (${GRID_DEVICES_MAX.md}) {
        max-width: 100%;
    }
`;

export const HeaderContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'auto')};
    position: static;
`;

export const Content = styled.main`
    width: 100%;
    padding: 65px 20px 35px;

    @media (${GRID_DEVICES_MAX.md}) {
        padding-bottom: ${() => (isSafari && isIos ? '90px' : 0)};
        padding-top: 0;
    }

    @media (${GRID_DEVICES_MIN.md}) {
        width: calc(100% - 315px);
        max-width: calc(1400px - 315px);
    }
`;
