import { Tabs } from 'antd';
import styled, { css } from 'styled-components';

export const StyledTabs = styled(Tabs)`
    ${({ $theme, $titleFont }) => css`
        & {
            & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: ${$theme.colors.primary};
            }

            & .ant-tabs-tab-with-remove,
            & .ant-tabs-tab-btn {
                font: ${$theme.fonts[$titleFont] ? $theme.fonts[$titleFont] : $theme.fonts.badgeMedium};

                &:hover {
                    color: ${$theme.colors.primary};
                }
            }

            & .ant-tabs-nav-add:hover .anticon {
                color: ${$theme.colors.primary};
            }

            & .ant-tabs-ink-bar {
                background-color: ${$theme.colors.primary};
            }
        }
    `}
`;
