import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { GRID_POINTS } from '../../constants';
import { useSimpleInterface } from '../../utils';
import { Button, Typography } from '../UiLibrary';
import PointsBadge from './components/PointsBadge';
import { PromotionImg } from './components/PromotionImg';
import { PromotionPrice } from './components/PromotionPrice';
import { PromotionStyles, StyledPromotionImgWrapper, StyledTitle } from './styles';
import { getVariantByPrice } from './utils';

export const Promotion = observer(
    ({
        promotion,
        onBuy,
        withoutButton = false,
        hidePrice = false,
        height,
        showPoints = true,
        afterPointsContent = null,
        placeHolder = null
    }) => {
        const [variant, setVariant] = useState();
        const simple = useSimpleInterface(GRID_POINTS.sm);
        const firstVariant = promotion?.variants?.[0];
        const pointsDetails = firstVariant ? firstVariant?.pointsDetails : {};

        useEffect(() => {
            setVariant(getVariantByPrice(promotion.variants));
        }, [promotion, setVariant]);

        return (
            <PromotionStyles
                $height={height}
                id={promotion.id}
                data-e2e='product-card'
                direction='row'
                img={
                    <StyledPromotionImgWrapper>
                        <div>
                            <PromotionImg
                                placeHolder={placeHolder}
                                style={{ maxHeight: '150px' }}
                                images={promotion?.images}
                            />
                            {simple && !hidePrice && showPoints ? <PointsBadge pointsDetails={pointsDetails} /> : null}
                            {simple && afterPointsContent}
                        </div>
                    </StyledPromotionImgWrapper>
                }
                onClick={(e) => {
                    e.stopPropagation();
                    onBuy(promotion);
                }}
                button={
                    simple || withoutButton ? null : (
                        <Button data-e2e='buy-button' type='primary' style={{ width: '100%' }}>
                            Buy
                        </Button>
                    )
                }
            >
                <StyledTitle level={3}>{promotion?.title}</StyledTitle>
                {promotion?.brandName && <Typography.Text type='body2'>{promotion?.brandName}</Typography.Text>}
                {!hidePrice ? <PromotionPrice variant={variant} /> : null}
                {!simple && !hidePrice && showPoints ? <PointsBadge pointsDetails={pointsDetails} /> : null}
                {!simple && afterPointsContent}
            </PromotionStyles>
        );
    }
);

Promotion.Img = PromotionImg;

export default Promotion;
