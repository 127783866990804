import React, { useContext } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import { StyledAntdButton } from './styles';

export const Button = ({ children, danger, ...props }) => {
    const themeContext = useContext(KliThemeContext);

    return (
        <StyledAntdButton $theme={themeContext} {...props}>
            {children}
        </StyledAntdButton>
    );
};
