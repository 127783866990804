import React from 'react';
import { BOTTOM_INDENTS } from '../../../../../../constants';
import { Button, Typography } from '../../../../../UiLibrary';
import { BodyStyles, ButtonsWrap, StyledText } from './styles';

export const CancelOrderConfirmation = ({ onModalClose, onConfirm, simple }) => {
    return (
        <BodyStyles $simple={simple}>
            <StyledText style={BOTTOM_INDENTS.XL} type='body1'>
                Are you sure you want to cancel your order? This action cannot be undone.
            </StyledText>
            <ButtonsWrap $simple={simple}>
                <Button type='unstyled' block onClick={onModalClose} data-e2e='cancel-ord-modal-go-back-btn'>
                    <Typography.Text type='badgeLarge'>Go back</Typography.Text>
                </Button>
                &nbsp; &nbsp;
                <Button type='primary' block onClick={onConfirm} data-e2e='cancel-ord-modal-confirm-btn'>
                    <Typography.Text type='badgeLarge'>Confirm</Typography.Text>
                </Button>
            </ButtonsWrap>
        </BodyStyles>
    );
};
