export const formatPoints = (
    points,
    params = {
        thousand: false,
        million: false,
        billion: false
    }
) => {
    const { thousand, billion, million } = params;
    const formatter = new Intl.NumberFormat('en-US');

    const thousands = points / 1000;
    const millions = points / 1000000;
    const billions = points / 1000000000;

    if (isNaN(Number(points))) return null;
    if (Array.isArray(points)) return null;

    if (thousand && millions < 1) {
        return thousands >= 1 ? `${formatter.format(Math.floor(thousands))}k` : formatter.format(points);
    }

    if (million && billions < 1) {
        return millions >= 1 ? `${formatter.format(Math.floor(millions))}M` : formatter.format(points);
    }

    if (billion) {
        return billions >= 1 ? `${formatter.format(Math.floor(billions))}B` : formatter.format(points);
    }

    return formatter.format(points);
};
