import styled from 'styled-components/macro';
import { Button } from '../../../../../../../../../UiLibrary';
import { $background } from '../../../../../../../../styles';

export const Styles = styled.div`
    display: flex;
    align-items: center;
`;

export const Input = styled.input`
    border: none;
    width: 40px;
    text-align: center;
    pointer-events: none;
    cursor: auto;
`;

export const StyledButton = styled(Button)`
    && {
        &,
        &:active,
        &:hover {
            width: 28px;
            height: 28px;
            background-color: ${$background} !important;
            border-radius: 50%;
            padding: 0;
        }

        &:disabled {
            background-color: ${$background} !important;
            opacity: 0.5;
        }
    }
`;
