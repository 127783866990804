import styled, { createGlobalStyle, css } from 'styled-components';
import Icon from '../../../Icon';
import Typography from '../../../Typography';

export const StyledPopover = createGlobalStyle`
    .popover-points {
        .ant-popover-inner {
            padding: 0;
            .ant-popover-inner-content {
                padding: 8px 16px;
            }
        }
    }
`;

export const StyledContent = styled(Typography.Text)`
    && {
        display: flex;
        align-items: center;
        flex-direction: row;
        white-space: nowrap;

        ${({ $textLineHeight }) =>
            $textLineHeight &&
            css`
                line-height: ${$textLineHeight};
            `};

        > span {
            margin-left: 4px;
            font-weight: 500;
        }
    }
`;

export const StyledIcon = styled(Icon)`
    margin-left: 12px;
`;
