import { Slider } from 'antd';
import styled, { css } from 'styled-components';

export const StyledAntdSlider = styled(Slider)`
    && {
        ${({ $theme }) => css`
            & .ant-slider-track,
            &:hover .ant-slider-track {
                background-color: ${$theme.colors.primaryText};
            }

            & .ant-slider-handle {
                width: 25px;
                height: 25px;
                top: 50%;
                transform: translate(-50%, -50%) !important;
                margin: 0;
                background-color: #fff;
                border: 2px solid #91d5ff;
                border-radius: 50%;
                box-shadow: none;
                cursor: pointer;
                position: absolute;
                transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

                &:after {
                    display: none;
                }
            }

            & .ant-slider-handle,
            &:hover .ant-slider-handle,
            &:hover .ant-slider-handle:not(.ant-tooltip-open) {
                border-color: ${$theme.colors.primaryText};
            }

            .ant-slider-handle:active {
                border-color: ${$theme.colors.primary};
                box-shadow: 0 0 0 5px rgb(242, 88, 88, 0.12);
            }

            .ant-slider-handle:focus {
                box-shadow: 0 0 0 5px rgb(242, 88, 88, 0.12);
            }
        `}
    }
`;
