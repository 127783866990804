import React from 'react';
import { inject, observer } from 'mobx-react';
import { StyledSuggestionButton, StyledText, StyledTypoButton, StyledWrapper } from './styled';

const Typos = inject('store')(
    observer(({ store }) => {
        const { search, onSearchChange, setSuggestions = () => null, suggestions } = store;

        const mostRelevantSuggestion = suggestions?.length ? suggestions[0] : null;

        return mostRelevantSuggestion && search && typeof search === 'string' ? (
            <StyledWrapper>
                <StyledText type='body1'>
                    <span>Showing results for</span>
                    <StyledTypoButton type='unstyled' onClick={() => setSuggestions([])}>
                        {search}
                    </StyledTypoButton>
                </StyledText>
                <StyledText type='body2'>
                    <span>Search instead for</span>
                    <StyledSuggestionButton
                        type='unstyled'
                        onClick={() =>
                            onSearchChange(mostRelevantSuggestion.text, {
                                suggest: 0
                            })
                        }
                    >
                        {mostRelevantSuggestion.text}
                    </StyledSuggestionButton>
                </StyledText>
            </StyledWrapper>
        ) : null;
    })
);

export default Typos;
