import styled, { css } from 'styled-components';
import { $background, $tartRed, GRID_DEVICES_MAX } from '../../../../constants';

export const StyledWrapper = styled.div`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background: ${({ $hasCustomBackground, $customBackgroundColor = $tartRed }) =>
            $hasCustomBackground ? $customBackgroundColor : $background};
        text-align: center;

        svg {
            width: auto;
            height: auto;
            margin-left: 10px;
        }

        button {
            cursor: pointer;

            &.ant-btn {
                white-space: initial;
                flex-wrap: initial;

                span:not(.kli-icon):not(.kli-text) {
                    @media (${GRID_DEVICES_MAX.md}) {
                        font-size: 15px;
                    }
                }
            }

            &:hover {
                * {
                    ${({ $customBackgroundColor }) =>
                        !$customBackgroundColor &&
                        css`
                            color: inherit;
                        `};
                }
            }

            &:focus {
                outline: none;
            }

            && {
                > *:not(:last-child) {
                    margin-right: 0;
                }
            }
        }
    }
`;

export const StyledTextWrapper = styled.div`
    &&& {
        margin-right: 0;
    }
`;
