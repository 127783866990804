import React from 'react';
import { useLocation } from 'react-router-dom';
import { ArrowChevronForwardIcon, Button } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { PAGE_TYPES } from '../../../../constants';
import BadgeTextMarketplace from './components/BadgeTextMarketplace';
import BadgeTextPdp from './components/BadgeTextPdp';
import CashbackLoader from './components/CashbackLoader';
import { PLACEHOLDERS } from './constants';
import { useCashBack } from './hooks';
import { StyledTextWrapper, StyledWrapper } from './styled';

const { PDP, THANK_YOU, MARKETPLACE, CHECKOUT } = PAGE_TYPES;

const CashBackBadge = inject('store')(
    observer(({ store, pageType, onClick, badgeText, publisherName, showZeroCashback, backgroundColor }) => {
        const location = useLocation();
        const { pending, isNewCustomer } = store.coldCustomerStore;
        const { previousCashBack, cashBack } = useCashBack(store, location);
        const badgeTextArray = badgeText ? badgeText.split(pageType === MARKETPLACE ? ' ' : PLACEHOLDERS.CASHBACK) : [];
        const cashBackWasChanged = previousCashBack.value > cashBack.value || !isNewCustomer;
        const hasCustomBackground = (cashBackWasChanged || showZeroCashback) && pageType !== PAGE_TYPES.MARKETPLACE;

        return (
            <StyledWrapper
                data-e2e='dp_cacheBadge-box'
                $hasCustomBackground={hasCustomBackground}
                $customBackgroundColor={backgroundColor}
                $withRedBackground={cashBackWasChanged || showZeroCashback}
            >
                <Button onClick={onClick} type='unstyled'>
                    {pending ? (
                        <CashbackLoader />
                    ) : (
                        <StyledTextWrapper data-e2e='dp_cacheBadge-text'>
                            {(pageType === PDP || pageType === THANK_YOU || pageType === CHECKOUT) && (
                                <BadgeTextPdp
                                    pageType={pageType}
                                    publisherName={publisherName}
                                    badgeTextArray={badgeTextArray}
                                    showZeroCashback={showZeroCashback}
                                />
                            )}
                            {pageType === MARKETPLACE && (
                                <BadgeTextMarketplace publisherName={publisherName} badgeTextArray={badgeTextArray} />
                            )}
                        </StyledTextWrapper>
                    )}
                    {((pageType === PDP && cashBack.value) ||
                        pageType === THANK_YOU ||
                        pageType === MARKETPLACE ||
                        pageType === CHECKOUT) && <ArrowChevronForwardIcon />}
                </Button>
            </StyledWrapper>
        );
    })
);

export default CashBackBadge;
