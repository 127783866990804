import { Typography } from '@klickly/front-packages';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
`;

export const StyledTitle = styled(Typography.Title)`
    margin: 16px 0 4px;
    font-weight: 500;
`;
