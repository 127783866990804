export class Cookie {
    static get(name) {
        const matches = document.cookie.match(
            new RegExp(
                // eslint-disable-next-line
            '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
            )
        );
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    static set(name, value, options = {}) {
        options = {
            path: '/',
            secure: true,
            sameSite: 'none',
            ...options
        };

        if (!options.expires) {
            const date = new Date();
            date.setDate(date.getDate() + 365);
            options.expires = date.toUTCString();
        } else if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

        for (const k in options) {
            updatedCookie += '; ' + k;

            const value = options[k];

            if (value !== true) {
                updatedCookie += '=' + value;
            }
        }

        document.cookie = updatedCookie;
    }

    static remove(name) {
        Cookie.set(name, '', { expires: new Date(0) });
    }
}
