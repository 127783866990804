import styled, { css } from 'styled-components';
import Typography from '../Typography';

export const CouponWrap = styled.div`
    display: flex;
    flex-direction: ${({ $align }) => ($align === 'center' ? 'column' : 'row')};
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-width: 2px;
    border-color: ${({ $primary, $theme }) => ($primary ? $theme.colors.primary : $theme.colors.primaryText)};
    border-style: dashed;
    cursor: ${({ $pointer }) => ($pointer ? 'pointer' : 'default')};

    ${({ $status, $theme }) =>
        $status === 'deleted'
            ? css`
                  border: none;
                  background-color: ${$theme.colors.background};
                  cursor: auto;
              `
            : ''};

    ${({ $status }) =>
        $status === 'expired' || $status === 'redeemed'
            ? css`
                  cursor: default;
                  opacity: 0.5;
              `
            : ''};
`;

export const CouponContent = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
`;

export const CouponButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const StyledText = styled(Typography.Text)`
    padding: 12px 0;
`;

export const CouponExpiredTextWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const BottomContentWrap = styled.div`
    padding: 0 24px 20px;
`;
