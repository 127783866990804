import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { ArrowChevronDownIcon, CheckIcon } from '../../../icons';
import Icon from '../Icon';
import { KliThemeContext } from '../KliThemeProvider';
import Label from './components/Label';
import { StyledOption, StyledSelect } from './styled';
// import { device } from '../../../utils';
// import NativeSelect from './components/NativeSelect';

// eslint-disable-next-line react/display-name
const Select = forwardRef(
    (
        {
            value: inputValue = '',
            onChange = () => null,
            options,
            allowClear = false,
            size,
            label,
            error: selectError = false,
            native = false,
            ...props
        },
        ref
    ) => {
        const [value, setValue] = useState('');
        const [error, setError] = useState(false);
        const theme = useContext(KliThemeContext);

        const handleOnChange = (v) => {
            setValue(typeof v === 'object' ? v.target.value : v);
            setError(false);
            return onChange(typeof v === 'object' ? v.target.value : v);
        };

        useEffect(() => {
            if (!value && selectError) setError(selectError);
        }, [selectError, value]);

        useEffect(() => {
            if (inputValue !== value) setValue(inputValue);
        }, [inputValue]);

        /*
         * TODO: [12-06-2022] Could be removed (also NativeSelect component) after release
         *  Or could be used if some issues will occur on IOS */
        // const isMobileDevice = device.isMobile();
        // const isAndroid = device.Android();
        // if ((native || isMobileDevice) && !isAndroid) {
        //     return (
        //         <NativeSelect
        //             value={value}
        //             onChange={handleOnChange}
        //             size={size}
        //             label={label}
        //             ref={ref}
        //             options={options || []}
        //             error={error}
        //             {...props}
        //         />
        //     );
        // }

        return (
            <Label label={label} value={value} theme={theme} size={size}>
                <StyledOption $theme={theme} />
                <StyledSelect
                    ref={ref}
                    value={value}
                    onChange={handleOnChange}
                    $theme={theme}
                    size={size}
                    getPopupContainer={(node) => node}
                    suffixIcon={
                        <Icon
                            style={{ pointerEvents: 'none' }}
                            size='24px'
                            component={() => <ArrowChevronDownIcon />}
                        />
                    }
                    $error={error}
                    {...props}
                >
                    {options.map((option, index) => (
                        <StyledSelect.Option
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                            data-e2e={`cancel-reason-option ${index}`}
                        >
                            <span>{option.label}</span>
                            <Icon className='checked' component={() => <CheckIcon />} />
                        </StyledSelect.Option>
                    ))}
                </StyledSelect>
            </Label>
        );
    }
);

export default Select;
