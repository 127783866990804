import React from 'react';
import { observer } from 'mobx-react';
import { WriteReview } from './components/WriteReview';
import { StyledModal, Styles } from './styles';

export const WriteReviewModal = observer(({ onModalClose, simple, store }) => {
    if (typeof store.useProductReviewNotification === 'function') {
        store.useProductReviewNotification(store.notifications?.PRODUCT_REVIEW[4]);
    }
    return (
        <Styles simple={simple}>
            <StyledModal />
            <WriteReview simple={simple} onModalClose={onModalClose} />
        </Styles>
    );
});
