export const getDiscount = (bags) => {
    const discountInUsd = bags.reduce(
        (result, bag) =>
            result + bag.priceDetails.discounts + (bag.priceDetails.originalSubTotal - bag.priceDetails.subTotal),
        0
    );
    const discountInPercents = Math.round(
        discountInUsd / (bags.reduce((result, bag) => result + bag.priceDetails.originalSubTotal, 0) / 100)
    );

    return { discountInUsd, discountInPercents };
};
