import { createGlobalStyle, css } from 'styled-components';

export const StyledForm = createGlobalStyle`
    ${({ $theme }) => css`
        .ant-form-item-explain-error {
            margin-top: -4px;
            font: ${$theme.fonts.descriptor2};
            color: ${$theme.colors.error};
        }

        .ant-form-item-has-error {
            *,
            .label.label-float {
                color: ${$theme.colors.error};
            }

            .ant-select-dropdown * {
                color: ${$theme.colors.secondaryText};
            }

            &:has(.ant-select-status-error) {
                .label {
                    color: ${$theme.colors.error};

                    &.label-float {
                        color: ${$theme.colors.secondaryText};
                    }
                }

                .ant-select-selection-item > span {
                    color: ${$theme.colors.primaryText};
                }
            }

            &:has(.ant-input:focus) .label.label-float {
                color: ${$theme.colors.secondaryText};
            }

            .ant-input-suffix {
                .ant-input-clear-icon * {
                    color: ${$theme.colors.error};
                }
            }

            .ant-form-item-control-input-content {
                .ant-input-affix-wrapper {
                    border-color: ${$theme.colors.error};
                }
            }

            .ant-form-item-feedback-icon.ant-form-item-feedback-icon-error {
                display: none;
            }

            .ant-input-suffix > :not(:last-child) {
                margin-right: 0;
            }

            .ant-form-item-control-input-content {
                .ant-input-affix-wrapper.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
                    border-color: ${$theme.colors.error};
                }
            }
        }
    `};
`;
