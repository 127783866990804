import React, { useContext } from 'react';
import { KliThemeContext } from '../KliThemeProvider';
import { StyledDivider } from './styled';

const Divider = ({ height = 1, color, margin }) => {
    const theme = useContext(KliThemeContext);
    return <StyledDivider $theme={theme} $height={height} $color={color} $margin={margin} />;
};

export default Divider;
