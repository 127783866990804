import React from 'react';
import { Provider } from 'mobx-react';
import store from '../../_store/RootStore';
import CategoryPageHeader from './components/CategoryPageHeader';

const PageHeader = ({ onBuy }) => {
    return (
        <Provider store={store}>
            <CategoryPageHeader onBuy={onBuy} />
        </Provider>
    );
};

export default PageHeader;
