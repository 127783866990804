import styled from 'styled-components';
import { Button } from '../../../UiLibrary';
import { $white } from '../../styles/colors';

export const StyledWrapper = styled.div`
    position: absolute;
    bottom: -12px;
    width: 100%;
    height: 0;
    z-index: 3;
`;

export const StyledContent = styled.div`
    padding: 16px 24px 24px;
    background: ${$white};
    box-shadow: 0 8px 48px rgba(0, 0, 0, 0.16), 0 4px 8px rgba(0, 0, 0, 0.12);
`;

export const StyledSuggestion = styled(Button)`
    && {
        display: block;
        width: 100%;
        padding: 0 0 16px 0;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
            padding: 0;
        }

        &:hover:not(:disabled) {
            color: inherit;
        }
    }
`;
