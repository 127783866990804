import { flow, getParent, types } from 'mobx-state-tree';
import { kcpAPI } from '../shared/constants';
import { models } from './constants';

export const CartStore = types
    .model('CartStore', {
        pending: types.boolean,
        error: models.ErrorModel
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => ({
        setError(error) {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }

            return false;
        },
        handleBuyNow: flow(function* handleBuyNow(payload) {
            self.pending = true;
            try {
                const data = yield self.root.post(kcpAPI.cart.buyNow, payload);
                return { cartId: data.cartId, checkoutKey: data.checkoutKey };
            } catch (error) {
                self.setError(error);
            } finally {
                self.pending = false;
            }
        }),
        creditCardVerification: flow(function* creditCardVerification({ last4, customerId }) {
            self.pending = true;
            const publisherId = self.root.publisherStore.publisherId;
            try {
                const response = yield self.root.post(kcpAPI.publishers.creditCardVerification(publisherId), {
                    last4,
                    customerId
                });

                return { email: response.email };
            } catch (error) {
                self.setError(error);
            } finally {
                self.pending = false;
            }
        })
    }));
