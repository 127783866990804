import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ImageIcon } from '../../../../../../../../../../icons';
import { Carousel, Typography } from '../../../../../../../../../UiLibrary';
import { GRID_POINTS } from '../../../../../../../../constants';
import { getVariantImage } from '../../../../../../../../utils';
import StyledIcon from './styled/StyledIcon';
import StyledImage from './styled/StyledImage';
import StyledImgWrapper from './styled/StyledImgWrapper';
import StyledSliderWrap from './styled/StyledSliderWrap';
import StyledViewGalleryButton from './styled/StyledViewGalleryButton';
import { useGalleryModal } from './hooks';

const ProductImage = inject('store')(
    observer(({ store }) => {
        const [mainImageIndex, setMainImageIndex] = useState(0);
        const simple = store.view.isSimple(GRID_POINTS.sm);
        const appWidth = store.view.appWidth;
        const images = store.product.getData('images', []);
        const selectedVariant = store.product.variants.selectedVariant;
        const carouselRef = useRef(null);

        useEffect(() => {
            const { index } = getVariantImage(images, selectedVariant);
            setMainImageIndex(index || 0);
            if (carouselRef?.current) carouselRef.current.goTo(index || 0);
        }, [selectedVariant]);

        const openGallery = useGalleryModal(simple);

        const showDots = images && images.length && images.length < 10;

        return (
            <StyledImgWrapper $appWidth={appWidth}>
                {!simple ? (
                    <StyledSliderWrap>
                        <Carousel.WithPreview
                            afterChange={(index) => setMainImageIndex(index)}
                            carouselRef={carouselRef}
                            previews={
                                Boolean(images && images.length) &&
                                images.map((image, key) => (
                                    <StyledImage
                                        $appWidth={appWidth}
                                        key={key}
                                        src={image.src}
                                        data-e2e='option-value'
                                    />
                                ))
                            }
                        >
                            {Boolean(images && images.length) &&
                                images.map((image, key) => (
                                    <StyledImage
                                        $appWidth={appWidth}
                                        key={key}
                                        src={image.src}
                                        data-e2e='option-value'
                                        onClick={() => openGallery(images, key)}
                                    />
                                ))}
                        </Carousel.WithPreview>
                    </StyledSliderWrap>
                ) : (
                    <StyledSliderWrap $appWidth={appWidth}>
                        <Carousel
                            afterChange={(index) => setMainImageIndex(index)}
                            dots={showDots ? { bottom: '-4px' } : false}
                            arrows
                            carouselRef={carouselRef}
                        >
                            {Boolean(images && images.length) &&
                                images.map((image, key) => (
                                    <StyledImage
                                        $appWidth={appWidth}
                                        key={key}
                                        src={image.src}
                                        onClick={() => openGallery(images, key)}
                                    />
                                ))}
                        </Carousel>
                        <StyledViewGalleryButton type='unstyled' onClick={() => openGallery(images, mainImageIndex)}>
                            <StyledIcon size='16px' marginRight='6px' component={() => <ImageIcon />} />
                            <Typography.Text type='badgeSmall'>View gallery</Typography.Text>
                        </StyledViewGalleryButton>
                    </StyledSliderWrap>
                )}
            </StyledImgWrapper>
        );
    })
);

export default ProductImage;
