export const GRID_POINTS = Object.freeze({
    xxs: 370,
    xs: 480,
    sm: 680,
    md: 890,
    lg: 950,
    xl: 1100,
    xxl: 1600
});

export const GRID_DEVICES_MAX = Object.freeze({
    xxs: `max-width: ${GRID_POINTS.xxs}px`,
    xs: `max-width: ${GRID_POINTS.xs}px`,
    sm: `max-width: ${GRID_POINTS.sm}px`,
    md: `max-width: ${GRID_POINTS.md}px`,
    lg: `max-width: ${GRID_POINTS.lg}px`,
    xl: `max-width: ${GRID_POINTS.xl}px`,
    xxl: `max-width: ${GRID_POINTS.xxl}px`
});
