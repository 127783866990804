import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { BOTTOM_INDENTS } from '../../../../../../../../../../../../constants';
import { roundToLocaleString } from '../../../../../../../../../../../../shared';
import { Typography } from '../../../../../../../../../../../UiLibrary';
import CashBackInfo from './components/CashBackInfo';
import EarnedPoints from './components/EarnedPoints';
import RewardsApplied from './components/RewardsApplied';
import TotalPrice from './components/TotalPrice';
import { PriceRowWrap, Styles } from './styles';
import { geEntityFromStoreByPath, getPrices } from './utils';

const PriceRow = observer(({ title, price, type = 'default' }) => {
    const textType = type === 'deal' ? 'badgeMedium' : 'body2';
    return (
        <PriceRowWrap style={BOTTOM_INDENTS.SM} type={type}>
            <Typography.Text type={textType}>{title}</Typography.Text>
            <Typography.Text type={textType}>{price}</Typography.Text>
        </PriceRowWrap>
    );
});

export const Prices = inject('store')(
    observer(({ store }) => {
        const originalPriceLabel = geEntityFromStoreByPath(
            store.view.customizations,
            'prices.originalPriceLabel',
            'Market price'
        );
        const discountLabel = geEntityFromStoreByPath(store.view.customizations, 'prices.discountLabel', 'You save');
        const priceLabel = geEntityFromStoreByPath(store.view.customizations, 'prices.dealPriceLabel', 'You pay');
        const showPointsRow = geEntityFromStoreByPath(store.view.customizations, 'points.showPoints', false);

        const { originalPrice, discount, price, showOriginalPrice } = getPrices(store.product.prices);
        const variant = store.product.variants.currentVariantData;

        return (
            <Styles style={BOTTOM_INDENTS.SM}>
                {showOriginalPrice && originalPrice && (
                    <Fragment>
                        <PriceRow
                            title={originalPriceLabel}
                            price={
                                !store.view.customizations.rewards?.showRewards
                                    ? originalPrice
                                    : `$${roundToLocaleString(
                                          variant?.priceDetails?.originalPrice * store.product.variants.qty
                                      )}`
                            }
                        />
                    </Fragment>
                )}
                {discount && !store.view.customizations.rewards?.showRewards && (
                    <Fragment>
                        <PriceRow title={discountLabel} price={discount} />
                    </Fragment>
                )}
                {store.view.customizations.rewards?.showRewards ? (
                    <Fragment>
                        <RewardsApplied />
                        <TotalPrice />
                        {store.view.customizations.cashBack?.showCashBack && <CashBackInfo />}
                    </Fragment>
                ) : (
                    <Fragment>
                        <PriceRow type='deal' title={priceLabel} price={price} />
                    </Fragment>
                )}
                {showPointsRow ? <EarnedPoints /> : null}
                <Typography.Text color='secondaryText' type='descriptor2'>
                    Shipping and taxes are calculated at checkout
                </Typography.Text>
            </Styles>
        );
    })
);
