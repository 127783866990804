import { loadGA4 } from './ga4';
import { loadTW } from './tw';

export const loadPixels = (pixels = [], options = {}) => {
    pixels.forEach((pixel) => {
        if (pixel.name === 'ga4') {
            loadGA4(pixel, options.ga);
        }
        if (pixel.name === 'tw') {
            loadTW(pixel, options.tw);
        }
    });
};
