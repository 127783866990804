import React, { useContext } from 'react';
import { Dropdown } from 'antd';
import { KliThemeContext } from '../../../KliThemeProvider';
import { StyledDropdown } from './styled';

const DropdownMenu = ({ items, children, menuMaxHeight, ...props }) => {
    const theme = useContext(KliThemeContext);
    return (
        <Dropdown
            menu={{ items }}
            trigger={['click']}
            placement='bottomRight'
            dropdownRender={(menus) => (
                <StyledDropdown $theme={theme} $menuMaxHeight={menuMaxHeight}>
                    {menus}
                </StyledDropdown>
            )}
            {...props}
        >
            {children}
        </Dropdown>
    );
};

export default DropdownMenu;
