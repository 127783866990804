import React from 'react';
import { useSimpleInterface } from '@klickly/front-packages';
import { GRID_POINTS } from '../../../../constants';
import { roundCashbackToLocaleString } from '../../../../utils';
import { StyledBadge, StyledPointsCount } from './styled';

function CashbackBadge({ cashback }) {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    if (!cashback) return null;

    return (
        <StyledBadge $isMobile={simple} color='primary'>
            <StyledPointsCount data-e2e='cashback-badge' type='description1' as='span'>
                ${roundCashbackToLocaleString(cashback)} back
            </StyledPointsCount>
        </StyledBadge>
    );
}

export default CashbackBadge;
