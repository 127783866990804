import { Checkbox } from 'antd';
import styled, { css } from 'styled-components';

export const StyledAntdCheckbox = styled(Checkbox)`
    && {
        ${({ $theme, $size, $type, $checkedColor }) => css`
            &.ant-checkbox-wrapper {
                display: flex;
                align-items: center;

                & .ant-checkbox-checked:after,
                &:hover .ant-checkbox-inner {
                    border-color: ${$theme.colors[$checkedColor]
                        ? $theme.colors[$checkedColor]
                        : $theme.colors.primaryText};
                }

                & .ant-checkbox,
                & .ant-checkbox-inner,
                & .ant-checkbox-input {
                    border-width: 2px;
                }

                &,
                &:focus,
                &:active {
                    & .ant-checkbox,
                    & .ant-checkbox-inner,
                    & .ant-checkbox-input {
                        border-color: ${$theme.colors.primaryText};
                    }
                }
            }

            & .ant-checkbox {
                top: 0;

                & + span:not(.kli-text) {
                    color: ${$theme.colors.primaryText};
                    font: ${$theme.fonts.body2};
                }
            }

            // size
            & .ant-checkbox,
            & .ant-checkbox-inner {
                width: ${() => $size};
                height: ${() => $size};
            }

            & .ant-checkbox-inner {
                &:after {
                    width: ${() => `calc(${$size} / 4.3)`};
                    height: ${() => `calc(${$size} / 2.3)`};
                    left: 29.5%;
                }
            }

            & .ant-checkbox-checked .ant-checkbox-inner {
                background-color: ${$theme.colors[$checkedColor]
                    ? $theme.colors[$checkedColor]
                    : $theme.colors.primaryText};
                border-color: ${$theme.colors[$checkedColor]
                    ? $theme.colors[$checkedColor]
                    : $theme.colors.primaryText};
            }

            // type = 'rounded'
            ${() =>
                $type === 'rounded'
                    ? css`
                          & .ant-checkbox,
                          & .ant-checkbox-inner,
                          & .ant-checkbox-checked:after {
                              border-radius: 50%;
                          }
                      `
                    : ''}
        `};
    }
`;
