import React, { Fragment, useEffect, useRef, useState } from 'react';
import { BOTTOM_INDENTS, dynamicFontSize, Icon, Typography, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { GRID_POINTS } from '../../../../../../shared/constants';
import { get } from '../../../../../../shared/utils';
import { StyledBannerText, StyledBannerWrap, StyledImage, StyledWrap } from './styled';

export const StrategyBlock = inject('store')(
    observer(({ store }) => {
        const {
            getStrategyInfoById,
            getStrategyInfoByName,
            clearStrategyInfo,
            strategyInfo,
            strategyPending,
            bannerTextSizePending,
            setBannerSizePending
        } = store.marketplaceStore;
        const simple = useSimpleInterface(GRID_POINTS.md);
        const tablet = useSimpleInterface(GRID_POINTS.xl);
        const bannerRef = useRef(null);
        const [fontSize, setFontSize] = useState(simple ? 48 : 56);
        const { strategy } = store.publisherStore;
        const displayName = get(strategyInfo, 'styles.displayName', false);
        const strategyName = get(strategyInfo, 'name', '');
        const bannerImage = get(strategyInfo, 'styles.bannerImage', null);
        const bannerText = get(strategyInfo, 'styles.bannerText', '');

        useEffect(() => {
            if (strategy.strategyName) {
                getStrategyInfoByName(strategy.strategyName);
            }

            if (strategy.strategyId) {
                getStrategyInfoById(strategy.strategyId);
            }

            return () => clearStrategyInfo();
        }, [strategy]);

        useEffect(() => {
            if (bannerRef.current) {
                dynamicFontSize(bannerRef, setFontSize, fontSize, bannerText, simple, setBannerSizePending);
            }
        }, [bannerRef.current, bannerText, fontSize]);

        if (!strategy) return null;

        if (strategyPending) {
            return (
                <StyledBannerWrap>
                    <Icon.Loader />
                </StyledBannerWrap>
            );
        }

        return (
            <StyledWrap style={bannerImage || displayName ? BOTTOM_INDENTS.M : null}>
                {bannerImage ? (
                    <StyledBannerWrap ref={bannerRef}>
                        {bannerTextSizePending && bannerText ? (
                            <Icon.Loader />
                        ) : (
                            <Fragment>
                                <StyledImage src={bannerImage} alt={bannerText} />
                                <StyledBannerText
                                    $tablet={tablet}
                                    $fontSize={`${fontSize}px`}
                                    level={1}
                                    color='canvas'
                                    $simple={simple}
                                >
                                    {bannerText}
                                </StyledBannerText>
                            </Fragment>
                        )}
                    </StyledBannerWrap>
                ) : null}
                {displayName ? (
                    <Typography.Title style={{ ...BOTTOM_INDENTS.XS }} level={2}>
                        {strategyName}
                    </Typography.Title>
                ) : null}
            </StyledWrap>
        );
    })
);
