import { Radio } from 'antd';
import styled, { css } from 'styled-components';

const getDefaultRadioStyles = ({ $theme, $size, $checkedColor }) => css`
    &.ant-radio-wrapper {
        display: flex;
        align-items: center;

        & .ant-radio-checked:after,
        &:not(.ant-radio-wrapper-disabled):hover .ant-radio-inner {
            border-color: ${$theme.colors[$checkedColor] ? $theme.colors[$checkedColor] : $theme.colors.primaryText};
        }

        & .ant-radio,
        & .ant-radio-inner,
        & .ant-radio-input {
            border-width: 2px;
        }

        &,
        &:focus,
        &:not(.ant-radio-wrapper-disabled),
        &:not(.ant-radio-wrapper-checked):active {
            & .ant-radio,
            & .ant-radio-inner,
            & .ant-radio-input {
                border-color: ${$theme.colors.primaryText};
            }
        }

        & .ant-radio-checked {
            border-color: ${$theme.colors[$checkedColor] ? $theme.colors[$checkedColor] : $theme.colors.primaryText};

            & .ant-radio-inner {
                background-color: ${$theme.colors[$checkedColor]
                    ? $theme.colors[$checkedColor]
                    : $theme.colors.primaryText};
                border-color: ${$theme.colors[$checkedColor]
                    ? $theme.colors[$checkedColor]
                    : $theme.colors.primaryText};
            }
        }
    }

    &.ant-radio-wrapper-disabled {
        opacity: 0.6;
    }

    &.ant-radio-wrapper-checked,
    & .ant-radio-checked {
        &:focus {
            border-color: transparent;
            color: transparent;
        }
    }

    & .ant-radio {
        top: 0;

        & + span:not(.kli-text) {
            color: ${$theme.colors.primaryText};
            font: ${$theme.fonts.body2};
        }
    }

    // size
    & .ant-radio,
    & .ant-radio-inner {
        width: ${() => $size};
        height: ${() => $size};
    }

    & .ant-radio-inner {
        &:after {
            width: ${() => $size};
            height: ${() => $size};
            background-color: ${$theme.colors.canvas};
            transform: translate(-50%, -50%) scale(0.5);
            margin: 0;
        }
    }

    & .ant-radio,
    & .ant-radio-input,
    & .ant-radio-inner {
        outline: none;
        box-shadow: none;
    }
`;

export const getButtonRadioStyles = ({ $theme }) => css`
    &.ant-radio-button-wrapper {
        &,
        &:focus {
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid ${$theme.colors.secondaryText};
            color: ${$theme.colors.primaryText};
            background-color: transparent;
        }

        & .ant-radio-button {
            font: ${$theme.fonts.body2};
        }

        &:before {
            background-color: ${$theme.colors.primaryText};
        }

        &-checked,
        &:not(.ant-radio-button-wrapper-disabled):hover,
        &:not(.ant-radio-button-wrapper-disabled):active {
            color: ${$theme.colors.primaryText};
            border: 2px solid ${$theme.colors.primaryText};
            opacity: 1;
            outline: none;
        }

        &-checked {
            &:not(.ant-radio-button-wrapper-disabled):before {
                background-color: ${$theme.colors.primaryText};
            }

            &:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
                border-right-color: ${$theme.colors.primaryText};
            }
        }
    }

    &.ant-radio-button-wrapper-disabled {
        opacity: 0.6;
    }
`;

export const getSolidButtonRadioStyles = ({ $theme }) => css`
    &.ant-radio-button-wrapper {
        &,
        &:focus {
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid ${$theme.colors.primaryText};
            color: ${$theme.colors.primaryText};
            background-color: transparent;
        }

        & .ant-radio-button {
            font: ${$theme.fonts.body2};
        }

        &:before {
            background-color: ${$theme.colors.primaryText};
        }

        &-checked,
        &:not(.ant-radio-button-wrapper-disabled):hover,
        &:not(.ant-radio-button-wrapper-disabled):active {
            color: ${$theme.colors.canvas};
            border: 2px solid ${$theme.colors.primaryText};
            background-color: ${$theme.colors.primaryText};
            opacity: 1;
            outline: none;
        }

        &-checked {
            &:not(.ant-radio-button-wrapper-disabled):before {
                background-color: ${$theme.colors.primaryText};
            }

            &:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
                border-right-color: ${$theme.colors.primaryText};
            }
        }
    }

    &.ant-radio-button-wrapper-disabled {
        opacity: 0.6;
    }
`;

export const StyledAntdRadio = styled(Radio)`
    ${({ $theme, $size, $checkedColor }) => getDefaultRadioStyles({ $theme, $size, $checkedColor })};
`;

export const StyledAntdRadioButton = styled(Radio.Button)`
    ${({ $theme, $checkedColor }) => getButtonRadioStyles({ $theme, $checkedColor })};
`;

export const StyledAntdRadioGroup = styled(Radio.Group)`
    & {
        ${({ $direction }) =>
            $direction === 'horizontal'
                ? css`
                      display: inline-flex;
                  `
                : css`
                      display: inline-block;

                      & .ant-radio-wrapper,
                      & .ant-radio-button-wrapper {
                          margin-bottom: 14px;
                      }
                  `}
    }

    & .ant-radio-wrapper {
        ${({ $theme, $size, $checkedColor }) => getDefaultRadioStyles({ $theme, $size, $checkedColor })};
    }

    &:not(.ant-radio-group-solid) .ant-radio-button-wrapper {
        ${({ $theme }) => getButtonRadioStyles({ $theme })};
    }

    &.ant-radio-group-solid .ant-radio-button-wrapper {
        ${({ $theme }) => getSolidButtonRadioStyles({ $theme })};
    }
`;
