import { flow, getParent, types } from 'mobx-state-tree';

const reviewModel = types.model({
    _id: types.identifier,
    customerFullName: types.string,
    customer: types.maybe(types.string),
    createdAt: types.maybe(types.string),
    rating: types.number,
    title: types.maybe(types.string),
    content: types.maybe(types.string),
    images: types.maybe(
        types.array(
            types.model({
                position: types.number,
                src: types.string
            })
        )
    )
});

const paginationMetaModel = types.model({
    currentPage: types.number,
    firstPage: types.number,
    hasNextPage: types.boolean,
    hasPreviousPage: types.boolean,
    lastPage: types.number,
    nextPage: types.number,
    previousPage: types.number,
    totalDocuments: types.number,
    totalPages: types.number
});

export const ReviewsStore = types
    .model('ReviewsStore', {
        isLoading: false,
        isWriting: false,
        activeReviewId: types.maybeNull(types.string),
        meta: types.maybeNull(paginationMetaModel),
        data: types.maybeNull(types.array(reviewModel)),
        page: 1,
        totalPages: 1,
        errorMessage: types.maybeNull(types.string),
        limit: 3
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get loading() {
            return self.isLoading;
        },
        get writing() {
            return self.isWriting;
        },
        get reviews() {
            return self.data ? self.data : null;
        },
        getActiveReview() {
            return self.activeReviewId ? self.data.find((review) => review._id === self.activeReviewId) : null;
        }
    }))
    .actions((self) => ({
        markLoading(loading) {
            self.isLoading = loading;
        },
        markWriting(writing) {
            self.isWriting = writing;
        },
        loadReviews: flow(function* loadReviews(isNextPage) {
            self.markLoading(true);
            if (self.data && self.data?.length === 3) {
                self.data = null;
                self.limit = 10;
            }
            if (self.data && isNextPage && self.page !== self.totalPages) {
                self.page = self.page + 1;
            }
            try {
                const response = yield self.root.callbacks.onReviewsFetch(`?page=${self.page}&limit=${self.limit}`);
                self.meta = response.meta;
                self.totalPages = response.meta.totalPages;
                self.data = (self.data || []).concat(response.data);
            } catch (err) {
                console.error("Failed to load ", err); // eslint-disable-line
            } finally {
                self.markLoading(false);
            }
        }),
        // eslint-disable-next-line promise/prefer-await-to-callbacks
        writeReview: flow(function* writeReview(fd, callback, submitChallenge) {
            self.errorMessage = null;

            try {
                submitChallenge();
                fd.append('promotion', self.root.product.getData('_id'));
                fd.append('account', self.root.product.getData('account'));
                fd.append('externalProductId', self.root.product.getData('externalId'));

                self.markWriting(true);

                yield self.root.callbacks.onReviewsWrite(fd);
                // eslint-disable-next-line promise/prefer-await-to-callbacks
                callback();
            } catch (error) {
                self.errorMessage = error.message;
            } finally {
                self.markWriting(false);
            }
        }),
        showReview(id) {
            self.activeReviewId = id;
            self.root.view.setRoute('review');
        },
        backToPDP() {
            self.activeReviewId = null;
            self.root.view.setRoute('pdp');
        }
    }));
