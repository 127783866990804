import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Icon } from '@klickly/front-packages';
import { ErrorBoundary } from './components/ErrorBoundary';
import { App } from './App';
import { GlobalStyle } from './App.styles';
import { routes } from './routes';

const root = createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    {
        element: <App />,
        children: routes
    }
]);

root.render(
    <ErrorBoundary errorView={<div>Error</div>}>
        <GlobalStyle />
        <Suspense fallback={<Icon.Loader />}>
            <RouterProvider router={router} />
        </Suspense>
    </ErrorBoundary>
);
