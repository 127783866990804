import React, { useCallback } from 'react';
import { useModal as useAntdModal, useSimpleInterface } from '@klickly/front-packages';
import Modal from '../components/Modal';
import { GRID_POINTS } from '../constants';

export const useModal = (options = {}) => {
    const simple = useSimpleInterface(GRID_POINTS.sm);
    const { setModal, hideModal } = useAntdModal();
    const { modalOptions } = options;

    const openModal = useCallback(
        ({ title, children }) => {
            setModal(
                <Modal onClose={hideModal} title={title} options={options} simple={simple}>
                    {children}
                </Modal>,
                {
                    fullscreen: simple,
                    closable: false,
                    ...modalOptions
                }
            );
        },
        [setModal, hideModal, simple]
    );

    return { openModal, closeModal: hideModal };
};
