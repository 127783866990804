import { useContext } from 'react';
import { LayoutContext } from '../constants/defaultLayoutContext';
import { useScrollable } from './useScrollable';

export const useLayout = () => {
    const { headerRef, sideRef, bodyRef, contentRef, wrapRef } = useContext(LayoutContext);
    return {
        headerRef,
        sideRef,
        bodyRef,
        contentRef,
        wrapRef
    };
};

export const useLayoutOnBottomHandler = (handler) => {
    const { bodyRef } = useLayout();
    useScrollable(bodyRef, null, handler);
};
