import { css } from 'styled-components';
import styled from 'styled-components/macro';

export default styled.div`
    display: flex;
    position: relative;

    ${({ simple }) =>
        simple
            ? css`
                  display: block;
              `
            : ''};
`;
