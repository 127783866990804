import React, { forwardRef } from 'react';
import InputNumber from '../InputNumber';

// eslint-disable-next-line react/display-name
const InputCurrency = forwardRef(
    ({ currency = 'USD', locales = 'en-us', minimumFractionDigits = 0, ...props }, ref) => {
        const formatter = (value) => {
            return new Intl.NumberFormat(locales, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: minimumFractionDigits
            }).format(value);
        };

        const parser = (value) => {
            return String(value).replace(/[^0-9.]/g, '');
        };

        return <InputNumber ref={ref} {...props} type='text' formatter={formatter} parser={parser} />;
    }
);

export default InputCurrency;
