import { BrowserStorage } from '@klickly/front-packages';
import { useCustomerId } from '../../../../../shared/hooks';

export const useMetaData = ({ publisherId, creditCardsLast4, irclickid }) => {
    const customerId = useCustomerId();

    const metadata = [
        {
            name: 'klpixel',
            data: {
                cid: BrowserStorage.get('_klpixel_cid'),
                hash: BrowserStorage.get('_klpixel_hash')
            }
        },
        {
            name: 'location',
            value: window.location.href
        },
        {
            name: 'publisherId',
            value: publisherId
        },
        {
            name: 'userId',
            value: customerId
        }
    ];

    if (creditCardsLast4) {
        metadata.push({
            name: 'creditCardsLast4',
            value: creditCardsLast4
        });
    }

    if (irclickid) {
        metadata.push({
            name: 'irclickid',
            value: irclickid
        });
    }

    return metadata;
};
