import React from 'react';
import { EmojiSadFrownIcon } from '../../../../../../../../../../../../icons';
import { Typography } from '../../../../../../../../../../../UiLibrary';
import { StyledErrorField, StyledIcon } from './styled';

const ErrorField = ({ error, showIcon = false, icon = <EmojiSadFrownIcon />, textType = 'descriptor2' }) => {
    return (
        <StyledErrorField>
            {showIcon && <StyledIcon color='error' size='130%' component={() => icon} />}
            <Typography.Text color='error' type={textType}>
                {error}
            </Typography.Text>
        </StyledErrorField>
    );
};

export default ErrorField;
