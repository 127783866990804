import { Button } from '@klickly/front-packages';
import styled from 'styled-components/macro';
import { GRID_DEVICES_MAX } from '../../../../../../shared/constants';

export const Styles = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    position: relative;
`;

export const CloseCheckoutButtonWrap = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
`;

export const WrapStyles = styled.div`
    width: 100%;
    height: calc(100% - 50px);
`;

export const StyledButton = styled(Button)`
    && {
        padding: 0 !important;

        @media (${GRID_DEVICES_MAX.md}) {
            padding-left: 24px !important;
        }
    }
`;
