export const getRewardsData = (bags) =>
    bags.reduce(
        (result, bag) => {
            const rewardDataArray = bag.items.map((item) => ({
                ...(item.metadata.find((metaItem) => metaItem.name === 'rewardsDetails')?.value || {}),
                qty: item.priceDetails.qty
            }));
            return {
                rewards: [
                    ...result.rewards,
                    ...rewardDataArray.reduce((res, rewardItem) => [...res, ...(rewardItem?.rewards || [])], [])
                ],
                priceDetails: [
                    ...result.priceDetails,
                    ...rewardDataArray.map((rewardItem) => ({
                        ...rewardItem.priceDetails,
                        qty: rewardItem.qty
                    }))
                ]
            };
        },
        { rewards: [], priceDetails: [] }
    );
