import React, { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import { CircleActionsAlertInfoIcon } from '../../../../../icons';
import { KliThemeContext } from '../../../KliThemeProvider';
import ClearIcon from '../ClearIcon';
import Label from '../Label';
import { StyledInput } from './styled';
import { formatInputValueByType } from './utils';

// eslint-disable-next-line react/display-name
const BaseInput = forwardRef(
    (
        {
            type = 'text',
            value: inputValue = '',
            onChange = () => null,
            onBlur = () => null,
            allowClear = false,
            size,
            label,
            prefix,
            suffix,
            showWarningIcon,
            status: inputStatus,
            nonOpacityOnDisabled = false,
            withoutBorderColorsOnValidate = false,
            formatter = null,
            parser = null,
            trim = true,
            component: Component = StyledInput,
            ...props
        },
        ref
    ) => {
        const [value, setValue] = useState('');
        const [status, setStatus] = useState(inputStatus);
        const theme = useContext(KliThemeContext);

        const formatValue = useCallback(
            (value) => {
                const parsedVal = typeof parser === 'function' ? parser(value) : value;
                return typeof formatter === 'function' ? formatter(parsedVal) : formatInputValueByType(value, type);
            },
            [type, formatter, parser]
        );

        const trimValue = (val) => (typeof val === 'string' && trim ? val.trim() : val);

        const handleOnChange = (e) => {
            setValue(formatValue(e.target.value));
            e.target.value = formatValue(e.target.value);
            return onChange(e);
        };
        const handleOnBlur = (e) => {
            if (!inputStatus && !withoutBorderColorsOnValidate) setStatus('success');
            const event = { ...e };
            if (trim) event.target.value = trimValue(event.target.value);
            return onBlur(event);
        };

        useEffect(() => {
            if (inputStatus !== status) setStatus(inputStatus || 'success');
        }, [inputStatus]);

        useEffect(() => {
            if (inputValue !== value) setValue(inputValue);
        }, [inputValue]);

        const showAlertSuffix =
            showWarningIcon && (props['aria-invalid'] || props['aria-describedby']?.includes('help'));

        return (
            <Label label={label} value={value} theme={theme} size={size} prefix={prefix} status={status}>
                <Component
                    ref={ref}
                    type={type}
                    value={value}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    $theme={theme}
                    size={size}
                    allowClear={
                        allowClear &&
                        !showWarningIcon && {
                            clearIcon: <ClearIcon size={size} />
                        }
                    }
                    prefix={prefix}
                    suffix={showAlertSuffix ? <CircleActionsAlertInfoIcon /> : suffix}
                    status={status}
                    $nonOpacityOnDisabled={nonOpacityOnDisabled}
                    $noValidationBorders={withoutBorderColorsOnValidate}
                    {...props}
                />
            </Label>
        );
    }
);

export default BaseInput;
