import React from 'react';
import { Styles } from './styles';

export function LayoutContentListItem({ children, fullWidth, fullHeight, inSlider, ...props }) {
    return (
        <Styles $fullWidth={fullWidth} $fullHeight={fullHeight} $inSlider={inSlider} {...props}>
            {children}
        </Styles>
    );
}
